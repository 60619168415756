<template>
  <fieldset
    :id="`${props.name}-repeater`"
    ref="repeater"
    class="form-input--repeater"
    :data-test="props.name"
  >
    <legend>
      {{ props.name }}
    </legend>
    <div class="flex-col gap-md">
      <slot
        name="inputs"
      />
      <button
        type="button"
        class="form-input--repeater-button flex-row-center gap-xs"
        :aria-label="`add ${props.name}`"
        :data-test="`${props.name}-add`"
        :disabled="props.disableAdd"
        @click="add"
      >
        <AddSvg />
      </button>
      <div />
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import { useTemplateRef, nextTick } from 'vue';
import AddSvg from '../svg/add-svg.component.vue';

/**
 * The props of the repeater container component.
 * 
 * - name: the name of repeater. This is used for IDs and testing attributes.
 */
const props = defineProps<{
  name: string;
  disableAdd: boolean;
}>();

const emit = defineEmits<{
  (event: 'add'): void;
}>();

const repeater = useTemplateRef<HTMLFieldSetElement>('repeater');

function add() {
  if(props.disableAdd) {
    return;
  }

  const numOfInputsBeforeAdd = repeater.value.querySelectorAll('input').length;

  emit('add');

  // After the component has updated, focus on the new input.
  nextTick(() => {
    const repeaterInputs = repeater.value.querySelectorAll('input');
    repeaterInputs[numOfInputsBeforeAdd].focus();
  });
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.form-input--repeater {
  max-height: 30rem;
  width: 100%;
  overflow-y: auto;

  :deep(input) {
    height: 3rem;
  }

  > div {
    padding: 0.5rem;
  }
}

.form-input--repeater-button {
  background-color: transparent;
  border: none;
  align-content: center;
  color: $app-apiture-blue;
  width: fit-content;

  span {
    margin: 0;
  }

  svg {
    width: 1.33rem;
    height: 1.33rem;
  }
}

fieldset {
  border: none;
  padding: 0;
}

legend {
  text-transform: capitalize;
}
</style>
