import { accessTokenExpired$, authLogin, authLogout } from '../../services/legacy/auth.service';
import { getApiKey, getEnvValue } from '../../services/legacy/target-env.service';
import { TargetEnvLoginComponent } from '../legacy/target-env-login.component';
import { ExplorerTargetEnv } from '../../models/target-env';
import * as bootstrap from 'bootstrap';

export class SessionExpiredComponent {
  private $modalElement: HTMLElement;
  private $modal: bootstrap.Modal;
  private $modalLoginButton: HTMLElement;
  private $modalErrorMessage: HTMLElement;

  constructor(private targetEnvLoginComponent: TargetEnvLoginComponent) {}

  init(): void {
    this.$modalElement = document.querySelector('#sessionExpiredComponent');
    this.$modal = new bootstrap.Modal(this.$modalElement);
    this.$modalLoginButton = this.$modalElement.querySelector('#sessionExpiredBtn');
    this.$modalErrorMessage = this.$modalElement.querySelector('#sessionExpiredErrorMsg');

    accessTokenExpired$.subscribe(accessTokenExpired => {
      if (accessTokenExpired) {
        this.onDevPortalAccessTokenExpired();
      }
    });

    accessTokenExpired$.subscribe(accessTokenExpired => {
      if (accessTokenExpired && !accessTokenExpired) {
        this.onTargetEnvAccessTokenExpired();
      }
    });
  }

  private open(name?: string): Promise<boolean> {
    let message = 'Your session has expired.';
    if (name) {
      message = `Your session for ${name} environment has expired.`;
    }

    this.$modalErrorMessage.textContent = message;
    this.$modal.show();

    return new Promise(resolve => {
      let resolved = false;

      this.$modalElement.addEventListener('hidden.bs.modal', () => {
        if (!resolved) {
          resolve(false);
        }
      });

      this.$modalLoginButton.addEventListener('click', () => {
        resolve(true);
        resolved = true;
      });
    });
  }

  private async onDevPortalAccessTokenExpired(): Promise<void> {
    const openResult = await this.open();

    if (openResult) {
      authLogin();
    } else {
      authLogout();
    }
  }

  private async onTargetEnvAccessTokenExpired(): Promise<void> {
    const env = getEnvValue();
    const apiKey = getApiKey();
    const openResult = await this.open(env.name);
    if (openResult) {
      this.$modal.hide();
      const loggedIn = await this.targetEnvLoginComponent.open(env as ExplorerTargetEnv, apiKey);
      if (loggedIn) {
        return;
      }
    }

    authLogout();
  }
}
