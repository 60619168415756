import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'app-alert.component',
  props: {
    visible: { type: Boolean, default: true },
    affectLayoutIfInvisible: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-alert", !props.visible ? (props.affectLayoutIfInvisible ? 'invisible' : 'hide') : '']),
    role: "alert"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})