import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal--dialog" }
const _hoisted_3 = { class: "modal--header" }
const _hoisted_4 = {
  key: 0,
  class: "modal-title"
}
const _hoisted_5 = { class: "modal--body" }

import { watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'app-modal.component',
  props: {
    id: {},
    title: { default: '' },
    openModal: { type: Boolean },
    removeCloseBtn: { type: Boolean, default: false }
  },
  emits: ['onModalClose'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

watchEffect(() => {
  if (props.openModal) document.body.classList.add('disable-scrolling');
  else document.body.classList.remove('disable-scrolling');
});

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (props.openModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: props.id,
        class: _normalizeClass(["modal modal--fade", [props.openModal ? 'modal--show' : 'modal-hide']]),
        tabindex: "-1"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (props.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(props.title), 1))
              : _createCommentVNode("", true),
            (!props.removeCloseBtn)
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 1,
                  type: "button",
                  "prefix-icon": "close",
                  label: " ",
                  "aria-label": `close ${props.id}`,
                  "input-class": "form-input--button-transparent",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('onModalClose')))
                }, null, 8, ["aria-label"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})