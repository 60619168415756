// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-10c2b087] .modal--body {
  min-height: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/modals/session-expired-modal.component.vue","webpack://./session-expired-modal.component.vue"],"names":[],"mappings":"AACA;EACE,aAAA;ACAF","sourcesContent":["\n:deep(.modal--body) {\n  min-height: 0;\n}\n",":deep(.modal--body) {\n  min-height: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
