import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import Modal from './modal.component.vue';
import { completeModal } from '../../../hooks/modal-store.hook';


export default /*@__PURE__*/_defineComponent({
  __name: 'session-expired-modal.component',
  props: {
    error: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    id: "sessionExpiredModal",
    size: "small",
    title: "Session Expired"
  }, {
    closeModal: _withCtx(() => [
      _createElementVNode("button", {
        class: "form-input--submit",
        "data-test": "sessionExpiredModal-login",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(completeModal)()))
      }, " Return to Login ")
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("p", null, "Your session has expired", -1)),
      (props.error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(props.error), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})