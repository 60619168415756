<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M12.8291 5.56352C13.0121 5.38046 13.3089 5.38046 13.492 5.56352C13.675 5.74658 13.675 6.04337 13.492 6.22643L8.32755 11.3909C8.14449 11.5739 7.8477 11.5739 7.66464 11.3909L2.5002 6.22643C2.31714 6.04337 2.31714 5.74658 2.5002 5.56352C2.68326 5.38046 2.98006 5.38046 3.16311 5.56352L7.99609 10.3965L12.8291 5.56352Z"
      fill="white"
    />
  </svg>
</template>
