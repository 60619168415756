import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center align-items-center" }
const _hoisted_2 = {
  class: "spinner-border my-2",
  role: "status"
}
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = { class: "ml-2" }


export default /*@__PURE__*/_defineComponent({
  __name: 'loading-indicator.component',
  props: {
    text: { default: 'Loading' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(props.text), 1)
    ]),
    _createElementVNode("strong", _hoisted_4, _toDisplayString(props.text), 1)
  ]))
}
}

})