import {
  Organization,
  PartnerOrganizationApi,
  CreateOrganization,
  CreateInvitation,
} from '@apiture/client-organizations-client-sdk';
import { config, getOptions } from './base-api.service';

const partnerApi = new PartnerOrganizationApi(config);

export async function getOrganizations(query?: {
  start?: string;
  limit?: number;
  domain?: string;
  type?: string;
  name?: string;
}) {
  const organizations = await partnerApi.getOrganizations(
    {
      start: query?.start,
      limit: query?.limit,
      domain: query?.domain,
      type: query?.type,
      name: query?.name,
    },
    await getOptions(),
  );
  return organizations.data;
}

export async function getOrganization(organizationId: string) {
  const organization = await partnerApi.getOrganization(
    organizationId,
    undefined,
    await getOptions(),
  );
  return organization.data;
}

export async function createOrganization(organization: CreateOrganization) {
  const createdOrganization = await partnerApi.createOrganization(organization, await getOptions());
  return createdOrganization.data;
}

export async function updateOrganization(organization: Organization, organizationId: string) {
  const updatedOrganization = await partnerApi.updateOrganization(
    organization,
    organizationId,
    undefined,
    await getOptions(),
  );
  return updatedOrganization.data;
}

export async function patchOrganization(organization: Organization, organizationId: string) {
  const patchedOrganization = await partnerApi.patchOrganization(
    organization,
    organizationId,
    undefined,
    await getOptions(),
  );
  return patchedOrganization.data;
}

export async function inviteMembers(organizationId: string, invitation: CreateInvitation) {
  const invitedMembers = await partnerApi.inviteMembers(
    invitation,
    organizationId,
    await getOptions(),
  );
  return invitedMembers.data;
}
