<template>
  <div
    :class="[
      'input--code',
      { 'input--code-error': props.context.node.parent.context.state.errors },
    ]"
    :data-test="props.context.node.name"
    @click="handleFocusOnClick"
  >
    <input
      v-for="digit in props.context.digits"
      :ref="
        element => {
          inputs[digit - 1] = element as HTMLInputElement;
        }
      "
      :key="digit"
      v-model="inputsValues[digit - 1]"
      :data-test="`${props.context.node.name}-${digit}`"
      type="text"
      maxlength="1"
      class="form-input--text input--code-input"
      @input="handleInput($event, digit - 1)"
      @keydown="handleKeydown($event, digit - 1)"
      @paste="handlePaste"
      @focus="placeCaretAtEndOfInput(digit - 1)"
    >
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';
import { addToaster } from '../../../hooks/handle-toaster';

const regex = new RegExp(/^[a-z0-9]*$/g);

const props = defineProps<{
  context: FormKitFrameworkContext & {
    digits: number;
  };
}>();

const inputs = ref<HTMLInputElement[]>([]);
const inputsValues = ref<string[]>([]);

function handleFocusOnClick(event: MouseEvent) {
  if (props.context.value) {
    return;
  }

  event.preventDefault();
  inputs.value[0].focus();
}

function handleInput(event: Event, index: number) {
  props.context.node.input(inputsValues.value.join(''));

  if (isInputEmpty((event.target as HTMLInputElement).value)) {
    return;
  }

  focusNext(index);
}

function handlePaste(event: ClipboardEvent) {
  event.preventDefault();

  const possibleCode = event.clipboardData.getData('Text').trim();

  if (possibleCode.length !== props.context.digits || !regex.exec(possibleCode)) {
    addToaster('error', 'Please paste a valid code.');
    return;
  }

  inputsValues.value = possibleCode.split('');
  props.context.node.input(inputsValues.value.join(''));
}

function handleKeydown(event: KeyboardEvent, index: number) {
  if (!('key' in event)) {
    return;
  }

  if (event.key === 'Tab') {
    return;
  }

  if (event.key === 'ArrowRight') {
    focusNext(index);
    return;
  }

  if (event.key === 'ArrowLeft') {
    focusPrevious(index);
    return;
  }

  if (isDeleteKeyPressed(event.key) && !isInputEmpty(inputsValues.value[index])) {
    return;
  }

  if (isDeleteKeyPressed(event.key)) {
    focusPrevious(index);
    return;
  }
}

function focusNext(index: number) {
  inputs.value[Math.min(index + 1, props.context.digits - 1)].focus();
}

function focusPrevious(index: number) {
  inputs.value[Math.max(index - 1, 0)].focus();
}

function isDeleteKeyPressed(key: string) {
  return key === 'Backspace' || key === 'Delete';
}

function placeCaretAtEndOfInput(index: number) {
  // HACK: setTimeout is needed to wait for the caret to be placed. nextTick does not work.
  setTimeout(() => {
    inputs.value[index].setSelectionRange(1, 1);
  }, 0);
}

function isInputEmpty(value: string) {
  return value === '' || value === undefined || value === null;
}
</script>

<style lang="scss" scoped>
@import 'src/scss/mixins';
@import 'src/scss/variables';

.input--code {
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input--code-input {
  width: 3rem;
  height: 3rem;
  border: $app-neutral-gray-40 solid 1px;
  border-radius: 3px;
  text-align: center;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;

  &:focus {
    outline: 1px solid #005a7c;
  }

  @include sm {
    width: 4rem;
    height: 4rem;
  }
}

.input--code-error {
  .input--code-input {
    border: 2px solid $app-error-red;
  }
}
</style>
