import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "table-actions-btn-group" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "text-break-all" }

import { onUnmounted, onMounted, ref } from 'vue';
import { Environment } from '@apiture/api-environments-client-sdk';
import { Key, KeyState, KeyType, ExplorerKey } from '@apiture/api-keys-client-sdk';
import { Subject, from, EMPTY, takeUntil, switchMap, tap, map, catchError } from 'rxjs';
import { Messages } from '../../constants/messages';
import {
  createCursorPagination,
  clearNavState,
  processEvent,
  processLinks,
  CursorPaginationEvent,
  CursorPagination,
} from '../../helpers/cursor-pagination-helper';
import { toLocalDate } from '../../helpers/date-helper';
import {
  activateKey,
  rejectKey,
  deactivateKey,
  getExplorerKeys,
  getKeys,
  getKey,
} from '../../services/api/keys-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { confirmDialog } from '../../services/legacy/dialog.service';
import ClipboardCopyComponent from './clipboard-copy.component.vue';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import CursorPaginationComponent from './cursor-pagination.component.vue';
import AppTableComponent from './app-table.component.vue';
import { useEnvironments, mapEnvironmentsToOptions } from '../../hooks/use-environments.hook';
import { setUrlParams, getIdFromUri, addUrlParamsToObject } from '../../helpers/window-helper';
import { ProductLine } from '../../utils/product-lines';

interface KeyData {
  key: Key;
  keyCreatedAt: string;
  environment: Environment;
}

interface KeyListFilterData {
  owner: string;
  environment: string;
  state: KeyState;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'keys-list.component',
  props: {
    mode: { default: 'user' },
    applicationId: { default: '' },
    keyId: { default: '' },
    type: {},
    productLine: {}
  },
  emits: ['onKeyActivated', 'onKeyApproved'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const keyListFilterData = ref<KeyListFilterData>({
  owner: '',
  environment: null,
  state: null,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
  saving: boolean;
  openModal: boolean;
}>({
  initialized: false,
  loading: false,
  saving: false,
  openModal: false,
});

const pagination = ref<CursorPagination>(createCursorPagination());

const keyData = ref<KeyData[]>([]);

const environments = useEnvironments(props.productLine);

const searchSubject = new Subject<{
  start: string;
  limit: number;
  application: string;
  environment: string;
  owner: string;
  state: KeyState[];
  productLine: ProductLine;
}>();

const destroySubject = new Subject();

onMounted(() => {
  addUrlParamsToObject<KeyListFilterData>(keyListFilterData.value);

  if (!props.keyId) {
    initSearchHandler();
  }

  state.value.initialized = true;
  refresh();
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function generateTableFields() {
  const tableFields = ['Name', 'Environment Host', 'Type', 'State', 'Created', 'Actions'];

  if (props.type === 'explorer') {
    tableFields[0] = 'Owner';
    tableFields.splice(2, 1);
  }

  return tableFields;
}

function onFilterChange() {
  clearNavState(pagination.value);
  search();
}

function onPaginationChange(evt: CustomEvent<[CursorPaginationEvent]>) {
  processEvent(pagination.value, evt as CursorPaginationEvent);
  search();
}

async function activateSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to activate <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Activate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully activated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
    emit('onKeyActivated', data.key._id);
  }
}

async function approveKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to approve <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'check-circle-o',
          label: 'Approve',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully approved.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
    emit('onKeyApproved', data.key._id);
  }
}

async function rejectSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to reject <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'ban',
          label: 'Reject',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await rejectKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully rejected.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function revokeKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to revoke <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Revoke',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateKey(data.key._id);
      showToastr({
        type: 'success',
        message: `Key has been successfully revoked.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function reactivateKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to reactivate API Key for ${data.key.environmentName} environment?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Reactivate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      const key = await activateKey(data.key._id);

      if (key.state !== 'active') {
        showToastr({
          type: 'info',
          message: `We have received your request to restore a deactivated API Key.
            Your API Key has been switched to the Pending state.
            Activation requires Apiture approval and intervention and thus may take some time.`,
        });
      } else {
        showToastr({
          type: 'success',
          message: `API Key has been successfully restored.`,
        });
      }
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deactivateSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Please confirm you wish to deactivate API Key for ${data.key.environmentName} environment.
          Deactivation is immediate and users who are using your application in the corresponding target environment will lose access to the application.
          Re-activating a deactivated client application or API key requires manual administrative approval from Apiture.`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Deactivate',
          class: 'btn-warning',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateKey(data.key._id);
      showToastr({
        type: 'success',
        message: `API Key has been successfully deactivated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

function initSearchHandler() {
  searchSubject
    .pipe(
      takeUntil(destroySubject),
      tap(() => {
        state.value.loading = true;
      }),
      switchMap(query =>
        from(props.type === KeyType.Explorer ? getExplorerKeys(query) : getKeys(query)).pipe(
          map(response => {
            return {
              query,
              response,
            };
          }),
          catchError(() => {
            showToastr({ type: 'error', message: Messages.internalError });
            return EMPTY;
          }),
        ),
      ),
      tap(() => {
        for (const paramKey in keyListFilterData.value) {
          if (paramKey === 'isOwner') continue;
          setUrlParams(paramKey, keyListFilterData.value[paramKey]);
        }
        state.value.loading = false;
      }),
    )
    .subscribe(result => {
      fillData(result.response._embedded.items);
      processLinks(pagination.value, result.response._links);
    });
}

function fillData(keys: (Key | ExplorerKey)[]) {
  keyData.value = keys.map(key => {
    const environmentId = getIdFromUri(key._links['apiture:environment']?.href);
    return {
      key,
      keyCreatedAt: toLocalDate(key.createdAt),
      environment: environmentId
        ? environments.value.find(environment => environment._id === environmentId)
        : undefined,
    };
  });
}

function search() {
  searchSubject.next({
    start: pagination.value.start,
    limit: pagination.value.limit,
    application: props.type === 'explorer' ? undefined : props.applicationId,
    environment: keyListFilterData.value.environment,
    owner: keyListFilterData.value.owner?.toLowerCase(),
    state: keyListFilterData.value.state ? [keyListFilterData.value.state] : undefined,
    productLine: props.productLine,
  });
}

async function refresh() {
  if (props.keyId) {
    await loadKey();
  } else {
    search();
  }
}

async function loadKey() {
  state.value.loading = true;
  let key: Key | ExplorerKey;
  try {
    key = await getKey(props.keyId);
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
    return;
  } finally {
    state.value.loading = false;
  }
  fillData([key]);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!props.keyId)
        ? (_openBlock(), _createBlock(_component_FormKit, {
            key: 0,
            id: "keyListFilter",
            modelValue: keyListFilterData.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((keyListFilterData).value = $event)),
            type: "group",
            onInput: onFilterChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormKit, {
                type: "text",
                label: "Owner",
                name: "owner",
                help: "Filter API keys to match only those owned by the named user, as identified by their email address.",
                "outer-class": "form-input--full"
              }),
              _createVNode(_component_FormKit, {
                type: "select",
                label: "Environment",
                name: "environment",
                help: "Filter keys to match only those which deploy in the named environment.",
                options: _unref(mapEnvironmentsToOptions)(_unref(environments)),
                "outer-class": "form-input--full",
                placeholder: "Select an Environment"
              }, null, 8, ["options"]),
              _createVNode(_component_FormKit, {
                type: "select",
                label: "State",
                name: "state",
                help: "Filter keys to match only those with the selected state.",
                options: ['', _unref(KeyState).Active, _unref(KeyState).Pending, _unref(KeyState).Inactive, _unref(KeyState).Rejected],
                "outer-class": "form-input--full",
                placeholder: "Select an Application State"
              }, null, 8, ["options"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(AppTableComponent, {
      "table-class": "data-table mb-0 mt-4",
      "primary-key": "key._id",
      fields: generateTableFields(),
      items: keyData.value,
      busy: state.value.loading,
      striped: ""
    }, {
      "cell(name)": _withCtx(({ row }: { row: { item: { key: Key } } }) => [
        _createTextVNode(_toDisplayString(row.item.key.name), 1)
      ]),
      "cell(type)": _withCtx(({ row }: { row: { item: { key: Key } } }) => [
        _createTextVNode(_toDisplayString(row.item.key.type), 1)
      ]),
      "cell(owner)": _withCtx(({ row }: { row: { item: { key: object } } }) => [
        _createTextVNode(_toDisplayString('owner' in row.item.key && row.item.key.owner), 1)
      ]),
      "cell(state)": _withCtx(({ row }: { row: { item: KeyData } }) => [
        _createTextVNode(_toDisplayString(row.item.key.state), 1)
      ]),
      "cell(environmentHost)": _withCtx(({ row }: { row: { item: KeyData } }) => [
        _createTextVNode(_toDisplayString(row.item.key.environmentHost), 1)
      ]),
      "cell(created)": _withCtx(({ row }: { row: { item: KeyData } }) => [
        _createTextVNode(_toDisplayString(row.item.keyCreatedAt), 1)
      ]),
      "cell(actions)": _withCtx(({
          row,
        }: {
          row: { item: KeyData, expandDetails: Function, isDetailsExpanded: boolean },
        }) => [
        _createElementVNode("div", _hoisted_2, [
          (
              row.item.key.state === 'active' &&
                (row.item.key.clientSecret || row.item.key.key || row.item.key.clientId)
            )
            ? (_openBlock(), _createBlock(_component_FormKit, {
                key: 0,
                type: "button",
                "outer-class": "align-self-start",
                "input-class": "btn btn-primary btn-sm",
                onClick: ($event: any) => (row.expandDetails(row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.isDetailsExpanded ? 'Fewer Details' : 'More Details'), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true),
          (props.mode === 'admin')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (row.item.key.state === 'inactive' || row.item.key.state === 'rejected')
                  ? (_openBlock(), _createBlock(_component_FormKit, {
                      key: 0,
                      type: "button",
                      "input-class": "btn btn-sm btn-success",
                      onClick: ($event: any) => (activateSelectedKey(row.item))
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Activate ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (row.item.key.state === 'active')
                  ? (_openBlock(), _createBlock(_component_FormKit, {
                      key: 1,
                      type: "button",
                      "input-class": "btn btn-sm btn-danger",
                      onClick: ($event: any) => (revokeKey(row.item))
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Revoke ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (row.item.key.state === 'pending')
                  ? (_openBlock(), _createBlock(_component_FormKit, {
                      key: 2,
                      type: "button",
                      "input-class": "btn btn-sm btn-success",
                      onClick: ($event: any) => (approveKey(row.item))
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Approve ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (row.item.key.state !== 'rejected')
                  ? (_openBlock(), _createBlock(_component_FormKit, {
                      key: 3,
                      type: "button",
                      "input-class": "btn btn-sm btn-danger",
                      onClick: ($event: any) => (rejectSelectedKey(row.item))
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Reject ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : (row.item.key.state !== 'rejected')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (row.item.key.state === 'inactive' && !!row.item.key._links['apiture:activate'])
                    ? (_openBlock(), _createBlock(_component_FormKit, {
                        key: 0,
                        type: "button",
                        "input-class": "btn btn-sm btn-success",
                        onClick: ($event: any) => (reactivateKey(row.item))
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Reactivate ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (row.item.key.state !== 'inactive')
                    ? (_openBlock(), _createBlock(_component_FormKit, {
                        key: 1,
                        type: "button",
                        "input-class": "btn btn-sm btn-warning",
                        onClick: ($event: any) => (deactivateSelectedKey(row.item))
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Deactivate ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
        ])
      ]),
      "row(expanded)": _withCtx(({ row }: { row: { item: KeyData } }) => [
        (row.item.key.key)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[7] || (_cache[7] = _createElementVNode("b", null, "API Key: ", -1)),
              _createVNode(SensitiveValueComponent, {
                id: row.item.key._id + 'apiKey',
                title: "API Key",
                value: row.item.key.key
              }, null, 8, ["id", "value"])
            ]))
          : _createCommentVNode("", true),
        (row.item.key.clientId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[8] || (_cache[8] = _createElementVNode("b", null, "Client ID: ", -1)),
              _createVNode(SensitiveValueComponent, {
                id: row.item.key._id + 'clientId',
                title: "Client ID",
                value: row.item.key.clientId
              }, null, 8, ["id", "value"])
            ]))
          : _createCommentVNode("", true),
        (row.item.key.clientSecret)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[9] || (_cache[9] = _createElementVNode("b", null, "Client Secret: ", -1)),
              _createVNode(SensitiveValueComponent, {
                id: row.item.key._id + 'clientSecret',
                title: "Client Secret",
                value: row.item.key.clientSecret
              }, null, 8, ["id", "value"])
            ]))
          : _createCommentVNode("", true),
        (row.item.key.serviceConnections)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createElementVNode("div", null, [
                _cache[10] || (_cache[10] = _createElementVNode("b", null, "Queue URI: ", -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(row.item.key.serviceConnections.queueConsumer.uri), 1),
                _createVNode(ClipboardCopyComponent, {
                  id: row.item.key._id + 'queueConsumerUri',
                  title: "Queue URI",
                  value: row.item.key.serviceConnections.queueConsumer.uri
                }, null, 8, ["id", "value"])
              ]),
              _createElementVNode("div", null, [
                _cache[11] || (_cache[11] = _createElementVNode("b", null, "Queue Access ID: ", -1)),
                _createVNode(SensitiveValueComponent, {
                  id: row.item.key._id + 'queueConsumerAccessKeyId',
                  title: "Queue Access ID",
                  value: row.item.key.serviceConnections.queueConsumer.accessKeyId
                }, null, 8, ["id", "value"])
              ]),
              _createElementVNode("div", null, [
                _cache[12] || (_cache[12] = _createElementVNode("b", null, "Queue Secret Access Key: ", -1)),
                _createVNode(SensitiveValueComponent, {
                  id: row.item.key._id + 'queueConsumerSecretAccessKey',
                  title: "Queue Secret Access Key",
                  value: row.item.key.serviceConnections.queueConsumer.secretAccessKey
                }, null, 8, ["id", "value"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["fields", "items", "busy"]),
    (!props.keyId)
      ? (_openBlock(), _createBlock(CursorPaginationComponent, {
          key: 1,
          next: !!pagination.value.next,
          previous: Boolean(pagination.value.previous.length),
          limit: pagination.value.limit,
          onOnChange: onPaginationChange
        }, null, 8, ["next", "previous", "limit"]))
      : _createCommentVNode("", true)
  ]))
}
}

})