// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-7ea1cec8] .form-input--pagination-fieldset {
  display: flex;
  flex-direction: row;
  min-height: min-content;
}
[data-v-7ea1cec8] .form-input--pagination-legend {
  width: auto;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/legacy/cursor-pagination.component.vue","webpack://./cursor-pagination.component.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;ACAF;ADGA;EACE,WAAA;EACA,YAAA;ACAF","sourcesContent":["\n:deep(.form-input--pagination-fieldset) {\n  display: flex;\n  flex-direction: row;\n  min-height: min-content;\n}\n\n:deep(.form-input--pagination-legend) {\n  width: auto;\n  height: auto;\n}\n",":deep(.form-input--pagination-fieldset) {\n  display: flex;\n  flex-direction: row;\n  min-height: min-content;\n}\n\n:deep(.form-input--pagination-legend) {\n  width: auto;\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
