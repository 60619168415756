import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "input-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-test"]
const _hoisted_4 = ["data-test", "onClick"]
const _hoisted_5 = { class: "flex-row--gap" }
const _hoisted_6 = ["disabled", "data-test"]
const _hoisted_7 = ["placeholder", "disabled", "data-test"]

import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'list.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const currentInputValue = ref<string>(props.context.value?.currentInputValue ?? '');
const addedValues = ref<string[]>(
  props.context.value?.addedValues.map(value => formatInput(value)) ?? [],
);

function handleRemove(item: string) {
  addedValues.value = addedValues.value.filter(value => value !== item);

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: currentInputValue.value,
  });
}

function handleAdd() {
  if (!currentInputValue.value) {
    return;
  }

  addToList();
}

function handleEnter(event: KeyboardEvent) {
  if (event.key !== 'Enter' || !currentInputValue.value) {
    return;
  }

  event.preventDefault();

  addToList();
}

function handleInput() {
  props.context.node.clearErrors();

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: formatInput(currentInputValue.value),
  });
}

function addToList() {
  if (Object.keys(props.context.messages).length > 0) {
    return;
  }

  const formattedInput = formatInput(currentInputValue.value);

  if (addedValues.value.includes(formattedInput)) {
    props.context.node.setErrors(`${formattedInput} already exists.`);
    return;
  }

  addedValues.value.push(currentInputValue.value);

  currentInputValue.value = '';

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: currentInputValue.value,
  });
}

function formatInput(value: string) {
  return value.toLocaleLowerCase().trim();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (addedValues.value.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addedValues.value, (item, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item,
              class: "input-list--item",
              tabindex: "-1",
              "data-test": `${props.context.node.name}-${String(i)}`
            }, [
              _createTextVNode(_toDisplayString(item) + " ", 1),
              _createElementVNode("button", {
                class: "input-list--btn",
                type: "button",
                "data-test": `${props.context.node.name}-${String(i)}-remove`,
                onClick: ($event: any) => (handleRemove(item))
              }, _cache[3] || (_cache[3] = [
                _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"17\" fill=\"none\" data-v-6354b512><g clip-path=\"url(#a)\" data-v-6354b512><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 8.594a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm-15.063 0a7.063 7.063 0 1 1 14.126 0 7.063 7.063 0 0 1-14.126 0Zm9.417-2.92a.488.488 0 0 1 .7.681l-.01.01-2.306 2.307 2.306 2.307a.488.488 0 0 1-.68.7l-.01-.01-2.307-2.306L5.74 11.67a.488.488 0 0 1-.7-.68l.01-.01 2.306-2.308L5.05 6.365a.488.488 0 0 1 .68-.7l.01.01 2.308 2.307 2.307-2.307Z\" fill=\"#005A7C\" data-v-6354b512></path></g><defs data-v-6354b512><clipPath id=\"a\" data-v-6354b512><path fill=\"#fff\" transform=\"translate(0 .594)\" d=\"M0 0h16v16H0z\" data-v-6354b512></path></clipPath></defs></svg>", 1)
              ]), 8, _hoisted_4)
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        disabled: props.context._value?.length >= props.context.maxItems,
        class: "input-list--btn",
        type: "button",
        "data-test": `${props.context.node.name}-add`,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleAdd()))
      }, _cache[4] || (_cache[4] = [
        _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" data-v-6354b512><g clip-path=\"url(#a)\" data-v-6354b512><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.5 8.282a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm-15.063 0a7.063 7.063 0 1 1 14.126 0 7.063 7.063 0 0 1-14.126 0Zm10.794-.473a.488.488 0 0 1 .012.976H9.004v3.227a.488.488 0 0 1-.976.013V8.786H4.8a.488.488 0 0 1-.013-.977h3.239V4.582a.488.488 0 0 1 .977-.012v3.239h3.227Z\" fill=\"#005A7C\" data-v-6354b512></path></g><defs data-v-6354b512><clipPath id=\"a\" data-v-6354b512><path fill=\"#fff\" transform=\"translate(.5 .282)\" d=\"M0 0h16v16H0z\" data-v-6354b512></path></clipPath></defs></svg>", 1)
      ]), 8, _hoisted_6),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentInputValue).value = $event)),
        type: "text",
        placeholder: props.context.attrs.placeholder ?? '',
        class: "input-list--input form-input--full",
        disabled: props.context._value?.length >= props.context.maxItems,
        "data-test": props.context.attrs['data-test'] ?? props.context.name,
        onKeydown: handleEnter,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (handleInput()))
      }, null, 40, _hoisted_7), [
        [_vModelText, currentInputValue.value]
      ])
    ])
  ]))
}
}

})