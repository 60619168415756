import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = {
  key: 0,
  class: "header-text"
}
const _hoisted_3 = { class: "header-icon" }
const _hoisted_4 = { class: "panel-content" }

import { ref, watch } from 'vue';
import { FormKitIcon } from '@formkit/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'expandable-panel.component',
  props: {
    enabled: { type: Boolean, default: true },
    header: { default: 'Try It' },
    expanded: { default: 'false' }
  },
  emits: ['update:expanded'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const expandedInner = ref<boolean>(convertStringToBoolean(props.expanded));
const injectContent = ref<boolean>(convertStringToBoolean(props.expanded));

const emit = __emit;

watch(
  () => convertStringToBoolean(props.expanded),
  (newExpanded: boolean) => {
    expandedInner.value = newExpanded;
    onChange();
  },
);

function toggle(): void {
  expandedInner.value = !expandedInner.value;
  emit('update:expanded', expandedInner.value);
  onChange();
}

function onChange(): void {
  if (expandedInner.value) {
    injectContent.value = true;
  }
}

// Can't pass booleans in jekyll template 'get-started.md' for some reason.
function convertStringToBoolean(str: 'true' | 'false'): boolean {
  return str === 'true' ? true : false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.enabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "panel",
          "aria-expanded": expandedInner.value
        }, [
          _createElementVNode("div", {
            class: "panel-header",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
          }, [
            (props.header)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(props.header), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (expandedInner.value)
                ? (_openBlock(), _createBlock(_unref(FormKitIcon), {
                    key: 0,
                    icon: "down"
                  }))
                : (_openBlock(), _createBlock(_unref(FormKitIcon), {
                    key: 1,
                    icon: "right"
                  }))
            ])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            (injectContent.value)
              ? _renderSlot(_ctx.$slots, "default", { key: 0 })
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, expandedInner.value]
          ])
        ], 8, _hoisted_1))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}
}

})