import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer--text" }
const _hoisted_2 = { class: "footer--date" }

import BorderSvg from './svg/border-svg.component.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'footer.component',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, [
        _createTextVNode("API"),
        _createElementVNode("span", null, "TURE")
      ], -1)),
      _createVNode(BorderSvg),
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        class: "footer--link",
        href: "https://www.apiture.com/privacy-policy/",
        target: "_blank"
      }, "Privacy Policy", -1)),
      _createVNode(BorderSvg),
      _cache[2] || (_cache[2] = _createElementVNode("a", {
        class: "footer--link",
        href: "https://developer.apiture.com/docs/Apiture-API-License-Agreement.pdf",
        target: "_blank"
      }, "Terms & Conditions", -1))
    ]),
    _createElementVNode("p", _hoisted_2, " Apiture Developer Portal © " + _toDisplayString(new Date().getFullYear()), 1)
  ]))
}
}

})