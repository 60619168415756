<template>
  <input
    v-model="phoneNumber"
    class="form-input form-input--text"
    type="tel"
    :data-test="props.context.node.name"
    autocomplete="mobile"
    @keydown="handleValidation"
    @input="handleInput()"
    @blur="handleBlur"
  >
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';

const phoneNumber = ref<string>('');

const phoneNumberRegex = /^(\+0?1\s?)?\(?(\d{3})\)?[\s.-]?(\d{3})[\s.-]?(\d{4})$/g;
const allowedNonNumericChars = ['.', '(', ')', '+', '-'];

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

function handleValidation(event: KeyboardEvent) {
  if (!isNaN(Number(event.key)) || event.key === 'Backspace' || event.key === 'Tab') {
    return;
  }

  if (!allowedNonNumericChars.includes(event.key)) {
    event.preventDefault();
  }
}

function handleInput() {
  props.context.node.input(phoneNumber.value);
}

function handleBlur() {
  if (!phoneNumber.value.match(phoneNumberRegex)) {
    props.context.node.setErrors(`${props.context.label} is incorrectly formatted`);
    return;
  }

  phoneNumber.value = phoneNumber.value.replace(phoneNumberRegex, '+1 ($2)-$3-$4');

  props.context.node.input(phoneNumber.value);
}
</script>
