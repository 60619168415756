<template>
  <div class="input-list">
    <ul v-if="addedValues.length > 0">
      <li
        v-for="(item, i) in addedValues"
        :key="item"
        class="input-list--item"
        tabindex="-1"
        :data-test="`${props.context.node.name}-${String(i)}`"
      >
        {{ item }}
        <button
          class="input-list--btn"
          type="button"
          :data-test="`${props.context.node.name}-${String(i)}-remove`"
          @click="handleRemove(item)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            fill="none"
          >
            <g clip-path="url(#a)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 8.594a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm-15.063 0a7.063 7.063 0 1 1 14.126 0 7.063 7.063 0 0 1-14.126 0Zm9.417-2.92a.488.488 0 0 1 .7.681l-.01.01-2.306 2.307 2.306 2.307a.488.488 0 0 1-.68.7l-.01-.01-2.307-2.306L5.74 11.67a.488.488 0 0 1-.7-.68l.01-.01 2.306-2.308L5.05 6.365a.488.488 0 0 1 .68-.7l.01.01 2.308 2.307 2.307-2.307Z"
                fill="#005A7C"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path
                  fill="#fff"
                  transform="translate(0 .594)"
                  d="M0 0h16v16H0z"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </li>
    </ul>
    <div class="flex-row--gap">
      <button
        :disabled="props.context._value?.length >= props.context.maxItems"
        class="input-list--btn"
        type="button"
        :data-test="`${props.context.node.name}-add`"
        @click="handleAdd()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          fill="none"
        >
          <g clip-path="url(#a)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5 8.282a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm-15.063 0a7.063 7.063 0 1 1 14.126 0 7.063 7.063 0 0 1-14.126 0Zm10.794-.473a.488.488 0 0 1 .012.976H9.004v3.227a.488.488 0 0 1-.976.013V8.786H4.8a.488.488 0 0 1-.013-.977h3.239V4.582a.488.488 0 0 1 .977-.012v3.239h3.227Z"
              fill="#005A7C"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path
                fill="#fff"
                transform="translate(.5 .282)"
                d="M0 0h16v16H0z"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <input
        v-model="currentInputValue"
        type="text"
        :placeholder="props.context.attrs.placeholder ?? ''"
        class="input-list--input form-input--full"
        :disabled="props.context._value?.length >= props.context.maxItems"
        :data-test="props.context.attrs['data-test'] ?? props.context.name"
        @keydown="handleEnter"
        @input="handleInput()"
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext<string[]> & {
    maxItems: number;
    value: { addedValues: string[]; currentInputValue: string };
  };
}>();

const currentInputValue = ref<string>(props.context.value?.currentInputValue ?? '');
const addedValues = ref<string[]>(
  props.context.value?.addedValues.map(value => formatInput(value)) ?? [],
);

function handleRemove(item: string) {
  addedValues.value = addedValues.value.filter(value => value !== item);

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: currentInputValue.value,
  });
}

function handleAdd() {
  if (!currentInputValue.value) {
    return;
  }

  addToList();
}

function handleEnter(event: KeyboardEvent) {
  if (event.key !== 'Enter' || !currentInputValue.value) {
    return;
  }

  event.preventDefault();

  addToList();
}

function handleInput() {
  props.context.node.clearErrors();

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: formatInput(currentInputValue.value),
  });
}

function addToList() {
  if (Object.keys(props.context.messages).length > 0) {
    return;
  }

  const formattedInput = formatInput(currentInputValue.value);

  if (addedValues.value.includes(formattedInput)) {
    props.context.node.setErrors(`${formattedInput} already exists.`);
    return;
  }

  addedValues.value.push(currentInputValue.value);

  currentInputValue.value = '';

  props.context.node.input({
    addedValues: addedValues.value,
    currentInputValue: currentInputValue.value,
  });
}

function formatInput(value: string) {
  return value.toLocaleLowerCase().trim();
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

.input-list {
  border: 1px solid #000;
  padding: 1rem;
  border-radius: 0.25rem;

  > ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }

  :focus-within input {
    outline: none;
  }

  :focus-within & {
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.input-list--btn {
  border: none;
  background-color: transparent;
  height: max-content;
  width: max-content;
  vertical-align: middle;
  align-self: center;
  padding: 0;

  > * {
    cursor: pointer;
    vertical-align: middle;
  }
}

.input-list--input {
  border: none;
  height: 3rem;
  padding: 0 1rem;
}

.input-list--input-container {
  display: inline-block;
}

.input-list--item {
  border: 1px solid $app-neutral-gray-30;
  border-radius: 2rem;
  width: max-content;
  padding: 0.25rem 1rem;
  font-weight: 500;
  line-height: 1.6875rem;
  margin-top: 0;
  margin-bottom: 0.5rem;

  .input-list--btn {
    margin-left: 0.5rem;
  }
}
</style>
