import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "nav--dropdown-item" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "nav--dropdown-item"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "nav--dropdown-item" }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  href: "https://dx.apiture.com",
  target: "_blank"
}
const _hoisted_11 = {
  key: 0,
  class: "nav--list-item"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 1 }

import { getUser, authLogoutCognito } from '../../services/portal-auth.service';
import NavigationContainer from './navigation-container.component.vue';
import DropdownComponent from './dropdown.component.vue';
import NewTabSvg from '../portal/svg/new-tab.component.vue';
import { siteOptions } from '../../options/site-options';


export default /*@__PURE__*/_defineComponent({
  __name: 'navigation.component',
  setup(__props) {

const user = getUser();

async function logout() {
  await authLogoutCognito();
  window.location.replace('/auth/login/');
}

return (_ctx: any,_cache: any) => {
  const _component_CaretDown = _resolveComponent("CaretDown")!

  return (_openBlock(), _createBlock(NavigationContainer, null, {
    default: _withCtx(({ isExpanded, baseUrl }) => [
      _createElementVNode("li", null, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: `${baseUrl}/dashboard`
            }, "Dashboard", 8, _hoisted_1)
          ]),
          (_unref(user))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_unref(user).role === 'Developer')
                  ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                      _createVNode(DropdownComponent, {
                        id: "clients-dropdown",
                        label: "Clients",
                        "dropdown-button-class": "nav--dropdown-button-clients",
                        "dropdown-content-class": "nav--dropdown-content",
                        "remove-pointer": isExpanded
                      }, {
                        labelIcon: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("svg", {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "16",
                            height: "17",
                            viewBox: "0 0 16 17",
                            fill: "none"
                          }, [
                            _createElementVNode("path", {
                              d: "M12.8291 5.56352C13.0121 5.38046 13.3089 5.38046 13.492 5.56352C13.675 5.74658 13.675 6.04337 13.492 6.22643L8.32755 11.3909C8.14449 11.5739 7.8477 11.5739 7.66464 11.3909L2.5002 6.22643C2.31714 6.04337 2.31714 5.74658 2.5002 5.56352C2.68326 5.38046 2.98006 5.38046 3.16311 5.56352L7.99609 10.3965L12.8291 5.56352Z",
                              fill: "white"
                            })
                          ], -1)
                        ])),
                        default: _withCtx(() => [
                          (_unref(user)?.role === 'Developer')
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                                _createElementVNode("li", _hoisted_4, [
                                  _createElementVNode("a", {
                                    href: `${baseUrl}/apps/list/`
                                  }, "Applications", 8, _hoisted_5)
                                ]),
                                (_unref(siteOptions).ssoIntegrationsEnabled)
                                  ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                                      _createElementVNode("a", {
                                        href: `${baseUrl}/integrations/list/`
                                      }, "SSO Integrations", 8, _hoisted_7)
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("li", _hoisted_8, [
                                  _createElementVNode("a", {
                                    href: `${baseUrl}/webhooks/list/`
                                  }, "Webhooks", 8, _hoisted_9)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["remove-pointer"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_10, [
              _cache[2] || (_cache[2] = _createTextVNode("Developer Docs ")),
              _createVNode(NewTabSvg)
            ])
          ])
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("ul", null, [
          (_unref(user))
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                _createVNode(DropdownComponent, {
                  label: `${_unref(user).firstName} ${_unref(user).lastName}`,
                  "dropdown-button-class": "nav--dropdown-button-profile",
                  "dropdown-content-class": "nav--dropdown-content",
                  "remove-pointer": isExpanded
                }, {
                  labelIcon: _withCtx(() => [
                    _createVNode(_component_CaretDown)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "personalLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/profile/personal/`
                        }, " My Profile ", 8, _hoisted_12)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "accountLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/profile/account/`
                        }, " My Account ", 8, _hoisted_13)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "partnerLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/profile/business/`
                        }, " My Company ", 8, _hoisted_14)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "logoutLink",
                          class: "nav--dropdown-item",
                          href: "javascript:",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (logout()))
                        }, " Logout ")
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["label", "remove-pointer"])
              ]))
            : _createCommentVNode("", true),
          (!_unref(user))
            ? (_openBlock(), _createElementBlock("li", _hoisted_15, _cache[3] || (_cache[3] = [
                _createElementVNode("a", { href: "/auth/login" }, "Login", -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})