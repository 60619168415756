export interface PromiseCacheStorage {
  getItem<T>(key: string): T;
  setItem<T>(key: string, value: T): void;
  removeItem(key: string): void;
  clear(): void;
}

interface PromiseCacheSessionData {
  readonly response: any;
  readonly timestamp: number;
}

export interface PromiseCacheParams {
  readonly timeout?: number;
  readonly storage?: PromiseCacheStorage;
}

export class PromiseCache {
  private readonly storage: PromiseCacheStorage;
  private readonly timeout?: number;
  private readonly promises: Map<string, Promise<any>> = new Map();

  constructor(params: PromiseCacheParams) {
    this.storage = params.storage;
    this.timeout = params.timeout;
  }

  async get<T>(key: string, promiseFactory: () => Promise<T>): Promise<T> {
    const executingPromise = this.promises.get(key);
     
    if (executingPromise) {
      return executingPromise;
    }

    const cachedSessionData = this.storage.getItem<PromiseCacheSessionData>(key);

    if (cachedSessionData) {
      const now = Date.now();

      if (!this.timeout || now - cachedSessionData.timestamp < this.timeout) {
        return Promise.resolve(cachedSessionData.response);
      }

      this.storage.removeItem(key);
    }

    const newPromise = promiseFactory();
    this.promises.set(key, newPromise);

    const response = await newPromise;
    const sessionData: PromiseCacheSessionData = {
      response,
      timestamp: Date.now(),
    };

    this.storage.setItem(key, sessionData);

    return response;
  }

  clear(): void {
    this.storage.clear();
    this.promises.clear();
  }
}

export class PromiseCacheMemoryStorage implements PromiseCacheStorage {
  private readonly map: Map<string, any> = new Map();

  getItem<T>(key: string): T {
    return this.map.get(key);
  }

  setItem<T>(key: string, value: T): void {
    this.map.set(key, value);
  }

  removeItem(key: string): void {
    this.map.delete(key);
  }

  clear(): void {
    this.map.clear();
  }
}

export class PromiseCacheBrowserStorage implements PromiseCacheStorage {
  private readonly keyPrefix: string;

  constructor(id: string, private readonly storage: Storage = sessionStorage) {
    this.keyPrefix = `PROMISE_CACHE_${id}_`;
  }

  getItem<T>(key: string): T {
    const value = this.storage.getItem(this.keyPrefix + key);
    return value ? JSON.parse(value) : undefined;
  }

  setItem<T>(key: string, value: T): void {
    this.storage.setItem(this.keyPrefix + key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.storage.removeItem(this.keyPrefix + key);
  }

  clear(): void {
    Object.keys(this.storage)
      .filter(key => key.startsWith(this.keyPrefix))
      .forEach(key => {
        this.storage.removeItem(key);
      });
  }
}
