import '../scss/legacy.scss';
import '@apiture/ui/dist/collection/assets/apiture-icon-font/css/apiture-iconfont.css';

import lodash from 'lodash';
window['_'] = lodash;

import jQuery from 'jquery';
window['jQuery'] = window['$'] = jQuery;

import URI from 'urijs';
window['URI'] = URI;

import moment from 'moment';
window['moment'] = moment;

import 'imagesloaded';
import 'jquery-highlight';

import * as Bloodhound from 'typeahead.js/dist/bloodhound';
window['Bloodhound'] = Bloodhound.default;
import 'typeahead.js/dist/typeahead.jquery';

import './app.bootstrap';
import './app.legacy';

import { SessionExpiredComponent } from './components/legacy/session-expired.component';
import { TargetEnvLoginComponent } from './components/legacy/target-env-login.component';
import { TargetEnvSelectComponent } from './components/legacy/target-env-select.component';
import { MfaConfirmComponent } from './components/legacy/mfa-confirm.component';
import { initTocService } from './services/legacy/toc.service';

const mfaConfirmComponent = new MfaConfirmComponent();
const targetEnvLoginComponent = new TargetEnvLoginComponent(mfaConfirmComponent);
const sessionExpiredComponent = new SessionExpiredComponent(targetEnvLoginComponent);
const targetEnvSelectComponent = new TargetEnvSelectComponent(targetEnvLoginComponent);

const components = {
  targetEnvLoginComponent,
  targetEnvSelectComponent,
  mfaConfirmComponent,
  ...(window.location.href.includes('/open') && {
    sessionExpiredComponent,
  }),
};

const services = {
  initTocService,
};

window['apt'] = { components, services };
