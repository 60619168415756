<template>
  <FormKit
    :id="props.id + 'CopyToClipboard'"
    type="button"
    input-class="form-input--button-transparent"
    outer-class="align-self-center"
    :disabled="props.disable"
    :ignore="true"
    @click="copy()"
  >
    Copy
  </FormKit>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    id: string;
    title: string;
    value: string;
    disable?: boolean;
  }>(),
  { disable: false },
);

async function copy() {
  await navigator.clipboard.writeText(props.value);
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

:deep(button) {
  color: $app-neutral-black;
  border: 1px solid $app-neutral-black;
  border-radius: 1rem;
  padding: 0.5rem 2rem;

  align-self: center;
}
</style>
