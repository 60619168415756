import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "form-container--row" }

import { ref, onBeforeMount } from 'vue';
import { createEnvironment, updateEnvironment } from '../../services/api/environments-api.service';
import { getUrlParams } from '../../helpers/window-helper';
import { showToastr } from '../../services/legacy/toastr.service';
import { getNode } from '@formkit/core';
import { getEnvironment } from '../../services/api/environments-api.service';
import type { Environment, EnvironmentType } from '@apiture/api-environments-client-sdk';
import type { ProductLine } from '@apiture/api-environments-client-sdk';

type EnvironmentForm = {
  name: string;
  host: string;
  domains: string[];
  type: EnvironmentType;
  institutionId?: string;
  description: string;
};

const environmentFormId = 'environmentForm';

export default /*@__PURE__*/_defineComponent({
  __name: 'environments-form.component',
  props: {
    productLine: {}
  },
  setup(__props: any) {

const props = __props;

const environmentId = getUrlParams().get('environmentId');
const environment = ref<Environment>();
const loading = ref<boolean>(true);

onBeforeMount(async () => {
  if (!environmentId) {
    loading.value = false;
    return;
  }

  environment.value = await getEnvironment(environmentId);

  mapEnvironmentToForm();

  loading.value = false;
});

async function handleSubmit(environmentForm: EnvironmentForm) {
  const formattedEnvironment = mapFormToEnvironment(environmentForm);

  try {
    if (environmentId) {
      await updateEnvironment(
        Object.assign(environment.value, formattedEnvironment),
        environmentId,
      );
    } else {
      await createEnvironment(formattedEnvironment);
    }
  } catch (error) {
    showToastr({
      type: 'error',
      message: error?._error.message ?? 'Internal Error.',
    });
    return;
  }

  showToastr({
    type: 'success',
    message: `Environment has been successfully ${environmentId ? 'updated' : 'created'}.`,
  });

  setTimeout(() => {
    window.location.href =
      props.productLine === 'adb' ? '/admin/environments' : '/openAdmin/environments/';
  }, 3000);
}

function mapEnvironmentToForm() {
  if (!environment.value) {
    return;
  }

  const environmentFormNode = getNode(environmentFormId);

  environmentFormNode.input({
    name: environment.value.name,
    host: environment.value.host,
    type: environment.value.type,
    domains: environment.value.domains,
    description: environment.value.description,
    ...(props.productLine === 'adb' && {
      institutionId: environment.value.institutionId ?? '',
    }),
  });
}

function mapFormToEnvironment(environmentForm: EnvironmentForm) {
  return {
    name: environmentForm.name,
    host: environmentForm.host,
    type: environmentForm.type,
    domains: environmentForm.domains,
    institutionId: environmentForm.institutionId,
    description: environmentForm.description,
    productLine: props.productLine,
  } satisfies Environment;
}

function disableInstitutionId() {
  if (!environmentId || !environment.value) {
    return false;
  }

  return Boolean(environment.value?.institutionId);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    id: environmentFormId,
    type: "form",
    "data-test": "environmentForm",
    "data-loaded": !loading.value,
    onSubmit: handleSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormKit, {
          type: "text",
          name: "name",
          label: "Environment Name",
          validation: "required|length:4,64",
          "outer-class": "form-input--full",
          "data-test": "name"
        }),
        _createVNode(_component_FormKit, {
          type: "text",
          name: "host",
          label: "Host",
          validation: [
          ['required'],
          ['length:8,64'],
          ['matches', /^[a-z\d]([a-z\d\-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d\-]{0,61}[a-z\d])?)*$/],
        ],
          "outer-class": "form-input--full",
          "data-test": "host"
        }),
        (props.productLine === 'adb')
          ? (_openBlock(), _createBlock(_component_FormKit, {
              key: 0,
              type: "text",
              name: "institutionId",
              label: "Institution Id",
              validation: [['required'], ['matches', /^[A-Z0-9]{3,8}$/]],
              "outer-class": "form-input--md",
              "data-test": "institutionId",
              disabled: disableInstitutionId()
            }, null, 8, ["disabled"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormKit, {
          id: "type",
          type: "radio",
          name: "type",
          label: "Environment Type",
          options: [
          { value: 'development', label: 'Development' },
          { value: 'demo', label: 'Demo' },
          { value: 'qualityAssurance', label: 'Quality Assurance' },
          { value: 'uat', label: 'UAT' },
          { value: 'partner', label: 'Partner' },
          { value: 'production', label: 'Production' },
        ],
          "outer-class": "form-input--half"
        }),
        _createVNode(_component_FormKit, {
          type: "domains",
          name: "domains",
          required: "",
          "outer-class": "form-input--half"
        })
      ]),
      _createVNode(_component_FormKit, {
        type: "textarea",
        name: "description",
        label: "Description of Environment",
        rows: "5",
        cols: "50",
        placeholder: "Description of the Environment",
        validation: "required|length:4,256",
        "data-test": "description"
      })
    ]),
    _: 1
  }, 8, ["data-loaded"]))
}
}

})