import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "clearfix" }
const _hoisted_2 = { class: "form-container--end" }
const _hoisted_3 = { class: "form-container--row" }
const _hoisted_4 = { class: "form-container--row" }

import { CursorPaginationEvent } from '../../helpers/cursor-pagination-helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'cursor-pagination.component',
  props: {
    next: { type: Boolean },
    previous: { type: Boolean },
    limit: {}
  },
  emits: ['onChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

function raiseChange(event: CursorPaginationEvent): void {
  emit('onChange', event);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormKit, {
          id: "cursorPagination",
          type: "group"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              type: "radio",
              label: "Show",
              name: "limit",
              options: [5, 10, 25, 50, 100],
              value: 5,
              "legend-class": "form-input--pagination-legend",
              "options-class": "form-input--pagination-fieldset",
              "fieldset-class": "form-input--pagination-fieldset",
              onInput: _cache[0] || (_cache[0] = (value) => {
              raiseChange({ type: 'limitChange', limit: Number(value) })
            })
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FormKit, {
                disabled: !props.previous,
                type: "button",
                label: "Previous",
                "prefix-icon": "stepBack",
                "input-class": "form-input--button-transparent",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (raiseChange({ type: 'navigatePrevious' })))
              }, null, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                disabled: !props.next,
                type: "button",
                label: "Next",
                "suffix-icon": "stepForward",
                "input-class": "form-input--button-transparent",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (raiseChange({ type: 'navigateNext' })))
              }, null, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                type: "button",
                "prefix-icon": "refresh",
                label: " ",
                "input-class": "form-input--button-transparent",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (raiseChange({ type: 'refresh' })))
              })
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})