import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  ICognitoUserAttributeData,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { siteOptions } from '../options/site-options';

export type CognitoServiceParams = {
  UserPoolId: string;
  ClientId: string;
};

const userPoolId = siteOptions.devPortalUserPoolId;
const clientId = siteOptions.devPortalClientId;

export class CognitoMfaRequiredError {}

export class CognitoUserNotDefined {}

/**
 * Performs Authentication to Cognito.  The returned promise will either return
 * a CognitoUserSession or the string "mfaRequired" if authentication is not
 * complete and an MFA code is needed. Authentication can be completed by calling "sendMFACode".
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<CognitoUserSession>}
 * @memberof CognitoService
 */
export function auth(username: string, password: string, environment?: CognitoServiceParams) {
  const authenticationDetails = getAuthenticationDetails(username, password);
  const cognitoUser = getCognitoUser(username, environment);

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        resolve(result);
      },
      mfaRequired: () => {
        reject(new CognitoMfaRequiredError() as Error);
      },
      onFailure: (error: unknown) => {
        reject(error as Error);
      },
    });
  });
}

/**
 * Confirms MFA code after an authentication by user name
 * @param code MFA code
 */
export async function sendMFACode(code: string): Promise<CognitoUserSession> {
  const currentUser = await getCurrentUser();
  return new Promise<CognitoUserSession>((resolve, reject) => {
    currentUser.sendMFACode(code, promisifyCallbackObject(resolve, reject), 'SMS_MFA');
  });
}

/**
 * Sets this device as a "remembered" device.  This prevents future MFA
 * checks on this device/user combination.  This can only be called AFTER
 * a user has logged in.
 */
export async function setDeviceStatusRemembered() {
  // Hack - had issues calling "setDeviceStatusRemembered" unless I
  // manually set the "signInUserSession" and ran "cacheDeviceKeyAndPassword".
  const session = await getSession();
  const cognitoUser = await getCurrentUser();

  cognitoUser['cacheDeviceKeyAndPassword']();
  cognitoUser.setSignInUserSession(session);
  return new Promise<string>((resolve, reject) => {
    cognitoUser.setDeviceStatusRemembered(promisifyCallbackObject(resolve, reject));
  });
}

export async function setDeviceStatusNotRemembered() {
  // Hack - had issues calling "setDeviceStatusRemembered" unless I
  // manually set the "signInUserSession" and ran "cacheDeviceKeyAndPassword".
  const session = await getSession();
  const cognitoUser = await getCurrentUser();

  cognitoUser['cacheDeviceKeyAndPassword']();
  cognitoUser.setSignInUserSession(session);
  return new Promise<string>((resolve, reject) => {
    cognitoUser.setDeviceStatusNotRemembered(promisifyCallbackObject(resolve, reject));
  });
}

/**
 * Creates a new user
 * @param username
 * @param password
 * @param attributes
 */
export function create(params: {
  username: string;
  password: string;
  attributes: object;
  environment?: CognitoServiceParams;
}) {
  const userPool: CognitoUserPool = getCognitoUserPool(params.environment);
  const cognitoUserAttributes = getCognitoUserAttributes(params.attributes);
  return new Promise<ISignUpResult>((resolve, reject) => {
    userPool.signUp(
      params.username,
      params.password,
      cognitoUserAttributes.map(data => new CognitoUserAttribute(data)),
      null,
      promisifyCallback(resolve, reject),
    );
  });
}

/**
 * Confirms registration of a new user using an emailed code
 * @param username
 * @param confirmationCode
 */
export function confirmRegistration(username: string, confirmationCode: string) {
  const cognitoUser = getCognitoUser(username);

  return new Promise<void>((resolve, reject) => {
    cognitoUser.confirmRegistration(confirmationCode, true, promisifyCallback(resolve, reject));
  });
}

/**
 * Resend a confirmation code from Cognito by user name
 * @param username
 */
export function resendConfirmationCode(username: string) {
  const cognitoUser = getCognitoUser(username);

  return new Promise<void>((resolve, reject) => {
    cognitoUser.resendConfirmationCode(promisifyCallback(resolve, reject));
  });
}

/**
 * Updates attributes of current user
 * @param attributes
 */
export async function updateUserAttributes(attributes: object): Promise<string> {
  const cognitoUser = await getCurrentUser();
  const cognitoUserAttributes = getCognitoUserAttributes(attributes);

  return new Promise<string>((resolve, reject) => {
    cognitoUser.getSession(() => {
      cognitoUser.updateAttributes(cognitoUserAttributes, promisifyCallback(resolve, reject));
    });
  });
}

/**
 * Changes a user password
 * @param oldPassword
 * @param newPassword
 */
export async function changePassword(oldPassword: string, newPassword: string): Promise<string> {
  const user = await getCurrentUser();

  user.getSession((err: unknown) => {
    if (err) {
      console.error(err);
      return;
    }
  });

  return new Promise<string>((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, promisifyCallback(resolve, reject));
  });
}

/**
 * Initiates forgot password sequence
 * @param username
 */
export function forgotPassword(username: string) {
  const cognitoUser = getCognitoUser(username);

  return new Promise<unknown>((resolve, reject) => {
    cognitoUser.forgotPassword(promisifyCallbackObject(resolve, reject));
  });
}

/**
 * Completes a forgot password sequence
 * @param username
 */
export function confirmPassword(username: string, verificationCode: string, password: string) {
  const cognitoUser = getCognitoUser(username);

  return new Promise<string>((resolve, reject) => {
    cognitoUser.confirmPassword(
      verificationCode,
      password,
      promisifyCallbackObject(resolve, reject),
    );
  });
}

/**
 * Deletes a user
 *
 * @param {any} username
 * @returns {Promise<string>}
 * @memberof CognitoService
 */
export function deleteUser(username: string): Promise<string> {
  const cognitoUser = getCognitoUser(username);

  return new Promise<string>((resolve, reject) => {
    cognitoUser.deleteUser(promisifyCallback(resolve, reject));
  });
}

/**
 * Retrieves user access token
 */
export async function getAccessToken() {
  const session = await getSession();
  return session.getAccessToken();
}

/**
 * Returns the CognitoUserAttributes for the current user
 */
export async function getUserAttributes(): Promise<CognitoUserAttribute[]> {
  const user = await getCurrentUser();
  return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
    user.getSession(() => {
      user.getUserAttributes(promisifyCallback(resolve, reject));
    });
  });
}

/**
 * Refreshes the current user session using a refresh token
 */
export async function refreshUserSession() {
  const [userSession, user] = await Promise.all([getSession(), getCurrentUser()]);
  const refreshToken = userSession.getRefreshToken();
  return new Promise<void>((resolve, reject) => {
    user.refreshSession(refreshToken, promisifyCallback(resolve, reject));
  });
}

/**
 * Signs out the current user
 *
 * @memberof CognitoService
 */
export async function signOut() {
  const currentUser = await getCurrentUser();
  currentUser.signOut();
}

/**
 * Returns a promise that resolves true if authenticated
 * and false if not.
 *
 * @returns {Promise<boolean>}
 * @memberof CognitoService
 */
export async function isCognitoUserAuthenticated() {
  try {
    const session = await getSession();
    return session.isValid();
  } catch {
    return false;
  }
}

/**
 * Retrieves the current user's CognitoUserSession
 *
 * @returns {Promise<CognitoUserSession>}
 * @memberof CognitoService
 */
async function getSession(): Promise<CognitoUserSession> {
  const currentUser = await getCurrentUser();
  return new Promise<CognitoUserSession>((resolve, reject) => {
    currentUser.getSession(promisifyCallback(resolve, reject));
  });
}

function getAuthenticationDetails(username: string, password: string): AuthenticationDetails {
  return new AuthenticationDetails({
    Username: username,
    Password: password,
  });
}

function getCurrentUser(environment?: CognitoServiceParams): Promise<CognitoUser> {
  return new Promise<CognitoUser>((resolve, reject) => {
    const currentUser = getCognitoUserPool(environment).getCurrentUser();

    if (!currentUser) {
      reject(new CognitoUserNotDefined() as Error);
    }

    return resolve(currentUser);
  });
}

function getCognitoUserPool(
  environment: CognitoServiceParams = {
    UserPoolId: userPoolId,
    ClientId: clientId,
  },
): CognitoUserPool {
  const cognitoUserPool = new CognitoUserPool(environment);
  return cognitoUserPool;
}

function getCognitoUser(username: string, environment?: CognitoServiceParams): CognitoUser {
  return new CognitoUser({
    Username: username,
    Pool: getCognitoUserPool(environment),
  });
}

function getCognitoUserAttributes(attributes: object): ICognitoUserAttributeData[] {
  const cognitoUserAttributes: ICognitoUserAttributeData[] = [];
  Object.getOwnPropertyNames(attributes).forEach(key => {
    const val = key === 'phone_number' ? cleanPhoneNumber(attributes[key]) : attributes[key];
    const data: ICognitoUserAttributeData = {
      Name: key,
      Value: val,
    };
    cognitoUserAttributes.push(data);
  });
  return cognitoUserAttributes;
}

function promisifyCallbackObject<T = unknown>(
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: unknown) => void,
) {
  return {
    onSuccess(data?: T) {
      resolve(data);
    },
    onFailure(err: unknown) {
      reject(err);
    },
  };
}

function promisifyCallback<T = unknown>(
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: unknown) => void,
) {
  return (error: unknown, result: T) => {
    if (error) {
      return reject(error);
    }

    return resolve(result);
  };
}

/**
 * Cognito is very specific in phone number format.  This function takes in various phone number
 * formats and returns an acceptable one.
 * @param phoneNumber
 */
function cleanPhoneNumber(phoneNumber: string) {
  const cleanPhone = phoneNumber.replace(/[-()\s]+/g, '');

  if (!cleanPhone.startsWith('+')) {
    // If 11, assume someone entered 1 (503) 867-5309, add '+'
    if (cleanPhone.length === 11) {
      return `+${cleanPhone}`;
    } else if (cleanPhone.length === 10) {
      // Assume area code + phone, add '+1'
      return `+1${cleanPhone}`;
    }
  }
  return cleanPhone;
}
