import { createAxiosCacheAdapterFactory, AxiosCacheSettings } from './axios-cache-adapter';
import axios, { AxiosAdapter } from 'axios';

export class AxiosAdapterBuilder {
  private cacheSettings: AxiosCacheSettings;

  constructor(private adapter: AxiosAdapter) {}

  cache(settings: AxiosCacheSettings): AxiosAdapterBuilder {
    this.cacheSettings = settings;
    return this;
  }

  build(): AxiosAdapter {
    let result = this.adapter;

    if (this.cacheSettings) {
      result = createAxiosCacheAdapterFactory(this.cacheSettings, result);
    }

    return result;
  }
}

export function createAxiosAdapterBuilderFactory(): AxiosAdapterBuilder {
  return new AxiosAdapterBuilder(axios.defaults.adapter);
}
