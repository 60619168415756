import * as aui from '@apiture/ui';
import { targetEnvLogin } from '../../services/legacy/target-env.service';
import { ExplorerTargetEnv } from '../../models/target-env';
import { MfaConfirmComponent } from './mfa-confirm.component';
import * as bootstrap from 'bootstrap';

export class TargetEnvLoginComponent {
  private $modalElement: JQuery<HTMLElement>;
  private $modal: bootstrap.Modal;
  private $modalEmailAddress: JQuery<HTMLElement>;
  private $modalPassword: JQuery<HTMLElement>;
  private $modalForm: JQuery<HTMLElement>;
  private $modalLoginButton: JQuery<aui.Components.AuiButton>;
  private $modalErrorMessage: JQuery<HTMLElement>;
  private $modalSignUpLink: JQuery<HTMLElement>;

  private openEnv: ExplorerTargetEnv;
  private explorerKey: string;
  private openResolve: (value: boolean) => void;

  constructor(private mfaConfirmComponent: MfaConfirmComponent) {}

  init(): void {
    this.$modalElement = $('#targetEnvLoginComponent');
    this.$modal = new bootstrap.Modal(this.$modalElement.get(0));
    this.$modalForm = this.$modalElement.find('#targetEnvLoginForm');
    this.$modalEmailAddress = this.$modalElement.find('#targetEnvLoginEmailAddress');
    this.$modalPassword = this.$modalElement.find('#targetEnvLoginPassword');
    this.$modalLoginButton = this.$modalElement.find(
      'aui-button[identifier="targetEnvLoginBtn"]',
    ) as any as JQuery<aui.Components.AuiButton>;
    this.$modalErrorMessage = this.$modalElement.find('#targetEnvLoginErrorMsg');
    this.$modalSignUpLink = this.$modalElement.find('#targetEnvLoginSignUpLink');

    this.$modalElement.on('hidden.bs.modal', this.onModalHidden.bind(this));
    this.$modalForm.on('submit', this.login.bind(this));

    $(() => {
      const validation = new FormValidations();

      validation.inputChecking();
      validation.initElement(
        this.$modalEmailAddress,
        this.$modalElement.find('#targetEnvLoginValidationEmail'),
        'keyup',
        'validatePasswordBlank',
      );
      validation.initElement(
        this.$modalPassword,
        this.$modalElement.find('#targetEnvLoginValidatePassword'),
        'keyup',
        'validatePasswordBlank',
      );
    });
  }

  open(env: ExplorerTargetEnv, explorerKey: string): Promise<boolean> {
    this.$modalElement.find('.modal-title').html(`Login to ${env.name}`);
    this.$modal.show();

    this.explorerKey = explorerKey;
    this.openEnv = env;
    this.$modalSignUpLink.attr('href', `${env.webUrl}/register`);

    return new Promise(resolve => {
      this.openResolve = resolve;
    });
  }

  private onModalHidden(): void {
    this.$modalErrorMessage.text('');

    if (this.openEnv) {
      this.openEnv = undefined;
      this.openResolve(false);
    }
  }

  private async login(): Promise<void> {
    const userName = this.$modalEmailAddress.val().toString().toLowerCase();
    const userPassword = this.$modalPassword.val() as string;

    this.$modalErrorMessage.text('');
    this.$modalLoginButton.get(0).active = true;

    try {
      const openResult = await targetEnvLogin({
        userName,
        userPassword,
        env: this.openEnv,
        explorerKey: this.explorerKey,
        sendMfaCode: async () => {
          this.$modal.hide();
          return await this.mfaConfirmComponent.open(userName, userPassword);
        },
      });

      this.openResolve(openResult);

      this.openEnv = undefined;
      this.$modal.hide();
    } catch (error) {
      this.$modalErrorMessage.text(error.message);
    }

    this.$modalLoginButton.get(0).active = false;
  }
}
