<template>
  <div
    ref="dropdown"
    :data-test="props.name ? `${props.name}-dropdown` : 'dropdown'"
  >
    <button
      ref="dropdownButton"
      type="button"
      :class="[
        props.dropdownButtonClass ? props.dropdownButtonClass : 'dropdown--button-default',
        'dropdown--button-base',
      ]"
      aria-haspopup="true"
      :aria-expanded="isExpanded"
      :data-test="props.name ? `${props.name}-dropdown-toggle` : 'dropdown-toggle'"
      :aria-label="props.name"
      :disabled="props.disable"
      :title="`Toggle ${startCase(props.name) ?? 'dropdown'}`"
      @click.prevent.stop="toggleDropdown"
    >
      {{ props.label }}
      <slot
        ref="dropdownButtonIcon"
        name="labelIcon"
      />
    </button>
    <div
      v-show="isExpanded"
      :class="'dropdown'"
      :data-test="props.name ? `${props.name}-dropdown-content` : 'dropdown-content'"
    >
      <div class="dropdown-container flex-col">
        <div
          :class="[
            props.dropdownContentClass ? props.dropdownContentClass : 'dropdown--content-default',
            !props.removePointer && 'dropdown-content-pointer',
            'dropdown-content-base',
          ]"
          @click="handleCloseOnInsideClick()"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useDetectOutsideClick } from '../../hooks/detect-outside-click.hook';
import { startCase } from 'lodash';

const props = withDefaults(
  defineProps<{
    label?: string;
    dropdownContentClass?: string;
    dropdownButtonClass?: string;
    name?: string;
    disable?: boolean;
    removePointer?: boolean;
    closeOnInsideClick?: boolean;
  }>(),
  {
    label: '',
    dropdownContentClass: '',
    dropdownButtonClass: '',
    name: '',
    disable: false,
    removePointer: false,
    closeOnClickInside: false,
  },
);

const isExpanded = ref(false);
const dropdown = ref<HTMLDivElement>();

useDetectOutsideClick(dropdown, () => {
  if (!isExpanded.value) {
    return;
  }

  isExpanded.value = false;
});

function handleCloseOnInsideClick() {
  if (!isExpanded.value || !props.closeOnInsideClick) {
    return;
  }

  isExpanded.value = false;
}

function toggleDropdown() {
  isExpanded.value = !isExpanded.value;
}
</script>

<style lang="scss" scoped>
div {
  height: min-content;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdown--button-default {
  display: inline-flex;
  font-weight: 400;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.dropdown--button-base {
  background: none;
  background-color: transparent;
  border: none;
  display: inline-flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .formkit-icon {
    height: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
  }
}

.dropdown-container {
  position: relative;
}

.dropdown--content-default {
  box-shadow: 0px 3px 12px 0px rgba(19, 35, 52, 0.15);
  font-weight: 400;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  gap: 1rem;
  background-color: #fff;
}

:deep(.dropdown-content-base) {
  z-index: 49;
  position: absolute;
  color: #000;
  max-width: 16rem;
  min-width: 8rem;
  width: max-content;
  background-color: #fff;
  margin-top: 0.6rem;

  * {
    text-decoration: none;
  }

  ul {
    max-height: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label span {
      padding-left: 0;
    }
  }

  > div {
    width: 100%;
  }

  button,
  a {
    background-color: transparent;
    width: 100%;
    padding: 0;
    display: flex;
    text-decoration: none;
    color: black;

    svg {
      margin-right: 0.25rem;
    }
  }

  button:hover,
  a:hover {
    text-decoration: underline;
  }
}

.dropdown-content-pointer:before {
  position: absolute;
  inset: 0;
  clip-path: polygon(50% 55%, 0% 100%, 100% 100%);
  width: 2rem;
  height: 2rem;
  z-index: 48;
  transform: translateY(-100%);
  margin: 0.1rem auto 0 auto;
  background-color: #fff;
  content: '';
}

:deep(button svg) {
  align-self: center;
}
</style>
