// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-form ::v-deep .form-array-item:not(:first-child) .tooltip {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/legacy/partner-organization-invite-members.component.vue","webpack://./partner-organization-invite-members.component.vue"],"names":[],"mappings":"AAII;EACE,aAAA;ACHN","sourcesContent":["\n// formerly a scoped style\n.invite-form ::v-deep {\n  .form-array-item:not(:first-child) {\n    .tooltip {\n      display: none;\n    }\n  }\n}\n",".invite-form ::v-deep .form-array-item:not(:first-child) .tooltip {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
