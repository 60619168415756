import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  role: "tablist",
  class: "tablist"
}
const _hoisted_2 = ["data-test", "onClick"]
const _hoisted_3 = ["id"]

import { shallowRef, onBeforeMount } from 'vue';
import { camelCase } from 'lodash';
import { convertValueToUrlPath, pushValueToHistory } from '../../helpers/window-helper';
import { usePopStateEvent } from '../../hooks/handle-popstate-event.hook';


export default /*@__PURE__*/_defineComponent({
  __name: 'tab-list.component',
  props: {
    labels: {},
    initialTab: {}
  },
  emits: ["tabChanged"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const selectedTabIndex = shallowRef<number>(0);

onBeforeMount(() => {
  if (!props.initialTab) {
    return;
  }

  const initialTabIndex = labels.indexOf(camelCase(props.initialTab));

  if (initialTabIndex !== -1) {
    selectedTabIndex.value = initialTabIndex;
  }
});

const labels = props.labels.map(label => convertValueToUrlPath(label));

usePopStateEvent(labels, handleChange);

function handleChange(index: string | number | symbol) {
  const formattedLabels = props.labels.map(label => convertValueToUrlPath(label));
  pushValueToHistory(formattedLabels, Number(index));
  selectedTabIndex.value = Number(index);

  emit('tabChanged');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.labels, (label, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: label,
          role: "presentation",
          class: _normalizeClass(["tablist--tab remove-list-bullets cursor-pointer", Number(index) === selectedTabIndex.value && 'tablist--tab-active']),
          "data-test": `${_unref(camelCase)(label)}-tab`,
          onClick: ($event: any) => (handleChange(index))
        }, [
          _createElementVNode("a", {
            id: label + index.toString(),
            role: "tab",
            target: "_self"
          }, _toDisplayString(label), 9, _hoisted_3)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.labels, (label, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `${label}-content`
      }, [
        (Number(index) === selectedTabIndex.value)
          ? _renderSlot(_ctx.$slots, `cell(${_unref(camelCase)(label)})`, { key: 0 })
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})