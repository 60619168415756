<template>
  <div>
    <PartnerDomainInvalidWarningComponent />
    <AppAlertComponent v-if="!profileFormData.termsConditionsAccepted || !profileFormData.certify">
      You will not be able to edit existing client applications, view or manage your API keys, or
      create new client applications until you accept the Terms and Conditions and certify that your
      profile information is correct.
    </AppAlertComponent>
    <FormKit
      id="profileForm"
      v-model="profileFormData"
      type="form"
      submit-label="Save"
      :actions="false"
      data-test="profileForm"
      @submit="saveProfile()"
    >
      <div class="form-container--row">
        <FormKit
          type="text"
          name="firstName"
          label="First Name"
          validation="required"
          outer-class="form-input--full"
          data-test="firstName"
        />
        <FormKit
          type="text"
          name="middleName"
          label="Middle Name"
          outer-class="form-input--full"
          data-test="middleName"
        />
        <FormKit
          type="text"
          name="lastName"
          label="Last Name"
          outer-class="form-input--full"
          data-test="lastName"
        />
        <FormKit
          type="text"
          name="suffix"
          label="Suffix"
          outer-class="form-input--full"
          data-test="suffix"
        />
      </div>
      <div class="form-container--row">
        <FormKit
          type="date"
          name="birthDate"
          label="Date of Birth"
          validation="required|date_before"
          outer-class="form-input--full"
          data-test="birthDate"
        />
        <FormKit
          type="email"
          name="email"
          label="Email"
          validation="required"
          :disabled="true"
          outer-class="form-input--full"
          data-test="email"
        />
        <FormKit
          type="tel"
          name="phoneNumber"
          label="Phone number"
          placeholder="xxx-xxx-xxxx"
          :validation="[['matches', '/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/']]"
          :validation-messages="{
            matches: 'Phone number must be in the format xxx-xxx-xxxx',
          }"
          validation-visibility="live"
          outer-class="form-input--full"
          data-test="phoneNumber"
        />
      </div>
      <FormKit
        type="radio"
        name="citizen"
        label="Are you a U.S. Citizen?"
        :options="['Yes', 'No']"
        data-test="citizen"
        options-class="form-input--citizen"
      />
      <div>
        <FormKit
          type="checkbox"
          label="I hereby certify, to the best of my knowledge, that the information provided above is complete and correct"
          name="certify"
          validation="accepted"
          validation-visibility="dirty"
          help-class="ms-5"
          help="This information enables us to better understand our developer community, ensure the security of our APIs and components, and satisfy regulatory obligations. Both providing and then certifying your U.S. citizenship status is a condition to creating applications using our APIs and components. Please note, however, that the value you select for U.S. citizenship status will not limit or affect your ability to create applications and interact with the Apiture Developer Portal."
          :validation-messages="{
            accepted: 'This field is required.',
          }"
          data-test="certify"
        />
        <FormKit
          type="checkbox"
          label="I accept the terms and conditions of the Apiture Open API License Agreement."
          :sections-schema="{
            label: {
              children: [
                'I accept the ',
                {
                  $el: 'a',
                  attrs: {
                    href: '/docs/Apiture-Open-API-License-Agreement.pdf',
                    target: 'blank',
                  },
                  children: '&nbsp;terms and conditions&nbsp;',
                },
                ' of the Apiture Open API License Agreement.',
              ],
            },
          }"
          name="termsConditionsAccepted"
          validation="accepted"
          validation-visibility="dirty"
          :validation-messages="{
            accepted: 'This field is required.',
          }"
          data-test="termsConditionsAccepted"
        />
      </div>
      <FormKit
        type="submit"
        :disabled="loading"
        label="Save"
        data-test="profileFormSubmit"
      />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { updateUser, getUser } from '../../services/legacy/auth.service';
import PartnerDomainInvalidWarningComponent from './partner-domain-invalid-warning.component.vue';
import AppAlertComponent from './app-alert.component.vue';

type MyProfileFormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  birthDate: string;
  email: string;
  phoneNumber: string;
  citizen: boolean;
  certify: boolean;
  termsConditionsAccepted: boolean;
};

const profileFormData = ref<MyProfileFormData>({
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  birthDate: '',
  email: '',
  phoneNumber: '',
  citizen: false,
  certify: false,
  termsConditionsAccepted: false,
});

const loading = ref<boolean>(false);
const user = getUser();

onMounted(() => {
  fillFormData();
});

async function saveProfile() {
  // TODO: update when Okta auth is added to users.
  // Since okta is admin only we don't need to update the profile.
  if (user.auth === 'okta') {
    return;
  }

  if (!profileFormData.value.termsConditionsAccepted) {
    showToastr({
      type: 'error',
      message: 'You must accept terms and conditions.',
    });
    return;
  }
  try {
    loading.value = true;
    await updateUser(getUserPatch());
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  fillFormData();

  showToastr({
    type: 'success',
    message: 'You profile has been updated',
  });

  setTimeout(() => {
    window.location.href = '/profile/business';
  }, 3000);
}

function getUserPatch() {
  return {
    ...user,
    ...profileFormData.value,
  };
}

function fillFormData() {
  Object.assign(profileFormData.value, {
    firstName: user.firstName,
    lastName: user.lastName,
    middleName: user.middleName,
    birthDate: user.birthDate,
    suffix: user.suffix,
    termsConditionsAccepted: user.termsConditionsAccepted,
    citizen: user.citizen ? 'Yes' : 'No',
    email: user.email,
    phoneNumber: formatPhoneNumber(user.phoneNumber),
    certify: user.certify,
  });
}

function formatPhoneNumber(phoneNumber: string) {
  const withoutCountryCode = phoneNumber.includes('+1')
    ? phoneNumber.substring(2, user.phoneNumber.length)
    : phoneNumber;

  const areaCode = withoutCountryCode.substring(0, 3);
  const prefix = withoutCountryCode.substring(3, 6);
  const lineNumber = withoutCountryCode.substring(6, 10);

  return `${areaCode}-${prefix}-${lineNumber}`;
}
</script>

<style>
.form-input--citizen {
  min-height: min-content;
}
</style>
