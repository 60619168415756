<template>
  <fieldset
    class="form-input--scopes-fieldset"
    data-test="scopes"
  >
    <legend>
      Scopes
      <ToolTipComponent
        tip="Select which authorization scopes your application requires. The API reference documentation lists the Scopes required to call each operation."
      />
    </legend>
    <div class="form-input form-input--scopes">
      <div class="form-input-scopes--excludeWriteScopes">
        <input
          id="excludeWriteScopes"
          type="checkbox"
          class="form-input--option"
          data-test="excludeWriteScopes"
          :checked="props.context.value?.excludeWriteScopes ?? false"
          @input="handleExcludeWriteScopes"
        >
        <label for="excludeWriteScopes">Exclude all write scopes</label>
      </div>
      <ul
        data-test="scopes-list"
        class="form-input--option-list form-input--scrollable"
      >
        <li
          v-for="(scope, i) in props.context.scopes"
          :key="scope.name"
          class="form-input--scopes-option"
        >
          <span class="flex-row--gap align-items-center">
            <input
              :id="`scope-${Number(i) + 1}`"
              class="form-input--option"
              type="checkbox"
              :aria-describedby="`${scope.name}-description`"
              :value="scope.name"
              :disabled="excludeWriteScope(scope.name)"
              :checked="shouldCheck(scope.name)"
              :data-test="`scope-${Number(i) + 1}`"
              @change.stop="handleScopeExclusion"
            >
            <label
              :for="`scope-${Number(i) + 1}`"
              :data-test="`scope-label-${Number(i) + 1}`"
              class="form-input--wrapper scopes-input--wrapper"
            >
              {{ scope.name }}
            </label>
          </span>
          <p
            :id="`${scope.name}-description`"
            class="formkit-option-help"
          >
            {{ scope.description }}
          </p>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
import { AuthorizationScope } from '@apiture/api-products-client-sdk';
import ToolTipComponent from './tooltip.component.vue';

const props = defineProps<{
  context: FormKitFrameworkContext<{
    excludedScopes: string[];
    excludeWriteScopes: boolean;
  }> & {
    scopes: AuthorizationScope[];
  };
}>();

function handleScopeExclusion(
  event: Event & {
    target: HTMLInputElement;
  },
) {
  const excludedScopes = props.context.value?.excludedScopes
    ? [...props.context.value.excludedScopes]
    : [];

  if (!event.target.checked) {
    props.context.node.input({
      ...props.context.value,
      excludedScopes: [...excludedScopes, event.target.value],
    });
  } else {
    props.context.node.input({
      ...props.context.value,
      excludedScopes: excludedScopes.slice(excludedScopes.indexOf(event.target.value), 1),
    });
  }
}

function handleExcludeWriteScopes(event: Event & { target: HTMLInputElement }) {
  props.context.node.input({
    ...props.context.value,
    excludeWriteScopes: event.target.checked,
  });
}

function excludeWriteScope(scopeName: string) {
  return props.context.value?.excludeWriteScopes && scopeName.includes('/write');
}

function shouldCheck(scopeName: string) {
  if (!props.context.value?.excludedScopes) {
    return !excludeWriteScope(scopeName);
  }

  return !props.context.value?.excludedScopes.includes(scopeName) && !excludeWriteScope(scopeName);
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.form-input--scopes {
  min-height: 10rem;
}

.form-input--scopes-fieldset {
  border: none;
  padding: 0;

  legend {
    display: inline-flex;
  }
}

.form-input-scopes--inner {
  height: 20rem;
  max-height: 20rem;
  padding: 1rem;
}

.form-input--scopes-option {
  border-bottom: 1px solid $app-neutral-gray-20;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.25rem;
  gap: 3rem;

  .formkit-option-help {
    @include md {
      margin: 0;
    }
  }

  @include md {
    flex-direction: row;
    justify-content: space-between;

    p {
      width: 60%;
    }
  }
}

.scopes-input--wrapper {
  input {
    margin-right: 0.5rem;
  }
}

.form-input-scopes--excludeWriteScopes {
  margin: 0 1rem;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $app-neutral-gray-20;

  label {
    align-self: center;
    margin-left: 0.25rem;
  }
}
</style>
