<template>
  <div :class="'mode-' + mode">
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <template v-else>
      <div class="form-container--row">
        <FormKit
          v-if="!props.applicationId"
          id="productForm"
          v-model="applicationFilterData"
          type="group"
          @input="onFilterChange()"
        >
          <FormKit
            v-if="props.mode === 'user'"
            type="checkbox"
            label="I am owner"
            name="isOwner"
            outer-class="form-input--sm align-self-center"
          />
          <FormKit
            v-if="props.mode === 'admin'"
            type="text"
            label="Owner"
            name="owner"
            help="Filter client applications to match only those owned by the named user, as identified by their email address."
            outer-class="form-input--full"
            placeholder="Select an Owner"
          />
          <FormKit
            type="select"
            label="Product"
            name="product"
            help="Filter applications to match only those which use the named product."
            :options="mapProductsToOptions(products, true)"
            outer-class="form-input--full"
            placeholder="Select a Product"
          />
          <FormKit
            type="select"
            label="Environment"
            name="environment"
            help="Filter applications to match only those which deploy in the named environment."
            :options="mapEnvironmentsToOptions(environments)"
            outer-class="form-input--full"
            placeholder="Select an Environment"
          />
          <FormKit
            type="select"
            label="State"
            name="state"
            help="Filter applications to match only those which in the selected state."
            :options="[
              '',
              ClientState.Active,
              ClientState.Pending,
              ClientState.Inactive,
              ClientState.Rejected,
            ]"
            outer-class="form-input--full"
            placeholder="Select an Application State"
          />
        </FormKit>
      </div>
      <div class="mt-4">
        <AppTableComponent
          :fields="['Type', 'Authentication', 'Name', 'Products', 'State', 'Actions']"
          :items="state.applicationData"
          :busy="state.loading"
          tbody-tr-class="cursor-pointer"
          @row-clicked="onRowClicked"
        >
          <template #cell(type)="{ row }: { row: { item: any } }">
            {{ row.item.application.type }}
          </template>
          <template #cell(authentication)="{ row }: { row: { item: any } }">
            {{ row.item.application.authentication }}
          </template>
          <template #cell(name)="{ row }: { row: { item: any } }">
            <p>
              {{
                row.item.application.name.length > 20
                  ? `${row.item.application.name.substring(0, 20)}...`
                  : row.item.application.name
              }}
            </p>
          </template>
          <template #cell(products)="{ row }: { row: { item: any } }">
            <ul v-if="row.item.application.products.length >= 1">
              <li
                v-for="product in row.item.application.products"
                :key="product._links.self.href"
              >
                {{ product.name }}
              </li>
            </ul>
          </template>
          <template #cell(actions)="{ row }: { row: { item: any } }">
            <div class="table-actions-btn-group">
              <FormKit
                type="button"
                input-class="btn btn-sm btn-primary table-input--button"
                @click="showDetails(row.item)"
              >
                Details
              </FormKit>
              <template v-if="props.mode === 'admin' && !props.keyId">
                <!-- prefix-icon="fa fa-toggle-on" -->
                <FormKit
                  v-if="
                    row.item.application.state === 'inactive' ||
                      row.item.application.state === 'rejected'
                  "
                  type="button"
                  input-class="btn btn-sm btn-success table-input--button"
                  @click="activateSelectedApplication(row.item)"
                >
                  Activate
                </FormKit>
                <!-- <i class="fa fa-toggle-off"></i> -->
                <FormKit
                  v-if="row.item.application.state === 'active'"
                  type="button"
                  input-class="btn btn-sm btn-danger table-input--button"
                  @click="revokeApplication(row.item)"
                >
                  Revoke
                </FormKit>
                <!-- <i class="fa fa-check-circle-o"></i> -->
                <FormKit
                  v-if="row.item.application.state === 'pending'"
                  type="button"
                  input-class="btn btn-sm btn-success table-input--button"
                  @click="approveApplication(row.item)"
                >
                  Approve
                </FormKit>
                <!-- <i class="fa fa-ban"></i> -->
                <FormKit
                  v-if="row.item.application.state !== 'rejected'"
                  type="button"
                  input-class="btn btn-sm btn-danger table-input--button"
                  @click="rejectSelectedApplication(row.item)"
                >
                  Reject
                </FormKit>
              </template>
              <template v-if="props.mode === 'user' && row.item.owned">
                <a
                  type="button"
                  class="btn btn-sm btn-secondary p-2 table-input--button"
                  :href="'/apps/update/?applicationId=' + row.item.application._id"
                >
                  <template v-if="row.item.application.state === 'rejected'">
                    <!-- <i class="fa fa-external-link-square"></i> -->
                    View
                  </template>
                  <template v-else>
                    <!-- <i class="fa fa-pencil-square-o"></i> -->
                    Edit
                  </template>
                </a>
                <a
                  type="btn btn-sm"
                  class="btn btn-sm btn-success p-2 table-input--button"
                  :href="
                    '/profile/applicationInviteOwner/?applicationId=' + row.item.application._id
                  "
                >
                  <!-- <i class="fa fa-user-plus"></i> -->
                  Invite
                </a>
                <template v-if="row.item.application.state !== 'rejected'">
                  <!-- <i class="fa fa-toggle-on"></i> -->
                  <FormKit
                    v-if="
                      row.item.application.state === 'inactive' &&
                        !!row.item.application._links['apiture:activate']
                    "
                    type="button"
                    input-class="btn btn-sm btn-success table-input--button"
                    @click="reactivateApplication(row.item)"
                  >
                    Reactivate
                  </FormKit>
                  <!-- <i class="fa fa-toggle-off"></i> -->
                  <FormKit
                    v-if="row.item.application.state !== 'inactive'"
                    type="button"
                    input-class="btn btn-sm btn-warning table-input--button"
                    @click="deactivateSelectedApplication(row.item)"
                  >
                    Deactivate
                  </FormKit>
                  <FormKit
                    type="button"
                    input-class="btn btn-sm btn-danger table-input--button"
                    @click="deleteSelectedApplication(row.item)"
                  >
                    Delete
                  </FormKit>
                </template>
              </template>
            </div>
          </template>
        </AppTableComponent>
        <CursorPaginationComponent
          v-if="!props.applicationId"
          :next="!!state.pagination.next"
          :previous="!!state.pagination.previous.length"
          :limit="Number(state.pagination.limit)"
          @on-change="onPaginationChange"
        />
      </div>
      <AppModalComponent
        id="selectedApplicationModal"
        title="Application Details"
        :open-modal="state.openModal"
        @on-modal-close="() => (state.openModal = false)"
      >
        <ApplicationDetailsComponent
          v-if="Boolean(state.selectedApplication)"
          :mode="props.mode"
          :application="state.selectedApplication"
          :application-products="state.selectedApplicationProducts"
          :key-id="props.keyId"
          @on-key-activated="refreshApplication(state.selectedApplication)"
          @on-key-approved="refreshApplication(state.selectedApplication)"
        />
      </AppModalComponent>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, onMounted, ref } from 'vue';
import { chain, includes } from 'lodash';
import { ClientState, Application } from '@apiture/client-applications-client-sdk';
import { Subject, from, EMPTY, takeUntil, switchMap, tap, map, catchError } from 'rxjs';
import { Messages } from '../../constants/messages';
import {
  getApplication,
  getApplications,
  activateApplication,
  deactivateApplication,
  deleteApplication,
  rejectApplication,
} from '../../services/api/applications-api.service';
import { confirmDialog } from '../../services/legacy/dialog.service';
import {
  clearNavState,
  processEvent,
  processLinks,
  CursorPaginationEvent,
  createCursorPagination,
  CursorPagination,
} from '../../helpers/cursor-pagination-helper';
import { getUser } from '../../services/legacy/auth.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { applicationTypesNames } from '../../services/apis.service';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import ApplicationDetailsComponent from './application-details.component.vue';
import AppModalComponent from '../legacy/app-modal.component.vue';
import AppTableComponent from '../legacy/app-table.component.vue';
import CursorPaginationComponent from '../legacy/cursor-pagination.component.vue';
import { useEnvironments, mapEnvironmentsToOptions } from '../../hooks/use-environments.hook';
import { useProducts, mapProductsToOptions } from '../../hooks/use-products.hook';
import { setUrlParams, getIdFromUri, addUrlParamsToObject } from '../../helpers/window-helper';
import { ProductLine } from '../../utils/product-lines';

interface ApplicationData {
  application: Application;
  applicationTypeName: string;
  applicationAuthenticationName: string;
  owned: boolean;
  products: {
    id: string;
    title: string;
  }[];
}

interface ApplicationFilterData {
  owner: string;
  isOwner: boolean;
  product: string;
  environment: string;
  state: ClientState;
}

const props = withDefaults(
  defineProps<{
    mode?: 'user' | 'admin';
    applicationId?: string;
    keyId?: string;
    productLine: ProductLine;
  }>(),
  { mode: 'user', applicationId: '', keyId: '' },
);

const applicationFilterData = ref<ApplicationFilterData>({
  owner: '',
  isOwner: true,
  product: '',
  environment: '',
  state: null,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
  saving: boolean;
  applicationData: ApplicationData[];
  selectedApplication: Application;
  selectedApplicationProducts: string[];
  openModal: boolean;
  pagination: CursorPagination;
}>({
  initialized: false,
  loading: false,
  saving: false,
  applicationData: [],
  selectedApplication: null,
  selectedApplicationProducts: null,
  openModal: false,
  pagination: createCursorPagination(),
});

const environments = useEnvironments(props.productLine);
const products = useProducts(props.productLine);
const user = getUser();

const searchSubject = new Subject<{
  query: {
    start: string;
    limit: number;
    owner: string;
    product: string;
    environment: string;
    state: ClientState[];
    productLine: ProductLine;
  };
  delay: boolean;
}>();
const destroySubject = new Subject();

onMounted(() => {
  if (props.mode === 'user' && user.role === 'Admin') {
    applicationFilterData.value.isOwner = true;
  }

  addUrlParamsToObject<ApplicationFilterData>(applicationFilterData.value);

  if (!props.applicationId) {
    initSearchHandler();
  }

  state.value.initialized = true;
  refresh();
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function onFilterChange(): void {
  clearNavState(state.value.pagination);
  search(true);
}

function onPaginationChange(evt: CustomEvent<CursorPaginationEvent>): void {
  processEvent(state.value.pagination, evt as CursorPaginationEvent);
  search();
}

function onRowClicked(data: { item: ApplicationData }): void {
  if (data.item.owned) {
    showDetails(data.item);
  }
}

function showDetails(data: ApplicationData): void {
  state.value.selectedApplication = data.application;
  state.value.selectedApplicationProducts = data.products.map(p => p.title);
  state.value.openModal = true;
}

async function activateSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to activate <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Activate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully activated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function revokeApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to revoke <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Revoke',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully revoked.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function approveApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to approve <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'check-circle-o',
          label: 'Approve',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully approved.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function rejectSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to reject <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'ban',
          label: 'Reject',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await rejectApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Client application has been successfully rejected`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function reactivateApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to reactivate <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Reactivate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      const application = await activateApplication(data.application._id);

      if (application.state !== ClientState.Active) {
        showToastr({
          type: 'info',
          message: `We have received your request to restore a deactivated application.
            Your application has been switched to the Pending state.value.
            Activation requires Apiture approval and intervention and thus may take some time.`,
        });
      } else {
        showToastr({
          type: 'success',
          message: `Application has been successfully restored.`,
        });
      }
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deactivateSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Please confirm you wish to deactivate <b>${data.application.name}</b>.
          Deactivation is immediate and users who are using your application in the corresponding target environment(s) will lose access to the application.
          Re-activating a deactivated client application or API key requires manual administrative approval from Apiture.`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Deactivate',
          class: 'btn-warning',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateApplication(data.application._id);
      showToastr({
        type: 'success',
        message: `Application has been successfully deactivated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deleteSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to delete <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'trash-o',
          label: 'Delete',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deleteApplication(data.application._id);
      showToastr({
        type: 'success',
        message: `Application has been successfully deleted.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function refreshApplication(application: Application): Promise<void> {
  const currentApplication = await getApplication(application._id);
  Object.assign(application, currentApplication);
}

function initSearchHandler(): void {
  searchSubject
    .pipe(
      takeUntil(destroySubject),
      tap(() => {
        state.value.loading = true;
      }),
      switchMap(params =>
        from(getApplications(params.query)).pipe(
          map(response => {
            return {
              query: params.query,
              response,
            };
          }),
          catchError(() => {
            showToastr({ type: 'error', message: Messages.internalError });
            return EMPTY;
          }),
        ),
      ),
      tap(() => {
        for (const paramKey in applicationFilterData.value) {
          if (paramKey === 'isOwner') continue;
          setUrlParams(paramKey, applicationFilterData.value[paramKey]);
        }
        state.value.loading = false;
      }),
    )
    .subscribe(result => {
      fillData(result.response._embedded.items);
      processLinks(state.value.pagination, result.response._links);
    });
}

function fillData(applications: Application[]): void {
  const productOptions = mapProductsToOptions(products.value, false);

  state.value.applicationData = applications.map(application => {
    return {
      application,
      applicationTypeName: applicationTypesNames.get(application.type),
      applicationAuthenticationName: applicationAuthenticationsNames.get(
        application.authentication,
      ),
      owned: includes(application.owners, user.email),
      products: chain(application.products)
        .map(product => {
          const id = getIdFromUri(product._links['self'].href);
          const oneOf = productOptions.find(o => o.value === id);

          return {
            id,
            title: oneOf?.label,
          };
        })
        .sortBy(item => item.title)
        .value(),
    };
  });
}

function search(delay: boolean = false): void {
  const filter = applicationFilterData.value;
  const owner: string = (() => {
    switch (props.mode) {
      case 'admin':
        return filter.owner?.toLowerCase();
      case 'user':
        if (!filter.isOwner) return '';
        return user.email.toLowerCase();
      default:
        return '';
    }
  })();

  searchSubject.next({
    delay,
    query: {
      owner,
      start: state.value.pagination.start,
      limit: state.value.pagination.limit,
      environment: filter.environment,
      state: filter.state ? [filter.state] : undefined,
      product: filter.product,
      productLine: props.productLine,
    },
  });
}

async function refresh(): Promise<void> {
  if (props.applicationId) {
    await loadApplication();
    if (state.value.applicationData.length) {
      showDetails(state.value.applicationData[0]);
    }
  } else {
    search();
  }
}

async function loadApplication(): Promise<void> {
  state.value.loading = true;
  let application: Application;
  try {
    application = await getApplication(props.applicationId);
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
    return;
  } finally {
    state.value.loading = false;
  }
  fillData([application]);
}
</script>
