<template>
  <div>
    <FormKit
      id="adminLogin"
      type="form"
      :actions="false"
    >
      <h1>Admin Login &#129323;</h1>
      <p>You will be redirected to login with your Okta credentials.</p>
      <FormKit
        id="login"
        type="button"
        input-class="oktaLoginBtn"
        :errors="[error]"
        :disabled="Boolean(error)"
        @click="oktaLogin()"
      >
        Login with Okta
      </FormKit>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue';
import { oktaAuthClient, getOktaAccessToken } from '../../services/okta-auth.service';

const error = ref<string>('');

onBeforeMount(async () => {
  if (!oktaAuthClient.features.isPKCESupported()) {
    error.value = 'Your web browser does not support PKCE.';
    return;
  }

  const currentAccessToken = await getOktaAccessToken();

  if (!currentAccessToken) {
    return;
  }

  window.location.replace('/dashboard');
});

function oktaLogin() {
  sessionStorage.clear();

  oktaAuthClient.token.getWithRedirect();
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

:deep(.oktaLoginBtn) {
  width: 100%;
  background-color: $app-apiture-blue;
  color: $app-neutral-white;
  border: none;
  border-radius: 3rem;
  height: 2rem;

  &:hover {
    background-color: $app-button-hover;
  }
}

form {
  background: #f2efe9;
}
</style>
