<template>
  <NavigationContainer>
    <template #default="{ isExpanded, baseUrl }">
      <li>
        <ul>
          <li>
            <a :href="`${baseUrl}/admin/dashboard`">Dashboard</a>
          </li>
          <li>
            <a
              href="https://dx.apiture.com"
              target="_blank"
            >Developer Docs <NewTabSvg /></a>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li v-if="user && user.role === 'Admin'">
            <DropdownComponent
              label="Admin"
              dropdown-button-class="nav--dropdown-button-profile"
              dropdown-content-class="nav--dropdown-content"
              :remove-pointer="isExpanded"
            >
              <template #labelIcon>
                <CaretDown />
              </template>
              <ul>
                <li>
                  <a
                    id="adminWebhooksLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/webhooks`"
                  >
                    Webhooks
                  </a>
                </li>
                <li>
                  <a
                    id="adbAdminApplicationsLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/applications`"
                  >
                    ADB Applications
                  </a>
                </li>
                <li>
                  <a
                    id="adbAdminExplorerKeysLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/api-keys/explorerKeys`"
                  >
                    ADB Explorer Keys
                  </a>
                </li>
                <li>
                  <a
                    id="adbAdminDiscovererKeysLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/api-keys/discovererKeys`"
                  >
                    ADB Discoverer Keys
                  </a>
                </li>
                <li>
                  <a
                    id="productsLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/products/`"
                  >
                    ADB Products
                  </a>
                </li>
                <li>
                  <a
                    id="environmentsLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/environments/`"
                  >
                    ADB Environments
                  </a>
                </li>
                <li>
                  <a
                    id="apiKeysLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/openAdmin/api-keys/`"
                  >
                    Open Applications and Keys
                  </a>
                </li>
                <li>
                  <a
                    id="productsLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/openAdmin/products/`"
                  >
                    Open Products
                  </a>
                </li>
                <li>
                  <a
                    id="environmentsLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/openAdmin/environments/`"
                  >
                    Open Environments
                  </a>
                </li>
              </ul>
            </DropdownComponent>
          </li>
          <li v-if="user">
            <DropdownComponent
              :label="`${user.firstName} ${user.lastName}`"
              dropdown-button-class="nav--dropdown-button-profile"
              dropdown-content-class="nav--dropdown-content"
              :remove-pointer="isExpanded"
            >
              <template #labelIcon>
                <CaretDown />
              </template>
              <ul>
                <li>
                  <a
                    id="accountLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/admin/account/`"
                  >
                    My Account
                  </a>
                </li>
                <li>
                  <a
                    id="logoutLink"
                    class="nav--dropdown-item"
                    href="javascript:"
                    @click="logout()"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </DropdownComponent>
          </li>
          <li v-if="!user">
            <a href="/auth/admin">Login</a>
          </li>
        </ul>
      </li>
    </template>
  </NavigationContainer>
</template>

<script lang="ts" setup>
import { getAdminUser } from '../../services/portal-auth.service';
import DropdownComponent from '../portal/dropdown.component.vue';
import NewTabSvg from '../portal/svg/new-tab.component.vue';
import NavigationContainer from '../portal/navigation-container.component.vue';
import { removeOktaTokens } from '../../services/okta-auth.service';
import { clearSessionStorage } from '../../services/storage.service';

const user = getAdminUser();

async function logout() {
  try {
    await removeOktaTokens();
  } catch {
    clearSessionStorage();
  }

  window.location.replace('/auth/admin/');
}
</script>
