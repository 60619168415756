import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "d-none" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form-container--submits" }
const _hoisted_5 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_6 = { class: "row align-items-start" }
const _hoisted_7 = ["href"]

import { onUnmounted, onMounted, ref, markRaw } from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { last, chain } from 'lodash';
import jsYaml from 'js-yaml';
import URI from 'urijs';
import { combineLatestWith, take, Subject, finalize, takeUntil, map } from 'rxjs';
import { getApiDocument, getApis, Api } from '../../services/apis.service';
import { apiKey$, env$, targetEnvAccessToken$ } from '../../services/legacy/target-env.service';
import { TargetEnv } from '../../models/target-env';
import { showToastr } from '../../services/legacy/toastr.service';
import { ApiReferenceDocRoots } from '../../models/api-doc-roots';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import CodeBlockComponent from './code-block.component.vue';
import { FormKitSchema } from '@formkit/vue';
import { FormKitSchemaFormKit, reset as formkitReset, FormKitSchemaComponent } from '@formkit/core';
import AppHtmlMarkdownComponent from './app-html-markdown.component.vue';

type ApiTryAuthentication = 'show' | 'hidden' | 'collapsed';
type ApiTryItParams = { [key: string]: string | string[] };

interface ApiParameterSchema {
  type?: 'integer' | 'number' | 'string' | 'boolean';
  default?: number | string;
  minLength?: number;
  maxLength?: number;
  minimum?: number;
  maximum?: number;
}

interface ApiParameter extends ApiParameterSchema {
  in: 'query' | 'header' | 'path' | 'query' | 'body';
  name: string;
  description: string;
  required?: boolean;
  schema?: ApiParameterSchema; // openapi v3.0
}


export default /*@__PURE__*/_defineComponent({
  __name: 'api-try-it.component',
  props: {
    id: { default: '' },
    api: { default: '' },
    version: { default: '' },
    operationId: {},
    authentication: { default: 'collapsed' },
    params: { default: '' },
    visibleParams: { default: null },
    docRoot: {}
  },
  emits: ['onRequestSuccess', 'onRequestError'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const formData = ref<{
  headers: {
    accept?: string;
    'Content-Type'?: string;//eslint-disable-line
    'api-key'?: string;//eslint-disable-line
    token?: string;
  };
  parameters: {};//eslint-disable-line
  body: {};//eslint-disable-line
}>({
  headers: {},
  parameters: {},
  body: {},
});

const eventRef = ref<HTMLElement>(null);

const emit = __emit;

const destroySubject = new Subject();

const state = ref({
  response: {
    initial: null,
    text: null,
    code: null,
    headersText: null,
  },
  authentication: {
    enabled: false,
    collapsed: true,
  },
  initialized: false,
  loading: false,
  url: null,
  method: null,
  parameters: [],
  path: null,
  operation: null,
  versionInner: null,
  hasFormBody: false,
});

const library = markRaw({
  AppHtmlMarkdownComponent: AppHtmlMarkdownComponent,
});

onMounted(async () => {
  state.value.versionInner = props.version;
  if (!state.value.versionInner) {
    const apis = await getApis();
    const foundApi: Api = apis.find((item: Api) => item.apiName === props.api);

    state.value.versionInner = last(foundApi.versions);
  }

  await parse();

  env$.pipe(takeUntil(destroySubject)).subscribe(targetEnv => {
    refreshEnv(targetEnv);
  });

  apiKey$.pipe(takeUntil(destroySubject)).subscribe(apiKey => {
    refreshApiKey(apiKey);
  });

  targetEnvAccessToken$.pipe(takeUntil(destroySubject)).subscribe(token => {
    refreshToken(token);
  });

  env$
    .pipe(
      combineLatestWith([apiKey$, targetEnvAccessToken$]),
      map(([apiKey, targetEnvAccessToken]) => [apiKey, targetEnvAccessToken]),
      take(1),
      finalize(() => {
        state.value.initialized = true;
      }),
    )
    .subscribe(() => {
      const parsedParams = parseParams(props.params);
      setParams(parsedParams);
    });
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function updateResponseType(responseType: 'json' | 'yaml'): void {
  const responseTypeLC = responseType.toLowerCase();
  if (responseTypeLC === 'json') {
    state.value.response.text = JSON.stringify(state.value.response.initial, undefined, ' ');
  }

  if (responseTypeLC === 'yaml') {
    try {
      const tempResponse = jsYaml.dump(state.value.response.initial);
      state.value.response.text = tempResponse;
    } catch (error) {
      showToastr({
        message: `Please check the format. ${error.message}`,
        type: 'error',
      });
    }
  }
}

function setParams(params: ApiTryItParams): void {
  if (!params) return;

  for (const key in params) {
    if (!Object.prototype.hasOwnProperty.call(formData.value['parameters'], key)) {
      formData.value[key] = params[key];
    }
  }
}

async function request(): Promise<void> {
  const url = state.value.url;
  let responseHeaders: AxiosResponse['headers'];

  const headers = {
    Authorization: `Bearer ${formData.value['headers']['token']}`,
    accept: formData.value['headers']['accept'],
    'api-key': formData.value['headers']['api-key'],//eslint-disable-line
  };

  const params = {
    query: formData.value['parameters']['query'],
  };

  try {
    state.value.loading = true;
    const axiosResponse = (await axios({
      url,
      headers,
      params,
      data: formData.value['body'],
      method: state.value.method,
    })) as AxiosResponse<{
      _embedded: { items: { _id: string }[] };
    }>;

    if (axiosResponse.data._embedded && axiosResponse.data._embedded.items[0]) {
      var accountId = axiosResponse.data._embedded.items[0]._id;
      setParams({ accountId: accountId });
      setParams({ account: accountId });
    }

    eventRef.value.dispatchEvent(new CustomEvent(`${props.id}Success`, { detail: axiosResponse }));

    emit('onRequestSuccess', axiosResponse);

    state.value.response.initial = axiosResponse.data;
    state.value.response.text = JSON.stringify(axiosResponse.data, undefined, ' ');
    state.value.response.code = axiosResponse.status;
    responseHeaders = axiosResponse.headers;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (!axiosError.response) {
      showToastr({
        message: `The API call failed. Please try again later.`,
        type: 'error',
      });
      return;
    }
    emit('onRequestError', axiosError);
    state.value.response.initial = axiosError.response.data;
    state.value.response.text = JSON.stringify(axiosError.response.data, undefined, ' ');
    state.value.response.code = axiosError.response.status;
    responseHeaders = axiosError.response.headers;
  } finally {
    state.value.loading = false;
    state.value.response.headersText = JSON.stringify(responseHeaders, null, '  ');
  }
}

function reset(): void {
  state.value.response.initial = undefined;
  state.value.response.text = null;
  state.value.response.code = null;
  state.value.response.headersText = null;

  apiKey$
    .pipe(
      combineLatestWith([targetEnvAccessToken$]),
      map(([apiKey, targetEnvAccessToken]) => [apiKey, targetEnvAccessToken]),
      take(1),
    )
    .subscribe(([apiKey, token]: [string, string]) => {
      refreshApiKey(apiKey);
      refreshToken(token);
      formkitReset(props.id + 'form');
    });
}

function refreshToken(token: string): void {
  formData.value.headers['token'] = token;
}

function refreshApiKey(apiKey: string): void {
  formData.value.headers['api-key'] = apiKey;
}

function refreshEnv(env: TargetEnv): void {
  state.value.url = env.apiHost ? `https://${env.apiHost}/${props.api}${state.value.path}` : '';
}

function parseParams(params: string): ApiTryItParams {
  if (params) {
    const parsedParams = URI.parseQuery(`?${params}`);
    return parsedParams as ApiTryItParams;
  }
  return undefined;
}

async function parse(): Promise<void> {
  const document = await getApiDocument(props.docRoot, props.api, state.value.versionInner);

  state.value.path = Object.keys(document.paths).find((path: string) => {
    return Object.keys(document.paths[path]).some((method: string) => {
      const operationId = document.paths[path][method].operationId;
      return operationId === props.operationId;
    });
  });

  if (!state.value.path) {
    throw new Error(`operationId '${props.operationId}' has not been found.`);
  }

  const operations = document.paths[state.value.path];
  state.value.parameters = operations.parameters ?? [];
  const operationKey = Object.keys(operations).find(element => {
    return operations[element].operationId === props.operationId;
  });

  state.value.operation = operations[operationKey];
  state.value.parameters = state.value.parameters.concat(state.value.operation.parameters ?? []);
  state.value.method = operationKey.toUpperCase() || 'GET';

  generateHeadersForm();
  generateParametersForm();
  generateBodyForm();
}

function generateHeadersForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  const headersForm: (FormKitSchemaFormKit | FormKitSchemaComponent)[] = [];
  const produces = getProduces();
  const contentTypes = getContentTypes();
  const securitySchema = state.value.operation.security?.[0];
  const headerParameters = state.value.parameters.filter(parameter => parameter.in === 'header');
  const visibleParamsParsed = parseVisibleParams();

  if (produces) {
    headersForm.push({
      $formkit: 'select',
      label: 'Accept',
      name: 'accept',
      required: true,
      options: [...produces],
    });
    formData.value['headers']['accept'] = produces[0];
  }

  if (contentTypes) {
    headersForm.push({
      $formkit: 'select',
      title: 'Content-Type',
      name: 'Content-Type',
      required: true,
      enum: [...contentTypes],
    });
    formData.value['headers']['Content-Type'] = contentTypes[0];
  }

  if (securitySchema) {
    if (securitySchema['apiKey']) {
      state.value.authentication.enabled = true;
      headersForm.push({
        $formkit: state.value.authentication.collapsed ? 'hidden' : 'password',
        title: 'API-Key',
        name: 'api-key',
        required: true,
      });
    }
    if (securitySchema['accessToken']) {
      state.value.authentication.enabled = true;
      headersForm.push({
        $formkit: state.value.authentication.collapsed ? 'hidden' : 'password',
        title: 'Authorization (Bearer access token)',
        name: 'token',
        required: true,
      });
    }
  }

  headerParameters.forEach(parameter => {
    const formSchemaLength = headersForm.push(getParameterSchema(parameter));
    if (visibleParamsParsed && !visibleParamsParsed?.includes(parameter.name)) {
      headersForm[formSchemaLength - 1]['$formkit'] = 'hidden';
    } else {
      headersForm.push({
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: parameter.description,
        },
      });
    }
  });

  return headersForm;
}

function generateParametersForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  const parameterForm: (FormKitSchemaFormKit | FormKitSchemaComponent)[] = [];
  const formParameters = state.value.parameters.filter(parameter => parameter.in !== 'header');
  const visibleParamsParsed = parseVisibleParams();

  formParameters.forEach(parameter => {
    const formSchemaLength = parameterForm.push(getParameterSchema(parameter));

    if (parameter.in === 'body') {
      parameterForm[formSchemaLength - 1]['$formkit'] = 'textarea';
    }

    parameterForm[formSchemaLength - 1]['placeholder'] = parameter.default;

    if (visibleParamsParsed && !visibleParamsParsed?.includes(parameter.name)) {
      parameterForm[formSchemaLength - 1]['$formkit'] = 'hidden';
    } else {
      parameterForm.push({
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: parameter.description,
        },
      });
    }
  });

  return parameterForm;
}

function generateBodyForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  if (!state.value.operation.requestBody) return [];

  const contentType = formData.value['contentType'];
  if (!contentType) {
    state.value.hasFormBody = false;
    return [];
  }

  state.value.hasFormBody = true;

  // openapi v3.0
  if (state.value.operation.requestBody) {
    const requestBodySchema = state.value.operation.requestBody.content[contentType].schema;
    return [
      {
        $formkit: 'text',
        required: Boolean(requestBodySchema.required?.length),
        title: requestBodySchema.title,
      },
      {
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: requestBodySchema.description,
        },
      },
    ];
  } else {
    // openapi v2.0
    const bodyParameter = state.value.parameters.find(p => p.in === 'body');
    return [
      {
        $formkit: 'text',
        required: bodyParameter.required,
        title: bodyParameter.name,
      },
      {
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: bodyParameter.description,
        },
      },
    ];
  }
}

function getParameterSchema(parameter: ApiParameter): FormKitSchemaFormKit {
  const parameterFormSchema: FormKitSchemaFormKit = { $formkit: 'text' };
  const parameterSchema = parameter.schema ?? parameter;
  switch (parameterSchema.type) {
    case 'boolean':
      parameterFormSchema.$formkit = 'checkbox';
      break;
    case 'integer':
    case 'number':
      parameterFormSchema.$formkit = 'number';
      parameterFormSchema.min = parameterSchema.minimum;
      parameterFormSchema.max = parameterSchema.maximum;
      break;
    case 'string':
    default:
      parameterFormSchema.minlength = parameterSchema.minLength;
      parameterFormSchema.maxlength = parameterSchema.maxLength;
  }

  return {
    ...parameterFormSchema,
    label: parameter.in === 'path' ? `{${parameter.name}}` : parameter.name,
    name: parameter.in === 'path' ? `{${parameter.name}}` : parameter.name,
    required: parameter.required,
    // help: parameter.description,
    placeholder: parameter.in,
  };
}

function getProduces(): string[] {
  if (state.value.operation.produces) {
    // openapi v2.0
    return state.value.operation.produces;
  } else if (state.value.operation.responses) {
    // openapi v3.0
    return chain(state.value.operation.responses)
      .map(v => (v.content ? Object.keys(v.content) : []))
      .flatMap()
      .uniq()
      .value();
  }
  return [];
}

function getContentTypes(): string[] {
  // openapi v3.0
  if (state.value.operation.requestBody) {
    return Object.keys(state.value.operation.requestBody.content);
  }
  // openapi v2.0
  if (state.value.operation.consumes) {
    return state.value.operation.consumes;
  } else if (state.value.parameters.find(p => p.in === 'body')) {
    return ['application/json'];
  }

  return null;
}

function parseVisibleParams(): string[] {
  if (!props.visibleParams) return null;
  return props.visibleParams.split(',').map(item => item.trim());
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", {
    id: props.id,
    ref_key: "eventRef",
    ref: eventRef
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_FormKit, {
            modelValue: state.value.url,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.value.url) = $event)),
            label: "Operation",
            type: "text",
            "outer-class": "form-input--full",
            disabled: "",
            "sections-schema": {
          prefix: {
            $el: 'div',
            attrs: {
              class: '$classes.prefix',
            },
            children: state.value.method,
          },
        }
          }, null, 8, ["modelValue", "sections-schema"]),
          _createVNode(_component_FormKit, {
            id: props.id + 'form',
            modelValue: formData.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData).value = $event)),
            type: "form",
            actions: false,
            "form-class": "mt-3",
            onSubmit: request
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormKit, {
                type: "group",
                name: "headers"
              }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("h4", null, "Header Requests", -1)),
                  (state.value.authentication.enabled && props.authentication !== 'hidden')
                    ? (_openBlock(), _createBlock(_component_FormKit, {
                        key: 0,
                        type: "button",
                        class: "btn btn-secondary btn-sm ml-auto",
                        onClick: _cache[1] || (_cache[1] = () => (state.value.authentication.collapsed = !state.value.authentication.collapsed))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(state.value.authentication.collapsed ? 'Show' : 'Hide') + " Authentication ", 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(FormKitSchema), {
                    schema: generateHeadersForm(),
                    library: _unref(library)
                  }, null, 8, ["schema", "library"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormKit, {
                type: "group",
                name: "parameters"
              }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Parameters", -1)),
                  _createVNode(_unref(FormKitSchema), {
                    schema: generateParametersForm(),
                    library: _unref(library)
                  }, null, 8, ["schema", "library"])
                ]),
                _: 1
              }),
              _createVNode(_component_FormKit, {
                type: "group",
                name: "body"
              }, {
                default: _withCtx(() => [
                  (state.value.hasFormBody)
                    ? (_openBlock(), _createElementBlock("h4", _hoisted_3, " Body "))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(FormKitSchema), {
                    schema: generateBodyForm(),
                    library: _unref(library)
                  }, null, 8, ["schema", "library"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_FormKit, {
                  disabled: !state.value.url || state.value.loading,
                  label: "Try It",
                  type: "submit"
                }, null, 8, ["disabled"]),
                _createVNode(_component_FormKit, {
                  type: "button",
                  "input-class": "form-input--button-transparent",
                  "outer-class": "align-self-center",
                  disabled: state.value.loading,
                  label: "Reset",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (reset()))
                }, null, 8, ["disabled"])
              ])
            ]),
            _: 1
          }, 8, ["id", "modelValue"]),
          (state.value.response.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[7] || (_cache[7] = _createElementVNode("h3", null, "API Response", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h4", null, [
                    _cache[6] || (_cache[6] = _createTextVNode(" Response Code: ")),
                    (state.value.response.code)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: `https://httpstatuses.com/${state.value.response.code}`
                        }, _toDisplayString(state.value.response.code), 9, _hoisted_7))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("h4", null, "Response Headers", -1)),
                _createVNode(CodeBlockComponent, {
                  text: state.value.response.headersText
                }, null, 8, ["text"]),
                _cache[9] || (_cache[9] = _createElementVNode("h4", null, "Response Body", -1)),
                _createVNode(_component_FormKit, {
                  type: "radio",
                  options: ['JSON', 'YAML'],
                  value: "JSON",
                  onInput: updateResponseType
                }),
                _createVNode(CodeBlockComponent, {
                  text: state.value.response.text
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 8, _hoisted_1))
}
}

})