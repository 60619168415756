<template>
  <FormKit
    v-model="inviteOwnersFormData"
    type="form"
    :actions="false"
    data-test="inviteOwnersForm"
    @submit="sendInvites()"
  >
    <FormKit
      type="text"
      name="applicationName"
      label="Application Name"
      data-test="applicationName"
    />
    <FormKit
      type="textarea"
      name="message"
      label="Message"
      help="Include this message in the invitation email to the invitees. You may use Markdown to format this text."
      validation="required|length:16,512"
      data-test="message"
    />
    <FormKit
      type="repeater"
      name="invitees"
      fieldset-label="New Owners"
      validation="required"
    >
      <FormKit
        type="text"
        name="firstName"
        label="First Name"
        validation="required|length:1,64"
        outer-class="form-input--full"
        data-test="firstName"
      />
      <FormKit
        type="text"
        name="lastName"
        label="Last Name"
        validation="required|length:1,64"
        outer-class="form-input--full"
        data-test="lastName"
      />
      <FormKit
        type="email"
        label="Email"
        name="emailAddress"
        :help="`Enter the invitee’s email address. The email addresses must match your partner organization's @${user.domain} domain.`"
        validation="required|email|length:6,128"
        outer-class="form-input--full"
        data-test="email"
        @input="updateNewOwners"
      />
    </FormKit>
    <div class="form-container--submits">
      <FormKit
        :disabled="loading"
        label="Send"
        type="submit"
        data-test="submit"
      >
        Send
      </FormKit>
      <FormKit
        v-if="inviteOwnersFormData.message"
        type="button"
        :disabled="!inviteOwnersFormData.message || loading"
        input-class="form-input--button-transparent"
        data-test="previewMessage"
        @click="previewMessage()"
      >
        Preview Message
      </FormKit>
    </div>
  </FormKit>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { getApplication, inviteJointOwners } from '../../services/api/applications-api.service';
import { Application, Invitee } from '@apiture/client-applications-client-sdk';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { FormKitNode } from '@formkit/core';

const inviteOwnersFormData = ref<{
  applicationName: string;
  applicationDescription: string;
  message: string;
  invitees: Invitee[];
}>({
  applicationName: '',
  applicationDescription: '',
  message: '',
  invitees: [],
});

const loading = ref<boolean>(false);
const application = ref<Application>();
const user = getUser();

onMounted(async () => {
  loading.value = true;

  const applicationId = new window.URI(window.location.href).search(true)['applicationId'];
  application.value = await getApplication(applicationId);

  loading.value = false;

  fillFormData();
});

function updateNewOwners(emailAddress: string, formNode: FormKitNode): void {
  const userDomain = user.domain;
  if (emailAddress && getEmailDomain(emailAddress) !== userDomain) {
    formNode.setErrors(`Email address must match your @${userDomain} domain.`);
  }
}

async function sendInvites(): Promise<void> {
  try {
    loading.value = true;

    application.value = await inviteJointOwners(application.value._id, {
      message: inviteOwnersFormData.value.message,
      invitees: inviteOwnersFormData.value.invitees,
    });
  } catch (error) {
    const errorResponse = error as ErrorResponse;

    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });

    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Invite(s) has been successfully sent.',
  });

  setTimeout(() => {
    if (window.location.href.includes('/open')) {
      window.location.href = '/profile/applications/';
    } else {
      window.location.href = '/apps/list/';
    }
  }, 3000);
}

function previewMessage(): void {
  const messageHtml = makeHtmlMarkdown(inviteOwnersFormData.value.message);
  bootbox.alert({
    title: 'The following formatted text will be included in the message:',
    message: messageHtml,
  });
}

function fillFormData(): void {
  inviteOwnersFormData.value = {
    applicationName: application.value.name,
    applicationDescription: application.value.description,
    invitees: [{ firstName: null, lastName: null, emailAddress: null }],
    message: null,
  };
}
</script>
