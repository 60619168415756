import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TargetEnv, ExplorerTargetEnv } from '../../models/target-env';
import { getUser } from './auth.service';
import {
  auth,
  CognitoMfaRequiredError,
  isCognitoUserAuthenticated,
  getAccessToken,
} from '../cognito.service';
import {
  getItemInSecureStorage,
  setItemInSecureStorage,
  setItemInSessionStorage,
} from '../storage.service';
import { createExplorerKey, getExplorerKey, getDiscovererKey } from '../api/keys-api.service';
import { siteOptions } from '../../options/site-options';
import {
  startAccessTokenRefresher,
  stopAccessTokenRefresher,
} from '../../utils/access-token-refresher';
import * as ApiKeysSdk from '@apiture/api-keys-client-sdk';

export const NOT_FOUND_STATUS = 404;

export interface TargetEnvLoginParams {
  env: ExplorerTargetEnv;
  userName: string;
  userPassword: string;
  explorerKey: string;
  sendMfaCode: () => Promise<boolean>;
}

const envSubject = new BehaviorSubject<TargetEnv>(undefined);
const apiKeySubject = new ReplaySubject<string>(1);
const accessTokenSubject = new ReplaySubject<string>(1);
const accessTokenExpiredSubject = new ReplaySubject<boolean>(1);

(() => {
  const savedEnv = getItemInSecureStorage<TargetEnv>('targetEnv');
  const user = getUser();
  if (user && savedEnv.type === 'explorer') {
    setEnv(savedEnv);
  } else {
    setDevBankEnv();
  }
})();

let apiKeyInner: string;
let accessTokenInner: string;
let accessTokenExpiredInner: boolean;
let defaultExplorerKeyInner: ApiKeysSdk.Key;

let timeoutHandlerId: number;

export const env$ = envSubject.asObservable();

export const apiKey$ = apiKeySubject.asObservable();

export const targetEnvAccessToken$ = accessTokenSubject.asObservable();

export const accessTokenExpired$ = accessTokenExpiredSubject.asObservable();

export function getEnvValue(): TargetEnv {
  return envSubject.value;
}

export function getApiKey(): string {
  return apiKeyInner;
}

export function getTargetEnvAccessToken(): string {
  return accessTokenInner;
}

export function accessTokenExpired(): boolean {
  return accessTokenExpiredInner;
}

export async function initTargetEnvService(): Promise<void> {
  const env = envSubject.value;
  const user = getUser();

  switch (env.type) {
    case 'explorer': {
      let explorerKey: ApiKeysSdk.Key;
      try {
        explorerKey = await getExplorerKey(env.apiHost);
      } catch {
        targetEnvLogout();
        return;
      }

      setApiKey(explorerKey.key);
      await recheckExplorerAccessToken();

      break;
    }
    case 'discoverer': {
      const discovererKey = await getDiscovererKey();

      setApiKey(discovererKey.apiKey.key);
      setAccessToken(discovererKey.accessToken);

      break;
    }
  }

  if (user) {
    let explorerKey;
    try {
      explorerKey = await getExplorerKey(siteOptions.devBankHost);
    } catch (error) {
      if (error._error?.statusCode === NOT_FOUND_STATUS) {
        explorerKey = await createExplorerKey(siteOptions.devBankHost);
      } else {
        console.error(error);
      }
    }
    setDefaultExplorerKey(explorerKey);
  }
}

export async function getOrCreateDefaultExplorerKey(): Promise<ApiKeysSdk.Key> {
  if (defaultExplorerKeyInner) {
    return defaultExplorerKeyInner;
  }

  let defaultExplorerKey: ApiKeysSdk.Key;
  try {
    defaultExplorerKey = await getExplorerKey(siteOptions.devBankHost);
  } catch (error) {
    if (error._error?.statusCode === NOT_FOUND_STATUS) {
      return createDefaultExplorerKey();
    }
  }

  setDefaultExplorerKey(defaultExplorerKey);

  return defaultExplorerKey;
}

async function createDefaultExplorerKey(): Promise<ApiKeysSdk.Key> {
  const defaultExplorerKey = await createExplorerKey(siteOptions.devBankHost);
  setDefaultExplorerKey(defaultExplorerKey);

  return defaultExplorerKey;
}

export async function targetEnvLogin(params: TargetEnvLoginParams): Promise<boolean> {
  try {
    await auth(params.userName, params.userPassword, {
      UserPoolId: params.env.cognitoUserPoolId,
      ClientId: params.env.cognitoClientId,
    });
  } catch (error) {
    if (error instanceof CognitoMfaRequiredError) {
      const isMfaCodeSent = await params.sendMfaCode();
      if (!(await isCognitoUserAuthenticated()) || !isMfaCodeSent) {
        return false;
      }
    } else {
      throw error;
    }
  }

  const accessToken = await getAccessToken();

  setEnv(params.env);
  setApiKey(params.explorerKey);
  setAccessToken(accessToken.getJwtToken());

  if (accessTokenExpired) {
    setAccessTokenExpired(false);
  }

  await startExplorerAccessTokenRefresh();
  return true;
}

export function targetEnvLogout(): void {
  setApiKey(undefined);
  setAccessToken(undefined);
  stopAccessTokenRefresher(timeoutHandlerId);

  setDevBankEnv();
  initTargetEnvService();
}

async function recheckExplorerAccessToken(): Promise<void> {
  try {
    const accessToken = await getAccessToken();
    setAccessToken(accessToken.getJwtToken());
    setAccessTokenExpired(false);
  } catch {
    setAccessTokenExpired(true);
  }
}

async function startExplorerAccessTokenRefresh(): Promise<void> {
  timeoutHandlerId = await startAccessTokenRefresher({
    timeoutTime: 1000 * 60 * 30,
    successCallback: accessToken => {
      setAccessToken(accessToken);
    },
    failCallback: () => {
      setAccessTokenExpired(true);
    },
  });
}

function setDevBankEnv(): void {
  setEnv({
    type: 'discoverer',
    name: siteOptions.devBankName,
    apiHost: siteOptions.devBankHost,
  });
}

function setEnv(env: TargetEnv): void {
  setItemInSecureStorage('targetEnv', env);

  if (getEnvValue() !== env) {
    envSubject.next(env);
  }
}

function setApiKey(apiKey: string): void {
  setItemInSessionStorage('targetEnvApiKey', apiKey);
  const currentApiKey = getApiKey();
  if (currentApiKey !== apiKey) {
    apiKeyInner = apiKey;
    apiKeySubject.next(apiKey);
  }
}

function setDefaultExplorerKey(defaultExplorerKey: ApiKeysSdk.Key): void {
  setItemInSessionStorage('defaultExplorerKey', defaultExplorerKey.key);
  defaultExplorerKeyInner = defaultExplorerKey;
}

function setAccessToken(accessToken: string): void {
  setItemInSessionStorage('targetEnvAccessToken', accessToken);
  const currentAccessToken = getTargetEnvAccessToken();

  if (currentAccessToken !== accessToken) {
    accessTokenInner = accessToken;
    accessTokenSubject.next(accessToken);
  }
}

function setAccessTokenExpired(accessTokenExpired: boolean): void {
  if (accessTokenExpired !== accessTokenExpired) {
    accessTokenExpiredInner = accessTokenExpired;
    accessTokenExpiredSubject.next(accessTokenExpired);
  }
}
