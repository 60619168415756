<template>
  <a
    href="javascript:"
    class="text-link"
    @click="download()"
  >{{ props.title }} </a>
</template>

<script lang="ts" setup>
import fileSaver from 'file-saver';
import { getApiReference } from '../../services/apis.service';
import { ApiReferenceType } from '../../models/api-reference-type';
import { ApiReferenceDocRoots } from '../../models/api-doc-roots';
import { getEnvValue } from '../../services/legacy/target-env.service';

const props = defineProps<{
  api: string;
  version: string;
  type: ApiReferenceType;
  title: string;
  docRoot: ApiReferenceDocRoots;
}>();

const mimeTypesMap = new Map<string, string>([
  ['json', 'application/json'],
  ['yaml', 'text/vnd.yaml'],
]);

async function download(): Promise<void> {
  const env = getEnvValue();
  const reference = await getApiReference({
    docRoot: props.docRoot,
    apiHost: env.apiHost,
    api: props.api,
    version: props.version,
    type: props.type,
  });

  fileSaver.saveAs(
    new Blob([reference], { type: mimeTypesMap.get(props.type) }),
    `${props.api}_${props.version}.${props.type.toLocaleLowerCase()}`,
  );
}
</script>
