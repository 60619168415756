import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex flex-column align-items-center mt-4"
}
const _hoisted_2 = { class: "d-flex align-items-center gap-2" }
const _hoisted_3 = { class: "d-flex align-items-center gap-2" }
const _hoisted_4 = { class: "mx-auto mt-3" }
const _hoisted_5 = { class: "form-container--submits" }

import { onMounted, ref } from 'vue';
import { setDiscovererKey, getDiscovererKey } from '../../services/api/keys-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { Messages } from '../../constants/messages';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import JsonWebTokenComponent from './json-web-token.component.vue';
import BootstrapModalComponent from './app-modal.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'discoverer-key-form.component',
  setup(__props) {

const changeCredentialsFormData = ref<{
  sampleUserName: string;
  sampleUserPassword: string;
}>({
  sampleUserName: '',
  sampleUserPassword: '',
});

const state = ref<{
  loading: boolean;
  credentialsSaving: boolean;
  openModal: boolean;
}>({
  loading: false,
  credentialsSaving: false,
  openModal: false,
});

const tokens = ref<{
  apiKey: string;
  accessToken: string;
}>({
  apiKey: '',
  accessToken: '',
});

onMounted(async () => {
  await refresh();
});

function openCredentialsModal() {
  state.value.openModal = true;
}

function closeCredentialsModal() {
  state.value.openModal = false;
}

async function saveCredentials() {
  let credentialsSaved = false;
  state.value.credentialsSaving = true;

  try {
    await setDiscovererKey(changeCredentialsFormData.value);
    credentialsSaved = true;

    showToastr({
      type: 'success',
      message: `Credentials have been successfully saved.`,
    });

    closeCredentialsModal();
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
  }

  state.value.credentialsSaving = false;

  if (credentialsSaved) {
    refresh();
  }
}

async function refresh() {
  state.value.loading = true;

  const discovererKey = await getDiscovererKey();
  tokens.value.apiKey = discovererKey.apiKey.key;
  tokens.value.accessToken = discovererKey.accessToken;

  state.value.loading = false;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (state.value.loading)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            _cache[5] || (_cache[5] = _createElementVNode("b", null, "API Key:", -1)),
            _createVNode(SensitiveValueComponent, {
              id: "discovererApiKey",
              title: "API Key",
              value: tokens.value.apiKey
            }, null, 8, ["value"])
          ]),
          _createElementVNode("p", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("b", null, "Access Token: ", -1)),
            _createVNode(SensitiveValueComponent, {
              id: "discovererAccessToken",
              title: "Access Token",
              value: tokens.value.accessToken
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(JsonWebTokenComponent, {
              token: tokens.value.accessToken
            }, null, 8, ["token"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormKit, {
              label: "Change Credentials",
              type: "button",
              "input-class": "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openCredentialsModal()))
            })
          ])
        ])),
    _createVNode(BootstrapModalComponent, {
      id: "credentialsModal",
      title: "Change Credentials",
      "open-modal": state.value.openModal,
      onOnModalClose: _cache[4] || (_cache[4] = ($event: any) => (closeCredentialsModal()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          id: "credentialForm",
          modelValue: changeCredentialsFormData.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((changeCredentialsFormData).value = $event)),
          type: "form",
          "submit-label": "Save",
          actions: false,
          onSubmit: _cache[3] || (_cache[3] = ($event: any) => (saveCredentials()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              type: "text",
              label: "Username",
              name: "sampleUsername",
              validation: "required",
              "outer-class": "form-input--full"
            }),
            _createVNode(_component_FormKit, {
              type: "password",
              label: "Password",
              name: "sampleUserPassword",
              validation: "required",
              "outer-class": "form-input--full"
            }),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_FormKit, {
                type: "submit",
                disabled: state.value.loading
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Save ")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                type: "button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (closeCredentialsModal()))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["open-modal"])
  ]))
}
}

})