import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]


export default /*@__PURE__*/_defineComponent({
  __name: 'close-button.component',
  props: {
    class: { default: '' },
    testId: { default: '' }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(`bg-transparent b-none ${props.class}`),
    "data-test": props.testId,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
  }, _cache[1] || (_cache[1] = [
    _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"30\" height=\"30\" fill=\"none\"><g clip-path=\"url(#a)\"><g clip-path=\"url(#b)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.76.323a.89.89 0 0 1 1.258 0L15.5 13.805 28.982.323a.89.89 0 0 1 1.273 1.242l-.015.016-13.482 13.481L30.24 28.544l.015.016a.89.89 0 0 1-1.273 1.242L15.5 16.32 2.018 29.802A.89.89 0 0 1 .745 28.56l.015-.016 13.482-13.482L.76 1.582l-.015-.016A.89.89 0 0 1 .76.323Z\" fill=\"#5a6472\"></path></g></g><defs><clipPath id=\"a\"><path fill=\"none\" transform=\"translate(.5 .063)\" d=\"M0 0h30v30H0z\"></path></clipPath><clipPath id=\"b\"><path fill=\"none\" transform=\"translate(.5 .063)\" d=\"M0 0h30v30H0z\"></path></clipPath></defs></svg>", 1)
  ]), 10, _hoisted_1))
}
}

})