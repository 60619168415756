import { onBeforeUnmount, onMounted, Ref } from 'vue';

export function useDetectOutsideClick(
  element: Ref<HTMLElement>,
  callback: (event: MouseEvent) => void,
) {
  const listener = (event: MouseEvent) => {
    if (
      element.value?.contains(event.target as Node) ||
      event.composedPath().includes(element.value)
    ) {
      return;
    }

    callback(event);
  };

  onMounted(() => {
    window.addEventListener('click', listener, true);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('click', listener);
  });

  return listener;
}
