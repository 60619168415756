import { camelCase } from 'lodash';

const ID_REGEX = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export function getUrl(): URL {
  return new URL(window.location.href);
}

export function getUrlParams(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function setUrlParams(paramKey: string, paramValue: string): void {
  if (!paramKey) {
    return;
  }

  const searchParams = getUrlParams();

  if (!paramValue && searchParams.has(paramKey)) {
    searchParams.delete(paramKey);
    return;
  }

  if (!paramValue) {
    return;
  }

  searchParams.set(paramKey, paramValue);

  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}?${searchParams}`),
  );
}

export function deleteUrlParam(paramKey: string): void {
  if (!paramKey) {
    return;
  }

  const searchParams = getUrlParams();

  searchParams.delete(paramKey);

  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}?${searchParams}`),
  );
}

export function addUrlParamsToObject<T>(object: T): void {
  const searchParams = getUrl().searchParams;
  searchParams.forEach((param, key) => {
    if (param) {
      object[key] = param;
    }
  });
}

export function getIdFromUri(uri: string): string {
  if (!uri) return '';

  let id = uri;
  // check to see if this is a uri and strip everything
  // before the last /
  const lastIndex = uri.lastIndexOf('/');
  if (lastIndex > 0) {
    id = uri.substring(lastIndex + 1);
  }

  if (ID_REGEX.test(id)) {
    return id;
  }

  return '';
}

export function convertValueToUrlPath(value: string, useCamelCase?: boolean) {
  if (useCamelCase) {
    return camelCase(value);
  }

  return value.toLowerCase().replace(' ', '');
}

export function getUrlPath() {
  return window.location.href.substring(0, window.location.href.lastIndexOf('/'));
}

export function getFocusedIndexFromUrl(values: string[]) {
  const urlPath = getUrlPath();
  const urlPathParts = urlPath.split('/');
  const lastUrlPath = urlPathParts[urlPathParts.length - 1];

  return values.findIndex(value => convertValueToUrlPath(value) === lastUrlPath.toLowerCase());
}

export function pushValueToHistory(values: string[], index: number) {
  const currentUrl = getUrlPath();
  const pathname = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
  const nextUrl = `${pathname}/${values[index]}/`;

  history.pushState(null, values[index], nextUrl);

  return index;
}
