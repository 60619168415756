import { ref } from 'vue';

export type ToasterReasons = 'success' | 'error';

export type Toaster = {
  message: string;
  reason: ToasterReasons;
};

const toasters = ref<Toaster[]>([]);

export function addToaster(reason: ToasterReasons, message: string) {
  toasters.value.push({ reason, message });

  window.setTimeout(() => {
    toasters.value.pop();
  }, 5000);
}

export function removeToaster(index: number) {
  toasters.value.splice(index);
}

export function useToaster() {
  return toasters;
}
