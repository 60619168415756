import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { makeHtmlMarkdown } from '../../helpers/markdown-helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'app-html-markdown.component',
  props: {
    html: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    innerHTML: _unref(makeHtmlMarkdown)(props.html) ?? props.html
  }, null, 8, _hoisted_1))
}
}

})