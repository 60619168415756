import config from '../../../_site-config.yml';

export const siteOptions = createSiteOptions();

function createSiteOptions() {
  return {
    apiUrl: config.basePathUrl,
    devPortalUserPoolId: config.devPortalUserPoolId,
    devPortalClientId: config.devPortalClientId,
    devBankName: config.devBankName,
    devBankHost: config.devBankHost,
    tryItEnabled: config.tryItEnabled,
    loginEnabled: config.loginEnabled,
    sampleDataGenerationEnabled: config.sampleDataGenerationEnabled,
    oktaIssuer: config.oktaIssuer,
    oktaClientId: config.oktaClientId,
    defaultProhibitedRegistrationDomains: config.defaultProhibitedRegistrationDomains.split(','),
    ssoIntegrationsEnabled: config.ssoIntegrationsEnabled,
  } as const;
}
