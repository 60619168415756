<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clip-path="url(#clip0_3467_235784)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.53471 3.53076C11.6318 3.53076 14.1109 5.11915 15.9274 7.94516C16.2913 8.51129 16.2913 9.23773 15.9274 9.80387C14.1109 12.6299 11.6318 14.2183 8.53471 14.2183C5.44337 14.2183 2.94941 12.6354 1.09567 9.81961C0.71811 9.24611 0.71811 8.50292 1.09567 7.92941C2.94941 5.11364 5.44337 3.53076 8.53471 3.53076ZM8.53471 4.46826C5.84426 4.46826 3.61157 5.81276 1.87871 8.44492C1.7071 8.7056 1.7071 9.04342 1.87871 9.3041C3.61157 11.9363 5.84426 13.2808 8.53471 13.2808C11.2285 13.2808 13.444 11.9335 15.1388 9.29695C15.3042 9.03961 15.3042 8.70941 15.1388 8.45208C13.444 5.81548 11.2285 4.46826 8.53471 4.46826ZM8.53125 5.15576C10.5851 5.15576 12.25 6.8207 12.25 8.87451C12.25 10.9283 10.5851 12.5933 8.53125 12.5933C6.47744 12.5933 4.8125 10.9283 4.8125 8.87451C4.8125 6.8207 6.47744 5.15576 8.53125 5.15576ZM8.53125 6.09326C6.99521 6.09326 5.75 7.33847 5.75 8.87451C5.75 10.4106 6.99521 11.6558 8.53125 11.6558C10.0673 11.6558 11.3125 10.4106 11.3125 8.87451C11.3125 7.33847 10.0673 6.09326 8.53125 6.09326Z"
        fill="#005A7C"
      />
    </g>
    <defs>
      <clipPath id="clip0_3467_235784">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.874512)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  transform: scale(1.2);
}
</style>
