<template>
  <RepeaterContainer
    :name="props.context.node.name"
    :disable-add="addInviteeDisabled"
    @add="addInvitee"
  >
    <template #inputs>
      <div
        v-for="(invitee, index) in invitees"
        :key="invitee.id"
        :data-test="`${props.context.node.name}-group-${Number(index) + 1}`"
        class="d-inline-flex gap-xs"
      >
        <FormKit
          :id="`invitee-${index + 1}-firstName`"
          v-model="invitees[index].firstName"
          type="text"
          name="firstName"
          label="First Name"
          validation="required|length:1,64"
          outer-class="form-input--full"
          :data-test="`invitee-${index + 1}-firstName`"
          ignore
          :delay="250"
          :sections-schema="{
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-firstName-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-firstName-error`,
              },
            },
          }"
          @node="handleNodeAdded"
        />
        <FormKit
          :id="`invitee-${index + 1}-lastName`"
          v-model="invitees[index].lastName"
          type="text"
          name="lastName"
          label="Last Name"
          validation="required|length:1,64"
          outer-class="form-input--full"
          :data-test="`invitee-${index + 1}-lastName`"
          ignore
          :delay="250"
          :sections-schema="{
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-lastName-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-lastName-error`,
              },
            },
          }"
          @node="handleNodeAdded"
        />
        <FormKit
          :id="`invitee-${index + 1}-emailAddress`"
          v-model="invitees[index].emailAddress"
          type="email"
          label="Email"
          name="emailAddress"
          validation="required|email|length:6,128|emailDomainsMatch"
          outer-class="form-input--full"
          :validation-rules="{ emailDomainsMatch }"
          :validation-messages="{
            emailDomainsMatch: `Email address must match your @${user.domain} domain.`,
          }"
          ignore
          :delay="250"
          :data-test="`invitee-${index + 1}-emailAddress`"
          :sections-schema="{
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-emailAddress-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-emailAddress-error`,
              },
            },
          }"
          @node="handleNodeAdded"
        />
        <RemoveButton
          :data-test="`${props.context.node.name}-group-${Number(index) + 1}-remove`"
          :label="`remove ${props.context.node.name} ${Number(index) + 1}`"
          @click="removeInvitee(index)"
        />
      </div>
    </template>
    <template #add-button-content>
      <AddSvg /> Add Owners
    </template>
  </RepeaterContainer>
</template>

<script lang="ts" setup>
import type { FormKitNode, FormKitFrameworkContext } from '@formkit/core';

import { ref, watch, computed } from 'vue';
import RepeaterContainer from './repeater-container.vue';
import { getUser } from '../../../services/portal-auth.service';
import { getEmailDomain } from '../../../helpers/email-helper';
import { Invitee } from '@apiture/client-organizations-client-sdk';
import AddSvg from '../svg/add-svg.component.vue';
import { token } from '@formkit/utils';
import { getNode } from '@formkit/core';
import RemoveButton from '../buttons/remove-button.component.vue';

const max = 5;

const props = defineProps<{
  context: FormKitFrameworkContext<Invitee[]>;
}>();

const user = getUser();

const invitees = ref<({ id: string } & Invitee)[]>([
  { id: token(), firstName: '', lastName: '', emailAddress: '' },
]);

const inviteeNodeIds = ref<string[]>([]);

const addInviteeDisabled = computed(() => {
  if (
    invitees.value.some(invitee => !invitee.firstName || !invitee.lastName || !invitee.emailAddress)
  ) {
    return true;
  }

  const inviteeNodesValidity = inviteeNodeIds.value.map(
    inviteeNodeId => getNode<string>(inviteeNodeId)?.context?.state.valid,
  );

  return inviteeNodesValidity.includes(false) || invitees.value.length >= max;
});

watch(
  () => invitees.value,
  async newInviteesWithId => {
    const newInvitees = newInviteesWithId.map(newInvitee => {
      return {
        firstName: newInvitee.firstName,
        lastName: newInvitee.lastName,
        emailAddress: newInvitee.emailAddress,
      };
    });

    await props.context.node.input(newInvitees);
  },
  { deep: true },
);

function addInvitee() {
  if (addInviteeDisabled.value) {
    return;
  }

  invitees.value.push({ id: token(), firstName: '', lastName: '', emailAddress: '' });
}

function removeInvitee(index: number) {
  invitees.value.splice(index, 1);
}

function emailDomainsMatch(node: FormKitNode<string>) {
  const userDomain = user.domain;
  const emailDomain = getEmailDomain(node.value);

  return emailDomain && emailDomain === userDomain;
}

function handleNodeAdded(node: FormKitNode<string>) {
  if (!node.context?.id) {
    return;
  }

  inviteeNodeIds.value.push(node.context.id);
}
</script>

<style lang="scss" scoped>
button {
  align-self: end;
  padding-bottom: 0.75rem;
}
</style>
