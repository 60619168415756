import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import hljs from 'highlight.js';
import { onMounted, onUpdated, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'code-block.component',
  props: {
    text: {}
  },
  setup(__props: any) {

const props = __props;

const code = ref<Element>();

onMounted(() => {
  highlightCode();
});

onUpdated(() => {
  highlightCode();
});

function highlightCode() {
  if (!props.text) {
    return;
  }

  const codeElement = code.value;

  codeElement.textContent = props.text;
  hljs.highlightBlock(code.value);
}

return (_ctx: any,_cache: any) => {
  return (Boolean(props.text))
    ? (_openBlock(), _createElementBlock("pre", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode("    ")),
        _createElementVNode("code", {
          ref_key: "code",
          ref: code,
          class: "hljs text-left"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 512),
        _cache[1] || (_cache[1] = _createTextVNode("\n  "))
      ]))
    : _createCommentVNode("", true)
}
}

})