// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-0d48e486] .tooltip--content {
  margin: 0;
  max-width: 20rem;
  align-self: center;
  padding: 1.25rem;
  margin-top: 0.5rem;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
[data-v-0d48e486] .tooltip--content a {
  align-self: flex-end;
}
[data-v-0d48e486] .tooltip--pointer {
  margin-top: -1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/tooltip.component.vue","webpack://./tooltip.component.vue"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,+CAAA;EACA,aAAA;EACA,sBAAA;EAEA,6CAAA;EACA,oBAAA;EACA,qBAAA;ACDF;ADGE;EACE,oBAAA;ACDJ;ADKA;EACE,iBAAA;ACFF","sourcesContent":["\n:deep(.tooltip--content) {\n  margin: 0;\n  max-width: 20rem;\n  align-self: center;\n  padding: 1.25rem;\n  margin-top: 0.5rem;\n  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.3);\n  display: flex;\n  flex-direction: column;\n\n  font-feature-settings: 'clig' off, 'liga' off;\n  font-size: 0.8125rem;\n  line-height: 1.125rem;\n\n  a {\n    align-self: flex-end;\n  }\n}\n\n:deep(.tooltip--pointer) {\n  margin-top: -1rem;\n}\n",":deep(.tooltip--content) {\n  margin: 0;\n  max-width: 20rem;\n  align-self: center;\n  padding: 1.25rem;\n  margin-top: 0.5rem;\n  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.3);\n  display: flex;\n  flex-direction: column;\n  font-feature-settings: \"clig\" off, \"liga\" off;\n  font-size: 0.8125rem;\n  line-height: 1.125rem;\n}\n:deep(.tooltip--content) a {\n  align-self: flex-end;\n}\n\n:deep(.tooltip--pointer) {\n  margin-top: -1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
