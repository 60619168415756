import { getOptions, config } from './base-api.service';
import {
  DiscovererKeyApi,
  ExplorerKeyApi,
  KeyApi,
  KeyState,
  KeyActionsApi,
  DiscoverKeyRequest,
  ProductLine as ProductLineEnum,
} from '@apiture/api-keys-client-sdk';
import { getItemInSecureStorage, setItemInSecureStorage } from '../storage.service';
import { ProductLine } from '../../utils/product-lines';

const discoverKeyApi = new DiscovererKeyApi(config);
const explorerKeyApi = new ExplorerKeyApi(config);
const keyApi = new KeyApi(config);
const keyActionsApi = new KeyActionsApi(config);

const earliestSampleDataRequestTimeOffset = 15;

export async function getKey(keyId: string) {
  const getKeyResponse = await keyApi.getKey(keyId, await getOptions());
  return getKeyResponse.data;
}

export async function getKeys(query?: {
  start?: string;
  limit?: number;
  application?: string;
  partner?: string;
  environment?: string;
  state?: KeyState[];
  productLine?: ProductLine;
}) {
  const getKeysResponse = await keyApi.getKeys(
    {
      start: query?.start,
      limit: query?.limit,
      application: query?.application,
      partner: query?.partner,
      environment: query?.environment,
      state: query?.state,
      productLine: query?.productLine as ProductLineEnum,
    },
    await getOptions(),
  );

  return getKeysResponse.data;
}

export async function getExplorerKeys(query?: {
  start?: string;
  limit?: number;
  environment?: string;
  owner?: string;
  state?: KeyState[];
}) {
  const getExplorerKeysResponse = await explorerKeyApi.getExplorerKeys(query, await getOptions());
  return getExplorerKeysResponse.data;
}

export async function getDiscovererKey() {
  const getDiscovererKeyResponse = await discoverKeyApi.getDiscovererKey(await getOptions());
  return getDiscovererKeyResponse.data;
}

export async function setDiscovererKey(request: DiscoverKeyRequest) {
  const discovererKeyResponse = await discoverKeyApi.discovererKey(
    request,
    undefined,
    await getOptions(),
  );

  return discovererKeyResponse.data;
}

export async function getExplorerKey(environment: string) {
  const getExplorerKeyResponse = await explorerKeyApi.getExplorerKey(
    { environment },
    await getOptions(),
  );

  return getExplorerKeyResponse.data;
}

export async function createExplorerKey(environment: string) {
  const explorerKeyResponse = await explorerKeyApi.explorerKey({ environment }, await getOptions());

  return explorerKeyResponse.data;
}

export async function checkSampleDataRequest(environment: string, environmentAccessToken: string) {
  const checkSampleDataRequestResponse = await explorerKeyApi.checkSampleDataRequest(
    { environment, environmentAccessToken },
    await getOptions(),
  );

  return checkSampleDataRequestResponse.data;
}

export async function createSampleDataRequest(environment: string, environmentAccessToken: string) {
  const createSampleDataRequestResponse = await explorerKeyApi.createSampleDataRequest(
    { environment, environmentAccessToken },
    await getOptions(),
  );

  return createSampleDataRequestResponse.data;
}

export function setSampleDataRequestTimeout(): void {
  const date = new Date();
  setItemInSecureStorage(
    'earliestSampleDataRequestTime',
    date.setMinutes(date.getMinutes() + earliestSampleDataRequestTimeOffset),
  );
}

export function checkSampleDataRequestTimeout(): boolean {
  const date = new Date();
  const earliestSampleDataRequestTime = getItemInSecureStorage<string>(
    'earliestSampleDataRequestTime',
  );

  if (!earliestSampleDataRequestTime || date.getTime() > Number(earliestSampleDataRequestTime)) {
    setItemInSecureStorage('earliestSampleDataRequestTime', undefined);
    return true;
  }

  return false;
}

export async function deleteKey(keyId: string) {
  const deleteKeyResponse = await keyApi.deleteKey(keyId, await getOptions());
  return deleteKeyResponse.data;
}

export async function activateKey(keyId: string) {
  const activateKeyResponse = await keyActionsApi.activateKey({ key: keyId }, await getOptions());
  return activateKeyResponse.data;
}

export async function rejectKey(keyId: string) {
  const rejectKeyResponse = await keyActionsApi.rejectKey({ key: keyId }, await getOptions());
  return rejectKeyResponse.data;
}

export async function deactivateKey(keyId: string) {
  const deactivateKeyResponse = await keyActionsApi.deactivateKey(
    { key: keyId },
    await getOptions(),
  );

  return deactivateKeyResponse.data;
}
