import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "form-input--scopes-fieldset",
  "data-test": "scopes"
}
const _hoisted_2 = { class: "form-input form-input--scopes" }
const _hoisted_3 = { class: "form-input-scopes--excludeWriteScopes" }
const _hoisted_4 = ["checked"]
const _hoisted_5 = {
  "data-test": "scopes-list",
  class: "form-input--option-list form-input--scrollable"
}
const _hoisted_6 = { class: "flex-row--gap align-items-center" }
const _hoisted_7 = ["id", "aria-describedby", "value", "disabled", "checked", "data-test"]
const _hoisted_8 = ["for", "data-test"]
const _hoisted_9 = ["id"]

import type { FormKitFrameworkContext } from '@formkit/core';
import { AuthorizationScope } from '@apiture/api-products-client-sdk';
import ToolTipComponent from './tooltip.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'scopes-input.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

function handleScopeExclusion(
  event: Event & {
    target: HTMLInputElement;
  },
) {
  const excludedScopes = props.context.value?.excludedScopes
    ? [...props.context.value.excludedScopes]
    : [];

  if (!event.target.checked) {
    props.context.node.input({
      ...props.context.value,
      excludedScopes: [...excludedScopes, event.target.value],
    });
  } else {
    props.context.node.input({
      ...props.context.value,
      excludedScopes: excludedScopes.slice(excludedScopes.indexOf(event.target.value), 1),
    });
  }
}

function handleExcludeWriteScopes(event: Event & { target: HTMLInputElement }) {
  props.context.node.input({
    ...props.context.value,
    excludeWriteScopes: event.target.checked,
  });
}

function excludeWriteScope(scopeName: string) {
  return props.context.value?.excludeWriteScopes && scopeName.includes('/write');
}

function shouldCheck(scopeName: string) {
  if (!props.context.value?.excludedScopes) {
    return !excludeWriteScope(scopeName);
  }

  return !props.context.value?.excludedScopes.includes(scopeName) && !excludeWriteScope(scopeName);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createElementVNode("legend", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" Scopes ")),
      _createVNode(ToolTipComponent, { tip: "Select which authorization scopes your application requires. The API reference documentation lists the Scopes required to call each operation." })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          id: "excludeWriteScopes",
          type: "checkbox",
          class: "form-input--option",
          "data-test": "excludeWriteScopes",
          checked: props.context.value?.excludeWriteScopes ?? false,
          onInput: handleExcludeWriteScopes
        }, null, 40, _hoisted_4),
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "excludeWriteScopes" }, "Exclude all write scopes", -1))
      ]),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.context.scopes, (scope, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: scope.name,
            class: "form-input--scopes-option"
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("input", {
                id: `scope-${Number(i) + 1}`,
                class: "form-input--option",
                type: "checkbox",
                "aria-describedby": `${scope.name}-description`,
                value: scope.name,
                disabled: excludeWriteScope(scope.name),
                checked: shouldCheck(scope.name),
                "data-test": `scope-${Number(i) + 1}`,
                onChange: _withModifiers(handleScopeExclusion, ["stop"])
              }, null, 40, _hoisted_7),
              _createElementVNode("label", {
                for: `scope-${Number(i) + 1}`,
                "data-test": `scope-label-${Number(i) + 1}`,
                class: "form-input--wrapper scopes-input--wrapper"
              }, _toDisplayString(scope.name), 9, _hoisted_8)
            ]),
            _createElementVNode("p", {
              id: `${scope.name}-description`,
              class: "formkit-option-help"
            }, _toDisplayString(scope.description), 9, _hoisted_9)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})