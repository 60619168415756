import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onBeforeMount } from 'vue';
import { oktaAuthClient, getOktaAccessToken } from '../../services/okta-auth.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'login-form.component',
  setup(__props) {

const error = ref<string>('');

onBeforeMount(async () => {
  if (!oktaAuthClient.features.isPKCESupported()) {
    error.value = 'Your web browser does not support PKCE.';
    return;
  }

  const currentAccessToken = await getOktaAccessToken();

  if (!currentAccessToken) {
    return;
  }

  window.location.replace('/dashboard');
});

function oktaLogin() {
  sessionStorage.clear();

  oktaAuthClient.token.getWithRedirect();
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormKit, {
      id: "adminLogin",
      type: "form",
      actions: false
    }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Admin Login 🤫", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "You will be redirected to login with your Okta credentials.", -1)),
        _createVNode(_component_FormKit, {
          id: "login",
          type: "button",
          "input-class": "oktaLoginBtn",
          errors: [error.value],
          disabled: Boolean(error.value),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (oktaLogin()))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Login with Okta ")
          ])),
          _: 1
        }, 8, ["errors", "disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})