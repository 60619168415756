import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-test"]
const _hoisted_2 = { class: "flex-col gap-md" }
const _hoisted_3 = ["aria-label", "data-test", "disabled"]

import { useTemplateRef, nextTick } from 'vue';
import AddSvg from '../svg/add-svg.component.vue';

/**
 * The props of the repeater container component.
 * 
 * - name: the name of repeater. This is used for IDs and testing attributes.
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'repeater-container',
  props: {
    name: {},
    disableAdd: { type: Boolean }
  },
  emits: ["add"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const repeater = useTemplateRef<HTMLFieldSetElement>('repeater');

function add() {
  if(props.disableAdd) {
    return;
  }

  const numOfInputsBeforeAdd = repeater.value.querySelectorAll('input').length;

  emit('add');

  // After the component has updated, focus on the new input.
  nextTick(() => {
    const repeaterInputs = repeater.value.querySelectorAll('input');
    repeaterInputs[numOfInputsBeforeAdd].focus();
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", {
    id: `${props.name}-repeater`,
    ref_key: "repeater",
    ref: repeater,
    class: "form-input--repeater",
    "data-test": props.name
  }, [
    _createElementVNode("legend", null, _toDisplayString(props.name), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "inputs"),
      _createElementVNode("button", {
        type: "button",
        class: "form-input--repeater-button flex-row-center gap-xs",
        "aria-label": `add ${props.name}`,
        "data-test": `${props.name}-add`,
        disabled: props.disableAdd,
        onClick: add
      }, [
        _createVNode(AddSvg)
      ], 8, _hoisted_3),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
    ])
  ], 8, _hoisted_1))
}
}

})