// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg[data-v-db582a9e] {
  transform: scale(1.2);
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/svg/eye-svg.component.vue","webpack://./eye-svg.component.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;ACAF","sourcesContent":["\nsvg {\n  transform: scale(1.2);\n}\n","svg {\n  transform: scale(1.2);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
