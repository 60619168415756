import { siteOptions } from './options/site-options';
import { createPageOptions } from './options/page-options';
import { initAuthService, partnerDomainValid$ } from './services/legacy/auth.service';
import { createApp, defineCustomElement } from 'vue';
import { plugin as formKitPlugin } from '@formkit/vue';
import { formKitConfig } from '../../formkit.config';
import { signOut } from './services/cognito.service';
import { getOktaAccessToken, removeOktaTokens } from './services/okta-auth.service';

import AccountInfoComponent from './components/legacy/account-info.component.vue';
import ApiReferenceDownloadComponent from './components/legacy/api-reference-download.component.vue';
import ApiTryItComponent from './components/legacy/api-try-it.component.vue';
import ApplicationFormComponent from './components/legacy/application-form.component.vue';
import ApplicationInviteOwnersComponent from './components/legacy/application-invite-owners.component.vue';
import ApplicationsKeysComponent from './components/admin/applications-keys.component.vue';
import ApplicationsListComponent from './components/legacy/applications-list.component.vue';
import CodeBlockComponent from './components/legacy/code-block.component.vue';
import CursorPaginationComponent from './components/legacy/cursor-pagination.component.vue';
import ExpandablePanelComponent from './components/legacy/expandable-panel.component.vue';
import PartnerOrganizationFormComponent from './components/legacy/partner-organization-form.component.vue';
import PartnerOrganizationInviteMembersComponent from './components/legacy/partner-organization-invite-members.component.vue';
import PersonalFormComponent from './components/legacy/personal-form.component.vue';
import ProductFormComponent from './components/admin/product-form.component.vue';
import ClipboardCopyComponent from './components/legacy/clipboard-copy.component.vue';
import EnvironmentsFormComponent from './components/admin/environments-form.component.vue';
import AdminLoginFormComponent from './components/admin/login-form.component.vue';
import AdminLoginCallbackComponent from './components/admin/admin-login-callback.component.vue';
import PortalNavComponent from './components/portal/navigation.component.vue';
import ModalContainerComponent from './components/portal/modals/modal-container.component.vue';
import PortalFooterComponent from './components/portal/footer.component.vue';
import SessionHandlerComponent from './components/portal/auth/session-handler.component.vue';
import AdminNav from './components/admin/admin-navigation.component.vue';
import CaretDown from './components/portal/svg/caret-down.component.vue';
import AdminSessionHandler from './components/admin/admin-session-handler.vue';

import {
  clearLocalStorage,
  clearSessionStorage,
  getItemInSessionStorage,
} from './services/storage.service';

const pageOptions = createPageOptions();

const vueApp = createApp({});

vueApp.use(formKitPlugin, formKitConfig);

window['initVue'] = () => {
  // Legacy open
  vueApp.component('product-form', ProductFormComponent);
  vueApp.component('personal-form', PersonalFormComponent);
  vueApp.component('applications-keys', ApplicationsKeysComponent);
  vueApp.component('api-reference-download', ApiReferenceDownloadComponent);
  vueApp.component('api-try-it', ApiTryItComponent);
  vueApp.component('code-block', CodeBlockComponent);
  vueApp.component('cursor-pagination', CursorPaginationComponent);
  vueApp.component('expandable-panel', ExpandablePanelComponent);
  vueApp.component('account-info', AccountInfoComponent);
  vueApp.component('application-invite-owners', ApplicationInviteOwnersComponent);
  vueApp.component('application-form', ApplicationFormComponent);
  vueApp.component('applications-list', ApplicationsListComponent);
  vueApp.component('partner-organization-form', PartnerOrganizationFormComponent);
  vueApp.component(
    'partner-organization-invite-members',
    PartnerOrganizationInviteMembersComponent,
  );
  vueApp.component('environments-form', EnvironmentsFormComponent);

  // Custom Elements for docs
  customElements.define(
    'api-reference-download',
    defineCustomElement(ApiReferenceDownloadComponent),
  );

  // dx.apiture.com
  vueApp.component('clipboard-copy-component', ClipboardCopyComponent);
  vueApp.component('admin-login-form', AdminLoginFormComponent);
  vueApp.component('admin-login-callback', AdminLoginCallbackComponent);
  vueApp.component('portal-nav', PortalNavComponent);
  vueApp.component('modal-container', ModalContainerComponent);
  vueApp.component('portal-footer', PortalFooterComponent);
  vueApp.component('session-handler', SessionHandlerComponent);
  vueApp.component('admin-portal-nav', AdminNav);
  vueApp.component('CaretDown', CaretDown);
  vueApp.component('admin-session-handler', AdminSessionHandler);

  vueApp.mount('#vue-app');
};

(() => {
  initAuthService();

  window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#legacy-nav')) {
      checkAuth();
      initTopNav();

      document.querySelector('#logoutLink').addEventListener('click', async () => {
        const devPortalAccessToken = getItemInSessionStorage('devPortalAccessToken');
        const oktaAccessToken = await getOktaAccessToken();

        try {
          if (devPortalAccessToken) {
            await signOut();
            clearLocalStorage();
            clearSessionStorage();
          } else if (oktaAccessToken) {
            await removeOktaTokens();
          }
        } catch {
          clearLocalStorage();
          clearSessionStorage();
        }

        window.location.href = '/open/dashboard';
      });
    }
  });
})();

function checkAuth(): void {
  if (!siteOptions.loginEnabled) {
    return;
  }

  if (pageOptions.authPartnerDomainValid) {
    partnerDomainValid$.subscribe(partnerDomainValid => {
      if (!partnerDomainValid) {
        window.location.replace('/404.html');
        return;
      }
    });
  }
}

function initTopNav(): void {
  const validPartnerDomainLinks = ['partnerLink', 'applicationsLink'];
  partnerDomainValid$.subscribe(partnerDomainValid => {
    validPartnerDomainLinks.forEach(id => {
      const $link = $(document).find(`#${id}`);
      $link.toggleClass('disabled', !partnerDomainValid);
    });
  });

  const devPortalAccessToken = getItemInSessionStorage('devPortalAccessToken');
  const loginLink = document.querySelector<HTMLAnchorElement>('#loginLink');

  if (devPortalAccessToken) {
    loginLink.classList.add('d-none');
  }
}
