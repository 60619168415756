<template>
  <table
    class="app-table"
    :class="[props.tableClass, props.striped && 'app-table--striped']"
  >
    <thead class="app-table--head">
      <tr class="app-table--row">
        <th
          v-for="(field, i) in props.fields"
          :key="field.toString() + i.toString()"
          scope="col"
          class="app-table--cell"
        >
          {{ field }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-if="props.busy"
        class="app-table--loading app-table--cell"
      >
        <LoadingIndicatorComponent />
      </tr>
      <tr v-else-if="props.items.length < 1 && !props.busy">
        There are no records to show
      </tr>
      <template
        v-for="(item, i) in items"
        :key="i"
      >
        <tr
          class="app-table--row"
          :class="props.tbodyTrClass"
          else
          @click="
            emit('row-clicked', {
              rowIndex: Number(i),
              item,
              expandDetails: expandDetails,
              isDetailsExpanded: expandedRows.includes(Number(i)),
            })
          "
        >
          <template
            v-for="field in props.fields"
            :key="field"
          >
            <td
              scope="row"
              class="app-table--cell"
            >
              <slot
                :name="`cell(${camelCase(field)})`"
                :row="{
                  rowIndex: Number(i),
                  item,
                  expandDetails: expandDetails,
                  isDetailsExpanded: expandedRows.includes(Number(i)),
                }"
              >
                {{ item[camelCase(field)] }}
              </slot>
            </td>
          </template>
        </tr>
        <th
          v-if="expandedRows.includes(Number(i))"
          scope="row"
          :colspan="props.fields.length"
        >
          <div class="app-table--details">
            <slot
              :name="`row(expanded)`"
              :row="{ item }"
            />
          </div>
        </th>
      </template>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import { camelCase } from 'lodash';
import { ref } from 'vue';

interface TableRow {
  rowIndex: number;
  item: unknown;
  expandDetails: () => void;
  isDetailsExpanded: boolean;
}

const props = withDefaults(
  defineProps<{
    items: unknown[];
    busy: boolean;
    fields: string[];
    tableClass?: string;
    tbodyTrClass?: string;
    striped?: boolean;
  }>(),
  {
    tbodyTrClass: '',
    tableClass: '',
    expandRowsOnClick: false,
    striped: false,
  },
);
const emit = defineEmits<{ (event: 'row-clicked', data: TableRow): void }>();
const expandedRows = ref<number[]>([]);

function expandDetails(row: TableRow) {
  const expandedRowsIndex = expandedRows.value.findIndex(i => row.rowIndex === i);

  if (expandedRowsIndex === -1) {
    expandedRows.value.push(row.rowIndex);
  } else {
    expandedRows.value.splice(expandedRowsIndex, 1);
  }

  row.isDetailsExpanded = !row.isDetailsExpanded;
}
</script>

<style lang="scss">
@import 'src/scss/variables';
.app-table {
  width: 100%;
  min-height: 20rem;
  margin-bottom: 1rem;
  border-collapse: collapse;
  background-color: $app-neutral-white;
  border: 1px solid $app-neutral-gray-20;
  position: relative;
}

.app-table--head {
  background-color: $app-neutral-gray-20;
}

.app-table--row {
  border-bottom: 1px solid $app-neutral-gray-20;
}

.app-table--cell {
  vertical-align: middle;
  padding: 0.5rem 1rem;
}

.app-table--loading {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-table--details {
  margin: 1rem 0 0 1rem;
  position: relative;
  max-width: fit-content;
}

.app-table--striped {
  tr:nth-child(even) {
    background-color: $app-neutral-gray-20;
  }
}
</style>
