import { sanitize } from '../../helpers/sanitize-helper';

export interface DialogConfirmParams {
  title?: string;
  message: string;
  buttons?: {
    cancel?: DialogConfirmButtonParams;
    confirm?: DialogConfirmButtonParams;
  };
}

export interface DialogConfirmButtonParams {
  faIcon?: string;
  label?: string;
  class?: string;
}

export async function confirmDialog(params: DialogConfirmParams): Promise<boolean> {
  return new Promise(resolve => {
    const defaultParams: Partial<DialogConfirmParams> = {
      title: 'Confirmation',
      buttons: {
        cancel: {
          faIcon: 'ban',
          label: 'Cancel',
          class: 'btn-secondary',
        },
        confirm: {
          faIcon: 'check-circle-o',
          label: 'Confirm',
          class: 'btn-primary',
        },
      },
    };

    const confirmParams: DialogConfirmParams = _.merge({}, defaultParams, params);
    const confirmOptions: BootboxConfirmOptions = {
      title: confirmParams.title,
      message: sanitize(confirmParams.message),
      backdrop: true,
      buttons: _.mapValues(confirmParams.buttons, (buttonParams: DialogConfirmButtonParams) => {
        return {
          label: sanitize(
            `${
              buttonParams.faIcon
                ? `<span class="fa fa-${buttonParams.faIcon}"></span> ${buttonParams.label}`
                : buttonParams.label
            }`,
          ),
          className: `${buttonParams.class}`,
        };
      }) as BootboxConfirmPromptButtonMap,
      callback: result => {
        resolve(result);
      },
    };

    bootbox.confirm(confirmOptions);
  });
}
