import { getOptions, config } from './base-api.service';
import {
  ClientApplicationApi,
  Application,
  CreateInvitation,
  ClientApplicationActionsApi,
  ClientState,
  DeveloperPortalApplicationApi,
  ProductLine as ProductLineEnum,
} from '@apiture/client-applications-client-sdk';
import { ProductLine } from '../../utils/product-lines';

const applicationApi = new ClientApplicationApi(config);
const applicationActionsApi = new ClientApplicationActionsApi(config);
const developerPortalApplicationApi = new DeveloperPortalApplicationApi(config);

export async function getApplications(query?: {
  start?: string;
  limit?: number;
  partner?: string;
  owner?: string;
  products?: string[];
  environments?: string[];
  states?: ClientState[];
  productLine?: ProductLine;
}) {
  const applications = await applicationApi.getApplications(
    {
      start: query?.start,
      limit: query?.limit,
      partner: query?.partner,
      owner: query?.owner,
      product: query?.products,
      environment: query?.environments,
      state: query?.states,
      productLine: query?.productLine as ProductLineEnum,
    },
    await getOptions(),
  );

  return applications.data;
}

export async function getApplication(applicationId: string) {
  const application = await applicationApi.getApplication(
    applicationId,
    undefined,
    await getOptions(),
  );

  return application.data;
}

export async function getDeveloperPortalApplication() {
  const devPortalApplication = await developerPortalApplicationApi.getDeveloperPortalApplication(
    await getOptions(),
  );

  return devPortalApplication.data;
}

export async function inviteJointOwners(applicationId: string, invitation: CreateInvitation) {
  const inviteJointOwnersResponse = await applicationApi.inviteJointOwners(
    invitation,
    applicationId,
    await getOptions(),
  );

  return inviteJointOwnersResponse.data;
}

export async function createApplication(application: Application) {
  const createdApplication = await applicationApi.createApplication(
    application,
    await getOptions(),
  );

  return createdApplication.data;
}

export async function updateApplication(application: Application, applicationId: string) {
  const updatedApplication = await applicationApi.updateApplication(
    application,
    applicationId,
    undefined,
    await getOptions(),
  );

  return updatedApplication.data;
}

export async function patchApplication(application: Application, applicationId: string) {
  const patchedApplication = await applicationApi.patchApplication(
    application,
    applicationId,
    undefined,
    await getOptions(),
  );

  return patchedApplication.data;
}

export async function deleteApplication(applicationId: string) {
  const deletedApplication = await applicationApi.deleteApplication(
    applicationId,
    await getOptions(),
  );

  return deletedApplication.data;
}

export async function activateApplication(applicationId: string) {
  const activatedApplication = await applicationActionsApi.activateApplication(
    { application: applicationId },
    undefined,
    await getOptions(),
  );

  return activatedApplication.data;
}

export async function rejectApplication(applicationId: string) {
  const rejectedApplication = await applicationActionsApi.rejectApplication(
    { application: applicationId },
    undefined,
    await getOptions(),
  );

  return rejectedApplication.data;
}

export async function deactivateApplication(applicationId: string) {
  const deactivatedApplication = await applicationActionsApi.deactivateApplication(
    { application: applicationId },
    undefined,
    await getOptions(),
  );

  return deactivatedApplication.data;
}
