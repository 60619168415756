import type { User } from '../models/user';
import type { UserRole } from '../models/user-role';

import {
  clearLocalStorage,
  clearSessionStorage,
  getItemInSessionStorage,
  setItemInSessionStorage,
} from './storage.service';
import { getEmailDomain } from '../helpers/email-helper';
import { getUserAttributes, updateUserAttributes, signOut } from './cognito.service';

export function authLogin(): void {
  const url = new URL(window.location.href);
  const pathnameIsOpen = url.pathname.includes('open');
  const loginUrlByUserRole =
    getItemInSessionStorage<UserRole>('userRole') === 'Admin' ? 'auth/admin' : 'auth/login';

  window.location.replace(pathnameIsOpen ? '/openAuth/login/' : loginUrlByUserRole);
}

export async function authLogoutCognito(reloadPage: boolean = true) {
  await signOut();

  clearLocalStorage();
  clearSessionStorage();

  if (reloadPage) {
    window.location.reload();
  }
}

export function getAdminUser() {
  return getItemInSessionStorage<User>('oktaUserData');
}

export function getUser() {
  return mapCognitoUserDataToUser();
}

export async function updateUser(user: User) {
  if (user.auth !== 'cognito') {
    return;
  }

  await updateUserAttributes(user);

  const userAttributes = await getUserAttributes();

  setItemInSessionStorage('userData', JSON.stringify(Object.assign(user, userAttributes)));
}

function mapCognitoUserDataToUser() {
  const userData = getItemInSessionStorage<{ [key: string]: string }>('userData');
  const email = getItemInSessionStorage('email');

  if (!userData) {
    return null;
  }

  return {
    firstName: userData['given_name'],
    lastName: userData['family_name'],
    middleName: userData['middle_name'],
    suffix: userData['custom:suffix'],
    birthDate: userData['birthdate'],
    phoneNumber: userData['phone_number'],
    citizen: userData['custom:usCitizen'] === 'Yes',
    certify: userData['custom:certify'] === 'true',
    domain: getEmailDomain(email),
    email,
    role: getItemInSessionStorage<UserRole>('userRole'),
    termsConditionsAccepted: userData['custom:tnc'] === 'true',
    auth: 'cognito',
  } as User;
}
