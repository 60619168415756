<template>
  <fieldset>
    <legend v-if="props.context.fieldsetLabel">
      {{ props.context.fieldsetLabel }}
    </legend>
    <FormKit
      type="group"
      :ignore="true"
      preserve
      @input="handleInput"
    >
      <div
        class="form-input--repeater"
        :data-test="props.context.node.name"
      >
        <FormKit
          v-for="(group, i) in groups"
          :key="group.id"
          type="group"
          :name="group.id"
          validation="required"
          :value="group.value"
          :index="i"
        >
          <div
            :ref="
              element => {
                if (groups.length - 1 !== i || !element) {
                  return;
                }

                latestRepeaterInput = (element as HTMLDivElement).querySelector('input');
              }
            "
            class="form-container--row"
            :data-test="`${props.context.node.name}-group-${i.toString()}`"
          >
            <component :is="props.context.slots['default']" />
            <FormKit
              type="button"
              input-class="form-input--repeater-button"
              outer-class="align-self-center"
              prefix-icon="trash"
              :aria-label="`remove ${props.context.node.name} ${i.toString()}`"
              :data-test="`${props.context.node.name}-group-${i.toString()}-remove`"
              label=" "
              @click="remove(i)"
            />
          </div>
        </FormKit>
        <div class="form-container--row">
          <FormKit
            type="button"
            input-class="form-input--repeater-button"
            outer-class="align-self-start"
            :aria-label="`add ${props.context.node.name}`"
            label=" "
            prefix-icon="add"
            :data-test="`${props.context.node.name}-add`"
            @click="add"
          />
          <FormKit
            type="button"
            input-class="form-input--repeater-button"
            outer-class="align-self-start"
            :aria-label="`reset ${props.context.node.name}`"
            label=" "
            prefix-icon="refresh"
            :data-test="`${props.context.node.name}-reset`"
            @click="reset()"
          />
        </div>
      </div>
    </FormKit>
  </fieldset>
</template>

<script lang="ts" setup>
import { ref, shallowRef, watch, nextTick } from 'vue';
import type { FormKitFrameworkContext } from '@formkit/core';
import { FormKit } from '@formkit/vue';
import { token } from '@formkit/utils';

type RepeaterInputValue = { [key: string]: string };
type RepeaterPayload = { [key: string]: RepeaterInputValue };
type RepeaterGroup = { id: string; value?: RepeaterInputValue };

const props = defineProps<{
  context: FormKitFrameworkContext & {
    // Label for fieldset, similar to checkbox list inputs.
    fieldsetLabel: string;
  };
}>();

const groups = ref<RepeaterGroup[]>([{ id: token() }]);
const latestRepeaterInput = ref<HTMLInputElement>();
const initialValues = shallowRef<RepeaterInputValue[]>([]);

watch(
  () => props.context.value,
  (values, previousValues) => {
    if (previousValues) {
      return;
    }

    groups.value = [];

    for (const value of values) {
      addWithValue(value);
      initialValues.value.push(value);
    }
  },
);

function remove(index: number) {
  if (groups.value.length <= 1) {
    return;
  }

  groups.value.splice(index, 1);
}

function add() {
  groups.value.push({ id: token() });

  if (!latestRepeaterInput.value) {
    return;
  }

  nextTick(() => {
    latestRepeaterInput.value.focus();
  });
}

function addWithValue(value: RepeaterInputValue) {
  groups.value.push({ id: token(), value });
}

function reset() {
  groups.value.splice(0, groups.value.length);

  if (initialValues.value.length === 0) {
    add();
  }

  for (const initialValue of initialValues.value) {
    addWithValue(initialValue);
  }
}

function handleInput(payload: RepeaterPayload) {
  const groupIds = groups.value.map(group => group.id);
  const payloadKeys = Object.keys(payload);
  const finalPayload: RepeaterInputValue[] = [];

  for (const key of payloadKeys) {
    if (groupIds.includes(key)) {
      finalPayload.push(payload[key]);
    }
  }

  props.context.node.input(finalPayload);
}
</script>

<style lang="scss" scoped>
.form-input--repeater {
  max-height: 30rem;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-input--repeater-button {
  background-color: transparent;

  span {
    margin: 0;
  }
}
</style>
