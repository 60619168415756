import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "portal-nav" }
const _hoisted_2 = {
  id: "portal-navbar",
  class: "nav--navbar app-container"
}
const _hoisted_3 = ["aria-expanded"]

import { ref } from 'vue';
import HamburgerMenu from './svg/hamburger-menu.component.vue';
import CloseButton from './close-button.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'navigation-container.component',
  setup(__props) {

const urlHref = window.location.href;
const baseUrl = window.location.origin;
const includeNavigation = !urlHref.includes('/auth/');

const isExpanded = ref(false);

function handleExpandNav() {
  isExpanded.value = !isExpanded.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        href: "#",
        class: "nav--header-link"
      }, [
        _createElementVNode("img", {
          src: "/img/group.png",
          alt: "Apiture Logo",
          class: "nav--header-logo",
          width: "300",
          height: "32"
        })
      ], -1)),
      includeNavigation
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "nav--btn-toggle",
            "aria-expanded": isExpanded.value,
            "aria-controls": "navbar",
            "aria-label": "Toggle navigation",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleExpandNav()))
          }, [
            (!isExpanded.value)
              ? (_openBlock(), _createBlock(HamburgerMenu, { key: 0 }))
              : (_openBlock(), _createBlock(CloseButton, { key: 1 }))
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      includeNavigation
        ? (_openBlock(), _createElementBlock("ul", {
            key: 1,
            class: _normalizeClass([
          'nav--list nav--list-collapsable',
          isExpanded.value ? 'nav--collapse-show' : 'nav--collapse-hide',
        ])
          }, [
            _renderSlot(_ctx.$slots, "default", {
              isExpanded: isExpanded.value,
              baseUrl: _unref(baseUrl)
            })
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})