import axios from 'axios';
import SwaggerParser from '@apidevtools/swagger-parser';
import { OpenAPI } from 'openapi-types';
import { PromiseCache, PromiseCacheMemoryStorage } from '../utils/promise-cache';
import { createAxiosAdapterBuilderFactory } from '../utils/axios-adapter-builder';
import { ApiReferenceType } from '../models/api-reference-type';
import { ApiReferenceDocRoots } from '../models/api-doc-roots';
import {
  AuthorizationScope,
  ProductLine,
  AllowedApplicationTypes,
} from '@apiture/api-products-client-sdk';
import { ApplicationType } from '@apiture/client-applications-client-sdk';

export interface Api {
  label: string;
  apiName: string;
  repo: string;
  versions: string[];
  summary: string;
  productLine?: ProductLine;
  productLines?: ProductLine[];
  internal: boolean;
  allowedApplicationTypes: AllowedApplicationTypes[];
}

export interface GetApiReferenceParams {
  docRoot: 'open' | 'docs';
  api: string;
  version: string;
  type: ApiReferenceType;
  apiHost?: string;
}

export interface ApiScopesWithOperationIds {
  apiName: string;
  apiScopes: {
    version: string;
    scopes: ApiScopes[];
  };
}

export type ApiScopes = {
  [key: string]: {
    description: string;
    operationIds: string[];
  };
};

export const defaultReferenceDomainRegExp = /devbank\.apiture\.com/g;

const jsonCache: PromiseCache = new PromiseCache({
  storage: new PromiseCacheMemoryStorage(),
});

const documentsCache: PromiseCache = new PromiseCache({
  storage: new PromiseCacheMemoryStorage(),
});

export const applicationTypesNames: ReadonlyMap<ApplicationType, string> = new Map([
  [ApplicationType.Desktop, 'Desktop'],
  [ApplicationType.Mobile, 'Native Mobile'],
  [ApplicationType.Service, 'Service Application'],
  [ApplicationType.Web, 'Web'],
]);

export function getApiDomainByHost(apiHost: string) {
  return _.trimStart(apiHost, 'api.');
}

export async function getApis(productLine: ProductLine = ProductLine.Open): Promise<Api[]> {
  const getApisResponse = await axios.get<{ apis: Api[] }>(`/docs/apis/apis.json`, {
    adapter: createAxiosAdapterBuilderFactory().cache({ cache: jsonCache }).build(),
  });

  return getApisResponse.data.apis.filter(
    api => api?.productLine === productLine || api?.productLines?.includes(productLine),
  );
}

export async function getApiDocument(
  docRoot: ApiReferenceDocRoots,
  api: string,
  version: string,
): Promise<OpenAPI.Document> {
  const referenceUrl = `/${docRoot}/apis/${api}/v${version}/openapi.json`;
  return documentsCache.get(
    referenceUrl,
    () =>
      new Promise((resolve, reject) => {
        // Only parse/dereference, not validate: if docRoot == 'docs' (ADB APIs) SwaggerParser.validate won't work, as ADB uses OpenAPI 3.1
        // and SwaggerParser only recognizes 3.0
        SwaggerParser.dereference(referenceUrl, (error, document) => {
          if (error) {
            return reject(error);
          }
          resolve(document);
        });
      }),
  );
}

export function getApiReferenceUrl(
  docRoot: ApiReferenceDocRoots,
  api: string,
  version: string,
  type: ApiReferenceType,
): string {
  const openApiUrl = `/${docRoot}/apis/${api}/v${version}/openapi.${type}`;
  return openApiUrl;
}

export async function getApiReference(params: GetApiReferenceParams): Promise<string> {
  const referenceUrl = getApiReferenceUrl(params.docRoot, params.api, params.version, params.type);
  const getReferenceResponse = await axios.get(referenceUrl);

  const reference: string =
    params.type === 'json'
      ? JSON.stringify(getReferenceResponse.data, null, '  ')
      : getReferenceResponse.data;

  /*
      The replace() below is supposed to affect only the JSON/YAML download buttons
      on /docs/reference/. This was useful for h-Open so that
      if the user was on integration, we replaced api.devbank.apiture.com
      with api.open-sandbox-team1.apiture.com, or if they have selected
      a custom env such as api.liveoak-uat.apiture.com, we swap that for
      api.devbank.apiture.com .
      It's not needed for ADB since ADB is multi-tenant and we don't
      use devbank.apiture.com in the API doc.
      Also, it appears to also affect the actual
      OpenAPI generated HTML API doc, though I can't track down where.
      So disabling for now:

      if (params.apiHost)
        reference = reference.replace(
          defaultReferenceDomainRegExp,
          getApiDomainByHost(params.apiHost),
        );
    */

  return reference;
}

export async function getApiScopes(): Promise<Map<string, AuthorizationScope[]>> {
  const apiScopesResponseMap = new Map();
  const apiScopesResponse = await axios.get<ApiScopesWithOperationIds[]>(
    `/docs/apis/apiScopes.json`,
  );

  // Convert ApiScopes to a map of apiNames and AuthorizationScopes
  for (const data of apiScopesResponse.data) {
    const apiScopeKeys = Object.keys(data.apiScopes.scopes);

    const authorizationScope = apiScopeKeys.map(scopeName => {
      return {
        name: scopeName,
        description: data.apiScopes.scopes[scopeName].description,
      };
    });

    apiScopesResponseMap.set(data.apiName, authorizationScope);
  }

  return apiScopesResponseMap;
}
