import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

import DropdownComponent from './dropdown.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'tooltip.component',
  props: {
    tip: {},
    link: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownComponent, {
    "dropdown-content-class": "tooltip--content",
    "dropdown-pointer-class": "tooltip--pointer"
  }, {
    labelIcon: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "17",
        viewBox: "0 0 16 17",
        fill: "none"
      }, [
        _createElementVNode("g", { "clip-path": "url(#clip0_2318_524568)" }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M8 0.947998C12.4183 0.947998 16 4.52972 16 8.948C16 13.3663 12.4183 16.948 8 16.948C3.58172 16.948 0 13.3663 0 8.948C0 4.52972 3.58172 0.947998 8 0.947998ZM8 1.8855C4.09949 1.8855 0.9375 5.04749 0.9375 8.948C0.9375 12.8485 4.09949 16.0105 8 16.0105C11.9005 16.0105 15.0625 12.8485 15.0625 8.948C15.0625 5.04749 11.9005 1.8855 8 1.8855ZM8.46875 7.64856V12.8543C8.46875 13.1131 8.25888 13.323 8 13.323C7.74112 13.323 7.53125 13.1131 7.53125 12.8543V7.64856C7.53125 7.38968 7.74112 7.17981 8 7.17981C8.25888 7.17981 8.46875 7.38968 8.46875 7.64856ZM7.3125 5.72925C7.3125 6.10894 7.6203 6.41675 8 6.41675C8.3797 6.41675 8.6875 6.10894 8.6875 5.72925C8.6875 5.34955 8.3797 5.04175 8 5.04175C7.6203 5.04175 7.3125 5.34955 7.3125 5.72925Z",
            fill: "#005A7C"
          })
        ]),
        _createElementVNode("defs", null, [
          _createElementVNode("clipPath", { id: "clip0_2318_524568" }, [
            _createElementVNode("rect", {
              width: "16",
              height: "16",
              fill: "white",
              transform: "translate(0 0.947998)"
            })
          ])
        ])
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(props.tip), 1),
      (props.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            target: "_blank",
            href: props.link.href
          }, _toDisplayString(props.link.label), 9, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})