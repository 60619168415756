<template>
  <pre v-if="Boolean(props.text)">
    <code
ref="code"
class="hljs text-left"
><slot /></code>
  </pre>
</template>

<script lang="ts" setup>
import hljs from 'highlight.js';
import { onMounted, onUpdated, ref } from 'vue';

const props = defineProps<{
  text: string;
}>();

const code = ref<Element>();

onMounted(() => {
  highlightCode();
});

onUpdated(() => {
  highlightCode();
});

function highlightCode() {
  if (!props.text) {
    return;
  }

  const codeElement = code.value;

  codeElement.textContent = props.text;
  hljs.highlightBlock(code.value);
}
</script>
