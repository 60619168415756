import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = ["id", "value", "onInput"]

import type { FormKitFrameworkContext } from '@formkit/core';

import { ref, watch } from 'vue';
import RepeaterContainer from './repeater-container.vue';
import AddSvg from '../svg/add-svg.component.vue';
import RemoveButton from '../buttons/remove-button.component.vue';
import { token } from '@formkit/utils';

// A basic regExp to valid that the supplied domains loosely match what is expected.

export default /*@__PURE__*/_defineComponent({
  __name: 'domains.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const domainRegex = /^[a-zA-Z-]+\.\S+$/g;

const props = __props;

/**
 * Creates a new domain with an ID to be used as the repeater key.
 */
function createNewDomain(domain: string) {
  return {
    value: domain,
    id: token(), // This prevents the list (v-for) from breaking when used as the key.
  }
}

// The domains will be prefilled with a single and empty domain input.
const domains = ref<{ id: string, value: string; }[]>([createNewDomain('')]);

/**
 * Add new domains that are added asynchronously to the input.
 * 
 * - This is only ran once.
 */
 watch(() => props.context.value, (newDomains, oldDomains) => {
  // The previous value has already been set, so do not update the value.
  if(oldDomains) {
    return;
  }

  domains.value = newDomains.map(newDomain => createNewDomain(newDomain));
}, { once: true });

function validateDomain() {
  if(!props.context.value){
    return;
  }

  const lastAddedDomain = props.context.value[props.context.value.length - 1];

  if(!lastAddedDomain || !lastAddedDomain.match(domainRegex)) {
    props.context.node.setErrors(`Domains must match ${domainRegex}`);
  }
}

/**
 * Adds a new domain input.
 * 
 * - This function does not alter the value of `props.context.value`.
 * - `domains` does not have a max length, so as many as desired can be added.
 */
function addDomain() {
  validateDomain();

  // Value should be empty when a new domain is added.
  props.context.node.input([...props.context.value, '']);
  domains.value.push(createNewDomain(''));
 
  // If any error messages are present, delete them.
  props.context.node.clearErrors();
}

/**
 * Removes a domain input.
 * 
 * - This function does not alter the value of `props.context.value`.
 */
function removeDomain(index: number) {
  // Remove reference from domains. This will remove the element from the page.
  domains.value.splice(index, 1);

  // Remove the domain value from the input's value
  props.context.node.input([...props.context.value].splice(index, 1));
}

/**
 * Updates the value of domains on input.
 */
function updateDomain(event: Event, index: number) {
  // TypeScript doesn't know if the Event target has a value.
  const value = (event.target as HTMLInputElement).value;

  if(!value) {
    return;
  }
 
  // If the props.context.value is present then add those values to the new list of values.
  const newDomains = props.context.value ? [...props.context.value] : [];

  newDomains[index] = value;

  props.context.node.input(newDomains);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RepeaterContainer, {
    name: props.context.node.name,
    "disable-add": false,
    onAdd: addDomain
  }, {
    inputs: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(domains.value, (domain, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: domain.id,
          class: "form-container--row",
          "data-test": `${props.context.node.name}-group-${Number(i) + 1}`
        }, [
          _createElementVNode("input", {
            id: `domain-${i + 1}`,
            value: domain.value,
            type: "text",
            name: "domain",
            "data-test": "domain",
            class: "form-input--full",
            onInput: ($event: any) => (updateDomain($event, i)),
            onBlur: validateDomain
          }, null, 40, _hoisted_2),
          _createVNode(RemoveButton, {
            "data-test": `${props.context.node.name}-group-${Number(i) + 1}-remove`,
            label: `remove ${props.context.node.name} ${Number(i) + 1}`,
            disabled: domains.value.length <= 1,
            onClick: ($event: any) => (removeDomain(i))
          }, null, 8, ["data-test", "label", "disabled", "onClick"])
        ], 8, _hoisted_1))
      }), 128))
    ]),
    "add-button-content": _withCtx(() => [
      _createVNode(AddSvg)
    ]),
    _: 1
  }, 8, ["name"]))
}
}

})