<template>
  <slot
    v-if="accessToken"
    :token="accessToken"
  />
</template>

<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue';
import { addModal } from '../../../hooks/modal-store.hook';
import SessionExpiredModal from '../modals/session-expired-modal.component.vue';
import { useAccessTokenRefresher } from '../../../hooks/use-access-token-refresher';
import {
  clearLocalStorage,
  clearSessionStorage,
  setItemInSessionStorage,
} from '../../../services/storage.service';
import { siteOptions } from '../../../options/site-options';
import { createPageOptions } from '../../../options/page-options';
import { getUser } from '../../../services/portal-auth.service';

const pageOptions = createPageOptions();

const { accessToken, timeoutHandlerId, stopAccessTokenRefresher } = useAccessTokenRefresher({
  maxTimeoutTime: 1000 * 60 * 30, // 30 minutes
  successCallback: accessToken => {
    setItemInSessionStorage('devPortalAccessToken', accessToken);
  },
  failCallback: () => {
    createSessionExpModal();
  },
});

onBeforeMount(() => {
  const user = getUser();

  if (!siteOptions.loginEnabled || !pageOptions.auth) {
    return;
  }

  if (!user) {
    window.location.replace('/auth/login/');
  }
});

onUnmounted(() => {
  stopAccessTokenRefresher(timeoutHandlerId.value);
});

function createSessionExpModal(error?: string) {
  addModal({
    component: SessionExpiredModal,
    props: {
      error,
    },
    onComplete: () => {
      routeToLogin();
    },
    onCancel: () => {
      clearLocalStorage();
      clearSessionStorage();

      window.location.replace('/dashboard');
    },
    onError: () => {
      routeToLogin();
    },
  });
}

function routeToLogin() {
  clearLocalStorage();
  clearSessionStorage();

  window.location.replace('/auth/login/');
}
</script>
