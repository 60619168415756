<template>
  <div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <template v-else>
      <FormKit
        id="productForm"
        v-model="productFormData"
        type="form"
        submit-label="Save"
        :actions="false"
        data-test="productForm"
        @submit="saveProduct()"
      >
        <div class="form-container--row">
          <FormKit
            type="text"
            name="name"
            label="Product Name"
            help="The name of the API product."
            validation="required|length:6,64"
            outer-class="form-input--full"
            data-test="name"
          />
          <FormKit
            type="text"
            name="tag"
            label="Tag"
            help="A unique read-only alphanumeric
            identifier for this product. When combined with the version, this creates a unique
            human-readable identifier for this product."
            :validation="[['required'], ['length:6,64'], ['matches', /^[a-z][-_a-z0-9A-Z]{0,63}/]]"
            :disabled="Boolean(product._id)"
            :errors="tagValid"
            outer-class="form-input--full"
            data-test="tag"
          />
          <FormKit
            type="text"
            name="version"
            label="Version"
            help="The semantic version of the product."
            validation="required|length:1,512"
            outer-class="form-input--sm"
            data-test="version"
          />
        </div>
        <FormKit
          v-if="props.productLine === ProductLine.Adb"
          label="Product Type"
          type="select"
          :options="['api', 'component']"
          name="type"
          data-test="productType"
          outer-class="form-input--sm"
        />
        <FormKit
          type="textarea"
          name="description"
          label="Description of Product"
          help="The description of the product, may use markdown."
          validation="required|length:1,512"
          data-test="description"
        />
        <div class="form-container--row">
          <FormKit
            type="checkbox"
            label="APIs"
            name="apis"
            :options="
              apis
                .map(api => ({
                  label: `${api.label} v${api.versions[api.versions.length - 1]}${
                    api.internal ? ' - internal' : ''
                  }`,
                  value: api.apiName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            "
            help="Specific APIs that are elements
             of this product."
            data-test="apis"
          />
          <FormKit
            type="checkbox"
            label="Products"
            name="products"
            :options="mapProductsToOptions(products, false)"
            help="Specific APIs that are elements
            of this product."
            data-test="products"
          />
        </div>
        <FormKit
          v-if="props.productLine === ProductLine.Adb"
          type="checkbox"
          label="Exclude Write Scopes"
          name="excludeWriteScopes"
        />
        <FormKit
          v-if="props.productLine === ProductLine.Adb"
          type="scopes"
          name="excludedScopes"
          :scopes="uniqueSelectedScopes"
          :exclude-write-scopes="productFormData.excludeWriteScopes"
        />
        <div class="form-container--row">
          <AppAlertComponent
            :visible="obsoleteApis.length > 0"
            :affect-layout-if-invisible="true"
          >
            This product uses the following obsolete APIs:
            <ul class="list-unstyled mb-0">
              <li
                v-for="api in obsoleteApis"
                :key="api.name"
              >
                {{ `${api.name} v${api.version}` }}
              </li>
            </ul>
          </AppAlertComponent>
          <AppAlertComponent
            :visible="obsoleteProductsApis.size > 0"
            :affect-layout-if-invisible="true"
          >
            {{ obsoleteProductsApis.size > 1 ? 'These products' : 'This product' }}
            {{ obsoleteProductsApis.size > 1 ? 'use' : 'uses' }} the following obsolete APIs:
            <ul class="list-unstyled mb-0">
              <li
                v-for="[innerProduct, innerApis] in obsoleteProductsApis"
                :key="innerProduct"
              >
                <b class="pl-3">{{ innerProduct }} </b>
                <ul>
                  <li
                    v-for="innerApi in innerApis"
                    :key="innerApi"
                  >
                    {{ innerApi }}
                  </li>
                </ul>
              </li>
            </ul>
          </AppAlertComponent>
        </div>
        <div class="form-container--submits">
          <FormKit
            type="submit"
            :disabled="tagValid.length > 0 || state.loading"
            data-test="submit"
          >
            Save
          </FormKit>
          <FormKit
            type="button"
            :disabled="!productFormData.description || state.loading"
            input-class="form-input--button-transparent"
            data-test="previewDescription"
            @click="showDescriptionPreview()"
          >
            Preview Description
          </FormKit>
        </div>
      </FormKit>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import bootbox from 'bootbox';
import {
  Product,
  Api as ProductApi,
  ProductLine,
  ProductType,
  ErrorResponse,
  AllowedApplicationTypes,
} from '@apiture/api-products-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { createProduct, patchProduct, getProduct } from '../../services/api/products-api.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getApis, Api } from '../../services/apis.service';
import { getUrlParams, getIdFromUri } from '../../helpers/window-helper';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import AppAlertComponent from '../legacy/app-alert.component.vue';
import { useProducts, mapProductsToOptions } from '../../hooks/use-products.hook';
import { useScopes } from '../../hooks/use-scopes.hook';

const props = defineProps<{
  productLine: ProductLine;
}>();

const productFormData = ref<{
  name: string;
  tag: string;
  version: string;
  description: string;
  products: string[];
  apis: string[];
  excludeWriteScopes: boolean;
  excludedScopes: string[];
  type: ProductType;
}>({
  name: '',
  tag: '',
  version: '',
  description: '',
  products: [],
  apis: [],
  excludeWriteScopes: false,
  excludedScopes: [],
  type: ProductType.Api,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
}>({
  initialized: false,
  loading: false,
});

const product = ref<Product>();

const apis = ref<Api[]>([]);
const obsoleteApis = ref<ProductApi[]>([]);

const products = useProducts(props.productLine);

const { uniqueSelectedScopes, apiScopes } = useScopes({
  formData: productFormData,
  products,
  apis,
});

onMounted(async () => {
  const productId = getUrlParams().get('productId');

  product.value = productId ? await getProduct(productId) : ({} as Product);

  apis.value = await getApis(props.productLine);

  if (product.value) {
    fillFormData();
  }

  state.value.initialized = true;
});

const tagValid = computed(() => {
  if (product.value._id) return [];

  return products.value.some(product => product.tag === productFormData.value.tag)
    ? ['Tag is already in use.']
    : [];
});

const obsoleteProductsApis = computed(() => {
  const obsoleteProductApis = new Map<string, string[]>();

  const includedProducts = products.value.filter(product =>
    productFormData.value.products?.includes(product._id),
  );

  for (const product of includedProducts) {
    const obsoleteApis: string[] = [];
    const productTagAndVersion = `${product.tag} v${product.version}`;

    for (const api of product.apis) {
      const existingApi = apis.value.some(
        apiInner =>
          `${api.name} v${api.version}` ===
          `${apiInner.label} v${apiInner.versions[apiInner.versions.length - 1]}`,
      );

      if (!existingApi) {
        obsoleteApis.push(`${api.name} v${api.version}`);
      }
    }

    if (obsoleteApis.length) {
      obsoleteProductApis.set(productTagAndVersion, obsoleteApis);
    }
  }

  return obsoleteProductApis;
});

function showDescriptionPreview() {
  const messageHtml = makeHtmlMarkdown(productFormData.value.description);
  bootbox.alert({
    title: 'Product Description',
    message: messageHtml,
  });
}

async function saveProduct() {
  Object.assign(product.value, getProductPatch());

  const isExistingProduct = Boolean(product.value._id);

  try {
    state.value.loading = true;
    product.value = isExistingProduct
      ? await patchProduct(product.value, product.value._id)
      : await createProduct(product.value);
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    state.value.loading = false;
  }

  showToastr({
    type: 'success',
    message: `Product has been successfully ${isExistingProduct ? 'updated' : 'created'}.`,
  });

  setTimeout(() => {
    window.location.href =
      props.productLine === ProductLine.Open ? '/openAdmin/products/' : '/admin/products/';
  }, 3000);
}

function getProductPatch() {
  const selectedProducts = products.value
    .filter(product => productFormData.value.products.includes(product._id))
    .map(product => ({
      name: product.name,
      version: product.version,
      _links: { self: { href: product._links['self'].href } },
    }));
  const productApis = apis.value
    .filter(api => productFormData.value.apis.includes(api.apiName))
    .map(api => {
      const productApi: ProductApi = {
        productLine: api.productLine
          ? api.productLine
          : api.productLines.find(apiProductLine => apiProductLine === props.productLine),
        name: api.apiName,
        version: api.versions[api.versions.length - 1],
        description: api.summary,
        allowedApplicationTypes:
          api.allowedApplicationTypes ?? Object.values(AllowedApplicationTypes),
      };

      if (props.productLine === ProductLine.Adb) {
        productApi.scopes = apiScopes.value.get(api.apiName);
      }

      return productApi;
    });

  // add obsolete APIs if user does not select actual versions.
  for (const obsoleteApi of obsoleteApis.value) {
    if (!productApis.some(api => api.name === obsoleteApi.name)) {
      productApis.push(obsoleteApi);
    }
  }

  const productPatch: Partial<Product> = {
    name: productFormData.value.name,
    version: productFormData.value.version,
    tag: productFormData.value.tag,
    description: productFormData.value.description,
    apis: productApis,
    products: selectedProducts,
  };

  if (props.productLine === ProductLine.Adb) {
    productPatch.excludeWriteScopes = productFormData.value.excludeWriteScopes ?? false;
    productPatch.excludedScopes =
      productFormData.value.excludedScopes.filter(
        excludedScope => productPatch.excludeWriteScopes && !excludedScope.includes('/write'),
      ) ?? [];
    productPatch.type = productFormData.value.type;
  }

  return productPatch;
}

function fillFormData() {
  Object.assign(productFormData.value, {
    name: product.value.name,
    tag: product.value.tag,
    version: product.value.version,
    description: product.value.description,
    products: product.value.products
      ?.map(productRef =>
        products.value.find(
          product => getIdFromUri(productRef._links['self'].href) === product._id,
        ),
      )
      .filter(product => Boolean(product))
      .map(product => product._id),
    apis: product.value.apis
      ?.filter(api => {
        const existingApi = apis.value.some(apiInner => api.name === apiInner.apiName);

        if (!existingApi) {
          obsoleteApis.value.push(api);
        }

        return existingApi;
      })
      .map(api => api.name),
  });

  if (props.productLine === ProductLine.Adb) {
    productFormData.value.excludeWriteScopes = product.value.excludeWriteScopes ?? false;
    productFormData.value.excludedScopes = product.value.excludedScopes ?? [];
    productFormData.value.type = product.value.type;
  }
}
</script>
