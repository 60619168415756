import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { ref } from 'vue';
import ClipboardCopyComponent from '../legacy/clipboard-copy.component.vue';
import EyeSvg from './svg/eye-svg.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'sensitive-value.component',
  props: {
    id: {},
    title: {},
    value: {},
    specialCharacter: { default: '.' },
    specialCharactersCount: { default: 25 }
  },
  setup(__props: any) {

const props = __props;

const visible = ref<boolean>(false);

function toggle(): void {
  visible.value = !visible.value;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("span", {
    class: "d-inline-flex",
    "data-test": props.id
  }, [
    (visible.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(props.value), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(props.specialCharacter.repeat(props.specialCharactersCount)), 1)),
    _createVNode(_component_FormKit, {
      id: props.id + 'ToggleVisibility',
      type: "button",
      "input-class": "form-input--button-transparent",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
    }, {
      default: _withCtx(() => [
        _createVNode(EyeSvg)
      ]),
      _: 1
    }, 8, ["id"]),
    _createVNode(ClipboardCopyComponent, {
      id: props.id,
      title: props.title,
      value: props.value
    }, null, 8, ["id", "title", "value"])
  ], 8, _hoisted_1))
}
}

})