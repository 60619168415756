<template>
  <div
    class="form-input--component-environment"
    :data-test="props.context.attrs['data-test']"
  >
    <fieldset :class="props.context.classes.fieldset">
      <legend :class="props.context.classes.legend">
        Choose an environment
      </legend>
      <ul class="form-input--option-list remove-list-bullets">
        <li
          v-for="(option, i) in mapEnvironmentsToOptions(props.context.environments, false)"
          :key="option.value"
          :class="props.context.classes.option"
        >
          <label class="form-input--wrapper">
            <span class="form-input--option-inner"><input
              :id="`input_${i.toString()}-option-${option.value}`"
              v-model="environment"
              class="form-input--option"
              type="radio"
              :name="context.node.name"
              :value="option.value"
              :data-test="`${props.context.attrs['data-test']}-${i.toString()}`"
              @input="handleEnvironmentInput"
            >
            </span>
            <span class="form-input--label">{{ option.label }}</span>
          </label>

          <div
            v-if="
              option.value === selectedEnvironment?._id &&
                selectedEnvironment?.type !== EnvironmentType.Production &&
                props.context.includeMaximumConnectionHours
            "
            class="form-input--maxConnectionHours"
          >
            <fieldset class="b-none flex-col">
              <legend class="d-inline-flex">
                Maximum Connection Time
                <Tooltip
                  tip="The maximum number of hours before user has to reconnect their banking access to non-production applications by actively logging in. This applies only to non-prod access. For production environments, this configuration is set by the financial institution."
                />
              </legend>
              <div class="form-container--row">
                <label class="flex-col">
                  <span class="form-input--label">Days</span>
                  <input
                    v-model="maximumConnectionTime.days"
                    data-test="days"
                    name="days"
                    type="number"
                    class="form-input form-input--text"
                    @input="handleMaxConnectionTimeDays"
                    @keydown="preventDecimalNumbers"
                  >
                </label>
                <label class="flex-col">
                  <span class="form-input--label">Hours</span>
                  <input
                    v-model="maximumConnectionTime.hours"
                    data-test="hours"
                    name="hours"
                    type="number"
                    class="form-input form-input--text"
                    @input="handleMaxConnectionTimeHours"
                    @keydown="preventDecimalNumbers"
                  >
                </label>
              </div>
            </fieldset>
          </div>
        </li>
      </ul>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue';
import { Environment } from '@apiture/api-environments-client-sdk';
import { FormKitFrameworkContext } from '@formkit/core';
import Tooltip from '../tooltip.component.vue';
import { EnvironmentType } from '@apiture/api-environments-client-sdk';
import { mapEnvironmentsToOptions } from '../../../hooks/use-environments.hook';

type MaximumConnectionTime = {
  days: number;
  hours: number;
};

const props = defineProps<{
  context: FormKitFrameworkContext<{
    id: string;
    maximumConnectionTime?: MaximumConnectionTime;
  }> & {
    environments: Environment[];
    includeMaximumConnectionHours: boolean;
  };
}>();

const environment = ref<string>();
const maximumConnectionTime = ref<MaximumConnectionTime>({
  days: 60,
  hours: 0,
});

const selectedEnvironment = computed(() =>
  props.context.environments.find(environment => environment?._id === props.context._value?.id),
);

onMounted(() => {
  updateEnvironment();

  props.context.node.input({
    id: environment.value,
    ...(props.context.includeMaximumConnectionHours && {
      maximumConnectionTime: maximumConnectionTime.value,
    }),
  });
});

watch(
  () => props.context.value,
  () => {
    if (
      props.context.value.id &&
      props.context.includeMaximumConnectionHours &&
      props.context.value.maximumConnectionTime
    ) {
      return;
    }

    updateEnvironment();
  },
);

function handleMaxConnectionTimeDays(event: Event & { target: HTMLInputElement }) {
  if (Number(event.target.value) > 365 || event.target.value.length > 3) {
    maximumConnectionTime.value.days = 365;
  } else if (Number(event.target.value) < 0) {
    maximumConnectionTime.value.days = 0;
  }

  props.context.node.input({
    ...props.context.value,
    maximumConnectionTime: {
      ...maximumConnectionTime.value,
      days: maximumConnectionTime.value.days,
    },
  });
}

function handleMaxConnectionTimeHours(event: Event & { target: HTMLInputElement }) {
  if (Number(event.target.value) > 99 || event.target.value.length > 2) {
    maximumConnectionTime.value.hours = 99;
  } else if (Number(event.target.value) < 0) {
    maximumConnectionTime.value.hours = 0;
  }

  props.context.node.input({
    ...props.context.value,
    maximumConnectionTime: {
      ...maximumConnectionTime.value,
      hours: maximumConnectionTime.value.hours,
    },
  });
}

function preventDecimalNumbers(event: KeyboardEvent) {
  if (event.key === '.') {
    event.preventDefault();
  }
}

function handleEnvironmentInput(event: Event & { target: HTMLInputElement }) {
  props.context.node.input({
    ...props.context.value,
    id: event.target.value,
  });
}

function updateEnvironment() {
  if (!props.context._value || (environment.value && maximumConnectionTime.value)) {
    return;
  }

  environment.value = props.context.value?.id;

  if (props.context.includeMaximumConnectionHours) {
    maximumConnectionTime.value = {
      days: props.context.value?.maximumConnectionTime?.days ?? 60,
      hours: props.context.value?.maximumConnectionTime?.hours ?? 0,
    };
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/mixins';

fieldset {
  border: none;
  padding: 0;
}

fieldset ul {
  max-width: 95%;

  @include md {
    float: right;
  }
}

.form-input--maxConnectionHours {
  padding: 1.5rem 0 0 0.5rem;

  fieldset {
    padding: 1rem 1rem 1rem 0;
  }

  legend {
    padding: 0;
  }

  > div {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
