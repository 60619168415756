// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button[data-v-947b8f0e] {
  border: none;
  background-color: transparent;
  transform: scale(1.25);
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/buttons/remove-button.component.vue","webpack://./remove-button.component.vue"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,6BAAA;EACA,sBAAA;ACAF","sourcesContent":["\nbutton {\n  border: none;\n  background-color: transparent;\n  transform: scale(1.25);\n}\n","button {\n  border: none;\n  background-color: transparent;\n  transform: scale(1.25);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
