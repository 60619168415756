import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["data-test"]
const _hoisted_3 = ["data-test"]
const _hoisted_4 = { class: "form-container--row" }

import { ref, shallowRef, watch, nextTick } from 'vue';
import type { FormKitFrameworkContext } from '@formkit/core';
import { FormKit } from '@formkit/vue';
import { token } from '@formkit/utils';

type RepeaterInputValue = { [key: string]: string };
type RepeaterPayload = { [key: string]: RepeaterInputValue };
type RepeaterGroup = { id: string; value?: RepeaterInputValue };


export default /*@__PURE__*/_defineComponent({
  __name: 'input-repeater.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const groups = ref<RepeaterGroup[]>([{ id: token() }]);
const latestRepeaterInput = ref<HTMLInputElement>();
const initialValues = shallowRef<RepeaterInputValue[]>([]);

watch(
  () => props.context.value,
  (values, previousValues) => {
    if (previousValues) {
      return;
    }

    groups.value = [];

    for (const value of values) {
      addWithValue(value);
      initialValues.value.push(value);
    }
  },
);

function remove(index: number) {
  if (groups.value.length <= 1) {
    return;
  }

  groups.value.splice(index, 1);
}

function add() {
  groups.value.push({ id: token() });

  if (!latestRepeaterInput.value) {
    return;
  }

  nextTick(() => {
    latestRepeaterInput.value.focus();
  });
}

function addWithValue(value: RepeaterInputValue) {
  groups.value.push({ id: token(), value });
}

function reset() {
  groups.value.splice(0, groups.value.length);

  if (initialValues.value.length === 0) {
    add();
  }

  for (const initialValue of initialValues.value) {
    addWithValue(initialValue);
  }
}

function handleInput(payload: RepeaterPayload) {
  const groupIds = groups.value.map(group => group.id);
  const payloadKeys = Object.keys(payload);
  const finalPayload: RepeaterInputValue[] = [];

  for (const key of payloadKeys) {
    if (groupIds.includes(key)) {
      finalPayload.push(payload[key]);
    }
  }

  props.context.node.input(finalPayload);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", null, [
    (props.context.fieldsetLabel)
      ? (_openBlock(), _createElementBlock("legend", _hoisted_1, _toDisplayString(props.context.fieldsetLabel), 1))
      : _createCommentVNode("", true),
    _createVNode(_unref(FormKit), {
      type: "group",
      ignore: true,
      preserve: "",
      onInput: handleInput
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "form-input--repeater",
          "data-test": props.context.node.name
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groups.value, (group, i) => {
            return (_openBlock(), _createBlock(_unref(FormKit), {
              key: group.id,
              type: "group",
              name: group.id,
              validation: "required",
              value: group.value,
              index: i
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  ref_for: true,
                  ref: 
              element => {
                if (groups.value.length - 1 !== i || !element) {
                  return;
                }

                latestRepeaterInput.value = (element as HTMLDivElement).querySelector('input');
              }
            ,
                  class: "form-container--row",
                  "data-test": `${props.context.node.name}-group-${i.toString()}`
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(props.context.slots['default']))),
                  _createVNode(_unref(FormKit), {
                    type: "button",
                    "input-class": "form-input--repeater-button",
                    "outer-class": "align-self-center",
                    "prefix-icon": "trash",
                    "aria-label": `remove ${props.context.node.name} ${i.toString()}`,
                    "data-test": `${props.context.node.name}-group-${i.toString()}-remove`,
                    label: " ",
                    onClick: ($event: any) => (remove(i))
                  }, null, 8, ["aria-label", "data-test", "onClick"])
                ], 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["name", "value", "index"]))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(FormKit), {
              type: "button",
              "input-class": "form-input--repeater-button",
              "outer-class": "align-self-start",
              "aria-label": `add ${props.context.node.name}`,
              label: " ",
              "prefix-icon": "add",
              "data-test": `${props.context.node.name}-add`,
              onClick: add
            }, null, 8, ["aria-label", "data-test"]),
            _createVNode(_unref(FormKit), {
              type: "button",
              "input-class": "form-input--repeater-button",
              "outer-class": "align-self-start",
              "aria-label": `reset ${props.context.node.name}`,
              label: " ",
              "prefix-icon": "refresh",
              "data-test": `${props.context.node.name}-reset`,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (reset()))
            }, null, 8, ["aria-label", "data-test"])
          ])
        ], 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}
}

})