export type CursorPaginationEvent = CursorPaginationActionEvent | CursorPaginationLimitChangeEvent;

export interface CursorPaginationActionEvent {
  type: 'navigatePrevious' | 'navigateNext' | 'refresh';
}

export interface CursorPaginationLimitChangeEvent {
  type: 'limitChange';
  limit: number;
}

export interface CursorPagination {
  start?: string;
  next?: string;
  limit: number;
  previous: string[];
}

export function createCursorPagination(
  params: Partial<CursorPagination> = { limit: 5, previous: [] },
): CursorPagination {
  return Object.assign({}, params) as CursorPagination;
}

export function clearNavState(self: CursorPagination): void {
  self.start = undefined;
  self.previous = [];
  self.next = undefined;
}

export function processLinks(
  self: CursorPagination,
  links: { [key: string]: { href: string } },
): void {
  const selfLink = links?.['self']?.href;
  self.start = selfLink ? new URI(selfLink).search(true)['start'] : undefined;

  const nextLink = links?.['next']?.href;
  self.next = nextLink ? new URI(nextLink).search(true)['start'] : undefined;
}

export function processEvent(self: CursorPagination, evt: CursorPaginationEvent): void {
  switch (evt.type) {
    case 'navigatePrevious': {
      self.next = self.start;
      self.start = _.last(self.previous);
      self.previous.splice(-1, 1);
      break;
    }
    case 'navigateNext': {
      if (!self.previous.length || _.last(self.previous) !== self.start) {
        self.previous.push(self.start);
      }
      self.start = self.next;
      self.next = undefined;
      break;
    }
    case 'limitChange': {
      self.limit = evt.limit;
      clearNavState(self);
      break;
    }
  }
}
