import {
  Environment,
  EnvironmentState,
  EnvironmentType,
} from '@apiture/api-environments-client-sdk';
import { ErrorResponse, Key } from '@apiture/api-keys-client-sdk/dist/api';
import {
  env$,
  getOrCreateDefaultExplorerKey,
  targetEnvLogout,
} from '../../services/legacy/target-env.service';
import { getEnvironments } from '../../services/api/environments-api.service';
import { getUser } from '../../services/legacy/auth.service';
import { TargetEnvLoginComponent } from './target-env-login.component';
import { TargetEnv } from '../../models/target-env';
import { getExplorerKey, createExplorerKey } from '../../services/api/keys-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { LinkNames } from '../../constants/link-names';
import { siteOptions } from '../../options/site-options';

export class TargetEnvSelectComponent {
  private envs: Environment[];

  private $dropdown: JQuery<HTMLElement>;
  private $dropdownToggle: JQuery<HTMLElement>;
  private $dropdownMenu: JQuery<HTMLElement>;
  private $logoutDropdownItem: JQuery<HTMLElement>;

  private readonly DATA_TOGGLE_TOOLTIP = 'environment-tooltip';
  private readonly DROPDOWN_TOGGLE_CLASS = 'dropdown-toggle';
  private readonly DROPDOWN_MENU_CLASS = 'dropdown-menu';

  constructor(private targetEnvLoginComponent: TargetEnvLoginComponent) {}

  async init(): Promise<void> {
    this.$dropdown = $('#targetEnvSelectComponent');

    this.$dropdownToggle = this.$dropdown.find('.dropdown-toggle');
    this.$dropdownMenu = this.$dropdown.find('.dropdown-menu');

    $('#targetEnvSelectComponent').on('shown.bs.dropdown', () => {
      this.recalculateTooltips(this.DROPDOWN_MENU_CLASS);
      this.findTooltips(this.DROPDOWN_TOGGLE_CLASS).tooltip('hide');
    });

    this.$dropdownMenu.on('click', 'a', this.onDropdownItemClick.bind(this));

    await this.refresh();

    env$.subscribe(targetEnv => {
      this.selectEnv(targetEnv);
    });
  }

  private createTooltips(parentClass: string): void {
    const tooltips = this.findTooltips(parentClass);

    if (!tooltips) {
      return;
    }

    // tooltips.tooltip();
  }

  private recalculateTooltips(parentClass: string): void {
    this.findTooltips(parentClass).each((index, element) => {
      const $element = $(element);
      if (!$element.attr('data-original-title')) {
        return;
      }
      const envName = $element.find('.environment-name');
      const envHost = $element.find('.environment-host');
      if (
        envName.width() >= envName.prop('scrollWidth') &&
        envHost.width() >= envHost.prop('scrollWidth') &&
        $element.attr('data-original-title')
      ) {
        $element.removeAttr('data-original-title');
      }
    });
  }

  private getEnvHtml(env: { name: string; host: string }, tooltipPosition: any = 'right'): string {
    return `<span class="environment" data-bs-toggle="${this.DATA_TOGGLE_TOOLTIP}" 
      data-html="true" data-placement="${tooltipPosition}" title="${env.name}<br/>${env.host}">
        <span class="environment-name">${env.name}</span>
        <span class="environment-host">${env.host}</span>
      </span>`;
  }

  private findTooltips(parentClass: string): JQuery<HTMLElement> {
    return $(
      `#targetEnvSelectComponent .${parentClass} [data-bs-toggle="${this.DATA_TOGGLE_TOOLTIP}"]`,
    );
  }

  private async refresh(): Promise<void> {
    const user = getUser();

    if (!user) {
      return;
    }

    const getEnvironmentsResponse = await getEnvironments({
      states: [EnvironmentState.Active],
      types: [
        EnvironmentType.Development,
        EnvironmentType.QualityAssurance,
        EnvironmentType.Demo,
        EnvironmentType.Partner,
        EnvironmentType.Uat,
      ],
    });

    this.envs = _(getEnvironmentsResponse._embedded.items)
      .filter(e => !!e.authenticationParameters)
      .sortBy(e => e.name)
      .value();

    this.$dropdownMenu.empty();

    this.envs.forEach(env => {
      this.$dropdownMenu.append(
        `<a class="dropdown-item environment" href="javascript:" data-id="${env._id}">
        ${this.getEnvHtml({
          name: env.name,
          host: env.host,
        })}
      </a>`,
      );
    });

    this.$logoutDropdownItem = $(`<a class="dropdown-item d-none" href="javascript:">Logout</a>`);
    this.$dropdownMenu.append(this.$logoutDropdownItem);

    this.$dropdown.removeClass('disabled');
    this.$dropdownToggle.removeClass('disabled');

    this.createTooltips(this.DROPDOWN_MENU_CLASS);
  }

  private selectEnv(selectEnv?: TargetEnv): void {
    this.$dropdownToggle.toggleClass('selected', !!selectEnv);

    this.$dropdownToggle
      .find('> span')
      .replaceWith(
        selectEnv
          ? this.getEnvHtml({ name: selectEnv.name, host: selectEnv.apiHost }, 'bottom')
          : '<span>Environment</span>',
      );

    this.$dropdownMenu.find('> .environment').each((index, envItem) => {
      const $envItem = $(envItem);
      const envId = $envItem.data('id');
      const env = this.envs.find(env => env._id === envId);

      $envItem.toggleClass(
        'active',
        selectEnv?.type === 'explorer' && selectEnv.apiHost === env.host,
      );
    });

    if (this.$logoutDropdownItem) {
      this.$logoutDropdownItem.toggleClass('d-none', selectEnv?.type !== 'explorer');
    }

    this.createTooltips(this.DROPDOWN_TOGGLE_CLASS);
    this.recalculateTooltips(this.DROPDOWN_TOGGLE_CLASS);
  }

  private async getExplorerKey(env: Environment): Promise<Key | undefined> {
    let explorerKey: Key;

    if (env.host === siteOptions.devBankHost) {
      try {
        explorerKey = await getOrCreateDefaultExplorerKey();
      } catch (error) {
        const errorResponse = error as ErrorResponse;
        showToastr({
          type: 'error',
          message: errorResponse._error?.message,
        });
      }
    } else {
      try {
        const exitingExplorerKey = await getExplorerKey(env.host);
        if (this.validateExplorerKey(exitingExplorerKey, env)) {
          explorerKey = exitingExplorerKey;
        }
      } catch (error) {
        const errorResponse = error as ErrorResponse;
        switch (errorResponse._error.statusCode) {
          case 404: {
            try {
              explorerKey = await createExplorerKey(env.host);
            } catch (error) {
              const errorResponse = error as ErrorResponse;
              showToastr({
                type: 'error',
                message: errorResponse._error.message,
              });
            }
            break;
          }
          case 403:
            showToastr({
              type: 'error',
              message: `You do not have authorization to access the environment ${env.host}. Please select another environment.`,
            });
        }
      }
    }

    if (explorerKey && this.validateExplorerKey(explorerKey, env)) {
      return explorerKey;
    }

    return undefined;
  }

  private validateExplorerKey(explorerKey: Key, env: Environment): boolean {
    if (explorerKey.state === 'pending') {
      showToastr({
        type: 'info',
        message: `Your Explorer Key for the ${env.host} environment is still pending approval.
            Approval may take one or two business days. Look for an email message confirming your explorer key approval.`,
      });
      return false;
    }
    if (explorerKey.state === 'inactive') {
      showToastr({
        type: 'warning',
        message: `Your Explorer Key for the ${env.host} environment has been disabled. Please contact customer support.`,
      });
      return false;
    }
    return true;
  }

  private async onDropdownItemClick(event: JQueryEventObject): Promise<void> {
    const $element = $(event.currentTarget);

    if ($element.is(this.$logoutDropdownItem)) {
      targetEnvLogout();
      return;
    }

    if ($element.hasClass('active')) {
      event.stopPropagation();
      return;
    }

    const envId = $element.data('id');
    const env = _.find(this.envs, { _id: envId });

    const explorerKey = await this.getExplorerKey(env);
    if (!explorerKey) {
      return;
    }

    await this.targetEnvLoginComponent.open(
      {
        type: 'explorer',
        name: env.name,
        apiHost: env.host,
        webUrl: env._links[LinkNames.webApplication].href,
        cognitoClientId: explorerKey.clientId,
        cognitoUserPoolId: env.authenticationParameters.identityGroupId,
      },
      explorerKey.key,
    );
  }
}
