import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "form-container--row" }
const _hoisted_3 = { class: "form-container--row" }
const _hoisted_4 = { class: "list-unstyled mb-0" }
const _hoisted_5 = { class: "list-unstyled mb-0" }
const _hoisted_6 = { class: "pl-3" }
const _hoisted_7 = { class: "form-container--submits" }

import { ref, onMounted, computed } from 'vue';
import bootbox from 'bootbox';
import {
  Product,
  Api as ProductApi,
  ProductLine,
  ProductType,
  ErrorResponse,
  AllowedApplicationTypes,
} from '@apiture/api-products-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { createProduct, patchProduct, getProduct } from '../../services/api/products-api.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getApis, Api } from '../../services/apis.service';
import { getUrlParams, getIdFromUri } from '../../helpers/window-helper';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import AppAlertComponent from '../legacy/app-alert.component.vue';
import { useProducts, mapProductsToOptions } from '../../hooks/use-products.hook';
import { useScopes } from '../../hooks/use-scopes.hook';


export default /*@__PURE__*/_defineComponent({
  __name: 'product-form.component',
  props: {
    productLine: {}
  },
  setup(__props: any) {

const props = __props;

const productFormData = ref<{
  name: string;
  tag: string;
  version: string;
  description: string;
  products: string[];
  apis: string[];
  excludeWriteScopes: boolean;
  excludedScopes: string[];
  type: ProductType;
}>({
  name: '',
  tag: '',
  version: '',
  description: '',
  products: [],
  apis: [],
  excludeWriteScopes: false,
  excludedScopes: [],
  type: ProductType.Api,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
}>({
  initialized: false,
  loading: false,
});

const product = ref<Product>();

const apis = ref<Api[]>([]);
const obsoleteApis = ref<ProductApi[]>([]);

const products = useProducts(props.productLine);

const { uniqueSelectedScopes, apiScopes } = useScopes({
  formData: productFormData,
  products,
  apis,
});

onMounted(async () => {
  const productId = getUrlParams().get('productId');

  product.value = productId ? await getProduct(productId) : ({} as Product);

  apis.value = await getApis(props.productLine);

  if (product.value) {
    fillFormData();
  }

  state.value.initialized = true;
});

const tagValid = computed(() => {
  if (product.value._id) return [];

  return products.value.some(product => product.tag === productFormData.value.tag)
    ? ['Tag is already in use.']
    : [];
});

const obsoleteProductsApis = computed(() => {
  const obsoleteProductApis = new Map<string, string[]>();

  const includedProducts = products.value.filter(product =>
    productFormData.value.products?.includes(product._id),
  );

  for (const product of includedProducts) {
    const obsoleteApis: string[] = [];
    const productTagAndVersion = `${product.tag} v${product.version}`;

    for (const api of product.apis) {
      const existingApi = apis.value.some(
        apiInner =>
          `${api.name} v${api.version}` ===
          `${apiInner.label} v${apiInner.versions[apiInner.versions.length - 1]}`,
      );

      if (!existingApi) {
        obsoleteApis.push(`${api.name} v${api.version}`);
      }
    }

    if (obsoleteApis.length) {
      obsoleteProductApis.set(productTagAndVersion, obsoleteApis);
    }
  }

  return obsoleteProductApis;
});

function showDescriptionPreview() {
  const messageHtml = makeHtmlMarkdown(productFormData.value.description);
  bootbox.alert({
    title: 'Product Description',
    message: messageHtml,
  });
}

async function saveProduct() {
  Object.assign(product.value, getProductPatch());

  const isExistingProduct = Boolean(product.value._id);

  try {
    state.value.loading = true;
    product.value = isExistingProduct
      ? await patchProduct(product.value, product.value._id)
      : await createProduct(product.value);
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    state.value.loading = false;
  }

  showToastr({
    type: 'success',
    message: `Product has been successfully ${isExistingProduct ? 'updated' : 'created'}.`,
  });

  setTimeout(() => {
    window.location.href =
      props.productLine === ProductLine.Open ? '/openAdmin/products/' : '/admin/products/';
  }, 3000);
}

function getProductPatch() {
  const selectedProducts = products.value
    .filter(product => productFormData.value.products.includes(product._id))
    .map(product => ({
      name: product.name,
      version: product.version,
      _links: { self: { href: product._links['self'].href } },
    }));
  const productApis = apis.value
    .filter(api => productFormData.value.apis.includes(api.apiName))
    .map(api => {
      const productApi: ProductApi = {
        productLine: api.productLine
          ? api.productLine
          : api.productLines.find(apiProductLine => apiProductLine === props.productLine),
        name: api.apiName,
        version: api.versions[api.versions.length - 1],
        description: api.summary,
        allowedApplicationTypes:
          api.allowedApplicationTypes ?? Object.values(AllowedApplicationTypes),
      };

      if (props.productLine === ProductLine.Adb) {
        productApi.scopes = apiScopes.value.get(api.apiName);
      }

      return productApi;
    });

  // add obsolete APIs if user does not select actual versions.
  for (const obsoleteApi of obsoleteApis.value) {
    if (!productApis.some(api => api.name === obsoleteApi.name)) {
      productApis.push(obsoleteApi);
    }
  }

  const productPatch: Partial<Product> = {
    name: productFormData.value.name,
    version: productFormData.value.version,
    tag: productFormData.value.tag,
    description: productFormData.value.description,
    apis: productApis,
    products: selectedProducts,
  };

  if (props.productLine === ProductLine.Adb) {
    productPatch.excludeWriteScopes = productFormData.value.excludeWriteScopes ?? false;
    productPatch.excludedScopes =
      productFormData.value.excludedScopes.filter(
        excludedScope => productPatch.excludeWriteScopes && !excludedScope.includes('/write'),
      ) ?? [];
    productPatch.type = productFormData.value.type;
  }

  return productPatch;
}

function fillFormData() {
  Object.assign(productFormData.value, {
    name: product.value.name,
    tag: product.value.tag,
    version: product.value.version,
    description: product.value.description,
    products: product.value.products
      ?.map(productRef =>
        products.value.find(
          product => getIdFromUri(productRef._links['self'].href) === product._id,
        ),
      )
      .filter(product => Boolean(product))
      .map(product => product._id),
    apis: product.value.apis
      ?.filter(api => {
        const existingApi = apis.value.some(apiInner => api.name === apiInner.apiName);

        if (!existingApi) {
          obsoleteApis.value.push(api);
        }

        return existingApi;
      })
      .map(api => api.name),
  });

  if (props.productLine === ProductLine.Adb) {
    productFormData.value.excludeWriteScopes = product.value.excludeWriteScopes ?? false;
    productFormData.value.excludedScopes = product.value.excludedScopes ?? [];
    productFormData.value.type = product.value.type;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createBlock(_component_FormKit, {
          key: 1,
          id: "productForm",
          modelValue: productFormData.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((productFormData).value = $event)),
          type: "form",
          "submit-label": "Save",
          actions: false,
          "data-test": "productForm",
          onSubmit: _cache[2] || (_cache[2] = ($event: any) => (saveProduct()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FormKit, {
                type: "text",
                name: "name",
                label: "Product Name",
                help: "The name of the API product.",
                validation: "required|length:6,64",
                "outer-class": "form-input--full",
                "data-test": "name"
              }),
              _createVNode(_component_FormKit, {
                type: "text",
                name: "tag",
                label: "Tag",
                help: "A unique read-only alphanumeric\n            identifier for this product. When combined with the version, this creates a unique\n            human-readable identifier for this product.",
                validation: [['required'], ['length:6,64'], ['matches', /^[a-z][-_a-z0-9A-Z]{0,63}/]],
                disabled: Boolean(product.value._id),
                errors: tagValid.value,
                "outer-class": "form-input--full",
                "data-test": "tag"
              }, null, 8, ["disabled", "errors"]),
              _createVNode(_component_FormKit, {
                type: "text",
                name: "version",
                label: "Version",
                help: "The semantic version of the product.",
                validation: "required|length:1,512",
                "outer-class": "form-input--sm",
                "data-test": "version"
              })
            ]),
            (props.productLine === _unref(ProductLine).Adb)
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 0,
                  label: "Product Type",
                  type: "select",
                  options: ['api', 'component'],
                  name: "type",
                  "data-test": "productType",
                  "outer-class": "form-input--sm"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_FormKit, {
              type: "textarea",
              name: "description",
              label: "Description of Product",
              help: "The description of the product, may use markdown.",
              validation: "required|length:1,512",
              "data-test": "description"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FormKit, {
                type: "checkbox",
                label: "APIs",
                name: "apis",
                options: 
              apis.value
                .map(api => ({
                  label: `${api.label} v${api.versions[api.versions.length - 1]}${
                    api.internal ? ' - internal' : ''
                  }`,
                  value: api.apiName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            ,
                help: "Specific APIs that are elements\n             of this product.",
                "data-test": "apis"
              }, null, 8, ["options"]),
              _createVNode(_component_FormKit, {
                type: "checkbox",
                label: "Products",
                name: "products",
                options: _unref(mapProductsToOptions)(_unref(products), false),
                help: "Specific APIs that are elements\n            of this product.",
                "data-test": "products"
              }, null, 8, ["options"])
            ]),
            (props.productLine === _unref(ProductLine).Adb)
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 1,
                  type: "checkbox",
                  label: "Exclude Write Scopes",
                  name: "excludeWriteScopes"
                }))
              : _createCommentVNode("", true),
            (props.productLine === _unref(ProductLine).Adb)
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 2,
                  type: "scopes",
                  name: "excludedScopes",
                  scopes: _unref(uniqueSelectedScopes),
                  "exclude-write-scopes": productFormData.value.excludeWriteScopes
                }, null, 8, ["scopes", "exclude-write-scopes"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(AppAlertComponent, {
                visible: obsoleteApis.value.length > 0,
                "affect-layout-if-invisible": true
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createTextVNode(" This product uses the following obsolete APIs: ")),
                  _createElementVNode("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(obsoleteApis.value, (api) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: api.name
                      }, _toDisplayString(`${api.name} v${api.version}`), 1))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["visible"]),
              _createVNode(AppAlertComponent, {
                visible: obsoleteProductsApis.value.size > 0,
                "affect-layout-if-invisible": true
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(obsoleteProductsApis.value.size > 1 ? 'These products' : 'This product') + " " + _toDisplayString(obsoleteProductsApis.value.size > 1 ? 'use' : 'uses') + " the following obsolete APIs: ", 1),
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(obsoleteProductsApis.value, ([innerProduct, innerApis]) => {
                      return (_openBlock(), _createElementBlock("li", { key: innerProduct }, [
                        _createElementVNode("b", _hoisted_6, _toDisplayString(innerProduct), 1),
                        _createElementVNode("ul", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(innerApis, (innerApi) => {
                            return (_openBlock(), _createElementBlock("li", { key: innerApi }, _toDisplayString(innerApi), 1))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["visible"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FormKit, {
                type: "submit",
                disabled: tagValid.value.length > 0 || state.value.loading,
                "data-test": "submit"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Save ")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                type: "button",
                disabled: !productFormData.value.description || state.value.loading,
                "input-class": "form-input--button-transparent",
                "data-test": "previewDescription",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showDescriptionPreview()))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Preview Description ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
  ]))
}
}

})