import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-container--submits" }

import { onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { getApplication, inviteJointOwners } from '../../services/api/applications-api.service';
import { Application, Invitee } from '@apiture/client-applications-client-sdk';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { FormKitNode } from '@formkit/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'application-invite-owners.component',
  setup(__props) {

const inviteOwnersFormData = ref<{
  applicationName: string;
  applicationDescription: string;
  message: string;
  invitees: Invitee[];
}>({
  applicationName: '',
  applicationDescription: '',
  message: '',
  invitees: [],
});

const loading = ref<boolean>(false);
const application = ref<Application>();
const user = getUser();

onMounted(async () => {
  loading.value = true;

  const applicationId = new window.URI(window.location.href).search(true)['applicationId'];
  application.value = await getApplication(applicationId);

  loading.value = false;

  fillFormData();
});

function updateNewOwners(emailAddress: string, formNode: FormKitNode): void {
  const userDomain = user.domain;
  if (emailAddress && getEmailDomain(emailAddress) !== userDomain) {
    formNode.setErrors(`Email address must match your @${userDomain} domain.`);
  }
}

async function sendInvites(): Promise<void> {
  try {
    loading.value = true;

    application.value = await inviteJointOwners(application.value._id, {
      message: inviteOwnersFormData.value.message,
      invitees: inviteOwnersFormData.value.invitees,
    });
  } catch (error) {
    const errorResponse = error as ErrorResponse;

    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });

    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Invite(s) has been successfully sent.',
  });

  setTimeout(() => {
    if (window.location.href.includes('/open')) {
      window.location.href = '/profile/applications/';
    } else {
      window.location.href = '/apps/list/';
    }
  }, 3000);
}

function previewMessage(): void {
  const messageHtml = makeHtmlMarkdown(inviteOwnersFormData.value.message);
  bootbox.alert({
    title: 'The following formatted text will be included in the message:',
    message: messageHtml,
  });
}

function fillFormData(): void {
  inviteOwnersFormData.value = {
    applicationName: application.value.name,
    applicationDescription: application.value.description,
    invitees: [{ firstName: null, lastName: null, emailAddress: null }],
    message: null,
  };
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    modelValue: inviteOwnersFormData.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inviteOwnersFormData).value = $event)),
    type: "form",
    actions: false,
    "data-test": "inviteOwnersForm",
    onSubmit: _cache[2] || (_cache[2] = ($event: any) => (sendInvites()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormKit, {
        type: "text",
        name: "applicationName",
        label: "Application Name",
        "data-test": "applicationName"
      }),
      _createVNode(_component_FormKit, {
        type: "textarea",
        name: "message",
        label: "Message",
        help: "Include this message in the invitation email to the invitees. You may use Markdown to format this text.",
        validation: "required|length:16,512",
        "data-test": "message"
      }),
      _createVNode(_component_FormKit, {
        type: "repeater",
        name: "invitees",
        "fieldset-label": "New Owners",
        validation: "required"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FormKit, {
            type: "text",
            name: "firstName",
            label: "First Name",
            validation: "required|length:1,64",
            "outer-class": "form-input--full",
            "data-test": "firstName"
          }),
          _createVNode(_component_FormKit, {
            type: "text",
            name: "lastName",
            label: "Last Name",
            validation: "required|length:1,64",
            "outer-class": "form-input--full",
            "data-test": "lastName"
          }),
          _createVNode(_component_FormKit, {
            type: "email",
            label: "Email",
            name: "emailAddress",
            help: `Enter the invitee’s email address. The email addresses must match your partner organization's @${_unref(user).domain} domain.`,
            validation: "required|email|length:6,128",
            "outer-class": "form-input--full",
            "data-test": "email",
            onInput: updateNewOwners
          }, null, 8, ["help"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormKit, {
          disabled: loading.value,
          label: "Send",
          type: "submit",
          "data-test": "submit"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Send ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        (inviteOwnersFormData.value.message)
          ? (_openBlock(), _createBlock(_component_FormKit, {
              key: 0,
              type: "button",
              disabled: !inviteOwnersFormData.value.message || loading.value,
              "input-class": "form-input--button-transparent",
              "data-test": "previewMessage",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (previewMessage()))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Preview Message ")
              ])),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})