<template>
  <div>
    <div class="flex-row--end">
      <button
        type="button"
        :class="['form-input--desc-btn', { 'form-input--desc-btn--active': !previewToggle }]"
        data-test="viewMarkdown"
        @click="handleMarkdown"
      >
        Markdown
      </button>
      <button
        type="button"
        :class="['form-input--desc-btn', { 'form-input--desc-btn--active': previewToggle }]"
        data-test="viewPreview"
        @click="handlePreview"
      >
        Preview
      </button>
    </div>
    <textarea
      v-show="!previewToggle"
      :id="props.context.id"
      v-model="markdown"
      class="form-input form-input--textarea form-input--desc"
      name="markdown"
      :data-test="props.context.node.name"
      @input="handleInput"
    />
    <div
      v-show="previewToggle"
      class="form-input form-input--desc h-max"
      :data-test="`${props.context.node.name}Preview`"
      v-html="makeHtmlMarkdown(props.context.node.value as string)"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';
import { makeHtmlMarkdown } from '../../../helpers/markdown-helper';
import { sanitize } from '../../../helpers/sanitize-helper';

const props = defineProps<{
  context: FormKitFrameworkContext<string>;
}>();

const markdown = ref<string>('');
const previewToggle = ref<boolean>(false);

onMounted(() => {
  markdown.value = props.context._value;
});

function handleMarkdown() {
  previewToggle.value = false;
}

function handlePreview() {
  previewToggle.value = true;
}

function handleInput(event: Event & { target: HTMLTextAreaElement }) {
  props.context.node.input(sanitize(event.target.value));
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
.form-input--desc {
  border-top-right-radius: 0rem;
  padding: 1rem;
  min-height: 10rem;
}

.form-input--desc-btn {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.25rem 1rem;
  transform: translate(0, 1px);
  background-color: $app-neutral-gray-80;
  border: 1px solid $app-neutral-black;
  color: #fff;
}

.form-input--desc-btn--active {
  border-bottom: 1px solid #fff;
  color: $app-neutral-black;
  background-color: #fff;
}
</style>
