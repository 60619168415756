<template>
  <fieldset class="form-input--selectAll-fieldset">
    <div class="form-input form-input--selectAll">
      <div class="form-input-scopes--selectAll flex-row--gap">
        <input
          id="selectAll"
          type="checkbox"
          class="form-input--option"
          :data-test="`${props.context.node.name}-selectAll`"
          :checked="allSelected"
          @input="handleSelectAll"
        >
        <label for="selectAll">Select All</label>
      </div>
      <ul
        :data-test="`${props.context.node.name}-list`"
        class="form-input--option-list form-input--scrollable"
      >
        <li
          v-for="(option, i) in props.context.xOptions"
          :key="option"
          class="form-input--selectAll-option"
        >
          <span class="flex-row--gap align-items-center">
            <input
              :id="`option-${Number(i) + 1}`"
              class="form-input--option"
              type="checkbox"
              :value="option"
              :data-test="`${props.context.node.name}-${Number(i) + 1}`"
              :checked="shouldCheck(option)"
              @input="handleCheck"
            >
            <label
              :for="`option-${Number(i) + 1}`"
              :data-test="`${props.context.node.name}-label-${Number(i) + 1}`"
              class="form-input--wrapper scopes-input--wrapper"
            >
              {{ option }}
            </label>
          </span>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
import { ref, computed, watch } from 'vue';

const props = defineProps<{
  context: FormKitFrameworkContext & {
    xOptions: string[];
  };
}>();

const allSelected = ref<boolean>(false);

const optionValues = computed(() => {
  return props.context.xOptions?.map(option => option);
});

watch(
  () => props.context.value,
  (current, previous) => {
    if (previous) {
      return;
    }

    checkAllSelectedByContextValue(current);
  },
);

function handleSelectAll(event: Event & { target: HTMLInputElement }) {
  if (!event.target.checked) {
    props.context.node.input([]);
    allSelected.value = false;
    return;
  }

  props.context.node.input(optionValues.value);
  allSelected.value = true;
}

async function handleCheck(event: Event & { target: HTMLInputElement }) {
  if (!event.target.checked) {
    props.context.node.input(props.context.value.filter(value => value !== event.target.value));
    allSelected.value = false;
    return;
  }

  const currentInput = props.context.value ?? [];

  props.context.node.input(currentInput.concat(event.target.value));
  await props.context.node.input(props.context.value.concat(event.target.value));

  checkAllSelectedByContextValue(props.context.value);
}

function shouldCheck(value: string) {
  if (!props.context.value || !Array.isArray(props.context.value)) {
    return false;
  }

  return props.context.value.includes(value);
}

function checkAllSelectedByContextValue(value: string[]) {
  if (value.length === props.context.xOptions?.length) {
    allSelected.value = true;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.form-input--selectAll,
.form-input--selectAll-fieldset {
  min-height: 15rem;
  max-height: 15rem;
}

.form-input--selectAll-fieldset {
  border: none;
  padding: 0;

  legend {
    display: inline-flex;
  }
}

.form-input-scopes--inner {
  padding: 1rem;
}

.form-input--selectAll-option {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 1rem;
  gap: 1rem;
  font-family: 'Courier New', Courier, monospace;

  .formkit-option-help {
    @include md {
      margin: 0;
    }
  }

  @include md {
    flex-direction: row;
    justify-content: space-between;

    p {
      width: 60%;
    }
  }
}

.scopes-input--wrapper {
  input {
    margin-right: 0.5rem;
  }
}

.form-input-scopes--selectAll {
  margin: 0 1rem;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid $app-neutral-gray-20;

  label {
    align-self: center;
  }
}

ul {
  max-height: 11.5rem;
}
</style>
