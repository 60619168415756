import * as toastr from 'toastr';

export interface ToastrShowParams {
  type: ToastrType;
  message: string;
  title?: string;
  options?: ToastrOptions;
}

const defaultOptions: ToastrOptions = {
  closeButton: true,
  positionClass: 'toast-top-center',
  timeOut: 5000,
};

export function showToastr(params: ToastrShowParams): void {
  const toastrOptions: ToastrOptions = { ...defaultOptions, ...(params.options || {}) };

  switch (params.type) {
    case 'info':
      toastr.info(params.message, params.title, toastrOptions);
      break;
    case 'error':
      toastr.error(params.message, params.title, toastrOptions);
      break;
    case 'warning':
      toastr.warning(params.message, params.title, toastrOptions);
      break;
    case 'success':
      toastr.success(params.message, params.title, toastrOptions);
      break;
  }
}
