// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1[data-v-d235783c] {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 56px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/headers/header-1.component.vue","webpack://./header-1.component.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;ACAF","sourcesContent":["\nh1 {\n  font-weight: 600;\n  font-size: 1.75rem;\n  line-height: 56px;\n}\n","h1 {\n  font-weight: 600;\n  font-size: 1.75rem;\n  line-height: 56px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
