import { AxiosPromise, AxiosError, AxiosAdapter } from 'axios';
import { siteOptions } from '../../options/site-options';
import {
  AxiosAdapterBuilder,
  createAxiosAdapterBuilderFactory,
} from '../../utils/axios-adapter-builder';
import { getOktaAccessToken } from '../okta-auth.service';
import { getItemInSessionStorage } from '../storage.service';

export interface ClientSdkConfig {
  host: string;
  baseOptions?: {
    headers: ClientSdkHeaders;
  };
}

export interface ClientSdkOptions {
  adapter?: AxiosAdapter;
  headers?: ClientSdkHeaders;
}

export interface ClientSdkHeaders {
  [header: string]: string;
}

export const config: ClientSdkConfig = {
  host: siteOptions.apiUrl,
};

export function createAdapterBuilder(): AxiosAdapterBuilder {
  return createAxiosAdapterBuilderFactory();
}

export function promisify<T>(axiosPromise: AxiosPromise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosPromise
      .then(response => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error.response?.data);
      });
  });
}

export async function getOptions(options?: ClientSdkOptions): Promise<ClientSdkOptions> {
  const cognitoAccessToken = getItemInSessionStorage('devPortalAccessToken');
  const oktaAccessToken = await getOktaAccessToken();

  if (cognitoAccessToken) {
    return {
      ...options,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'x-amz-security-token': cognitoAccessToken,
      },
    };
  }

  if (oktaAccessToken) {
    return {
      ...options,
      headers: {
         // eslint-disable-next-line @typescript-eslint/naming-convention
        'x-okta-access-token': oktaAccessToken,
      },
    };
  }

  return {
    ...options,
  };
}

export function getAccessToken() {
  return `Bearer ${getItemInSessionStorage('devPortalAccessToken')}`;
}

export function generateDevConfig() {
  return {
    headers: {
      Authorization: getAccessToken(),
    },
  };
}
