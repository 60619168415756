<template>
  <NavigationContainer>
    <template #default="{ isExpanded, baseUrl }">
      <li>
        <ul>
          <li>
            <a :href="`${baseUrl}/dashboard`">Dashboard</a>
          </li>
          <template v-if="user">
            <li v-if="user.role === 'Developer'">
              <DropdownComponent
                id="clients-dropdown"
                label="Clients"
                dropdown-button-class="nav--dropdown-button-clients"
                dropdown-content-class="nav--dropdown-content"
                :remove-pointer="isExpanded"
              >
                <template #labelIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M12.8291 5.56352C13.0121 5.38046 13.3089 5.38046 13.492 5.56352C13.675 5.74658 13.675 6.04337 13.492 6.22643L8.32755 11.3909C8.14449 11.5739 7.8477 11.5739 7.66464 11.3909L2.5002 6.22643C2.31714 6.04337 2.31714 5.74658 2.5002 5.56352C2.68326 5.38046 2.98006 5.38046 3.16311 5.56352L7.99609 10.3965L12.8291 5.56352Z"
                      fill="white"
                    />
                  </svg>
                </template>
                <ul v-if="user?.role === 'Developer'">
                  <li class="nav--dropdown-item">
                    <a :href="`${baseUrl}/apps/list/`">Applications</a>
                  </li>
                  <li
                    v-if="siteOptions.ssoIntegrationsEnabled"
                    class="nav--dropdown-item"
                  >
                    <a :href="`${baseUrl}/integrations/list/`">SSO Integrations</a>
                  </li>
                  <li class="nav--dropdown-item">
                    <a :href="`${baseUrl}/webhooks/list/`">Webhooks</a>
                  </li>
                </ul>
              </DropdownComponent>
            </li>
          </template>
          <li>
            <a
              href="https://dx.apiture.com"
              target="_blank"
            >Developer Docs <NewTabSvg /></a>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li
            v-if="user"
            class="nav--list-item"
          >
            <DropdownComponent
              :label="`${user.firstName} ${user.lastName}`"
              dropdown-button-class="nav--dropdown-button-profile"
              dropdown-content-class="nav--dropdown-content"
              :remove-pointer="isExpanded"
            >
              <template #labelIcon>
                <CaretDown />
              </template>
              <ul>
                <li>
                  <a
                    id="personalLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/profile/personal/`"
                  >
                    My Profile
                  </a>
                </li>
                <li>
                  <a
                    id="accountLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/profile/account/`"
                  >
                    My Account
                  </a>
                </li>
                <li>
                  <a
                    id="partnerLink"
                    class="nav--dropdown-item"
                    :href="`${baseUrl}/profile/business/`"
                  >
                    My Company
                  </a>
                </li>
                <li>
                  <a
                    id="logoutLink"
                    class="nav--dropdown-item"
                    href="javascript:"
                    @click="logout()"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </DropdownComponent>
          </li>
          <li v-if="!user">
            <a href="/auth/login">Login</a>
          </li>
        </ul>
      </li>
    </template>
  </NavigationContainer>
</template>

<script lang="ts" setup>
import { getUser, authLogoutCognito } from '../../services/portal-auth.service';
import NavigationContainer from './navigation-container.component.vue';
import DropdownComponent from './dropdown.component.vue';
import NewTabSvg from '../portal/svg/new-tab.component.vue';
import { siteOptions } from '../../options/site-options';

const user = getUser();

async function logout() {
  await authLogoutCognito();
  window.location.replace('/auth/login/');
}
</script>

<style lang="scss" scoped>
:deep(.nav--list #clients-dropdown > button) {
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
}
</style>
