<template>
  <div>
    <LoadingIndicatorComponent v-if="state.loading" />
    <div
      v-else
      class="d-flex flex-column align-items-center mt-4"
    >
      <p class="d-flex align-items-center gap-2">
        <b>API Key:</b>
        <SensitiveValueComponent
          id="discovererApiKey"
          title="API Key"
          :value="tokens.apiKey"
        />
      </p>
      <p class="d-flex align-items-center gap-2">
        <b>Access Token: </b>
        <SensitiveValueComponent
          id="discovererAccessToken"
          title="Access Token"
          :value="tokens.accessToken"
        />
      </p>
      <div>
        <JsonWebTokenComponent :token="tokens.accessToken" />
      </div>
      <div class="mx-auto mt-3">
        <FormKit
          label="Change Credentials"
          type="button"
          input-class="btn btn-primary"
          @click="openCredentialsModal()"
        />
      </div>
    </div>
    <BootstrapModalComponent
      id="credentialsModal"
      title="Change Credentials"
      :open-modal="state.openModal"
      @on-modal-close="closeCredentialsModal()"
    >
      <FormKit
        id="credentialForm"
        v-model="changeCredentialsFormData"
        type="form"
        submit-label="Save"
        :actions="false"
        @submit="saveCredentials()"
      >
        <FormKit
          type="text"
          label="Username"
          name="sampleUsername"
          validation="required"
          outer-class="form-input--full"
        />
        <FormKit
          type="password"
          label="Password"
          name="sampleUserPassword"
          validation="required"
          outer-class="form-input--full"
        />
        <div class="form-container--submits">
          <FormKit
            type="submit"
            :disabled="state.loading"
          >
            Save
          </FormKit>
          <FormKit
            type="button"
            @click="closeCredentialsModal()"
          >
            Cancel
          </FormKit>
        </div>
      </FormKit>
    </BootstrapModalComponent>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { setDiscovererKey, getDiscovererKey } from '../../services/api/keys-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { Messages } from '../../constants/messages';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import JsonWebTokenComponent from './json-web-token.component.vue';
import BootstrapModalComponent from './app-modal.component.vue';

const changeCredentialsFormData = ref<{
  sampleUserName: string;
  sampleUserPassword: string;
}>({
  sampleUserName: '',
  sampleUserPassword: '',
});

const state = ref<{
  loading: boolean;
  credentialsSaving: boolean;
  openModal: boolean;
}>({
  loading: false,
  credentialsSaving: false,
  openModal: false,
});

const tokens = ref<{
  apiKey: string;
  accessToken: string;
}>({
  apiKey: '',
  accessToken: '',
});

onMounted(async () => {
  await refresh();
});

function openCredentialsModal() {
  state.value.openModal = true;
}

function closeCredentialsModal() {
  state.value.openModal = false;
}

async function saveCredentials() {
  let credentialsSaved = false;
  state.value.credentialsSaving = true;

  try {
    await setDiscovererKey(changeCredentialsFormData.value);
    credentialsSaved = true;

    showToastr({
      type: 'success',
      message: `Credentials have been successfully saved.`,
    });

    closeCredentialsModal();
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
  }

  state.value.credentialsSaving = false;

  if (credentialsSaved) {
    refresh();
  }
}

async function refresh() {
  state.value.loading = true;

  const discovererKey = await getDiscovererKey();
  tokens.value.apiKey = discovererKey.apiKey.key;
  tokens.value.accessToken = discovererKey.accessToken;

  state.value.loading = false;
}
</script>
