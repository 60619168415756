<template>
  <button
    type="button"
    class="align-self-center"
    :aria-label="props.label"
    :data-test="props.dataTest"
    :disabled="props.disabled"
    @click="handleClick"
  >
    <span><TrashSvg /></span>
  </button>
</template>

<script lang="ts" setup>
import TrashSvg from '../svg/trash-svg.component.vue';

/**
 * The props for the remove button component.
 * 
 * - dataTest: the value of the attribute used for data-test.
 * - ariaLabel: the value of the attribute used for aria-label.
 */
const props = defineProps<{
  label: string;
  dataTest: string;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (event: 'click'): void;
}>();

function handleClick() {
  emits('click');
}
</script>

<style lang="scss" scoped>
button {
  border: none;
  background-color: transparent;
  transform: scale(1.25);
}
</style>