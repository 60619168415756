import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "form-container--row" }
const _hoisted_3 = { class: "form-container--row" }
const _hoisted_4 = { class: "form-container--row" }

import { onMounted, ref } from 'vue';
import {
  Organization,
  OrganizationAddress,
  EmailType,
  AddressType,
  PhoneNumberType,
} from '@apiture/client-organizations-client-sdk';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import {
  patchOrganization,
  createOrganization,
  getOrganizations,
} from '../../services/api/partner-organizations-api.service';
import { countryCodes } from '../../constants/country-codes';
import { regionCodes } from '../../constants/region-codes';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import LoadingIndicatorComponent from './loading-indicator.component.vue';

type FormAddress = OrganizationAddress | {};//eslint-disable-line


export default /*@__PURE__*/_defineComponent({
  __name: 'partner-organization-form.component',
  setup(__props) {

const partnerOrganizationFormData = ref<{
  domainName: string;
  tradeName: string;
  address: FormAddress;  
  differentMailingAddress: string;
  mailingAddress?: FormAddress;  
  homeUrl: string;
  emailAddress: string;
  phoneNumber: string;
}>({
  domainName: '',
  tradeName: '',
  address: {},
  differentMailingAddress: 'No',
  mailingAddress: {},
  homeUrl: '',
  emailAddress: '',
  phoneNumber: '',
});

const loading = ref<boolean>(false);

const organization = ref<Organization>();

const user = getUser();

onMounted(async () => {
  loading.value = true;

  const organizations = await getOrganizations({
    domain: user.domain,
  });

  loading.value = false;

  if (!organizations._embedded.items[0]) {
    partnerOrganizationFormData.value.domainName = getEmailDomain(user.email);
    return;
  }

  organization.value = organizations._embedded.items[0];

  fillFormData();
});

async function saveOrganization() {
  Object.assign(organization.value, getOrganizationPatch());

  try {
    loading.value = true;
    organization.value = organization.value._id
      ? await patchOrganization(organization.value, organization.value._id)
      : await createOrganization(organization.value);
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Company information has been successfully updated.',
  });

  fillFormData();
}

function getOrganizationPatch() {
  const form = partnerOrganizationFormData.value;
  const addresses = [{ ...form.address, ...{ _id: 'wao', type: AddressType.Work } }];

  if (form.differentMailingAddress === 'Yes') {
    addresses.push({
      ...form.mailingAddress,
      ...{ _id: 'mao', type: AddressType.Shipping },
    });
  }

  return {
    name: form.tradeName,
    label: form.tradeName,
    addresses,
    phones: form.phoneNumber
      ? [{ _id: 'wp0', type: PhoneNumberType.Work, number: form.phoneNumber }]
      : undefined,
    homeUrl: form.homeUrl,
    emailAddresses: form.emailAddress
      ? [{ _id: 'we0', type: EmailType.Work, value: form.emailAddress }]
      : undefined,
    preferredMailingAddressId: form.differentMailingAddress ? 'mao' : 'wa0',
    preferredPhoneId: form.phoneNumber ? 'wp0' : undefined,
    preferredEmailAddressId: form.emailAddress ? 'we0' : undefined,
  };
}

function fillFormData() {
  const mainAddress = organization.value.addresses?.filter(
    item => item.type === AddressType.Work,
  )[0];
  const mailingAddress = organization.value.addresses?.filter(
    item => item.type === AddressType.Shipping,
  )[0];
  const emailAddress = organization.value.emailAddresses?.[0]?.value;
  const phoneNumber = organization.value.phones?.filter(
    item => item.type === PhoneNumberType.Work,
  )[0]?.number;

  partnerOrganizationFormData.value = {
    domainName: emailAddress ? getEmailDomain(emailAddress) : getEmailDomain(user.email),
    tradeName: organization.value.name,
    address: mainAddress ?? {},
    differentMailingAddress: mailingAddress ? 'Yes' : 'No',
    homeUrl: organization.value.homeUrl,
    emailAddress,
    phoneNumber,
  };

  if (!mailingAddress) return;

  partnerOrganizationFormData.value.mailingAddress = {
    addressLine1: mailingAddress.addressLine1,
    addressLine2: mailingAddress.addressLine2,
    city: mailingAddress.city,
    countryCode: mailingAddress.countryCode,
    postalCode: mailingAddress.postalCode,
    regionCode: mailingAddress.regionCode,
  };
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createBlock(_component_FormKit, {
          key: 1,
          modelValue: partnerOrganizationFormData.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((partnerOrganizationFormData).value = $event)),
          type: "form",
          "submit-label": "Save",
          actions: false,
          "data-test": "partnerOrganizationForm",
          onSubmit: _cache[1] || (_cache[1] = ($event: any) => (saveOrganization()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FormKit, {
                name: "tradeName",
                type: "text",
                label: "Company Trade Name",
                validation: "length:0,128",
                "outer-class": "form-input--full",
                "data-test": "tradeName"
              }),
              _createVNode(_component_FormKit, {
                name: "domainName",
                type: "text",
                label: "Domain Name",
                validation: "required|length:4,128",
                disabled: true,
                "outer-class": "form-input--full",
                "data-test": "domainName"
              })
            ]),
            _createVNode(_component_FormKit, {
              type: "group",
              name: "address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormKit, {
                  type: "text",
                  name: "addressLine1",
                  label: "Address Line 1",
                  validation: "length:0,128",
                  "data-test": "addressLine1"
                }),
                _createVNode(_component_FormKit, {
                  type: "text",
                  name: "addressLine2",
                  label: "Address Line 2",
                  validation: "length:0,128",
                  "data-test": "addressLine2"
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_FormKit, {
                    type: "text",
                    name: "city",
                    label: "City",
                    validation: "length:0,128",
                    "data-test": "city"
                  }),
                  _createVNode(_component_FormKit, {
                    type: "select",
                    name: "regionCode",
                    label: "State",
                    validation: "length:0,128",
                    options: _unref(regionCodes),
                    placeholder: "Select a State",
                    "data-test": "regionCode"
                  }, null, 8, ["options"]),
                  _createVNode(_component_FormKit, {
                    type: "text",
                    name: "postalCode",
                    label: "Zip Code",
                    "data-test": "postalCode"
                  }),
                  _createVNode(_component_FormKit, {
                    type: "select",
                    name: "countryCode",
                    label: "Country",
                    options: _unref(countryCodes),
                    placeholder: "Select a Country",
                    "data-test": "countryCode"
                  }, null, 8, ["options"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_FormKit, {
              type: "radio",
              name: "differentMailingAddress",
              label: "Different Mailing Address",
              options: ['Yes', 'No'],
              "options-class": "form-input--different-mailing-address",
              "data-test": "differentMailingAddress"
            }),
            (partnerOrganizationFormData.value.differentMailingAddress === 'Yes')
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 0,
                  type: "group",
                  name: "mailingAddress",
                  preserve: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormKit, {
                      type: "text",
                      name: "addressLine1",
                      label: "Address Line 1",
                      validation: "length:0,128",
                      "data-test": "mailingAddress-addressLine1"
                    }),
                    _createVNode(_component_FormKit, {
                      type: "text",
                      name: "addressLine2",
                      label: "Address Line 2",
                      validation: "length:0,128",
                      "data-test": "mailingAddress-addressLine2"
                    }),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_FormKit, {
                        type: "text",
                        name: "city",
                        label: "City",
                        validation: "length:0,128",
                        "data-test": "mailingAddress-city"
                      }),
                      _createVNode(_component_FormKit, {
                        type: "select",
                        name: "regionCode",
                        label: "State",
                        validation: "length:0,128",
                        options: _unref(regionCodes),
                        placeholder: "Select a State",
                        "data-test": "mailingAddress-state"
                      }, null, 8, ["options"]),
                      _createVNode(_component_FormKit, {
                        type: "text",
                        name: "postalCode",
                        label: "Zip Code",
                        "data-test": "mailingAddress-postalCode"
                      }),
                      _createVNode(_component_FormKit, {
                        type: "select",
                        name: "countryCode",
                        label: "Country",
                        options: _unref(countryCodes),
                        placeholder: "Select a Country",
                        "data-test": "mailingAddress-country"
                      }, null, 8, ["options"])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FormKit, {
                type: "tel",
                name: "phoneNumber",
                label: "Phone Number",
                "outer-class": "form-input--full",
                "data-test": "phoneNumber"
              }),
              _createVNode(_component_FormKit, {
                type: "email",
                name: "emailAddress",
                label: "Email Address",
                validation: "length:0,128",
                "outer-class": "form-input--full",
                "data-test": "emailAddress"
              })
            ]),
            _createVNode(_component_FormKit, {
              type: "url",
              name: "homeUrl",
              label: "Home Url",
              validation: "length:0,512|url",
              "data-test": "homeUrl"
            }),
            _createVNode(_component_FormKit, {
              disabled: loading.value,
              type: "submit",
              "data-test": "submit"
            }, null, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
  ]))
}
}

})