<template>
  <div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <template v-else>
      <div class="row">
        <div class="col-md-9">
          <div class="card bg-light">
            <div class="card-body">
              <div
                v-if="sampleDataGenerationEnabled && targetEnv && targetEnv.type === 'explorer'"
                class="float-right mb-3"
              >
                <!-- v-b-tooltip.hover="state.generateSampleDataTooltip" -->
                <span class="d-inline-block">
                  <FormKit
                    :disabled="state.generateSampleDataDisabled"
                    label="Generate Sample Data"
                    @click="generateSampleData()"
                  />
                </span>
              </div>
              <PartnerDomainInvalidWarningComponent />
              <AppAlertComponent
                :visible="targetEnv?.type === 'discoverer' && Boolean(targetEnvAccessToken)"
              >
                Select an environment in the selector control at the top of the screen and
                authenticate to get an access token for that environment.
              </AppAlertComponent>
              <h4 class="font-weight-bold mb-4">
                Profile Details:
              </h4>
              <p>
                <b>First Name: </b>
                <span>{{ user.firstName }}</span>
              </p>
              <p>
                <b>Last Name: </b>
                <span>{{ user.lastName }}</span>
              </p>
              <p>
                <b>Email Address: </b>
                <span>{{ user.email }}</span>
              </p>
              <p v-if="partnerDomainValid && organization">
                <b>Company: </b>
                <span>{{ organization.name }}</span>
              </p>
              <template v-if="state.apiDetails.length">
                <h4 class="font-weight-bold mt-4">
                  API Details:
                  <a
                    id="helpLink"
                    href="/help/devbank"
                    target="_blank"
                  >Help</a>
                </h4>
                <div class="px-3">
                  <div
                    v-for="apiDetail in state.apiDetails"
                    :key="apiDetail.id"
                    class="d-flex justify-content-start mt-3 flex-grow-1"
                  >
                    <div class="text-nowrap font-weight-bold">
                      <span>{{ apiDetail.title }}</span><span v-if="apiDetail.description">
                        <br>
                        {{ apiDetail.description }}</span><span>:</span>
                    </div>
                    <div class="ps-3">
                      <span>
                        <SensitiveValueComponent
                          :id="apiDetail.id"
                          :title="apiDetail.title"
                          :value="apiDetail.value.toString()"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <a
          id="applicationsButton"
          href="/profile/applications"
          class="btn btn-secondary"
          role="button"
          :class="{ disabled: !partnerDomainValid }"
        >Application List</a>
        <a
          id="changePassword"
          href="/auth/changePassword"
          class="btn btn-secondary ms-2"
          role="button"
        >
          Change Password
        </a>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, shallowRef } from 'vue';
import * as OrganizationSdk from '@apiture/client-organizations-client-sdk';
import * as ApiKeysSdk from '@apiture/api-keys-client-sdk';
import { TargetEnv } from '../../models/target-env';
import { getOrganizations } from '../../services/api/partner-organizations-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { getUser } from '../../services/legacy/auth.service';
import {
  checkSampleDataRequest,
  checkSampleDataRequestTimeout,
  createSampleDataRequest,
  setSampleDataRequestTimeout,
} from '../../services/api/keys-api.service';
import { Messages } from '../../constants/messages';
import { siteOptions } from '../../options/site-options';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import PartnerDomainInvalidWarningComponent from './partner-domain-invalid-warning.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import AppAlertComponent from './app-alert.component.vue';
import { getItemInSecureStorage, getItemInSessionStorage } from '../../services/storage.service';
import { getOktaAccessToken, oktaAuthClient } from '../../services/okta-auth.service';
import { apiKey$, env$, targetEnvAccessToken$ } from '../../services/legacy/target-env.service';
import { Subject, takeUntil, filter, map, forkJoin } from 'rxjs';
import { isEqual } from 'lodash';

interface ApiDetail {
  id: string;
  title: string;
  description?: string;
  value: string;
}

interface AccountInfoState {
  initialized: boolean;
  generateSampleDataDisabled: boolean;
  generateSampleDataTooltip: string;
  apiDetails: ApiDetail[];
}

const sampleDataGenerationEnabled = siteOptions.sampleDataGenerationEnabled;

const state = ref<AccountInfoState>({
  initialized: false,
  generateSampleDataDisabled: false,
  generateSampleDataTooltip: null,
  apiDetails: [],
});

const user = getUser();
const partnerDomainValid = shallowRef<boolean>(
  getItemInSecureStorage<boolean>('partnerDomainValid'),
);
const organization = ref<OrganizationSdk.Organization>();
const targetEnvAccessToken = ref(getItemInSessionStorage('targetEnvAccessToken'));
const targetEnv = ref<TargetEnv>(getItemInSecureStorage<TargetEnv>('targetEnv'));
const destroySubject = new Subject();

onMounted(async () => {
  if (user.role == 'Admin') {
    state.value.apiDetails.push({
      id: 'adminDeveloperPortalAccessToken',
      title: 'Developer Portal Access Token',
      value: await getOktaAccessToken(),
    });
  }

  organization.value = await getOrganization();

  forkJoin([env$, targetEnvAccessToken$, apiKey$]).subscribe(
    ([env, targetEnvAccessToken, apiKey]: [TargetEnv, string, string]) => {
      if (env && env.type === 'explorer') {
        state.value.apiDetails.push({
          id: 'explorerAccessToken',
          title: 'OAuth2 Access Token',
          description: `for ${env.apiHost}`,
          value: targetEnvAccessToken,
        });

        state.value.apiDetails.push({
          id: 'explorerApiKey',
          title: 'Explorer API Key',
          description: `for ${env.apiHost}`,
          value: apiKey,
        });
      }
    },
  );

  forkJoin([env$, targetEnvAccessToken$])
    .pipe(
      map(([env, targetEnvAccessToken]) => [env, targetEnvAccessToken]),
      filter(
        ([targetEnv, targetEnvAccessToken]: [TargetEnv, string]) =>
          targetEnv.type === 'discoverer' ||
          (!isEqual(targetEnv, targetEnv) && targetEnvAccessToken !== targetEnvAccessToken),
      ),
      takeUntil(destroySubject),
    )
    .subscribe(async ([env, accessToken]: [TargetEnv, string]) => {
      targetEnv.value = env;
      targetEnvAccessToken.value = accessToken;

      if (siteOptions.sampleDataGenerationEnabled && env.type === 'explorer') {
        await checkSampleData();
      }
    });

  state.value.initialized = true;

  oktaAuthClient.tokenManager.on('renewed', async () => await setAccessTokenValue());
});

onUnmounted(() => {
  oktaAuthClient.tokenManager.off('renewed');
  destroySubject.next(null);
  destroySubject.complete();
});

async function generateSampleData(): Promise<void> {
  try {
    await createSampleDataRequest(targetEnv.value.apiHost, targetEnvAccessToken.value);
    showToastr({
      message: `Data generation has been started.`,
      type: 'success',
    });
    state.value.generateSampleDataDisabled = true;
    setSampleDataRequestTimeout();
  } catch (error) {
    const errorResponse = error as ApiKeysSdk.ErrorResponse;
    showToastr({
      message: errorResponse?._error.message ?? Messages.internalError,
      type: 'error',
    });
  }
}

async function checkSampleData(): Promise<void> {
  if (!checkSampleDataRequestTimeout()) {
    state.value.generateSampleDataDisabled = true;
    return;
  }
  try {
    const sampleDataRequest = await checkSampleDataRequest(
      targetEnv.value.apiHost,
      targetEnvAccessToken.value,
    );
    if (sampleDataRequest.state === ApiKeysSdk.SampleDataRequestState.Started) {
      state.value.generateSampleDataDisabled = true;
      state.value.generateSampleDataTooltip =
        'We are already generating your sample data. Please check later.';
    }
  } catch (error) {
    const errorResponse = error as ApiKeysSdk.ErrorResponse;
    showToastr({
      message: errorResponse?._error.message ?? Messages.internalError,
      type: 'error',
    });
  }
}

async function getOrganization(): Promise<OrganizationSdk.Organization> {
  const organizations = await getOrganizations({
    domain: user.domain,
  });

  return organizations._embedded.items[0];
}

async function setAccessTokenValue() {
  const developerPortalAccessTokenIndex = state.value.apiDetails.findIndex(
    apiDetail => apiDetail.id === 'developerPortalAccessToken',
  );

  if (developerPortalAccessTokenIndex !== -1) {
    state.value.apiDetails[developerPortalAccessTokenIndex].value = await getOktaAccessToken();
  }
}
</script>
