import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "table-actions-btn-group" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

import { onUnmounted, onMounted, ref } from 'vue';
import { chain, includes } from 'lodash';
import { ClientState, Application } from '@apiture/client-applications-client-sdk';
import { Subject, from, EMPTY, takeUntil, switchMap, tap, map, catchError } from 'rxjs';
import { Messages } from '../../constants/messages';
import {
  getApplication,
  getApplications,
  activateApplication,
  deactivateApplication,
  deleteApplication,
  rejectApplication,
} from '../../services/api/applications-api.service';
import { confirmDialog } from '../../services/legacy/dialog.service';
import {
  clearNavState,
  processEvent,
  processLinks,
  CursorPaginationEvent,
  createCursorPagination,
  CursorPagination,
} from '../../helpers/cursor-pagination-helper';
import { getUser } from '../../services/legacy/auth.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { applicationTypesNames } from '../../services/apis.service';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import ApplicationDetailsComponent from './application-details.component.vue';
import AppModalComponent from '../legacy/app-modal.component.vue';
import AppTableComponent from '../legacy/app-table.component.vue';
import CursorPaginationComponent from '../legacy/cursor-pagination.component.vue';
import { useEnvironments, mapEnvironmentsToOptions } from '../../hooks/use-environments.hook';
import { useProducts, mapProductsToOptions } from '../../hooks/use-products.hook';
import { setUrlParams, getIdFromUri, addUrlParamsToObject } from '../../helpers/window-helper';
import { ProductLine } from '../../utils/product-lines';

interface ApplicationData {
  application: Application;
  applicationTypeName: string;
  applicationAuthenticationName: string;
  owned: boolean;
  products: {
    id: string;
    title: string;
  }[];
}

interface ApplicationFilterData {
  owner: string;
  isOwner: boolean;
  product: string;
  environment: string;
  state: ClientState;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'applications-list.component',
  props: {
    mode: { default: 'user' },
    applicationId: { default: '' },
    keyId: { default: '' },
    productLine: {}
  },
  setup(__props: any) {

const props = __props;

const applicationFilterData = ref<ApplicationFilterData>({
  owner: '',
  isOwner: true,
  product: '',
  environment: '',
  state: null,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
  saving: boolean;
  applicationData: ApplicationData[];
  selectedApplication: Application;
  selectedApplicationProducts: string[];
  openModal: boolean;
  pagination: CursorPagination;
}>({
  initialized: false,
  loading: false,
  saving: false,
  applicationData: [],
  selectedApplication: null,
  selectedApplicationProducts: null,
  openModal: false,
  pagination: createCursorPagination(),
});

const environments = useEnvironments(props.productLine);
const products = useProducts(props.productLine);
const user = getUser();

const searchSubject = new Subject<{
  query: {
    start: string;
    limit: number;
    owner: string;
    product: string;
    environment: string;
    state: ClientState[];
    productLine: ProductLine;
  };
  delay: boolean;
}>();
const destroySubject = new Subject();

onMounted(() => {
  if (props.mode === 'user' && user.role === 'Admin') {
    applicationFilterData.value.isOwner = true;
  }

  addUrlParamsToObject<ApplicationFilterData>(applicationFilterData.value);

  if (!props.applicationId) {
    initSearchHandler();
  }

  state.value.initialized = true;
  refresh();
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function onFilterChange(): void {
  clearNavState(state.value.pagination);
  search(true);
}

function onPaginationChange(evt: CustomEvent<CursorPaginationEvent>): void {
  processEvent(state.value.pagination, evt as CursorPaginationEvent);
  search();
}

function onRowClicked(data: { item: ApplicationData }): void {
  if (data.item.owned) {
    showDetails(data.item);
  }
}

function showDetails(data: ApplicationData): void {
  state.value.selectedApplication = data.application;
  state.value.selectedApplicationProducts = data.products.map(p => p.title);
  state.value.openModal = true;
}

async function activateSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to activate <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Activate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully activated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function revokeApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to revoke <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Revoke',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully revoked.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function approveApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to approve <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'check-circle-o',
          label: 'Approve',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Application has been successfully approved.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function rejectSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to reject <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'ban',
          label: 'Reject',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await rejectApplication(data.application._id);

      showToastr({
        type: 'success',
        message: `Client application has been successfully rejected`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function reactivateApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to reactivate <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Reactivate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      const application = await activateApplication(data.application._id);

      if (application.state !== ClientState.Active) {
        showToastr({
          type: 'info',
          message: `We have received your request to restore a deactivated application.
            Your application has been switched to the Pending state.value.
            Activation requires Apiture approval and intervention and thus may take some time.`,
        });
      } else {
        showToastr({
          type: 'success',
          message: `Application has been successfully restored.`,
        });
      }
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deactivateSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Please confirm you wish to deactivate <b>${data.application.name}</b>.
          Deactivation is immediate and users who are using your application in the corresponding target environment(s) will lose access to the application.
          Re-activating a deactivated client application or API key requires manual administrative approval from Apiture.`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Deactivate',
          class: 'btn-warning',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateApplication(data.application._id);
      showToastr({
        type: 'success',
        message: `Application has been successfully deactivated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deleteSelectedApplication(data: ApplicationData): Promise<void> {
  if (
    await confirmDialog({
      message: `Do you want to delete <b>${data.application.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'trash-o',
          label: 'Delete',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deleteApplication(data.application._id);
      showToastr({
        type: 'success',
        message: `Application has been successfully deleted.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function refreshApplication(application: Application): Promise<void> {
  const currentApplication = await getApplication(application._id);
  Object.assign(application, currentApplication);
}

function initSearchHandler(): void {
  searchSubject
    .pipe(
      takeUntil(destroySubject),
      tap(() => {
        state.value.loading = true;
      }),
      switchMap(params =>
        from(getApplications(params.query)).pipe(
          map(response => {
            return {
              query: params.query,
              response,
            };
          }),
          catchError(() => {
            showToastr({ type: 'error', message: Messages.internalError });
            return EMPTY;
          }),
        ),
      ),
      tap(() => {
        for (const paramKey in applicationFilterData.value) {
          if (paramKey === 'isOwner') continue;
          setUrlParams(paramKey, applicationFilterData.value[paramKey]);
        }
        state.value.loading = false;
      }),
    )
    .subscribe(result => {
      fillData(result.response._embedded.items);
      processLinks(state.value.pagination, result.response._links);
    });
}

function fillData(applications: Application[]): void {
  const productOptions = mapProductsToOptions(products.value, false);

  state.value.applicationData = applications.map(application => {
    return {
      application,
      applicationTypeName: applicationTypesNames.get(application.type),
      applicationAuthenticationName: applicationAuthenticationsNames.get(
        application.authentication,
      ),
      owned: includes(application.owners, user.email),
      products: chain(application.products)
        .map(product => {
          const id = getIdFromUri(product._links['self'].href);
          const oneOf = productOptions.find(o => o.value === id);

          return {
            id,
            title: oneOf?.label,
          };
        })
        .sortBy(item => item.title)
        .value(),
    };
  });
}

function search(delay: boolean = false): void {
  const filter = applicationFilterData.value;
  const owner: string = (() => {
    switch (props.mode) {
      case 'admin':
        return filter.owner?.toLowerCase();
      case 'user':
        if (!filter.isOwner) return '';
        return user.email.toLowerCase();
      default:
        return '';
    }
  })();

  searchSubject.next({
    delay,
    query: {
      owner,
      start: state.value.pagination.start,
      limit: state.value.pagination.limit,
      environment: filter.environment,
      state: filter.state ? [filter.state] : undefined,
      product: filter.product,
      productLine: props.productLine,
    },
  });
}

async function refresh(): Promise<void> {
  if (props.applicationId) {
    await loadApplication();
    if (state.value.applicationData.length) {
      showDetails(state.value.applicationData[0]);
    }
  } else {
    search();
  }
}

async function loadApplication(): Promise<void> {
  state.value.loading = true;
  let application: Application;
  try {
    application = await getApplication(props.applicationId);
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
    return;
  } finally {
    state.value.loading = false;
  }
  fillData([application]);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('mode-' + _ctx.mode)
  }, [
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            (!props.applicationId)
              ? (_openBlock(), _createBlock(_component_FormKit, {
                  key: 0,
                  id: "productForm",
                  modelValue: applicationFilterData.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((applicationFilterData).value = $event)),
                  type: "group",
                  onInput: _cache[1] || (_cache[1] = ($event: any) => (onFilterChange()))
                }, {
                  default: _withCtx(() => [
                    (props.mode === 'user')
                      ? (_openBlock(), _createBlock(_component_FormKit, {
                          key: 0,
                          type: "checkbox",
                          label: "I am owner",
                          name: "isOwner",
                          "outer-class": "form-input--sm align-self-center"
                        }))
                      : _createCommentVNode("", true),
                    (props.mode === 'admin')
                      ? (_openBlock(), _createBlock(_component_FormKit, {
                          key: 1,
                          type: "text",
                          label: "Owner",
                          name: "owner",
                          help: "Filter client applications to match only those owned by the named user, as identified by their email address.",
                          "outer-class": "form-input--full",
                          placeholder: "Select an Owner"
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_FormKit, {
                      type: "select",
                      label: "Product",
                      name: "product",
                      help: "Filter applications to match only those which use the named product.",
                      options: _unref(mapProductsToOptions)(_unref(products), true),
                      "outer-class": "form-input--full",
                      placeholder: "Select a Product"
                    }, null, 8, ["options"]),
                    _createVNode(_component_FormKit, {
                      type: "select",
                      label: "Environment",
                      name: "environment",
                      help: "Filter applications to match only those which deploy in the named environment.",
                      options: _unref(mapEnvironmentsToOptions)(_unref(environments)),
                      "outer-class": "form-input--full",
                      placeholder: "Select an Environment"
                    }, null, 8, ["options"]),
                    _createVNode(_component_FormKit, {
                      type: "select",
                      label: "State",
                      name: "state",
                      help: "Filter applications to match only those which in the selected state.",
                      options: [
              '',
              _unref(ClientState).Active,
              _unref(ClientState).Pending,
              _unref(ClientState).Inactive,
              _unref(ClientState).Rejected,
            ],
                      "outer-class": "form-input--full",
                      placeholder: "Select an Application State"
                    }, null, 8, ["options"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(AppTableComponent, {
              fields: ['Type', 'Authentication', 'Name', 'Products', 'State', 'Actions'],
              items: state.value.applicationData,
              busy: state.value.loading,
              "tbody-tr-class": "cursor-pointer",
              onRowClicked: onRowClicked
            }, {
              "cell(type)": _withCtx(({ row }: { row: { item: any } }) => [
                _createTextVNode(_toDisplayString(row.item.application.type), 1)
              ]),
              "cell(authentication)": _withCtx(({ row }: { row: { item: any } }) => [
                _createTextVNode(_toDisplayString(row.item.application.authentication), 1)
              ]),
              "cell(name)": _withCtx(({ row }: { row: { item: any } }) => [
                _createElementVNode("p", null, _toDisplayString(row.item.application.name.length > 20
                  ? `${row.item.application.name.substring(0, 20)}...`
                  : row.item.application.name), 1)
              ]),
              "cell(products)": _withCtx(({ row }: { row: { item: any } }) => [
                (row.item.application.products.length >= 1)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.application.products, (product) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: product._links.self.href
                        }, _toDisplayString(product.name), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              "cell(actions)": _withCtx(({ row }: { row: { item: any } }) => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FormKit, {
                    type: "button",
                    "input-class": "btn btn-sm btn-primary table-input--button",
                    onClick: ($event: any) => (showDetails(row.item))
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Details ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  (props.mode === 'admin' && !props.keyId)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (
                    row.item.application.state === 'inactive' ||
                      row.item.application.state === 'rejected'
                  )
                          ? (_openBlock(), _createBlock(_component_FormKit, {
                              key: 0,
                              type: "button",
                              "input-class": "btn btn-sm btn-success table-input--button",
                              onClick: ($event: any) => (activateSelectedApplication(row.item))
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Activate ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (row.item.application.state === 'active')
                          ? (_openBlock(), _createBlock(_component_FormKit, {
                              key: 1,
                              type: "button",
                              "input-class": "btn btn-sm btn-danger table-input--button",
                              onClick: ($event: any) => (revokeApplication(row.item))
                            }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode(" Revoke ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (row.item.application.state === 'pending')
                          ? (_openBlock(), _createBlock(_component_FormKit, {
                              key: 2,
                              type: "button",
                              "input-class": "btn btn-sm btn-success table-input--button",
                              onClick: ($event: any) => (approveApplication(row.item))
                            }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode(" Approve ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (row.item.application.state !== 'rejected')
                          ? (_openBlock(), _createBlock(_component_FormKit, {
                              key: 3,
                              type: "button",
                              "input-class": "btn btn-sm btn-danger table-input--button",
                              onClick: ($event: any) => (rejectSelectedApplication(row.item))
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode(" Reject ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (props.mode === 'user' && row.item.owned)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("a", {
                          type: "button",
                          class: "btn btn-sm btn-secondary p-2 table-input--button",
                          href: '/apps/update/?applicationId=' + row.item.application._id
                        }, [
                          (row.item.application.state === 'rejected')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" View ")
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(" Edit ")
                              ], 64))
                        ], 8, _hoisted_5),
                        _createElementVNode("a", {
                          type: "btn btn-sm",
                          class: "btn btn-sm btn-success p-2 table-input--button",
                          href: 
                    '/profile/applicationInviteOwner/?applicationId=' + row.item.application._id
                  
                        }, " Invite ", 8, _hoisted_6),
                        (row.item.application.state !== 'rejected')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (
                      row.item.application.state === 'inactive' &&
                        !!row.item.application._links['apiture:activate']
                    )
                                ? (_openBlock(), _createBlock(_component_FormKit, {
                                    key: 0,
                                    type: "button",
                                    "input-class": "btn btn-sm btn-success table-input--button",
                                    onClick: ($event: any) => (reactivateApplication(row.item))
                                  }, {
                                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                                      _createTextVNode(" Reactivate ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (row.item.application.state !== 'inactive')
                                ? (_openBlock(), _createBlock(_component_FormKit, {
                                    key: 1,
                                    type: "button",
                                    "input-class": "btn btn-sm btn-warning table-input--button",
                                    onClick: ($event: any) => (deactivateSelectedApplication(row.item))
                                  }, {
                                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createTextVNode(" Deactivate ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_FormKit, {
                                type: "button",
                                "input-class": "btn btn-sm btn-danger table-input--button",
                                onClick: ($event: any) => (deleteSelectedApplication(row.item))
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode(" Delete ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["items", "busy"]),
            (!props.applicationId)
              ? (_openBlock(), _createBlock(CursorPaginationComponent, {
                  key: 0,
                  next: !!state.value.pagination.next,
                  previous: !!state.value.pagination.previous.length,
                  limit: Number(state.value.pagination.limit),
                  onOnChange: onPaginationChange
                }, null, 8, ["next", "previous", "limit"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(AppModalComponent, {
            id: "selectedApplicationModal",
            title: "Application Details",
            "open-modal": state.value.openModal,
            onOnModalClose: _cache[4] || (_cache[4] = () => (state.value.openModal = false))
          }, {
            default: _withCtx(() => [
              (Boolean(state.value.selectedApplication))
                ? (_openBlock(), _createBlock(ApplicationDetailsComponent, {
                    key: 0,
                    mode: props.mode,
                    application: state.value.selectedApplication,
                    "application-products": state.value.selectedApplicationProducts,
                    "key-id": props.keyId,
                    onOnKeyActivated: _cache[2] || (_cache[2] = ($event: any) => (refreshApplication(state.value.selectedApplication))),
                    onOnKeyApproved: _cache[3] || (_cache[3] = ($event: any) => (refreshApplication(state.value.selectedApplication)))
                  }, null, 8, ["mode", "application", "application-products", "key-id"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["open-modal"])
        ], 64))
  ], 2))
}
}

})