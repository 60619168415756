<template>
  <button
    type="button"
    :class="`bg-transparent b-none ${props.class}`"
    :data-test="props.testId"
    @click="emit('click')"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
    >
      <g clip-path="url(#a)">
        <g clip-path="url(#b)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M.76.323a.89.89 0 0 1 1.258 0L15.5 13.805 28.982.323a.89.89 0 0 1 1.273 1.242l-.015.016-13.482 13.481L30.24 28.544l.015.016a.89.89 0 0 1-1.273 1.242L15.5 16.32 2.018 29.802A.89.89 0 0 1 .745 28.56l.015-.016 13.482-13.482L.76 1.582l-.015-.016A.89.89 0 0 1 .76.323Z"
            fill="#5a6472"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="none"
            transform="translate(.5 .063)"
            d="M0 0h30v30H0z"
          />
        </clipPath>
        <clipPath id="b">
          <path
            fill="none"
            transform="translate(.5 .063)"
            d="M0 0h30v30H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits<{ (event: 'click') }>();

const props = withDefaults(defineProps<{ class?: string; testId?: string }>(), {
  class: '',
  testId: '',
});
</script>
