<template>
  <div>
    <ul
      role="tablist"
      class="tablist"
    >
      <li
        v-for="(label, index) in props.labels"
        :key="label"
        role="presentation"
        class="tablist--tab remove-list-bullets cursor-pointer"
        :class="Number(index) === selectedTabIndex && 'tablist--tab-active'"
        :data-test="`${camelCase(label)}-tab`"
        @click="handleChange(index)"
      >
        <a
          :id="label + index.toString()"
          role="tab"
          target="_self"
        >{{ label }}</a>
      </li>
    </ul>
    <template
      v-for="(label, index) in props.labels"
      :key="`${label}-content`"
    >
      <slot
        v-if="Number(index) === selectedTabIndex"
        :name="`cell(${camelCase(label)})`"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { shallowRef, onBeforeMount } from 'vue';
import { camelCase } from 'lodash';
import { convertValueToUrlPath, pushValueToHistory } from '../../helpers/window-helper';
import { usePopStateEvent } from '../../hooks/handle-popstate-event.hook';

const props = defineProps<{
  labels: string[];
  initialTab: string;
}>();

const emit = defineEmits<{
  (event: 'tabChanged'): void;
}>();

const selectedTabIndex = shallowRef<number>(0);

onBeforeMount(() => {
  if (!props.initialTab) {
    return;
  }

  const initialTabIndex = labels.indexOf(camelCase(props.initialTab));

  if (initialTabIndex !== -1) {
    selectedTabIndex.value = initialTabIndex;
  }
});

const labels = props.labels.map(label => convertValueToUrlPath(label));

usePopStateEvent(labels, handleChange);

function handleChange(index: string | number | symbol) {
  const formattedLabels = props.labels.map(label => convertValueToUrlPath(label));
  pushValueToHistory(formattedLabels, Number(index));
  selectedTabIndex.value = Number(index);

  emit('tabChanged');
}
</script>

<style lang="scss">
@import 'src/scss/variables';

.tablist {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tablist--tab {
  background: #fff;
  height: 5rem;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: #000;
  }
}

.tablist--tab-active {
  color: $app-neutral-black;
  background: #f2efe9;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}
</style>
