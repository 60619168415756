<template>
  <AppAlertComponent :visible="visible">
    Creating client applications is disabled for users who register with generic email service.
    Please register on the Apiture Developer Portal using your company email address.
  </AppAlertComponent>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Subject, takeUntil } from 'rxjs';
import { partnerDomainValid$ } from '../../services/legacy/auth.service';
import AppAlertComponent from './app-alert.component.vue';

const visible = ref<boolean>(false);
const destroySubject = new Subject();

onMounted(() => {
  partnerDomainValid$.pipe(takeUntil(destroySubject)).subscribe(partnerDomainValid => {
    visible.value = !partnerDomainValid;
  });
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});
</script>
