import SecureLS from 'secure-ls';

export type SecureLocalStorageKeys =
  | 'targetEnv'
  | 'partnerChecked'
  | 'partnerDomainValid'
  | 'earliestSampleDataRequestTime'
  | 'basePathUrl';

export type SessionStorageKeys =
  | 'devPortalAccessToken'
  | 'userData'
  | 'oktaUserData'
  | 'email'
  | 'userRole'
  | 'targetEnvAccessToken'
  | 'targetEnvApiKey'
  | 'defaultExplorerKey';

const secureLS: SecureLS = (() => {
  const createSecureStorage = (): SecureLS => {
    return new SecureLS({
      encodingType: 'aes',
      isCompression: false,
    });
  };

  try {
    return createSecureStorage();
  } catch {
    // If failed to create secure storage then local storage can contain garbage.
    // Try to clean up and recreate.
    localStorage.clear();
    return createSecureStorage();
  }
})();

export function getItemInSecureStorage<T = unknown>(key: SecureLocalStorageKeys) {
  return secureLS.get(key) as T;
}

export function setItemInSecureStorage(key: SecureLocalStorageKeys, value: unknown): void {
  if (value === undefined || value === null) {
    secureLS.remove(key);
  } else {
    secureLS.set(key, value);
  }
}

export function clearLocalStorage(): void {
  localStorage.clear();
}

export function getSecureLS(): SecureLS {
  return secureLS;
}

export function getItemInSessionStorage<T = string>(key: SessionStorageKeys) {
  const item = sessionStorage.getItem(key);

  try {
    return JSON.parse(item) as T;
  } catch {
    return item as T;
  }
}

export function setItemInSessionStorage(key: SessionStorageKeys, value: string) {
  return sessionStorage.setItem(key, value);
}

export function removeItemInSessionStorage(key: SessionStorageKeys) {
  return sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
  sessionStorage.clear();
}
