import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';
import { KeyType } from '@apiture/api-keys-client-sdk';
import { getKey } from '../../services/api/keys-api.service';
import { getUrlParams, getIdFromUri } from '../../helpers/window-helper';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import DiscovererKeyFormComponent from '../legacy/discoverer-key-form.component.vue';
import ApplicationsListComponent from '../legacy/applications-list.component.vue';
import keysListComponent from '../legacy/keys-list.component.vue';
import TabListComponent from '../portal/tab-list.component.vue';
import type { ProductLine } from '../../utils/product-lines';


export default /*@__PURE__*/_defineComponent({
  __name: 'applications-keys.component',
  props: {
    productLine: {},
    initialTab: {}
  },
  setup(__props: any) {

const props = __props;

const state = ref<{
  initialized: boolean;
  applicationId: string;
  keyId: string;
  keyType: KeyType;
}>({
  initialized: false,
  applicationId: null,
  keyId: null,
  keyType: null,
});

onMounted(async () => {
  const urlParams = getUrlParams();

  state.value.applicationId = urlParams.get('application');
  state.value.keyId = urlParams.get('key');

  if (state.value.keyId) {
    const key = await getKey(state.value.keyId);
    state.value.keyType = key.type;
    state.value.applicationId = getIdFromUri(key._links['apiture:clientApplication'].href);
  }

  state.value.initialized = true;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (state.value.keyType === 'explorer')
            ? (_openBlock(), _createBlock(ApplicationsListComponent, {
                key: 0,
                mode: "admin",
                "key-id": state.value.keyId,
                "product-line": props.productLine
              }, null, 8, ["key-id", "product-line"]))
            : (state.value.applicationId || state.value.keyId)
              ? (_openBlock(), _createBlock(ApplicationsListComponent, {
                  key: 1,
                  mode: "admin",
                  "application-id": state.value.applicationId,
                  "key-id": state.value.keyId,
                  "product-line": props.productLine
                }, null, 8, ["application-id", "key-id", "product-line"]))
              : (_openBlock(), _createBlock(TabListComponent, {
                  key: 2,
                  labels: 
            props.productLine === 'adb'
              ? ['Explorer Keys', 'Discoverer Keys']
              : ['Applications', 'Explorer Keys', 'Discoverer Keys']
          ,
                  "initial-tab": props.initialTab
                }, {
                  "cell(applications)": _withCtx(() => [
                    (props.productLine === 'open')
                      ? (_openBlock(), _createBlock(ApplicationsListComponent, {
                          key: 0,
                          mode: "admin",
                          "product-line": "open"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  "cell(explorerKeys)": _withCtx(() => [
                    _createVNode(keysListComponent, {
                      mode: "admin",
                      type: "explorer",
                      "product-line": props.productLine
                    }, null, 8, ["product-line"])
                  ]),
                  "cell(discovererKeys)": _withCtx(() => [
                    _createVNode(DiscovererKeyFormComponent)
                  ]),
                  _: 1
                }, 8, ["labels", "initial-tab"]))
        ], 64))
  ]))
}
}

})