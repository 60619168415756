import { OktaAuth } from '@okta/okta-auth-js';
import { siteOptions } from '../options/site-options';
import { clearSessionStorage } from './storage.service';

export type OktaUser = {
  email: string;
  domain: string;
  familyName: string;
  givenName: string;
  locale: string;
  name: string;
  preferredUsername: string;
  updatedAt: number;
  zoneInfo: string;
};

export const oktaAuthClient = new OktaAuth({
  issuer: siteOptions.oktaIssuer,
  clientId: siteOptions.oktaClientId,
  redirectUri: `${window.location.origin}/auth/admin-callback/`,
  responseType: 'code',
  pkce: true,
  scopes: ['openid', 'offline_access', 'profile'],
  logoutUrl: `${window.location.origin}/auth/logout`,
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
    storage: 'sessionStorage',
  },
});

export async function getOktaAccessToken() {
  const oktaTokens = await oktaAuthClient.tokenManager.getTokens();
  return oktaTokens?.accessToken?.accessToken;
}

export async function isOktaAccessTokenExpired() {
  const token = (await oktaAuthClient.tokenManager.getTokens()).accessToken;
  return oktaAuthClient.tokenManager.hasExpired(token);
}

export async function removeOktaTokens() {
  oktaAuthClient.revokeAccessToken();

  await oktaAuthClient.closeSession().catch(() => {
    oktaAuthClient.tokenManager.clear();
  });

  clearSessionStorage();
}

// Make sure an okta access token exists before starting the service.
getOktaAccessToken()
  .then(token => {
    if (!token) return;
    oktaAuthClient.start();
  })
  .catch(error => {
    console.error('Could not start Okta Client.', error);
  });
