import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

import { onBeforeMount, onUnmounted, ref } from 'vue';
import {
  oktaAuthClient,
  getOktaAccessToken,
  isOktaAccessTokenExpired,
  removeOktaTokens,
} from '../../services/okta-auth.service';
import { addModal } from '../../hooks/modal-store.hook';
import SessionExpiredModal from '../portal/modals/session-expired-modal.component.vue';
import { clearSessionStorage } from '../../services/storage.service';
import { siteOptions } from '../../options/site-options';
import { getAdminUser } from '../../services/portal-auth.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'admin-session-handler',
  setup(__props) {

const user = getAdminUser();

const token = ref<string>();

onBeforeMount(async () => {
  if (siteOptions.loginEnabled && !user) {
    window.location.replace('/404.html');
    return;
  }

  const oktaAccessToken = await getOktaAccessToken();

  if (!oktaAccessToken) {
    window.location.replace('/auth/admin/');
    return;
  }

  const oktaAccessTokenExpired = await isOktaAccessTokenExpired();

  if (oktaAccessTokenExpired) {
    createSessionExpModal();
    return;
  }

  try {
    await oktaAuthClient.start();
    token.value = oktaAccessToken;
  } catch {
    createSessionExpModal();
    return;
  }

  oktaAuthClient.tokenManager.on('error', () => {
    createSessionExpModal();
  });
});

onUnmounted(() => {
  oktaAuthClient.tokenManager.off('error');
});

function createSessionExpModal(error?: string) {
  addModal({
    component: SessionExpiredModal,
    props: {
      error,
    },
    onComplete: () => {
      routeToLogin();
    },
    onCancel: () => {
      clearSessionStorage();
      window.location.assign('/admin/dashboard');
    },
    onError: async () => {
      await routeToLogin();
    },
  });
}

async function routeToLogin() {
  try {
    await removeOktaTokens();
  } catch {
    clearSessionStorage();
  }

  window.location.replace('/auth/admin');
}

return (_ctx: any,_cache: any) => {
  return (token.value)
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        token: token.value
      })
    : _createCommentVNode("", true)
}
}

})