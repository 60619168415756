import { onBeforeUnmount, onBeforeMount, Ref } from 'vue';
import { getFocusedIndexFromUrl, convertValueToUrlPath } from '../helpers/window-helper';

export function usePopStateEvent(
  values: string[],
  callback?: (index: number, event: PopStateEvent) => void,
) {
  const listener = (event: PopStateEvent) => {
    if (!values || values.length < 1) {
      return;
    }

    const index = getFocusedIndexFromUrl(values);

    callback(index, event);
  };

  onBeforeUnmount(() => {
    window.removeEventListener('popstate', listener);
  });

  onBeforeMount(() => {
    window.addEventListener('popstate', listener);
  });

  return listener;
}

export function useStateFromUrlOnMount(values: string[], selectedIndex: Ref<number>) {
  if (!values || values.length < 1) {
    return;
  }

  onBeforeMount(() => {
    const index = getFocusedIndexFromUrl(values);

    selectedIndex.value = index;

    if (index !== -1) {
      return;
    }

    history.pushState(
      null,
      values[0],
      `${window.location.href}${convertValueToUrlPath(values[0])}/`,
    );
  });
}
