<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="22"
    viewBox="0 0 30 22"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5352 0.632812C29.3442 0.632812 30 1.15748 30 1.80469C30 2.44542 29.3572 2.96606 28.5594 2.97641L28.5352 2.97656H1.46484C0.655833 2.97656 0 2.4519 0 1.80469C0 1.16395 0.642782 0.643318 1.44062 0.632969L1.46484 0.632812H28.5352ZM18.2227 9.89062C19.0317 9.89062 19.6875 10.4153 19.6875 11.0625C19.6875 11.7032 19.0447 12.2239 18.2469 12.2342L18.2227 12.2344H1.46484C0.655833 12.2344 0 11.7097 0 11.0625C0 10.4218 0.642782 9.90113 1.44062 9.89078L1.46484 9.89062H18.2227ZM26.0742 20.3203C26.0742 19.6731 25.4184 19.1484 24.6094 19.1484H1.46484L1.44062 19.1486C0.642782 19.1589 0 19.6796 0 20.3203C0 20.9675 0.655833 21.4922 1.46484 21.4922H24.6094L24.6336 21.492C25.4314 21.4817 26.0742 20.9611 26.0742 20.3203Z"
      fill="white"
    />
  </svg>
</template>
