// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-alert {
  background-color: #b6effb;
  color: #055160;
  border-radius: 0.25rem;
  width: 100%;
  border: none;
  padding: 1rem;
  margin-bottom: 1rem;
}
.invisible {
  visibility: hidden;
}
.hide {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/legacy/app-alert.component.vue","webpack://./app-alert.component.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;ACAF;ADGA;EACE,kBAAA;ACAF;ADGA;EACE,aAAA;ACAF","sourcesContent":["\n.app-alert {\n  background-color: #b6effb;\n  color: #055160;\n  border-radius: 0.25rem;\n  width: 100%;\n  border: none;\n  padding: 1rem;\n  margin-bottom: 1rem;\n}\n\n.invisible {\n  visibility: hidden;\n}\n\n.hide {\n  display: none;\n}\n",".app-alert {\n  background-color: #b6effb;\n  color: #055160;\n  border-radius: 0.25rem;\n  width: 100%;\n  border: none;\n  padding: 1rem;\n  margin-bottom: 1rem;\n}\n\n.invisible {\n  visibility: hidden;\n}\n\n.hide {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
