import type { Component } from 'vue';

import { ref, markRaw, watch } from 'vue';

type Modal<Props = Record<string, unknown>> = Readonly<{
  component: null | Component;
  props?: Props;
  onComplete?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
  state?: {
    completed: boolean;
    cancelled: boolean;
  };
}>;

type AsyncModal = Modal & { finished: boolean };

const modals = ref<(Modal | AsyncModal)[]>([]);

export function useModalStore() {
  return modals;
}

export function addModal<Props = Record<string, unknown>>(newModal: Modal<Props>) {
  modals.value.push(markRaw(newModal) as Modal);
}

export async function addAsyncModal<Props = Record<string, unknown>>(newModal: Modal<Props>) {
  modals.value.push(
    markRaw({
      ...newModal,
      state: { completed: false, cancelled: false },
      finished: false,
    } as AsyncModal),
  );

  return new Promise((resolve: (completed: boolean) => void) => {
    const modal = modals.value[modals.value.length - 1];

    const stopWatching = watch(
      () => modal.state,
      () => {
        resolve(modal.state.completed);
        closeModal();
        stopWatching();
      },
    );
  });
}

export function closeModal() {
  modals.value.pop();
}

export async function completeModal() {
  const modal = modals.value[modals.value.length - 1];

  try {
    if (typeof modal.onComplete === 'function') {
      await modal.onComplete();
      modal.state.completed = true;
    }

    if ('finished' in modal) {
      modal.finished = true;
    }

    closeModal();
  } catch (error: unknown) {
    if (typeof modal.onError === 'function') {
      await modal.onError(error);
    }
  }
}

export async function cancelModal() {
  const modal = modals.value[modals.value.length - 1];

  if (typeof modal.onCancel === 'function') {
    await modal.onCancel();
    modal.state.cancelled = true;
  }

  if ('finished' in modal) {
    modal.finished = true;
  }

  closeModal();
}
