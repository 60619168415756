import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { class: "form-input--option-list remove-list-bullets" }
const _hoisted_3 = { class: "form-input--wrapper" }
const _hoisted_4 = { class: "form-input--option-inner" }
const _hoisted_5 = ["id", "name", "value", "data-test"]
const _hoisted_6 = { class: "form-input--label" }
const _hoisted_7 = {
  key: 0,
  class: "form-input--maxConnectionHours"
}
const _hoisted_8 = { class: "b-none flex-col" }
const _hoisted_9 = { class: "d-inline-flex" }
const _hoisted_10 = { class: "form-container--row" }
const _hoisted_11 = { class: "flex-col" }
const _hoisted_12 = { class: "flex-col" }

import { ref, onMounted, watch, computed } from 'vue';
import { Environment } from '@apiture/api-environments-client-sdk';
import { FormKitFrameworkContext } from '@formkit/core';
import Tooltip from '../tooltip.component.vue';
import { EnvironmentType } from '@apiture/api-environments-client-sdk';
import { mapEnvironmentsToOptions } from '../../../hooks/use-environments.hook';

type MaximumConnectionTime = {
  days: number;
  hours: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'environment.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const environment = ref<string>();
const maximumConnectionTime = ref<MaximumConnectionTime>({
  days: 60,
  hours: 0,
});

const selectedEnvironment = computed(() =>
  props.context.environments.find(environment => environment?._id === props.context._value?.id),
);

onMounted(() => {
  updateEnvironment();

  props.context.node.input({
    id: environment.value,
    ...(props.context.includeMaximumConnectionHours && {
      maximumConnectionTime: maximumConnectionTime.value,
    }),
  });
});

watch(
  () => props.context.value,
  () => {
    if (
      props.context.value.id &&
      props.context.includeMaximumConnectionHours &&
      props.context.value.maximumConnectionTime
    ) {
      return;
    }

    updateEnvironment();
  },
);

function handleMaxConnectionTimeDays(event: Event & { target: HTMLInputElement }) {
  if (Number(event.target.value) > 365 || event.target.value.length > 3) {
    maximumConnectionTime.value.days = 365;
  } else if (Number(event.target.value) < 0) {
    maximumConnectionTime.value.days = 0;
  }

  props.context.node.input({
    ...props.context.value,
    maximumConnectionTime: {
      ...maximumConnectionTime.value,
      days: maximumConnectionTime.value.days,
    },
  });
}

function handleMaxConnectionTimeHours(event: Event & { target: HTMLInputElement }) {
  if (Number(event.target.value) > 99 || event.target.value.length > 2) {
    maximumConnectionTime.value.hours = 99;
  } else if (Number(event.target.value) < 0) {
    maximumConnectionTime.value.hours = 0;
  }

  props.context.node.input({
    ...props.context.value,
    maximumConnectionTime: {
      ...maximumConnectionTime.value,
      hours: maximumConnectionTime.value.hours,
    },
  });
}

function preventDecimalNumbers(event: KeyboardEvent) {
  if (event.key === '.') {
    event.preventDefault();
  }
}

function handleEnvironmentInput(event: Event & { target: HTMLInputElement }) {
  props.context.node.input({
    ...props.context.value,
    id: event.target.value,
  });
}

function updateEnvironment() {
  if (!props.context._value || (environment.value && maximumConnectionTime.value)) {
    return;
  }

  environment.value = props.context.value?.id;

  if (props.context.includeMaximumConnectionHours) {
    maximumConnectionTime.value = {
      days: props.context.value?.maximumConnectionTime?.days ?? 60,
      hours: props.context.value?.maximumConnectionTime?.hours ?? 0,
    };
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-input--component-environment",
    "data-test": props.context.attrs['data-test']
  }, [
    _createElementVNode("fieldset", {
      class: _normalizeClass(props.context.classes.fieldset)
    }, [
      _createElementVNode("legend", {
        class: _normalizeClass(props.context.classes.legend)
      }, " Choose an environment ", 2),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mapEnvironmentsToOptions)(props.context.environments, false), (option, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: option.value,
            class: _normalizeClass(props.context.classes.option)
          }, [
            _createElementVNode("label", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  id: `input_${i.toString()}-option-${option.value}`,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((environment).value = $event)),
                  class: "form-input--option",
                  type: "radio",
                  name: _ctx.context.node.name,
                  value: option.value,
                  "data-test": `${props.context.attrs['data-test']}-${i.toString()}`,
                  onInput: handleEnvironmentInput
                }, null, 40, _hoisted_5), [
                  [_vModelRadio, environment.value]
                ])
              ]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(option.label), 1)
            ]),
            (
              option.value === selectedEnvironment.value?._id &&
                selectedEnvironment.value?.type !== _unref(EnvironmentType).Production &&
                props.context.includeMaximumConnectionHours
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("fieldset", _hoisted_8, [
                    _createElementVNode("legend", _hoisted_9, [
                      _cache[3] || (_cache[3] = _createTextVNode(" Maximum Connection Time ")),
                      _createVNode(Tooltip, { tip: "The maximum number of hours before user has to reconnect their banking access to non-production applications by actively logging in. This applies only to non-prod access. For production environments, this configuration is set by the financial institution." })
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "form-input--label" }, "Days", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((maximumConnectionTime.value.days) = $event)),
                          "data-test": "days",
                          name: "days",
                          type: "number",
                          class: "form-input form-input--text",
                          onInput: handleMaxConnectionTimeDays,
                          onKeydown: preventDecimalNumbers
                        }, null, 544), [
                          [_vModelText, maximumConnectionTime.value.days]
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_12, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "form-input--label" }, "Hours", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((maximumConnectionTime.value.hours) = $event)),
                          "data-test": "hours",
                          name: "hours",
                          type: "number",
                          class: "form-input form-input--text",
                          onInput: handleMaxConnectionTimeHours,
                          onKeydown: preventDecimalNumbers
                        }, null, 544), [
                          [_vModelText, maximumConnectionTime.value.hours]
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ])
    ], 2)
  ], 8, _hoisted_1))
}
}

})