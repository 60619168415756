import { parse } from 'marked';
import { sanitize } from './sanitize-helper';

/**
 * Convert markdown string to html using dompurify.
 */
export function makeHtmlMarkdown(markdown: string): string {
  if (!markdown) {
    return '';
  }
  return sanitize(parse(markdown));
}
