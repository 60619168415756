<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clip-path="url(#clip0_5361_14966)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0547 8.625C16.0547 4.20672 12.473 0.625 8.05469 0.625C3.63641 0.625 0.0546875 4.20672 0.0546875 8.625C0.0546875 13.0433 3.63641 16.625 8.05469 16.625C12.473 16.625 16.0547 13.0433 16.0547 8.625ZM0.992188 8.625C0.992188 4.72449 4.15418 1.5625 8.05469 1.5625C11.9552 1.5625 15.1172 4.72449 15.1172 8.625C15.1172 12.5255 11.9552 15.6875 8.05469 15.6875C4.15418 15.6875 0.992188 12.5255 0.992188 8.625ZM11.7852 8.15244C12.0549 8.15244 12.2734 8.37101 12.2734 8.64063C12.2734 8.90597 12.0617 9.12187 11.798 9.12866L11.7852 9.12882H8.55849V12.3556C8.55849 12.6252 8.33992 12.8438 8.0703 12.8438C7.80496 12.8438 7.58906 12.6321 7.58227 12.3684L7.58211 12.3556V9.12882H4.35538C4.08576 9.12882 3.86719 8.91025 3.86719 8.64063C3.86719 8.37529 4.07888 8.15939 4.34258 8.1526L4.35538 8.15244H7.58211V4.92569C7.58211 4.65607 7.80068 4.4375 8.0703 4.4375C8.33564 4.4375 8.55154 4.64919 8.55833 4.91289L8.55849 4.92569V8.15244H11.7852Z"
        fill="#005A7C"
      />
    </g>
    <defs>
      <clipPath id="clip0_5361_14966">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.0546875 0.625)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
