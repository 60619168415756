import { env$ } from './target-env.service';
import { defaultReferenceDomainRegExp, getApiDomainByHost } from '../apis.service';
import { TargetEnv } from '../../models/target-env';

type ReferenceLink = {
  element: HTMLLinkElement;
  originalHref: string;
  originalInnerHtml: string;
};

export function initTocService(): void {
  const referenceLinks = getReferenceLinks();
  env$.subscribe(env => {
    replaceReferenceLinks(env, referenceLinks);
  });

  replaceDownloadLinks();
}

function getReferenceLinks(): ReferenceLink[] {
  return $('a')
    .filter((index, element: HTMLLinkElement) => {
      return element.href.search(defaultReferenceDomainRegExp) !== -1;
    })
    .toArray()
    .map((element: HTMLLinkElement) => {
      return {
        element,
        originalHref: element.href,
        originalInnerHtml: element.innerHTML,
      };
    });
}

function replaceReferenceLinks(targetEnv: TargetEnv, referenceLinks: ReferenceLink[]): void {
  const apiDomain = getApiDomainByHost(targetEnv.apiHost);

  referenceLinks.forEach(link => {
    link.element.href = link.originalHref.replace(defaultReferenceDomainRegExp, apiDomain);
    link.element.innerHTML = link.originalInnerHtml.replace(
      defaultReferenceDomainRegExp,
      apiDomain,
    );
  });
}

function replaceDownloadLinks(): void {
  const matchRegExp = /\/docs\/apis\/(?<api>.*?)\/v(?<version>.*?)\/openapi\.(?<type>yaml)/;

  $('a').each((index, element: HTMLLinkElement) => {
    const matchGroups = matchRegExp.exec(element.href)?.groups;
    if (matchGroups) {
      $(element).replaceWith(
        `<api-reference-download api="${matchGroups.api}" version="${matchGroups.version}" type="${matchGroups.type}" title="${element.innerText}"></api-reference-download>`,
      );
    }
  });
}
