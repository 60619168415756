// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button[data-v-5ebab97b] {
  align-self: end;
  padding-bottom: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/inputs/invitees.component.vue","webpack://./invitees.component.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,uBAAA;ACAF","sourcesContent":["\nbutton {\n  align-self: end;\n  padding-bottom: 0.75rem;\n}\n","button {\n  align-self: end;\n  padding-bottom: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
