import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'clipboard-copy.component',
  props: {
    id: {},
    title: {},
    value: {},
    disable: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

async function copy() {
  await navigator.clipboard.writeText(props.value);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    id: props.id + 'CopyToClipboard',
    type: "button",
    "input-class": "form-input--button-transparent",
    "outer-class": "align-self-center",
    disabled: props.disable,
    ignore: true,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (copy()))
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode(" Copy ")
    ])),
    _: 1
  }, 8, ["id", "disabled"]))
}
}

})