<template>
  <div v-html="makeHtmlMarkdown(props.html) ?? props.html" />
</template>

<script lang="ts" setup>
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';

const props = defineProps<{
  html: string;
}>();
</script>
