<template>
  <svg
    class="footer--svg"
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="25"
    viewBox="0 0 3 25"
    fill="none"
  >
    <path
      d="M0.5 0.5V24.5H2.5V0.5H0.5Z"
      fill="#DDE0E4"
      mask="url(#path-1-inside-1_4271_14218)"
    />
  </svg>
</template>
