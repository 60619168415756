import bootbox from 'bootbox';
import { getUser } from './auth.service';

export function checkUserTermsAccepted(message: string): void {
  const user = getUser();
  if (user.termsConditionsAccepted) {
    return;
  }
  bootbox.dialog({
    title: 'Please confirm the terms & condition',
    message,
    closeButton: false,
    buttons: {
      confirm: {
        label: 'Go to My Profile',
        className: 'btn-primary',
        callback: (): void => {
          window.location.replace('/openProfile/personal/');
        },
      },
    },
  });
}
