import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  href: "https://dx.apiture.com",
  target: "_blank"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 2 }

import { getAdminUser } from '../../services/portal-auth.service';
import DropdownComponent from '../portal/dropdown.component.vue';
import NewTabSvg from '../portal/svg/new-tab.component.vue';
import NavigationContainer from '../portal/navigation-container.component.vue';
import { removeOktaTokens } from '../../services/okta-auth.service';
import { clearSessionStorage } from '../../services/storage.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'admin-navigation.component',
  setup(__props) {

const user = getAdminUser();

async function logout() {
  try {
    await removeOktaTokens();
  } catch {
    clearSessionStorage();
  }

  window.location.replace('/auth/admin/');
}

return (_ctx: any,_cache: any) => {
  const _component_CaretDown = _resolveComponent("CaretDown")!

  return (_openBlock(), _createBlock(NavigationContainer, null, {
    default: _withCtx(({ isExpanded, baseUrl }) => [
      _createElementVNode("li", null, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: `${baseUrl}/admin/dashboard`
            }, "Dashboard", 8, _hoisted_1)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_2, [
              _cache[1] || (_cache[1] = _createTextVNode("Developer Docs ")),
              _createVNode(NewTabSvg)
            ])
          ])
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("ul", null, [
          (_unref(user) && _unref(user).role === 'Admin')
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                _createVNode(DropdownComponent, {
                  label: "Admin",
                  "dropdown-button-class": "nav--dropdown-button-profile",
                  "dropdown-content-class": "nav--dropdown-content",
                  "remove-pointer": isExpanded
                }, {
                  labelIcon: _withCtx(() => [
                    _createVNode(_component_CaretDown)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "adminWebhooksLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/webhooks`
                        }, " Webhooks ", 8, _hoisted_4)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "adbAdminApplicationsLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/applications`
                        }, " ADB Applications ", 8, _hoisted_5)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "adbAdminExplorerKeysLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/api-keys/explorerKeys`
                        }, " ADB Explorer Keys ", 8, _hoisted_6)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "adbAdminDiscovererKeysLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/api-keys/discovererKeys`
                        }, " ADB Discoverer Keys ", 8, _hoisted_7)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "productsLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/products/`
                        }, " ADB Products ", 8, _hoisted_8)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "environmentsLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/environments/`
                        }, " ADB Environments ", 8, _hoisted_9)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "apiKeysLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/openAdmin/api-keys/`
                        }, " Open Applications and Keys ", 8, _hoisted_10)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "productsLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/openAdmin/products/`
                        }, " Open Products ", 8, _hoisted_11)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "environmentsLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/openAdmin/environments/`
                        }, " Open Environments ", 8, _hoisted_12)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["remove-pointer"])
              ]))
            : _createCommentVNode("", true),
          (_unref(user))
            ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                _createVNode(DropdownComponent, {
                  label: `${_unref(user).firstName} ${_unref(user).lastName}`,
                  "dropdown-button-class": "nav--dropdown-button-profile",
                  "dropdown-content-class": "nav--dropdown-content",
                  "remove-pointer": isExpanded
                }, {
                  labelIcon: _withCtx(() => [
                    _createVNode(_component_CaretDown)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "accountLink",
                          class: "nav--dropdown-item",
                          href: `${baseUrl}/admin/account/`
                        }, " My Account ", 8, _hoisted_14)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          id: "logoutLink",
                          class: "nav--dropdown-item",
                          href: "javascript:",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (logout()))
                        }, " Logout ")
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["label", "remove-pointer"])
              ]))
            : _createCommentVNode("", true),
          (!_unref(user))
            ? (_openBlock(), _createElementBlock("li", _hoisted_15, _cache[2] || (_cache[2] = [
                _createElementVNode("a", { href: "/auth/admin" }, "Login", -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})