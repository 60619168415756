import { Ref, computed, ref } from 'vue';
import { AuthorizationScope, Product } from '@apiture/api-products-client-sdk';
import { Api } from '../services/apis.service';
import { getApiScopes } from '../services/apis.service';

//TODO: Refactor
export function useScopes(params: {
  formData: Ref<{ apis?: string[]; products?: string[] }>;
  products?: Ref<Product[]>;
  apis?: Ref<Api[]>;
}) {
  const apiScopes = useApiScopes();

  const uniqueSelectedScopes = computed(() => {
    const uniqueScopes = new Map<string, AuthorizationScope>();

    const products = params.products ? params.products.value : [];
    const apis = params.apis ? params.apis.value : [];
    const selectedApis = params.formData.value.apis ? params.formData.value.apis : [];
    const selectedProducts = params.formData.value.products ? params.formData.value.products : [];

    const selectedApiScopes = apis
      .filter(api => selectedApis.some(apiName => api.apiName === apiName))
      .flatMap(api => apiScopes.value.get(api.apiName))
      .filter(scope => Boolean(scope));

    const selectedProductScopes = getSelectedProductScopes(products, selectedProducts);

    const selectedScopes = selectedApiScopes.concat(selectedProductScopes);

    for (const scope of selectedScopes) {
      if (!uniqueScopes.has(scope.name)) {
        uniqueScopes.set(scope.name, scope);
      }
    }

    return Array.from(uniqueScopes.values());
  });

  return {
    uniqueSelectedScopes,
    apiScopes,
  };
}

export function useApiScopes() {
  const apiScopes = ref<Map<string, AuthorizationScope[]>>();

  const fetchApiScopes = async () => {
    apiScopes.value = await getApiScopes();
  };

  fetchApiScopes();

  return apiScopes;
}

export function getSelectedProductScopes(
  products: Product[],
  selectedProducts: string[] | undefined,
) {
  if (!selectedProducts) {
    return [];
  }

  const productScopes = products
    .filter(product => selectedProducts.some(id => product._id === id))
    .flatMap(product => product.scopes);

  return productScopes.filter(
    (scope, index) =>
      productScopes.findIndex(innerScope => innerScope.name === scope.name) === index,
  );
}
