import moment from 'moment';
import 'moment-timezone';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const DATE_TIME_FORMAT = 'MM/DD/YYYY, h:mm:ss A';

export function toLocalDate(date: moment.MomentInput): string {
  return moment(date).local().format(DATE_FORMAT);
}

export function toLocalDateTime(date: moment.MomentInput): string {
  return moment(date).local().format(DATE_TIME_FORMAT);
}
