import { ref, Ref } from 'vue';
import { Environment, EnvironmentState } from '@apiture/api-environments-client-sdk';
import { chain } from 'lodash';
import { getEnvironments } from '../services/api/environments-api.service';
import { ProductLine } from '../utils/product-lines';

interface EnvironmentOptions {
  value: string;
  label: string;
}

export function useEnvironments(productLine: ProductLine): Ref<Environment[]> {
  const environments = ref<Environment[]>([]);

  const setEnvironments = async () => {
    const environmentsResponse = await getEnvironments({
      states: [EnvironmentState.Active],
      productLine,
    });

    environments.value = environmentsResponse._embedded.items;
  };

  setEnvironments();

  return environments;
}

export function mapEnvironmentOptionsByName(environments: Environment[]) {
  return environments.map(environment => environment.name).sort();
}

export function mapEnvironmentsToOptions(
  environments: Environment[] = [],
  includeEmptyOption: boolean = true,
): EnvironmentOptions[] {
  const options = chain(environments)
    .sortBy(environment => environment.name)
    .map(environment => {
      return {
        value: environment._id,
        label: environment.name,
      };
    })
    .sortBy(item => item.label)
    .value();

  if (includeEmptyOption) options.push({ label: '', value: '' });

  return options;
}
