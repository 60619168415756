import { ref, Ref } from 'vue';
import { ProductState, Product } from '@apiture/api-products-client-sdk';
import { chain } from 'lodash';
import { getProducts } from '../services/api/products-api.service';
import { ProductLine } from '../utils/product-lines';

interface ProductOptions {
  value: string;
  label: string;
}

export function useProducts(productLine: ProductLine): Ref<Product[]> {
  const products = ref<Product[]>([]);

  const setProducts = async () => {
    const productsResponse = await getProducts({
      states: [ProductState.Active],
      productLine,
    });

    products.value = productsResponse._embedded.items;
  };

  setProducts();

  return products;
}

export function mapProductOptionsByName(products: Product[]) {
  return products
    .map(product => {
      return {
        value: product.name,
        label: `${product.name} v${product.version}`,
      };
    })
    .sort();
}

export function mapProductsToOptions(
  products: Product[],
  includeEmptyOption: boolean,
): ProductOptions[] {
  const options = chain(products)
    .map(product => {
      return {
        value: product._id,
        label: `${product.name} v${product.version}`,
      };
    })
    .sortBy(item => item.label)
    .value();

  if (includeEmptyOption) options.push({ label: '', value: '' });

  return options;
}
