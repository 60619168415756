<template>
  <h1>
    <slot />
  </h1>
</template>

<style lang="scss" scoped>
h1 {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 56px;
}
</style>
