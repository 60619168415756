<template>
  <div>
    <div class="row mb-2">
      <div class="col-6">
        <b>Type:</b> {{ applicationTypeName }}
      </div>
      <div
        v-if="applicationAuthenticationName"
        class="col-6"
      >
        <b>Authentication:</b> {{ applicationAuthenticationName }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <b>Name:</b>
        <br>
        <div class="ml-3">
          {{ props.application.name }}
        </div>
      </div>
      <div
        v-if="props.application.description"
        class="col-6"
      >
        <b>Description:</b>
        <br>
        <div
          class="ml-3 mb-n2"
          v-html="applicationDescriptionHtml"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <b>Products:</b>
        <br>
        <ul class="pl-3 ml-3 mb-0">
          <li
            v-for="product in props.applicationProducts"
            :key="product"
          >
            {{ product }}
          </li>
        </ul>
      </div>
      <div class="col-6">
        <b>Owners:</b>
        <br>
        <ul class="pl-3 ml-3 mb-0">
          <li
            v-for="applicationOwner in applicationOwners"
            :key="applicationOwner"
          >
            {{ applicationOwner }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <b>Site URL / Download Location:</b>
        <br>
        <div class="ml-3">
          {{ props.application.applicationUrl }}
        </div>
      </div>
      <div
        v-if="props.application.redirectUrls"
        class="col-6"
      >
        <b>Redirect URL:</b>
        <br>
        <div class="ml-3">
          {{ props.application.redirectUrls }}
        </div>
      </div>
    </div>
    <template v-if="showApplicationKeys">
      <h4 class="mt-3">
        API Key<span v-if="!props.keyId">s</span>
      </h4>
      <KeysListComponent
        :mode="props.mode"
        :application-id="props.application._id"
        :key-id="props.keyId"
        type="application"
        product-line="open"
        @on-key-activated="emit('onKeyActivated', props.keyId)"
        @on-key-approved="emit('onKeyApproved', props.keyId)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { sortBy } from 'lodash';
import type { Application } from '@apiture/client-applications-client-sdk';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { applicationTypesNames } from '../../services/apis.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getUser } from '../../services/legacy/auth.service';
import KeysListComponent from './keys-list.component.vue';

const props = withDefaults(
  defineProps<{
    mode?: 'user' | 'admin';
    application: Application;
    applicationProducts: string[];
    keyId: string;
  }>(),
  { mode: 'user' },
);
const emit = defineEmits(['onKeyActivated', 'onKeyApproved']);

const user = getUser();

const applicationTypeName = applicationTypesNames.get(props.application.type);

const applicationAuthenticationName = applicationAuthenticationsNames.get(
  props.application.authentication,
);

const applicationDescriptionHtml = makeHtmlMarkdown(props.application.description);

const applicationOwners = sortBy(props.application.owners);

const showApplicationKeys = props.mode === 'admin' || props.application.owners.includes(user.email);
</script>
