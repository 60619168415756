<template>
  <div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <div
      v-else
      :class="{ disabled: state.application.state === 'rejected' }"
    >
      <FormKit
        v-model="applicationFormData"
        type="form"
        submit-label="Save"
        :actions="false"
        data-test="applicationFormOpen"
        @submit="saveApplication()"
      >
        <div class="form-container--row">
          <FormKit
            type="select"
            name="type"
            label="Application Type"
            help="The type of
          client application. Different application types have different API and security
          requirements. For example, web and mobile applications are harder to keep secure because
          they often operate in unsecure public networks. They use an client ID but do not use client
          secrets key to authenticate the application; they use an API when invoking API
          operations. Trusted service applications use client IDs and client secrets for their OAuth
          flow and API keys for API calls."
            :options="[
              { label: '', value: '' },
              ...Array.from(applicationTypesNames.entries(), ([type, name]) => {
                return { value: type, label: name };
              }),
            ]"
            validation="required"
            :disabled="Boolean(state.application?._id)"
            outer-class="form-input--full"
            data-test="type"
          />
          <FormKit
            type="select"
            name="authentication"
            label="Authentication"
            :options="[
              { label: '', value: '' },
              ...Array.from(applicationAuthenticationsNames.entries(), ([type, name]) => {
                return { value: type, label: name };
              }),
            ]"
            :disabled="Boolean(state.application?._id)"
            outer-class="form-input--full"
            help="Select how your application with authenticate to the Apiture services: Authorization Code Flow Flow for interactive Client applications or Credentials for secure back-office services and applications."
            date-test="authentication"
          />
        </div>
        <FormKit
          type="text"
          label="Application Name"
          name="name"
          help="The name of the client application."
          validation="required|length:6,64"
          :disabled="state.application.state === ClientState.Rejected"
          data-test="applicationName"
        />
        <FormKit
          type="textarea"
          name="description"
          label="Description and Purpose of Application"
          help="The description of the client application, may use markdown."
          validation="length:0,512"
          :disabled="state.application.state === ClientState.Rejected"
          data-test="description"
        />
        <FormKit
          name="applicationUrl"
          type="url"
          label="Site URL / Download Location"
          help="A URL of a web page with additional information about the application, including information on downloading or licensing the application."
          validation="required|length:16,4096|url"
          placeholder="Publicly accessible URL"
          :disabled="state.application.state === ClientState.Rejected"
          data-test="applicationUrl"
        />
        <FormKit
          v-if="applicationFormData.authentication === ClientAuthentication.AuthorizationCode"
          name="redirectUrl"
          type="url"
          label="Redirect URL"
          help="The URL used in OAuth flows. When a user authenticates with the application's authorization server, the authorization server will redirect to this URL to complete the authentication process. This prevents other applications from using the application's client ID and secret."
          validation="length:16,4096|url"
          placeholder="OAuth2 Redirect URL"
          :disabled="state.application.state === ClientState.Rejected"
          data-test="redirectUrl"
        />
        <div class="form-container--row">
          <FormKit
            name="products"
            type="checkbox"
            label="Products"
            validation="required"
            help="Select the API products you client application will use."
            :options="mapProductsToOptions(products, false)"
            data-test="products"
          />
          <FormKit
            name="environments"
            type="checkbox"
            label="Environments"
            validation="required"
            help="Select the API environments that your client application will run against."
            :options="mapEnvironmentsToOptions(environments, false)"
            data-test="environments"
          />
        </div>
        <div class="form-container--submits">
          <FormKit
            type="submit"
            :disabled="state.loading"
            data-test="submit"
          >
            Save
          </FormKit>
          <FormKit
            :disabled="!applicationFormData.description || state.loading"
            type="button"
            input-class="form-input--button-transparent"
            data-test="showDescription"
            @click="showDescriptionPreview()"
          >
            Preview Description
          </FormKit>
        </div>
      </FormKit>
      <div
        v-if="state.application.state !== 'rejected'"
        class="text-right"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import {
  ApplicationType,
  ClientAuthentication,
  Application,
  ErrorResponse,
} from '@apiture/client-applications-client-sdk';
import { ProductLine } from '@apiture/api-products-client-sdk';
import { applicationTypesNames } from '../../services/apis.service';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import {
  getApplication,
  patchApplication,
  createApplication,
} from '../../services/api/applications-api.service';
import { checkUserTermsAccepted } from '../../services/legacy/terms-conditions.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getUrlParams } from '../../helpers/window-helper';
import { ClientState } from '@apiture/client-applications-client-sdk';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import { mapEnvironmentsToOptions, useEnvironments } from '../../hooks/use-environments.hook';
import { mapProductsToOptions, useProducts } from '../../hooks/use-products.hook';

const applicationFormData = ref<{
  type: ApplicationType;
  authentication: ClientAuthentication;
  name: string;
  description: string;
  applicationUrl: string;
  redirectUrl: string;
  products: string[];
  environments: string[];
}>({
  type: null,
  authentication: null,
  name: '',
  description: '',
  applicationUrl: '',
  redirectUrl: '',
  products: [],
  environments: [],
});

const products = useProducts(ProductLine.Open);
const environments = useEnvironments(ProductLine.Open);

const state = reactive<{
  initialized: boolean;
  loading: boolean;
  application: Application;
}>({
  initialized: false,
  loading: false,
  application: {},
});

function showDescriptionPreview(): void {
  const messageHtml = makeHtmlMarkdown(applicationFormData.value.description);
  bootbox.alert({
    title: 'Application Description',
    message: messageHtml,
  });
}

async function saveApplication(): Promise<void> {
  const isExistingApplication = !!state.application._id;

  try {
    state.loading = true;

    if (isExistingApplication) {
      Object.assign(state.application, getApplicationPatch());
      state.application = await patchApplication(state.application, state.application._id);
    } else {
      state.application = await createApplication(getApplicationPatch());
    }
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error?.message ?? Messages.internalError,
    });
    return;
  } finally {
    state.loading = false;
  }

  showToastr({
    type: 'success',
    message: `Application has been successfully ${isExistingApplication ? 'updated' : 'created'}.`,
  });

  fillFormData();

  setTimeout(function () {
    window.location.href = '/profile/applications/';
  }, 3000);
}

onMounted(async () => {
  const applicationId = getUrlParams().get('applicationId');

  checkUserTermsAccepted(
    applicationId
      ? 'It is mandatory to accept the terms & condition to edit an application.'
      : 'It is mandatory to accept the terms & condition before creating a new application.',
  );

  state.application = applicationId ? await getApplication(applicationId) : ({} as Application);
  state.initialized = true;

  fillFormData();
});

function getApplicationPatch(): Partial<Application> {
  const selectedEnvironments = environments.value
    .filter(environment => applicationFormData.value.environments.includes(environment._id))
    .map(environment => {
      return { name: environment.name, host: environment.host, _links: environment._links };
    });
  const selectedProducts = products.value
    .filter(product => {
      return applicationFormData.value.products.includes(product._id);
    })
    .map(product => {
      return { name: product.name, version: product.version, _links: product._links };
    });

  return {
    type: applicationFormData.value.type,
    authentication: applicationFormData.value.authentication,
    name: applicationFormData.value.name,
    description: applicationFormData.value.description,
    applicationUrl: applicationFormData.value.applicationUrl,
    environments: selectedEnvironments,
    products: selectedProducts,
    ...(applicationFormData.value.redirectUrl && {
      redirectUrls: [applicationFormData.value.redirectUrl],
    }),
  };
}

function fillFormData(): void {
  applicationFormData.value = {
    name: state.application?.name,
    type: state.application?.type,
    authentication: state.application?.authentication,
    description: state.application?.description,
    applicationUrl: state.application?.applicationUrl,
    products: state.application?.products?.map(product => `${product.name} v${product.version}`),
    environments: state.application?.environments?.map(environment => environment.name),
    ...(state.application?.redirectUrls?.length > 0 && {
      redirectUrl: state.application?.redirectUrls[0],
    }),
  };
}
</script>
