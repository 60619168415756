import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = {
  key: 0,
  class: "col-6"
}
const _hoisted_4 = { class: "row mb-2" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "ml-3" }
const _hoisted_7 = {
  key: 0,
  class: "col-6"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "row mb-2" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "pl-3 ml-3 mb-0" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = { class: "pl-3 ml-3 mb-0" }
const _hoisted_14 = { class: "row mb-2" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "ml-3" }
const _hoisted_17 = {
  key: 0,
  class: "col-6"
}
const _hoisted_18 = { class: "ml-3" }
const _hoisted_19 = { class: "mt-3" }
const _hoisted_20 = { key: 0 }

import { sortBy } from 'lodash';
import type { Application } from '@apiture/client-applications-client-sdk';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { applicationTypesNames } from '../../services/apis.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getUser } from '../../services/legacy/auth.service';
import KeysListComponent from './keys-list.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'application-details.component',
  props: {
    mode: { default: 'user' },
    application: {},
    applicationProducts: {},
    keyId: {}
  },
  emits: ['onKeyActivated', 'onKeyApproved'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const user = getUser();

const applicationTypeName = applicationTypesNames.get(props.application.type);

const applicationAuthenticationName = applicationAuthenticationsNames.get(
  props.application.authentication,
);

const applicationDescriptionHtml = makeHtmlMarkdown(props.application.description);

const applicationOwners = sortBy(props.application.owners);

const showApplicationKeys = props.mode === 'admin' || props.application.owners.includes(user.email);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("b", null, "Type:", -1)),
        _createTextVNode(" " + _toDisplayString(_unref(applicationTypeName)), 1)
      ]),
      (_unref(applicationAuthenticationName))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("b", null, "Authentication:", -1)),
            _createTextVNode(" " + _toDisplayString(_unref(applicationAuthenticationName)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[4] || (_cache[4] = _createElementVNode("b", null, "Name:", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_6, _toDisplayString(props.application.name), 1)
      ]),
      (props.application.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("b", null, "Description:", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", {
              class: "ml-3 mb-n2",
              innerHTML: _unref(applicationDescriptionHtml)
            }, null, 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[8] || (_cache[8] = _createElementVNode("b", null, "Products:", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("ul", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.applicationProducts, (product) => {
            return (_openBlock(), _createElementBlock("li", { key: product }, _toDisplayString(product), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[10] || (_cache[10] = _createElementVNode("b", null, "Owners:", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("ul", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(applicationOwners), (applicationOwner) => {
            return (_openBlock(), _createElementBlock("li", { key: applicationOwner }, _toDisplayString(applicationOwner), 1))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _cache[12] || (_cache[12] = _createElementVNode("b", null, "Site URL / Download Location:", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_16, _toDisplayString(props.application.applicationUrl), 1)
      ]),
      (props.application.redirectUrls)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[14] || (_cache[14] = _createElementVNode("b", null, "Redirect URL:", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_18, _toDisplayString(props.application.redirectUrls), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_unref(showApplicationKeys))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h4", _hoisted_19, [
            _cache[16] || (_cache[16] = _createTextVNode(" API Key")),
            (!props.keyId)
              ? (_openBlock(), _createElementBlock("span", _hoisted_20, "s"))
              : _createCommentVNode("", true)
          ]),
          _createVNode(KeysListComponent, {
            mode: props.mode,
            "application-id": props.application._id,
            "key-id": props.keyId,
            type: "application",
            "product-line": "open",
            onOnKeyActivated: _cache[0] || (_cache[0] = ($event: any) => (emit('onKeyActivated', props.keyId))),
            onOnKeyApproved: _cache[1] || (_cache[1] = ($event: any) => (emit('onKeyApproved', props.keyId)))
          }, null, 8, ["mode", "application-id", "key-id"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})