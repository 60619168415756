<template>
  <div>
    <LoadingIndicatorComponent v-if="loading" />
    <FormKit
      v-else
      v-model="partnerOrganizationFormData"
      type="form"
      submit-label="Save"
      :actions="false"
      data-test="partnerOrganizationForm"
      @submit="saveOrganization()"
    >
      <div class="form-container--row">
        <FormKit
          name="tradeName"
          type="text"
          label="Company Trade Name"
          validation="length:0,128"
          outer-class="form-input--full"
          data-test="tradeName"
        />
        <FormKit
          name="domainName"
          type="text"
          label="Domain Name"
          validation="required|length:4,128"
          :disabled="true"
          outer-class="form-input--full"
          data-test="domainName"
        />
      </div>
      <FormKit
        type="group"
        name="address"
      >
        <FormKit
          type="text"
          name="addressLine1"
          label="Address Line 1"
          validation="length:0,128"
          data-test="addressLine1"
        />
        <FormKit
          type="text"
          name="addressLine2"
          label="Address Line 2"
          validation="length:0,128"
          data-test="addressLine2"
        />
        <div class="form-container--row">
          <FormKit
            type="text"
            name="city"
            label="City"
            validation="length:0,128"
            data-test="city"
          />
          <FormKit
            type="select"
            name="regionCode"
            label="State"
            validation="length:0,128"
            :options="regionCodes"
            placeholder="Select a State"
            data-test="regionCode"
          />
          <FormKit
            type="text"
            name="postalCode"
            label="Zip Code"
            data-test="postalCode"
          />
          <FormKit
            type="select"
            name="countryCode"
            label="Country"
            :options="countryCodes"
            placeholder="Select a Country"
            data-test="countryCode"
          />
        </div>
      </FormKit>
      <FormKit
        type="radio"
        name="differentMailingAddress"
        label="Different Mailing Address"
        :options="['Yes', 'No']"
        options-class="form-input--different-mailing-address"
        data-test="differentMailingAddress"
      />
      <FormKit
        v-if="partnerOrganizationFormData.differentMailingAddress === 'Yes'"
        type="group"
        name="mailingAddress"
        preserve
      >
        <FormKit
          type="text"
          name="addressLine1"
          label="Address Line 1"
          validation="length:0,128"
          data-test="mailingAddress-addressLine1"
        />
        <FormKit
          type="text"
          name="addressLine2"
          label="Address Line 2"
          validation="length:0,128"
          data-test="mailingAddress-addressLine2"
        />
        <div class="form-container--row">
          <FormKit
            type="text"
            name="city"
            label="City"
            validation="length:0,128"
            data-test="mailingAddress-city"
          />
          <FormKit
            type="select"
            name="regionCode"
            label="State"
            validation="length:0,128"
            :options="regionCodes"
            placeholder="Select a State"
            data-test="mailingAddress-state"
          />
          <FormKit
            type="text"
            name="postalCode"
            label="Zip Code"
            data-test="mailingAddress-postalCode"
          />
          <FormKit
            type="select"
            name="countryCode"
            label="Country"
            :options="countryCodes"
            placeholder="Select a Country"
            data-test="mailingAddress-country"
          />
        </div>
      </FormKit>
      <div class="form-container--row">
        <FormKit
          type="tel"
          name="phoneNumber"
          label="Phone Number"
          outer-class="form-input--full"
          data-test="phoneNumber"
        />
        <FormKit
          type="email"
          name="emailAddress"
          label="Email Address"
          validation="length:0,128"
          outer-class="form-input--full"
          data-test="emailAddress"
        />
      </div>
      <FormKit
        type="url"
        name="homeUrl"
        label="Home Url"
        validation="length:0,512|url"
        data-test="homeUrl"
      />
      <FormKit
        :disabled="loading"
        type="submit"
        data-test="submit"
      />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import {
  Organization,
  OrganizationAddress,
  EmailType,
  AddressType,
  PhoneNumberType,
} from '@apiture/client-organizations-client-sdk';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import {
  patchOrganization,
  createOrganization,
  getOrganizations,
} from '../../services/api/partner-organizations-api.service';
import { countryCodes } from '../../constants/country-codes';
import { regionCodes } from '../../constants/region-codes';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import LoadingIndicatorComponent from './loading-indicator.component.vue';

type FormAddress = OrganizationAddress | {};//eslint-disable-line

const partnerOrganizationFormData = ref<{
  domainName: string;
  tradeName: string;
  address: FormAddress;  
  differentMailingAddress: string;
  mailingAddress?: FormAddress;  
  homeUrl: string;
  emailAddress: string;
  phoneNumber: string;
}>({
  domainName: '',
  tradeName: '',
  address: {},
  differentMailingAddress: 'No',
  mailingAddress: {},
  homeUrl: '',
  emailAddress: '',
  phoneNumber: '',
});

const loading = ref<boolean>(false);

const organization = ref<Organization>();

const user = getUser();

onMounted(async () => {
  loading.value = true;

  const organizations = await getOrganizations({
    domain: user.domain,
  });

  loading.value = false;

  if (!organizations._embedded.items[0]) {
    partnerOrganizationFormData.value.domainName = getEmailDomain(user.email);
    return;
  }

  organization.value = organizations._embedded.items[0];

  fillFormData();
});

async function saveOrganization() {
  Object.assign(organization.value, getOrganizationPatch());

  try {
    loading.value = true;
    organization.value = organization.value._id
      ? await patchOrganization(organization.value, organization.value._id)
      : await createOrganization(organization.value);
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Company information has been successfully updated.',
  });

  fillFormData();
}

function getOrganizationPatch() {
  const form = partnerOrganizationFormData.value;
  const addresses = [{ ...form.address, ...{ _id: 'wao', type: AddressType.Work } }];

  if (form.differentMailingAddress === 'Yes') {
    addresses.push({
      ...form.mailingAddress,
      ...{ _id: 'mao', type: AddressType.Shipping },
    });
  }

  return {
    name: form.tradeName,
    label: form.tradeName,
    addresses,
    phones: form.phoneNumber
      ? [{ _id: 'wp0', type: PhoneNumberType.Work, number: form.phoneNumber }]
      : undefined,
    homeUrl: form.homeUrl,
    emailAddresses: form.emailAddress
      ? [{ _id: 'we0', type: EmailType.Work, value: form.emailAddress }]
      : undefined,
    preferredMailingAddressId: form.differentMailingAddress ? 'mao' : 'wa0',
    preferredPhoneId: form.phoneNumber ? 'wp0' : undefined,
    preferredEmailAddressId: form.emailAddress ? 'we0' : undefined,
  };
}

function fillFormData() {
  const mainAddress = organization.value.addresses?.filter(
    item => item.type === AddressType.Work,
  )[0];
  const mailingAddress = organization.value.addresses?.filter(
    item => item.type === AddressType.Shipping,
  )[0];
  const emailAddress = organization.value.emailAddresses?.[0]?.value;
  const phoneNumber = organization.value.phones?.filter(
    item => item.type === PhoneNumberType.Work,
  )[0]?.number;

  partnerOrganizationFormData.value = {
    domainName: emailAddress ? getEmailDomain(emailAddress) : getEmailDomain(user.email),
    tradeName: organization.value.name,
    address: mainAddress ?? {},
    differentMailingAddress: mailingAddress ? 'Yes' : 'No',
    homeUrl: organization.value.homeUrl,
    emailAddress,
    phoneNumber,
  };

  if (!mailingAddress) return;

  partnerOrganizationFormData.value.mailingAddress = {
    addressLine1: mailingAddress.addressLine1,
    addressLine2: mailingAddress.addressLine2,
    city: mailingAddress.city,
    countryCode: mailingAddress.countryCode,
    postalCode: mailingAddress.postalCode,
    regionCode: mailingAddress.regionCode,
  };
}
</script>

<style>
.form-input--different-mailing-address {
  min-height: min-content;
}
</style>
