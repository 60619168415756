import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import AWSConfigService from 'aws-sdk/clients/configservice';
import AwsSns from 'aws-sdk/clients/sns';
import bootbox from 'bootbox';
import hljs from 'highlight.js';
import jsyaml from 'js-yaml';
import jwtDecode from 'jwt-decode';
import lunr from 'lunr';
import slug from 'slug';
import { getSecureLS } from './services/storage.service';
import { initAuthService } from './services/legacy/auth.service';
import {
  apiKey$,
  env$,
  targetEnvAccessToken$,
  getOrCreateDefaultExplorerKey,
} from './services/legacy/target-env.service';
import { parse } from 'marked';

const setJQueryEvents = (): void => {
  env$.subscribe(() => {
    $(document).trigger('changed.apt.targetEnv');
  });

  apiKey$.subscribe(() => {
    $(document).trigger('changed.apt.targetEnvApiKey');
  });

  targetEnvAccessToken$.subscribe(() => {
    $(document).trigger('changed.apt.targetEnvAccessToken');
  });
};

// sorted alphabetically
const setGlobalDependencies = (): void => {
  // amazon identity classes for old cognito service
  window['AmazonCognitoIdentity'] = AmazonCognitoIdentity;

  // amazon SDK for some old pages, TODO: Investigate how it is using and remove if it should not be used
  window['AWS'] = {
    config: AWSConfigService,
    SNS: AwsSns,
  };

  window['bootbox'] = bootbox;

  // using in try it blocks
  window['hljs'] = hljs;

  // js-yaml, using in custom.js
  window['jsyaml'] = jsyaml;

  // using in index.html to decode token
  window['jwt_decode'] = jwtDecode;

  // local storage for old js files
  window['local_storage'] = getSecureLS();

  // using in _search.js
  window['lunr'] = lunr;

  // marked, using in admin js files to preview markdown
  window['markedParsed'] = parse;

  // using in create product to set tag by product name
  window['slug'] = slug;
};

const setLegacyObject = (): void => {
  // Called from /auth/login (source: ../../_auth/login.md via ../../js/login.js )
  window['legacy'] = {
    onLogin: async (): Promise<void> => {
      // Note: redirect after login ( setting window.location.href ) is managed in ../../js/login.js
      try {
        await initAuthService();
        await getOrCreateDefaultExplorerKey();
      } catch (error) {
        console.error(error);
      }
    },
  };
};

setGlobalDependencies();
setJQueryEvents();
setLegacyObject();
