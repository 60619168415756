<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.21094 14.7812V3.44506C2.95929 3.43682 2.75781 3.23022 2.75781 2.97656C2.75781 2.71768 2.96768 2.50781 3.22656 2.50781H5.59026C5.81172 1.08712 7.04062 0 8.52344 0C10.0063 0 11.2352 1.08712 11.4566 2.50781H13.9453C14.2042 2.50781 14.4141 2.71768 14.4141 2.97656C14.4141 3.23545 14.2042 3.44531 13.9453 3.44531H13.9297V14.7812C13.9297 15.4543 13.384 16 12.7109 16H4.42969C3.75659 16 3.21094 15.4543 3.21094 14.7812ZM6.54471 2.50781C6.75348 1.60803 7.56015 0.9375 8.52344 0.9375C9.48672 0.9375 10.2934 1.60803 10.5022 2.50781H6.54471ZM4.14844 14.7812V3.90625H12.9922V14.7812C12.9922 14.9366 12.8663 15.0625 12.7109 15.0625H4.42969C4.27436 15.0625 4.14844 14.9366 4.14844 14.7812ZM5.82031 6.20207V12.6562C5.82031 12.9151 6.03018 13.125 6.28906 13.125C6.54795 13.125 6.75781 12.9151 6.75781 12.6562V6.20207C6.75781 5.94319 6.54795 5.73332 6.28906 5.73332C6.03018 5.73332 5.82031 5.94319 5.82031 6.20207ZM8.13281 12.6562V6.20207C8.13281 5.94319 8.34268 5.73332 8.60156 5.73332C8.86045 5.73332 9.07031 5.94319 9.07031 6.20207V12.6562C9.07031 12.9151 8.86045 13.125 8.60156 13.125C8.34268 13.125 8.13281 12.9151 8.13281 12.6562ZM10.4453 12.6562V6.20207C10.4453 5.94319 10.6552 5.73332 10.9141 5.73332C11.1729 5.73332 11.3828 5.94319 11.3828 6.20207V12.6562C11.3828 12.9151 11.1729 13.125 10.9141 13.125C10.6552 13.125 10.4453 12.9151 10.4453 12.6562Z"
      fill="#132334"
    />
  </svg>
</template>
