import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = ["onUpdate:modelValue", "data-test", "onInput", "onKeydown", "onFocus"]

import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';
import { addToaster } from '../../../hooks/handle-toaster';


export default /*@__PURE__*/_defineComponent({
  __name: 'code.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const regex = new RegExp(/^[a-z0-9]*$/g);

const props = __props;

const inputs = ref<HTMLInputElement[]>([]);
const inputsValues = ref<string[]>([]);

function handleFocusOnClick(event: MouseEvent) {
  if (props.context.value) {
    return;
  }

  event.preventDefault();
  inputs.value[0].focus();
}

function handleInput(event: Event, index: number) {
  props.context.node.input(inputsValues.value.join(''));

  if (isInputEmpty((event.target as HTMLInputElement).value)) {
    return;
  }

  focusNext(index);
}

function handlePaste(event: ClipboardEvent) {
  event.preventDefault();

  const possibleCode = event.clipboardData.getData('Text').trim();

  if (possibleCode.length !== props.context.digits || !regex.exec(possibleCode)) {
    addToaster('error', 'Please paste a valid code.');
    return;
  }

  inputsValues.value = possibleCode.split('');
  props.context.node.input(inputsValues.value.join(''));
}

function handleKeydown(event: KeyboardEvent, index: number) {
  if (!('key' in event)) {
    return;
  }

  if (event.key === 'Tab') {
    return;
  }

  if (event.key === 'ArrowRight') {
    focusNext(index);
    return;
  }

  if (event.key === 'ArrowLeft') {
    focusPrevious(index);
    return;
  }

  if (isDeleteKeyPressed(event.key) && !isInputEmpty(inputsValues.value[index])) {
    return;
  }

  if (isDeleteKeyPressed(event.key)) {
    focusPrevious(index);
    return;
  }
}

function focusNext(index: number) {
  inputs.value[Math.min(index + 1, props.context.digits - 1)].focus();
}

function focusPrevious(index: number) {
  inputs.value[Math.max(index - 1, 0)].focus();
}

function isDeleteKeyPressed(key: string) {
  return key === 'Backspace' || key === 'Delete';
}

function placeCaretAtEndOfInput(index: number) {
  // HACK: setTimeout is needed to wait for the caret to be placed. nextTick does not work.
  setTimeout(() => {
    inputs.value[index].setSelectionRange(1, 1);
  }, 0);
}

function isInputEmpty(value: string) {
  return value === '' || value === undefined || value === null;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'input--code',
      { 'input--code-error': props.context.node.parent.context.state.errors },
    ]),
    "data-test": props.context.node.name,
    onClick: handleFocusOnClick
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.context.digits, (digit) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        ref_for: true,
        ref: 
        element => {
          inputs.value[digit - 1] = element as HTMLInputElement;
        }
      ,
        key: digit,
        "onUpdate:modelValue": ($event: any) => ((inputsValues.value[digit - 1]) = $event),
        "data-test": `${props.context.node.name}-${digit}`,
        type: "text",
        maxlength: "1",
        class: "form-input--text input--code-input",
        onInput: ($event: any) => (handleInput($event, digit - 1)),
        onKeydown: ($event: any) => (handleKeydown($event, digit - 1)),
        onPaste: handlePaste,
        onFocus: ($event: any) => (placeCaretAtEndOfInput(digit - 1))
      }, null, 40, _hoisted_2)), [
        [_vModelText, inputsValues.value[digit - 1]]
      ])
    }), 128))
  ], 10, _hoisted_1))
}
}

})