<template>
  <RepeaterContainer
    :name="props.context.node.name"
    :disable-add="false"
    @add="addDomain"
  >
    <template
      #inputs
    >
      <div
        v-for="(domain, i) in domains"
        :key="domain.id"
        class="form-container--row"
        :data-test="`${props.context.node.name}-group-${Number(i) + 1}`"
      >
        <input
          :id="`domain-${i + 1}`"
          :value="domain.value"
          type="text"
          name="domain"
          data-test="domain"
          class="form-input--full"
          @input="updateDomain($event, i)"
          @blur="validateDomain"
        >
        <RemoveButton
          :data-test="`${props.context.node.name}-group-${Number(i) + 1}-remove`"
          :label="`remove ${props.context.node.name} ${Number(i) + 1}`"
          :disabled="domains.length <= 1"
          @click="removeDomain(i)"
        />
      </div>
    </template>
    <template #add-button-content>
      <AddSvg />
    </template>
  </RepeaterContainer>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';

import { ref, watch } from 'vue';
import RepeaterContainer from './repeater-container.vue';
import AddSvg from '../svg/add-svg.component.vue';
import RemoveButton from '../buttons/remove-button.component.vue';
import { token } from '@formkit/utils';

// A basic regExp to valid that the supplied domains loosely match what is expected.
const domainRegex = /^[a-zA-Z-]+\.\S+$/g;

const props = defineProps<{
  context: FormKitFrameworkContext<string[]>;
}>();

/**
 * Creates a new domain with an ID to be used as the repeater key.
 */
function createNewDomain(domain: string) {
  return {
    value: domain,
    id: token(), // This prevents the list (v-for) from breaking when used as the key.
  }
}

// The domains will be prefilled with a single and empty domain input.
const domains = ref<{ id: string, value: string; }[]>([createNewDomain('')]);

/**
 * Add new domains that are added asynchronously to the input.
 * 
 * - This is only ran once.
 */
 watch(() => props.context.value, (newDomains, oldDomains) => {
  // The previous value has already been set, so do not update the value.
  if(oldDomains) {
    return;
  }

  domains.value = newDomains.map(newDomain => createNewDomain(newDomain));
}, { once: true });

function validateDomain() {
  if(!props.context.value){
    return;
  }

  const lastAddedDomain = props.context.value[props.context.value.length - 1];

  if(!lastAddedDomain || !lastAddedDomain.match(domainRegex)) {
    props.context.node.setErrors(`Domains must match ${domainRegex}`);
  }
}

/**
 * Adds a new domain input.
 * 
 * - This function does not alter the value of `props.context.value`.
 * - `domains` does not have a max length, so as many as desired can be added.
 */
function addDomain() {
  validateDomain();

  // Value should be empty when a new domain is added.
  props.context.node.input([...props.context.value, '']);
  domains.value.push(createNewDomain(''));
 
  // If any error messages are present, delete them.
  props.context.node.clearErrors();
}

/**
 * Removes a domain input.
 * 
 * - This function does not alter the value of `props.context.value`.
 */
function removeDomain(index: number) {
  // Remove reference from domains. This will remove the element from the page.
  domains.value.splice(index, 1);

  // Remove the domain value from the input's value
  props.context.node.input([...props.context.value].splice(index, 1));
}

/**
 * Updates the value of domains on input.
 */
function updateDomain(event: Event, index: number) {
  // TypeScript doesn't know if the Event target has a value.
  const value = (event.target as HTMLInputElement).value;

  if(!value) {
    return;
  }
 
  // If the props.context.value is present then add those values to the new list of values.
  const newDomains = props.context.value ? [...props.context.value] : [];

  newDomains[index] = value;

  props.context.node.input(newDomains);
}
</script>
