import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row--end" }
const _hoisted_2 = ["id", "data-test"]
const _hoisted_3 = ["data-test", "innerHTML"]

import { ref, onMounted } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';
import { makeHtmlMarkdown } from '../../../helpers/markdown-helper';
import { sanitize } from '../../../helpers/sanitize-helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'markdown.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const markdown = ref<string>('');
const previewToggle = ref<boolean>(false);

onMounted(() => {
  markdown.value = props.context._value;
});

function handleMarkdown() {
  previewToggle.value = false;
}

function handlePreview() {
  previewToggle.value = true;
}

function handleInput(event: Event & { target: HTMLTextAreaElement }) {
  props.context.node.input(sanitize(event.target.value));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(['form-input--desc-btn', { 'form-input--desc-btn--active': !previewToggle.value }]),
        "data-test": "viewMarkdown",
        onClick: handleMarkdown
      }, " Markdown ", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(['form-input--desc-btn', { 'form-input--desc-btn--active': previewToggle.value }]),
        "data-test": "viewPreview",
        onClick: handlePreview
      }, " Preview ", 2)
    ]),
    _withDirectives(_createElementVNode("textarea", {
      id: props.context.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((markdown).value = $event)),
      class: "form-input form-input--textarea form-input--desc",
      name: "markdown",
      "data-test": props.context.node.name,
      onInput: handleInput
    }, null, 40, _hoisted_2), [
      [_vShow, !previewToggle.value],
      [_vModelText, markdown.value]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "form-input form-input--desc h-max",
      "data-test": `${props.context.node.name}Preview`,
      innerHTML: _unref(makeHtmlMarkdown)(props.context.node.value as string)
    }, null, 8, _hoisted_3), [
      [_vShow, previewToggle.value]
    ])
  ]))
}
}

})