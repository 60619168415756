import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  href: "/auth/admin"
}

import { ref, onBeforeMount } from 'vue';
import { oktaAuthClient } from '../../services/okta-auth.service';
import { getItemInSecureStorage, setItemInSessionStorage } from '../../services/storage.service';
import { getEmailDomain } from '../../helpers/email-helper';
import type { UserClaims } from '@okta/okta-auth-js';


export default /*@__PURE__*/_defineComponent({
  __name: 'admin-login-callback.component',
  setup(__props) {

const error = ref<string>('');

onBeforeMount(async () => {
  const tokenResponse = await oktaAuthClient.token.parseFromUrl().catch(tokenError => {
    error.value = tokenError.message;
    return;
  });

  if (!tokenResponse) {
    error.value = 'Unable to get access token.';
    return;
  }

  oktaAuthClient.tokenManager.setTokens(tokenResponse.tokens);

  const user = await oktaAuthClient.token.getUserInfo();
  const accessTokenClaims = tokenResponse.tokens.accessToken.claims;
  const isAdmin = getAdminRoleByEnvironment(accessTokenClaims);

  const email = user['preferred_username'];

  setItemInSessionStorage('userRole', isAdmin ? 'Admin' : 'Developer');

  // For now map okta users to match the current cognito set up.
  // TODO: update once Okta is added for users.
  setItemInSessionStorage(
    'oktaUserData',
    JSON.stringify({
      firstName: user['given_name'],
      lastName: user['family_name'],
      middleName: '',
      suffix: '',
      birthDate: '',
      phoneNumber: '',
      citizen: true,
      certify: true,
      domain:
        typeof accessTokenClaims.partnerDomain === 'string'
          ? accessTokenClaims.partnerDomain
          : getEmailDomain(email),
      email: email.toLowerCase(),
      termsConditionsAccepted: true,
      auth: 'okta',
      role: isAdmin ? 'Admin' : 'Developer',
    }),
  );

  window.location.replace('/admin/dashboard');
});

function getAdminRoleByEnvironment(claims: UserClaims) {
  const basePathUrl = getItemInSecureStorage<string>('basePathUrl');

  if (basePathUrl === 'https://api.developer.apiture.com') {
    return Boolean(claims.isProdAdmin);
  }

  return Boolean(claims.isDevAdmin);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (error.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Something went wrong. " + _toDisplayString(error.value), 1))
      : _createCommentVNode("", true),
    (error.value)
      ? (_openBlock(), _createElementBlock("a", _hoisted_2, "Return to Login"))
      : _createCommentVNode("", true)
  ], 64))
}
}

})