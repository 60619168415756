import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-table--head" }
const _hoisted_2 = { class: "app-table--row" }
const _hoisted_3 = {
  key: 0,
  class: "app-table--loading app-table--cell"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["colspan"]
const _hoisted_7 = { class: "app-table--details" }

import LoadingIndicatorComponent from './loading-indicator.component.vue';
import { camelCase } from 'lodash';
import { ref } from 'vue';

interface TableRow {
  rowIndex: number;
  item: unknown;
  expandDetails: () => void;
  isDetailsExpanded: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'app-table.component',
  props: {
    items: {},
    busy: { type: Boolean },
    fields: {},
    tableClass: { default: '' },
    tbodyTrClass: { default: '' },
    striped: { type: Boolean, default: false }
  },
  emits: ["row-clicked"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const expandedRows = ref<number[]>([]);

function expandDetails(row: TableRow) {
  const expandedRowsIndex = expandedRows.value.findIndex(i => row.rowIndex === i);

  if (expandedRowsIndex === -1) {
    expandedRows.value.push(row.rowIndex);
  } else {
    expandedRows.value.splice(expandedRowsIndex, 1);
  }

  row.isDetailsExpanded = !row.isDetailsExpanded;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["app-table", [props.tableClass, props.striped && 'app-table--striped']])
  }, [
    _createElementVNode("thead", _hoisted_1, [
      _createElementVNode("tr", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.fields, (field, i) => {
          return (_openBlock(), _createElementBlock("th", {
            key: field.toString() + i.toString(),
            scope: "col",
            class: "app-table--cell"
          }, _toDisplayString(field), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (props.busy)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
            _createVNode(LoadingIndicatorComponent)
          ]))
        : (props.items.length < 1 && !props.busy)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_4, " There are no records to show "))
          : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          _createElementVNode("tr", {
            class: _normalizeClass(["app-table--row", props.tbodyTrClass]),
            else: "",
            onClick: ($event: any) => (
            emit('row-clicked', {
              rowIndex: Number(i),
              item,
              expandDetails: expandDetails,
              isDetailsExpanded: expandedRows.value.includes(Number(i)),
            })
          )
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.fields, (field) => {
              return (_openBlock(), _createElementBlock("td", {
                key: field,
                scope: "row",
                class: "app-table--cell"
              }, [
                _renderSlot(_ctx.$slots, `cell(${_unref(camelCase)(field)})`, {
                  row: {
                  rowIndex: Number(i),
                  item,
                  expandDetails: expandDetails,
                  isDetailsExpanded: expandedRows.value.includes(Number(i)),
                }
                }, () => [
                  _createTextVNode(_toDisplayString(item[_unref(camelCase)(field)]), 1)
                ])
              ]))
            }), 128))
          ], 10, _hoisted_5),
          (expandedRows.value.includes(Number(i)))
            ? (_openBlock(), _createElementBlock("th", {
                key: 0,
                scope: "row",
                colspan: props.fields.length
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, `row(expanded)`, {
                    row: { item }
                  })
                ])
              ], 8, _hoisted_6))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ], 2))
}
}

})