import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]

import { ref } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'phone-number.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const phoneNumber = ref<string>('');

const phoneNumberRegex = /^(\+0?1\s?)?\(?(\d{3})\)?[\s.-]?(\d{3})[\s.-]?(\d{4})$/g;
const allowedNonNumericChars = ['.', '(', ')', '+', '-'];

const props = __props;

function handleValidation(event: KeyboardEvent) {
  if (!isNaN(Number(event.key)) || event.key === 'Backspace' || event.key === 'Tab') {
    return;
  }

  if (!allowedNonNumericChars.includes(event.key)) {
    event.preventDefault();
  }
}

function handleInput() {
  props.context.node.input(phoneNumber.value);
}

function handleBlur() {
  if (!phoneNumber.value.match(phoneNumberRegex)) {
    props.context.node.setErrors(`${props.context.label} is incorrectly formatted`);
    return;
  }

  phoneNumber.value = phoneNumber.value.replace(phoneNumberRegex, '+1 ($2)-$3-$4');

  props.context.node.input(phoneNumber.value);
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phoneNumber).value = $event)),
    class: "form-input form-input--text",
    type: "tel",
    "data-test": props.context.node.name,
    autocomplete: "mobile",
    onKeydown: handleValidation,
    onInput: _cache[1] || (_cache[1] = ($event: any) => (handleInput())),
    onBlur: handleBlur
  }, null, 40, _hoisted_1)), [
    [_vModelText, phoneNumber.value]
  ])
}
}

})