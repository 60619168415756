<template>
  <nav id="portal-nav">
    <div
      id="portal-navbar"
      class="nav--navbar app-container"
    >
      <a
        href="#"
        class="nav--header-link"
      >
        <img
          src="/img/group.png"
          alt="Apiture Logo"
          class="nav--header-logo"
          width="300"
          height="32"
        >
      </a>
      <button
        v-if="includeNavigation"
        class="nav--btn-toggle"
        :aria-expanded="isExpanded"
        aria-controls="navbar"
        aria-label="Toggle navigation"
        @click="handleExpandNav()"
      >
        <HamburgerMenu v-if="!isExpanded" />
        <CloseButton v-else />
      </button>
      <ul
        v-if="includeNavigation"
        :class="[
          'nav--list nav--list-collapsable',
          isExpanded ? 'nav--collapse-show' : 'nav--collapse-hide',
        ]"
      >
        <slot
          :is-expanded="isExpanded"
          :base-url="baseUrl"
        />
      </ul>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import HamburgerMenu from './svg/hamburger-menu.component.vue';
import CloseButton from './close-button.component.vue';

const urlHref = window.location.href;
const baseUrl = window.location.origin;
const includeNavigation = !urlHref.includes('/auth/');

const isExpanded = ref(false);

function handleExpandNav() {
  isExpanded.value = !isExpanded.value;
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';
@import 'src/scss/containers';

nav {
  background-color: #003849;
  height: $app-nav-height;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  z-index: 9999;

  @include md {
    padding: 1rem 2rem;
  }

  @include lg {
    height: $app-nav-height;
  }
}

.nav--list-collapsable:after {
  width: 20%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: $app-nav-height;
  right: 0;
  content: '';

  @include md {
    content: none;
  }
}

.nav--navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav--profile-image {
  width: 2rem;
  height: 2rem;
}

.nav--collapse-show {
  display: inline-block;
}

.nav--collapse-hide {
  display: none;
}

.nav--list {
  position: fixed;
  width: 80%;
  background-color: #003849;
  top: 0;
  left: 0;
  padding: 2rem;
  color: #fff;
  height: 100vh;

  @include md {
    margin-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-color: transparent;
    padding: 0;
    color: #000;
  }

  @include lg {
    flex-direction: row;
  }
}

:deep(.nav--list > li) {
  padding: 0.5rem 0;
  list-style: none;

  > ul {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    @include md {
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      > li {
        @include md {
          padding: 0 0.5rem;
        }
      }

      > li > a {
        color: #fff;
        white-space: nowrap;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        svg {
          vertical-align: bottom;
          margin-left: 0.25rem;
        }
      }
    }
  }

  a {
    display: inherit;
    text-decoration: none;
    color: #fff;
  }

  :deep(button) {
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
  }

  .formkit-icon {
    margin-left: 0.5rem;
  }

  @include md {
    align-self: center;
  }

  @include lg {
    margin-left: 1rem;
  }
}

:deep(.nav--btn-toggle) {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  height: 3rem;
  width: 3rem;

  @include md {
    display: none;
  }
}

:deep(.nav--dropdown) {
  position: absolute;
  display: block;
  background-color: #fff;
}

:deep(.nav--dropdown-button-profile) {
  font-weight: 500;
  text-decoration: none;

  @include md {
    font-weight: 300;
    color: #fff;
  }
}

:deep(.nav--dropdown-content) {
  padding-left: 1rem;
  position: relative;
  background-color: #003849;

  li a {
    font-weight: 500;
    color: #fff;
  }

  @include md {
    padding: 1.5rem;
    position: absolute;
    margin-top: 1rem;
    box-shadow: 0px 3px 12px 0px rgba(19, 35, 52, 0.15);
    background-color: #fff;
    align-self: center;

    li a {
      color: #000;
    }
  }
}

:deep(.nav--dropdown-pointer) {
  display: none;

  @include md {
    display: block;
  }
}
</style>
