<template>
  <CodeBlockComponent
    language="json"
    :text="tokenText"
  >
    {{ tokenText }}
  </CodeBlockComponent>
</template>

<script lang="ts" setup>
import jwt from 'jsonwebtoken';
import moment from 'moment';

import { toLocalDateTime } from '../../helpers/date-helper';
import CodeBlockComponent from './code-block.component.vue';

const props = defineProps<{
  token: string;
}>();

const tokenText = getTokenText();

function getTokenText(): string {
  if (!props.token) return '';

  const decodedToken = jwt.decode(props.token);
  return (
    JSON.stringify(decodedToken, null, '  ') +
    '\n\n' +
    `  Issued At: ${formatDate(decodedToken['iat'])}` +
    '\n' +
    `  Expiration Time: ${formatDate(decodedToken['exp'])}`
  );
}

function formatDate(timestamp: number): string {
  return `${timestamp} ${toLocalDateTime(moment.unix(timestamp))}`;
}
</script>
