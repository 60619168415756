<template>
  <div
    v-for="(modal, i) in modals"
    :key="`modal-${i.toString()}`"
    class="modal disable-scrolling"
    tabindex="-1"
  >
    <transition>
      <component
        :is="modal.component"
        v-bind="modal.props"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue';
import { useModalStore } from '../../../hooks/modal-store.hook';

const modals = useModalStore();

watchEffect(() => {
  if (modals.value.length > 0) {
    document.body.classList.add('disable-scrolling');
    document.documentElement.classList.add('disable-scrolling');
  } else {
    document.body.classList.remove('disable-scrolling');
    document.documentElement.classList.remove('disable-scrolling');
  }
});
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(198, 204, 210, 0.5);
  z-index: 998;
  display: flex;
  flex-direction: column;
  margin-top: $app-nav-height;
}

:global(.disable-scrolling) {
  overflow: hidden;
  max-height: 100%;
  margin: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.15s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
