import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

import { onBeforeMount, onUnmounted } from 'vue';
import { addModal } from '../../../hooks/modal-store.hook';
import SessionExpiredModal from '../modals/session-expired-modal.component.vue';
import { useAccessTokenRefresher } from '../../../hooks/use-access-token-refresher';
import {
  clearLocalStorage,
  clearSessionStorage,
  setItemInSessionStorage,
} from '../../../services/storage.service';
import { siteOptions } from '../../../options/site-options';
import { createPageOptions } from '../../../options/page-options';
import { getUser } from '../../../services/portal-auth.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'session-handler.component',
  setup(__props) {

const pageOptions = createPageOptions();

const { accessToken, timeoutHandlerId, stopAccessTokenRefresher } = useAccessTokenRefresher({
  maxTimeoutTime: 1000 * 60 * 30, // 30 minutes
  successCallback: accessToken => {
    setItemInSessionStorage('devPortalAccessToken', accessToken);
  },
  failCallback: () => {
    createSessionExpModal();
  },
});

onBeforeMount(() => {
  const user = getUser();

  if (!siteOptions.loginEnabled || !pageOptions.auth) {
    return;
  }

  if (!user) {
    window.location.replace('/auth/login/');
  }
});

onUnmounted(() => {
  stopAccessTokenRefresher(timeoutHandlerId.value);
});

function createSessionExpModal(error?: string) {
  addModal({
    component: SessionExpiredModal,
    props: {
      error,
    },
    onComplete: () => {
      routeToLogin();
    },
    onCancel: () => {
      clearLocalStorage();
      clearSessionStorage();

      window.location.replace('/dashboard');
    },
    onError: () => {
      routeToLogin();
    },
  });
}

function routeToLogin() {
  clearLocalStorage();
  clearSessionStorage();

  window.location.replace('/auth/login/');
}

return (_ctx: any,_cache: any) => {
  return (_unref(accessToken))
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        token: _unref(accessToken)
      })
    : _createCommentVNode("", true)
}
}

})