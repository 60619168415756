import { getAccessToken, refreshUserSession } from '../services/cognito.service';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';

export type AccessTokenRefresherParams = {
  timeoutTime: number;
  successCallback: (accessToken: string) => void;
  failCallback: () => void;
};

export type AccessTokenRefresherConfig = { timeoutTime: number; timeoutHandlerId: string | null };

export async function startAccessTokenRefresher(
  accessTokenRefresherParams: AccessTokenRefresherParams,
) {
  const accessToken = await getAccessToken().catch(() => {
    accessTokenRefresherParams.failCallback();
  });

  if (!accessToken) {
    return null;
  }

  return scheduleAccessTokenRefresh(accessToken, accessTokenRefresherParams);
}

export function stopAccessTokenRefresher(timeoutHandlerId: number) {
  window.clearTimeout(timeoutHandlerId);
}

function scheduleAccessTokenRefresh(
  accessToken: CognitoAccessToken,
  accessTokenRefresherParams: AccessTokenRefresherParams,
) {
  const timeout = Math.max(
    accessToken.getExpiration() * 1000 - Date.now() - accessTokenRefresherParams.timeoutTime,
    0,
  );

  const timeoutHandlerId = window.setTimeout(async () => {
    try {
      await refreshUserSession();
      const refreshedAccessToken = await getAccessToken();
      accessTokenRefresherParams.successCallback(refreshedAccessToken.getJwtToken());

      scheduleAccessTokenRefresh(refreshedAccessToken, accessTokenRefresherParams);
    } catch {
      accessTokenRefresherParams.failCallback();
    }
  }, timeout);

  return timeoutHandlerId;
}
