import {
  EnvironmentsApi,
  EnvironmentState,
  EnvironmentType,
  Environment,
  ProductLine as ProductLineEnum,
} from '@apiture/api-environments-client-sdk';
import { PromiseCache, PromiseCacheBrowserStorage } from '../../utils/promise-cache';
import { getOptions, config, createAdapterBuilder } from './base-api.service';
import { ProductLine } from '../../utils/product-lines';

const environmentsApi = new EnvironmentsApi(config);

const environmentsCache: PromiseCache = new PromiseCache({
  storage: new PromiseCacheBrowserStorage('ENVIRONMENTS'),
  timeout: 1000 * 60, // 1 minute
});

export async function getEnvironment(environmentId: string) {
  const environmentResponse = await environmentsApi.getEnvironment(
    environmentId,
    undefined,
    await getOptions(),
  );

  return environmentResponse.data;
}

export async function getEnvironments(query?: {
  start?: string;
  limit?: number;
  host?: string;
  domain?: string;
  states?: EnvironmentState[];
  types?: EnvironmentType[];
  productLine?: ProductLine;
}) {
  const adapter = createAdapterBuilder()
    .cache({
      cache: environmentsCache,
    })
    .build();

  const environmentsResponse = await environmentsApi.getEnvironments(
    {
      start: query?.start,
      limit: query?.limit,
      host: query?.host,
      domain: query?.domain,
      state: query?.states,
      type: query?.types,
      productLine: query?.productLine as ProductLineEnum,
    },
    await getOptions({ adapter }),
  );

  return environmentsResponse.data;
}

export async function createEnvironment(environment: Environment) {
  const createEnvironmentResponse = await environmentsApi.createEnvironment(
    environment,
    await getOptions(),
  );

  return createEnvironmentResponse.data;
}

export async function updateEnvironment(environment: Environment, environmentId: string) {
  const updateEnvironmentResponse = await environmentsApi.updateEnvironment(
    environment,
    environmentId,
    undefined,
    await getOptions(),
  );

  return updateEnvironmentResponse.data;
}
