<template>
  <div class="invite-form">
    <FormKit
      v-model="organizationInviteMembersData"
      type="form"
      submit-label="Save"
      :actions="false"
      data-test="organizationInviteMembersForm"
      @submit="sendInvites()"
    >
      <FormKit
        type="text"
        name="organizationName"
        label="Organization Name"
        data-test="organizationName"
      />
      <FormKit
        type="textarea"
        name="message"
        label="Message"
        help="Include this message in the invitation email to the invitees. You may use Markdown to format this text."
        validation="required|length:16,512"
        data-test="message"
      />
      <FormKit
        type="repeater"
        name="invitees"
        fieldset-label="New Owners"
      >
        <FormKit
          type="text"
          name="firstName"
          label="First Name"
          validation="length:1,64"
          outer-class="form-input--full"
          data-test="firstName"
        />
        <FormKit
          type="text"
          name="lastName"
          label="Last Name"
          validation="length:1,64"
          outer-class="form-input--full"
          data-test="lastName"
        />
        <FormKit
          type="email"
          label="Email"
          name="emailAddress"
          :help="`Enter the invitee’s email address. The email addresses must match your partner organization's @${user.domain} domain.`"
          validation="required|length:6,128"
          outer-class="form-input--full"
          data-test="emailAddress"
          @input="updateNewOwners"
        />
      </FormKit>
      <div class="form-container--submits">
        <FormKit
          :disabled="loading"
          label="Send"
          type="submit"
          data-test="send"
        >
          Send
        </FormKit>
        <FormKit
          v-if="organizationInviteMembersData.message"
          type="button"
          input-class="form-input--button-transparent"
          :disabled="!organizationInviteMembersData.message || loading"
          data-test="previewMessage"
          @click="previewMessage()"
        >
          Preview Message
        </FormKit>
      </div>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import {
  inviteMembers,
  getOrganizations,
} from '../../services/api/partner-organizations-api.service';
import { Organization, CreateInvitation, Invitee } from '@apiture/client-organizations-client-sdk';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { FormKitNode } from '@formkit/core';

const organizationInviteMembersData = ref<{
  organizationName: string;
  message: string;
  invitees: Invitee[];
}>({
  organizationName: '',
  message: '',
  invitees: [],
});

const loading = ref<boolean>(false);
const organization = ref<Organization>();
const user = getUser();

onMounted(async () => {
  loading.value = true;
  const getOrganizationsResponse = await getOrganizations({
    domain: user.domain,
  });
  organization.value = getOrganizationsResponse._embedded.items[0];
  loading.value = false;

  fillFormData();
});

function previewMessage(): void {
  const messageHtml = makeHtmlMarkdown(organizationInviteMembersData.value.message);
  bootbox.alert({
    title: 'The following formatted text will be included in the message:',
    message: messageHtml,
  });
}

async function sendInvites(): Promise<void> {
  try {
    loading.value = true;
    await inviteMembers(organization.value._id, getInviteData());
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Invite(s) has been successfully sent.',
  });

  setTimeout(() => {
    window.location.href = '/profile/business/';
  }, 3000);
}

function updateNewOwners(emailAddress: string, formNode: FormKitNode): void {
  const userDomain = user.domain;
  if (emailAddress && getEmailDomain(emailAddress) !== userDomain) {
    formNode.setErrors(`Email address must match your @${userDomain} domain.`);
  }
}

function getInviteData(): CreateInvitation {
  return {
    message: organizationInviteMembersData.value.message,
    invitees: organizationInviteMembersData.value.invitees,
  };
}

function fillFormData() {
  organizationInviteMembersData.value = {
    organizationName: organization.value.name,
    invitees: [{ firstName: '', lastName: '', emailAddress: '' }],
    message: null,
  };
}
</script>

<style lang="scss">
// formerly a scoped style
.invite-form ::v-deep {
  .form-array-item:not(:first-child) {
    .tooltip {
      display: none;
    }
  }
}
</style>
