import type { DefaultConfigOptions } from '@formkit/vue';
import type { FormKitNode, FormKitExtendableSchemaRoot, FormKitGroupValue } from '@formkit/core';
import type { FormKitInputs } from '@formkit/inputs';
import type { AuthorizationScope } from '@apiture/api-products-client-sdk';
import type { Environment } from '@apiture/api-environments-client-sdk';
import type { Invitee } from '@apiture/client-organizations-client-sdk';

import { generateClasses } from '@formkit/themes';
import { applicationIcons, directionalIcons } from '@formkit/icons';
import { createInput, defaultConfig } from '@formkit/vue';
import InputListComponent from './src/app/components/portal/inputs/list.component.vue';
import InputMarkdownComponent from './src/app/components/portal/inputs/markdown.component.vue';
import InputRepeaterComponent from './src/app/components/portal/input-repeater.component.vue';
import ScopesInputComponent from './src/app/components/portal/scopes-input.component.vue';
import InputCodeComponent from './src/app/components/portal/inputs/code.component.vue';
import PhoneNumberInputComponent from './src/app/components/portal/inputs/phone-number.component.vue';
import EnvironmentInputComponent from './src/app/components/portal/inputs/environment.component.vue';
import InviteesInputComponent from './src/app/components/portal/inputs/invitees.component.vue';
import SelectAllInputComponent from './src/app/components/portal/inputs/select-all.component.vue';
import DomainsInputComponent from './src/app/components/portal/inputs/domains.component.vue';

declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    repeater: {
      type: 'repeater';
      fieldsetLabel: string;
      value: FormKitGroupValue[];
    };
    invitees: {
      type: 'invitees';
      value: Invitee[];
    };
    scopes: {
      type: 'scopes';
      scopes: AuthorizationScope[];
      value: string;
    };
    code: {
      type: 'code';
      digits: number;
      value: {
        excludedScopes: string[];
        excludeWriteScopes: boolean;
      };
    };
    phoneNumber: {
      type: 'phoneNumber';
      value: string;
    };
    listInput: {
      type: 'listInput';
      maxItems: number;
      value: {
        addedValues: string[];
        currentInputValue: string[];
      };
    };
    markdown: {
      type: 'markdown';
      value: string;
    };
    environment: {
      type: 'environment';
      environments: Environment[];
      includeMaximumConnectionHours: boolean;
      value: {
        id: string;
        maximumConnectionTime?: {
          days: number;
          hours: number;
        };
      };
    };
    selectAll: {
      type: 'selectAll';
      value: string[];
      xOptions: string[];
    };
    domains: {
      type: 'domains';
      value: { domain: string };
    };
  }
}

export const configOptions = {
  config: {
    classes: generateClasses({
      global: {
        messages: 'form-input--errors remove-list-bullets',
        help: 'form-input--help',
        label: 'form-input--label',
      },
      form: {
        form: 'form-container',
      },
      text: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
        inner: 'form-input-text--inner',
        prefix: 'form-input-text--prefix',
      },
      textarea: {
        input: 'form-input form-input--textarea',
      },
      checkbox: {
        options: 'form-input form-input--option-list remove-list-bullets form-input--scrollable',
        wrapper: 'form-input--wrapper',
        outer: 'form-input--option-outer',
        input: 'form-input--option',
        inner: 'form-input--option-inner',
      },
      radio: {
        options: 'form-input--option-list remove-list-bullets',
        wrapper: 'form-input--wrapper',
        outer: 'form-input--option-outer',
        input: 'form-input--option',
        inner: 'form-input--option-inner',
      },
      submit: {
        input: 'form-input--submit',
      },
      button: {
        input: 'form-input--button',
        outer: 'form-input--button-outer',
        wrapper: 'form-input--button-wrapper',
      },
      tel: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      email: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      date: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      password: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      select: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      number: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      url: {
        input: 'form-input form-input--text',
        outer: 'form-input-text--outer',
      },
      group: {
        wrapper: 'form-input--row',
      },
      search: {
        input: 'form-input--search',
        wrapper: 'form-input--search-wrapper',
      },
    }),
    icons: {
      ...applicationIcons,
      ...directionalIcons,
    },
  },
} as DefaultConfigOptions;

export const formKitConfig = defaultConfig({
  ...configOptions,
  inputs: {
    repeater: createInput(InputRepeaterComponent, {
      props: ['fieldsetLabel'],
    }),
    invitees: createInput(InviteesInputComponent),
    scopes: createInput(ScopesInputComponent, {
      props: ['scopes'],
    }),
    code: createInput(InputCodeComponent, {
      props: ['digits'],
    }),
    phoneNumber: createInput(PhoneNumberInputComponent),
    listInput: createInput(InputListComponent, {
      props: ['maxItems'],
    }),
    markdown: createInput(InputMarkdownComponent),
    environment: createInput(EnvironmentInputComponent, {
      props: ['environments', 'includeMaximumConnectionHours'],
    }),
    selectAll: createInput(SelectAllInputComponent, {
      props: ['xOptions'],
    }),
    domains: createInput(DomainsInputComponent),
  },
  plugins: [setDataTestAttrs],
});

function setDataTestAttrs(node: FormKitNode) {
  node.on('created', event => {
    const createdNode = event.payload as FormKitNode;
    const schemaFn = createdNode.props.definition?.schema as FormKitExtendableSchemaRoot;

    if (!schemaFn) {
      return;
    }

    createdNode.props.definition.schema = (sectionsSchema = {}) => {
      if (!createdNode.props.attrs['data-test']) {
        node.props.attrs['data-test'] = node.context.id ?? node.name;

        sectionsSchema.input = {
          attrs: {
            'data-test': '$id',
          },
        };
      }

      sectionsSchema.messages = {
        attrs: {
          'data-test': '$id + "-errors"',
        },
      };

      sectionsSchema.message = {
        attrs: {
          'data-test': '$id + "-error"',
        },
      };

      return schemaFn(sectionsSchema);
    };
  });
}
