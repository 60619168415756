<template>
  <Modal
    id="sessionExpiredModal"
    size="small"
    title="Session Expired"
  >
    <p>Your session has expired</p>
    <p v-if="props.error">
      {{ props.error }}
    </p>
    <template #closeModal>
      <button
        class="form-input--submit"
        data-test="sessionExpiredModal-login"
        @click="completeModal()"
      >
        Return to Login
      </button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from './modal.component.vue';
import { completeModal } from '../../../hooks/modal-store.hook';

const props = defineProps<{ error?: string }>();
</script>

<style lang="scss" scoped>
:deep(.modal--body) {
  min-height: 0;
}
</style>
