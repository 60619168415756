import { AxiosRequestConfig, AxiosPromise, AxiosAdapter, AxiosResponse } from 'axios';
import { PromiseCache } from './promise-cache';
import URI from 'urijs';
import { chain } from 'lodash';

export interface AxiosCacheSettings {
  cache: PromiseCache;
  pathParams?: object;
}

interface AxiosCache {
  data: any;
  status: number;
  statusText: string;
  headers: any;
  url: string;
  request?: any;
}

export class AxiosCacheAdapter {
  constructor(private settings: AxiosCacheSettings, private adapter: AxiosAdapter) {}

  execute(config: AxiosRequestConfig): AxiosPromise<any> {
    const uri = URI(config.url);
    const queryParams = uri.query(true);
    const cacheKey = this.getCacheKey(queryParams);

    const promiseFactory = (): Promise<AxiosCache> => {
      return this.adapter(config).then(response => {
        return {
          url: config.url,
          data: response.data,
          headers: response.headers,
          request: response.request,
          status: response.status,
          statusText: response.statusText,
        } as AxiosCache;
      });
    };

    return this.settings.cache.get(cacheKey, promiseFactory).then(cache => {
      return {
        config,
        data: cache.data,
        headers: cache.headers,
        request: cache.request,
        status: cache.status,
        statusText: cache.statusText,
      } as AxiosResponse;
    });
  }

  // TODO: Refactor
  private getCacheKey(queryParams?: object): string {
    const params = chain({ ...(queryParams || {}), ...(this.settings.pathParams || {}) })
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .values();

    return new URLSearchParams(Object.entries(params)).toString();
  }
}

export function createAxiosCacheAdapterFactory(
  settings: AxiosCacheSettings,
  adapter: AxiosAdapter,
): AxiosAdapter {
  const cacheAdapter = new AxiosCacheAdapter(settings, adapter);
  return cacheAdapter.execute.bind(cacheAdapter);
}
