<template>
  <div>
    <div
      v-if="props.enabled"
      class="panel"
      :aria-expanded="expandedInner"
    >
      <div
        class="panel-header"
        @click="toggle()"
      >
        <div
          v-if="props.header"
          class="header-text"
        >
          {{ props.header }}
        </div>
        <div class="header-icon">
          <!-- <i class="fa" :class="[expandedInner ? 'fa-chevron-down' : 'fa-chevron-right']"></i> -->
          <FormKitIcon
            v-if="expandedInner"
            icon="down"
          />
          <FormKitIcon
            v-else
            icon="right"
          />
        </div>
      </div>
      <div
        v-show="expandedInner"
        class="panel-content"
      >
        <slot v-if="injectContent" />
      </div>
    </div>
    <slot v-else />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { FormKitIcon } from '@formkit/vue';

const props = withDefaults(
  defineProps<{
    enabled?: boolean;
    header?: string;
    expanded?: 'true' | 'false';
  }>(),
  { enabled: true, expanded: 'false', header: 'Try It' },
);

const expandedInner = ref<boolean>(convertStringToBoolean(props.expanded));
const injectContent = ref<boolean>(convertStringToBoolean(props.expanded));

const emit = defineEmits(['update:expanded']);

watch(
  () => convertStringToBoolean(props.expanded),
  (newExpanded: boolean) => {
    expandedInner.value = newExpanded;
    onChange();
  },
);

function toggle(): void {
  expandedInner.value = !expandedInner.value;
  emit('update:expanded', expandedInner.value);
  onChange();
}

function onChange(): void {
  if (expandedInner.value) {
    injectContent.value = true;
  }
}

// Can't pass booleans in jekyll template 'get-started.md' for some reason.
function convertStringToBoolean(str: 'true' | 'false'): boolean {
  return str === 'true' ? true : false;
}
</script>

<style lang="scss">
.panel {
  background: rgba(191, 191, 191, 0.3);
  border: 1px solid #cccccc;
  border-radius: 4px;

  &[aria-expanded='true'] {
    .panel-header {
      border-bottom: 1px solid #cccccc;
    }
  }
}

.panel-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px 28px;

  .header-text {
    font-size: 18px;
    font-weight: bold;
  }

  .header-icon {
    flex: none;
    margin-left: auto;
  }
}

.panel-content {
  margin: 10px 0;
  padding: 0 28px;
}
</style>
