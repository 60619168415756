import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { User } from '../../models/user';
import {
  getItemInSecureStorage,
  clearLocalStorage,
  setItemInSecureStorage,
  getItemInSessionStorage,
  setItemInSessionStorage,
} from '../storage.service';
import { getAccessToken, getUserAttributes, updateUserAttributes } from '../cognito.service';
import { startAccessTokenRefresher } from '../../utils/access-token-refresher';
import { getOrganizations, createOrganization } from '../api/partner-organizations-api.service';
import { Organization, ErrorResponse } from '@apiture/client-organizations-client-sdk';
import { getEmailDomain } from '../../helpers/email-helper';
import { getOktaAccessToken } from '../okta-auth.service';
import { UserRole } from '../../models/user-role';

const userSubject = new BehaviorSubject<User>(undefined);
const accessTokenSubject = new BehaviorSubject<string>(undefined);
const accessTokenExpiredSubject = new BehaviorSubject(false);
const partnerDomainValidSubject = new ReplaySubject<boolean>(1);

(() => {
  const cognitoAccessToken = getItemInSessionStorage('devPortalAccessToken');
  const partnerDomainValid = getItemInSecureStorage<boolean>('partnerDomainValid');

  if (!cognitoAccessToken) {
    return;
  }

  fillUser();
  setAccessToken(cognitoAccessToken);

  if (partnerDomainValid !== undefined) {
    partnerDomainValidSubject.next(partnerDomainValid);
  }
})();

export const user$ = userSubject.asObservable();

export const authAccessToken$ = accessTokenSubject.asObservable();

export const accessTokenExpired$ = accessTokenExpiredSubject.asObservable();

export const partnerDomainValid$ = partnerDomainValidSubject.asObservable();

export function getUserValue() {
  return userSubject.getValue();
}

export function getAuthAccessTokenValue() {
  return accessTokenSubject.value;
}

export function getAccessTokenExpiredValue() {
  return accessTokenExpiredSubject.value;
}

export async function initAuthService(): Promise<void> {
  const devPortalAccessToken = getItemInSessionStorage('devPortalAccessToken');
  const oktaAccessToken = await getOktaAccessToken();

  if (!devPortalAccessToken && !oktaAccessToken) {
    return;
  }

  if (devPortalAccessToken) {
    try {
      const accessToken = await getAccessToken();
      setAccessToken(accessToken.getJwtToken());

      startAccessTokenRefresher({
        timeoutTime: 1000 * 60 * 30, // 30 minutes
        successCallback: accessToken => {
          setAccessToken(accessToken);
        },
        failCallback: () => {
          setAccessTokenExpired(true);
        },
      });
    } catch {
      setAccessTokenExpired(true);
      return;
    }
  }

  await checkPartnerOrganization();
}

export function authLogin(): void {
  const url = new URL(window.location.href);
  const pathnameIsOpen = url.pathname.includes('open');
  const loginUrlByUserRole =
    getItemInSessionStorage('userRole') === 'Admin' ? 'auth/admin' : 'auth/login';

  clearLocalStorage();
  window.location.replace(pathnameIsOpen ? '/openAuth/login/' : loginUrlByUserRole);
}

export function authLogout(reloadPage: boolean = true): void {
  clearLocalStorage();

  if (reloadPage) {
    window.location.reload();
  }
}

export async function updateUser(user: User): Promise<void> {
  if (user.auth !== 'cognito') {
    return;
  }

  await updateUserAttributes(user);

  const userAttributes = await getUserAttributes();
  const userData = _.chain(userAttributes).keyBy('Name').mapValues('Value').value();

  setItemInSessionStorage('userData', JSON.stringify(userData));
  fillUser();
}

export function getUser() {
  const cognitoUser = mapCognitoUserDataToUser();
  const oktaUser = getItemInSessionStorage<User>('oktaUserData');

  return cognitoUser ? cognitoUser : oktaUser;
}

/**
 * Check and create partner domain if it is not exists once after login
 * TODO: Move it to app.bootstrap.ts in scope of DX-666
 */
async function checkPartnerOrganization(): Promise<void> {
  if (getItemInSecureStorage('partnerChecked')) return;

  const user = userSubject.getValue() ?? getItemInSessionStorage('oktaUserData');

  let organization: Organization;

  try {
    const getOrganizationsResponse = await getOrganizations({
      domain: user.domain,
    });

    organization = getOrganizationsResponse._embedded.items[0];

    if (!organization) {
      try {
        organization = await createOrganization({
          name: user.domain,
        });
      } catch (createError) {
        const createErrorResponse = createError as ErrorResponse;
        // 403 error means that user email has restricted domain
        if (createErrorResponse?._error.statusCode !== 403) {
          throw createError;
        }
      }
    }
  } catch (error) {
    console.error(error);
    return;
  }

  validatePartnerDomain(organization);
  setItemInSecureStorage('partnerChecked', true);
}

/**
 * Validate partner domain, will be rewritten to devapi call in scope of DX-666
 */
function validatePartnerDomain(organization: Organization): void {
  const partnerDomainValid = Boolean(organization);

  partnerDomainValidSubject.next(partnerDomainValid);
  setItemInSecureStorage('partnerDomainValid', partnerDomainValid);
}

function setUser(user: User): void {
  userSubject.next(user);
}

function fillUser() {
  const userData = mapCognitoUserDataToUser();

  setUser(userData);
}

function setAccessToken(accessToken: string): void {
  setItemInSessionStorage('devPortalAccessToken', accessToken);
  const currentAccessToken = getAuthAccessTokenValue();

  if (currentAccessToken !== accessToken) {
    accessTokenSubject.next(accessToken);
  }
}

function setAccessTokenExpired(accessTokenExpired: boolean): void {
  const accessTokenExpiredValue = getAccessTokenExpiredValue();
  if (accessTokenExpiredValue !== accessTokenExpired) {
    accessTokenExpiredSubject.next(accessTokenExpired);
  }
}

function mapCognitoUserDataToUser() {
  const userData = getItemInSessionStorage('userData');
  const email = getItemInSessionStorage('email');

  if (!userData || !email) {
    return undefined;
  }

  return {
    firstName: userData['given_name'],
    lastName: userData['family_name'],
    middleName: userData['middle_name'],
    suffix: userData['custom:suffix'],
    birthDate: userData['birthdate'],
    phoneNumber: userData['phone_number'],
    citizen: userData['custom:usCitizen'] === 'Yes',
    certify: userData['custom:certify'] === 'true',
    domain: getEmailDomain(email),
    email,
    role: getItemInSessionStorage<UserRole>('userRole'),
    termsConditionsAccepted: userData['custom:tnc'] === 'true',
    auth: 'cognito',
  } as User;
}
