import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import fileSaver from 'file-saver';
import { getApiReference } from '../../services/apis.service';
import { ApiReferenceType } from '../../models/api-reference-type';
import { ApiReferenceDocRoots } from '../../models/api-doc-roots';
import { getEnvValue } from '../../services/legacy/target-env.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'api-reference-download.component',
  props: {
    api: {},
    version: {},
    type: {},
    title: {},
    docRoot: {}
  },
  setup(__props: any) {

const props = __props;

const mimeTypesMap = new Map<string, string>([
  ['json', 'application/json'],
  ['yaml', 'text/vnd.yaml'],
]);

async function download(): Promise<void> {
  const env = getEnvValue();
  const reference = await getApiReference({
    docRoot: props.docRoot,
    apiHost: env.apiHost,
    api: props.api,
    version: props.version,
    type: props.type,
  });

  fileSaver.saveAs(
    new Blob([reference], { type: mimeTypesMap.get(props.type) }),
    `${props.api}_${props.version}.${props.type.toLocaleLowerCase()}`,
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: "javascript:",
    class: "text-link",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (download()))
  }, _toDisplayString(props.title), 1))
}
}

})