<template>
  <div
    v-if="props.openModal"
    :id="props.id"
    class="modal modal--fade"
    :class="[props.openModal ? 'modal--show' : 'modal-hide']"
    tabindex="-1"
  >
    <div class="modal--dialog">
      <div class="modal--header">
        <h2
          v-if="props.title"
          class="modal-title"
        >
          {{ props.title }}
        </h2>
        <FormKit
          v-if="!props.removeCloseBtn"
          type="button"
          prefix-icon="close"
          label=" "
          :aria-label="`close ${props.id}`"
          input-class="form-input--button-transparent"
          @click="emit('onModalClose')"
        />
      </div>
      <div class="modal--body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue';

const props = withDefaults(
  defineProps<{
    id: string;
    title?: string;
    openModal: boolean;
    removeCloseBtn?: boolean;
  }>(),
  { title: '', removeCloseBtn: false },
);

const emit = defineEmits(['onModalClose']);

watchEffect(() => {
  if (props.openModal) document.body.classList.add('disable-scrolling');
  else document.body.classList.remove('disable-scrolling');
});
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.modal {
  position: fixed;
  inset: 0;
}

.modal--dialog {
  background-color: $app-neutral-white;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  max-height: 90vh;
  overflow-y: auto;
  min-height: 15rem;

  @include lg {
    max-width: 60rem;
    min-width: 40rem;
  }

  @include xl {
    max-width: 75rem;
    min-width: 40rem;
  }
}

.modal--header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $app-neutral-gray-20;
  padding: 0rem 1.5rem 0rem 2rem;
}

.modal--show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal--hide {
  display: none;
  opacity: 0;
}

.modal--fade {
  transition: opacity 0.25s linear;
}

.disable-scrolling {
  overflow: hidden;
}

:deep(p) {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0;
}
</style>
