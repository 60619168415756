<template>
  <footer>
    <div class="footer--text">
      <h1>API<span>TURE</span></h1>
      <BorderSvg />
      <a
        class="footer--link"
        href="https://www.apiture.com/privacy-policy/"
        target="_blank"
      >Privacy Policy</a>
      <BorderSvg />
      <a
        class="footer--link"
        href="https://developer.apiture.com/docs/Apiture-API-License-Agreement.pdf"
        target="_blank"
      >Terms & Conditions</a>
    </div>
    <p class="footer--date">
      Apiture Developer Portal &copy; {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>

<script setup lang="ts">
import BorderSvg from './svg/border-svg.component.vue';
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

footer {
  width: 100%;
  height: auto;
  padding: 0.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;

  @include md {
    padding: 0 2rem;
    flex-direction: row;
    height: $app-footer-height;
  }
}

.footer--text h1 {
  font-size: medium;
  background: linear-gradient(270deg, #ffa300 -4.44%, #aa0061 106.85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.2rem;

  span {
    font-weight: 300;
  }
}

.footer--text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer--link {
  text-decoration: none;
  color: #000;
}

.footer--link,
.footer--date {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.footer--svg {
  margin: 0 0.5rem 0 0.5rem;

  @include md {
    margin: 0 1rem 0 1rem;
  }
}
</style>
