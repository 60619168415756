// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input--repeater[data-v-3eb2c12a] {
  max-height: 30rem;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form-input--repeater-button[data-v-3eb2c12a] {
  background-color: transparent;
}
.form-input--repeater-button span[data-v-3eb2c12a] {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/input-repeater.component.vue","webpack://./input-repeater.component.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;ACAF;ADGA;EACE,6BAAA;ACAF;ADEE;EACE,SAAA;ACAJ","sourcesContent":["\n.form-input--repeater {\n  max-height: 30rem;\n  width: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.form-input--repeater-button {\n  background-color: transparent;\n\n  span {\n    margin: 0;\n  }\n}\n",".form-input--repeater {\n  max-height: 30rem;\n  width: 100%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.form-input--repeater-button {\n  background-color: transparent;\n}\n.form-input--repeater-button span {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
