<template>
  <div
    class="app-alert"
    :class="!props.visible ? (props.affectLayoutIfInvisible ? 'invisible' : 'hide') : ''"
    role="alert"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    visible?: boolean;
    affectLayoutIfInvisible?: boolean;
  }>(),
  {
    visible: true,
    affectLayoutIfInvisible: false,
  },
);
</script>

<style lang="scss">
.app-alert {
  background-color: #b6effb;
  color: #055160;
  border-radius: 0.25rem;
  width: 100%;
  border: none;
  padding: 1rem;
  margin-bottom: 1rem;
}

.invisible {
  visibility: hidden;
}

.hide {
  display: none;
}
</style>
