<template>
  <div
    :id="props.id"
    ref="eventRef"
  >
    <div class="d-none">
      <slot />
    </div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <template v-else>
      <FormKit
        v-model="state.url"
        label="Operation"
        type="text"
        outer-class="form-input--full"
        disabled
        :sections-schema="{
          prefix: {
            $el: 'div',
            attrs: {
              class: '$classes.prefix',
            },
            children: state.method,
          },
        }"
      />
      <FormKit
        :id="props.id + 'form'"
        v-model="formData"
        type="form"
        :actions="false"
        form-class="mt-3"
        @submit="request"
      >
        <FormKit
          type="group"
          name="headers"
        >
          <h4>Header Requests</h4>
          <FormKit
            v-if="state.authentication.enabled && props.authentication !== 'hidden'"
            type="button"
            class="btn btn-secondary btn-sm ml-auto"
            @click="() => (state.authentication.collapsed = !state.authentication.collapsed)"
          >
            {{ state.authentication.collapsed ? 'Show' : 'Hide' }} Authentication
          </FormKit>
          <FormKitSchema
            :schema="generateHeadersForm()"
            :library="library"
          />
        </FormKit>
        <FormKit
          type="group"
          name="parameters"
        >
          <h4>Parameters</h4>
          <FormKitSchema
            :schema="generateParametersForm()"
            :library="library"
          >
            <!-- <template #default="{ help }: { help: any }">
              <div v-html="makeHtmlMarkdown(help)"></div>
            </template> -->
          </FormKitSchema>
        </FormKit>
        <FormKit
          type="group"
          name="body"
        >
          <h4 v-if="state.hasFormBody">
            Body
          </h4>
          <FormKitSchema
            :schema="generateBodyForm()"
            :library="library"
          >
            <!-- <template #default="{ help }: { help: any }">
              <div v-html="makeHtmlMarkdown(help)"></div>
            </template> -->
          </FormKitSchema>
        </FormKit>
        <div class="form-container--submits">
          <FormKit
            :disabled="!state.url || state.loading"
            label="Try It"
            type="submit"
          />
          <FormKit
            type="button"
            input-class="form-input--button-transparent"
            outer-class="align-self-center"
            :disabled="state.loading"
            label="Reset"
            @click="reset()"
          />
        </div>
      </FormKit>
      <div
        v-if="state.response.text"
        class="mt-3"
      >
        <h3>API Response</h3>
        <div class="row align-items-start">
          <h4>
            Response Code:
            <a
              v-if="state.response.code"
              :href="`https://httpstatuses.com/${state.response.code}`"
            >
              {{ state.response.code }}</a>
          </h4>
        </div>
        <h4>Response Headers</h4>
        <CodeBlockComponent :text="state.response.headersText" />
        <h4>Response Body</h4>
        <FormKit
          type="radio"
          :options="['JSON', 'YAML']"
          value="JSON"
          @input="updateResponseType"
        />
        <CodeBlockComponent :text="state.response.text" />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, onMounted, ref, markRaw } from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { last, chain } from 'lodash';
import jsYaml from 'js-yaml';
import URI from 'urijs';
import { combineLatestWith, take, Subject, finalize, takeUntil, map } from 'rxjs';
import { getApiDocument, getApis, Api } from '../../services/apis.service';
import { apiKey$, env$, targetEnvAccessToken$ } from '../../services/legacy/target-env.service';
import { TargetEnv } from '../../models/target-env';
import { showToastr } from '../../services/legacy/toastr.service';
import { ApiReferenceDocRoots } from '../../models/api-doc-roots';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import CodeBlockComponent from './code-block.component.vue';
import { FormKitSchema } from '@formkit/vue';
import { FormKitSchemaFormKit, reset as formkitReset, FormKitSchemaComponent } from '@formkit/core';
import AppHtmlMarkdownComponent from './app-html-markdown.component.vue';

type ApiTryAuthentication = 'show' | 'hidden' | 'collapsed';
type ApiTryItParams = { [key: string]: string | string[] };

interface ApiParameterSchema {
  type?: 'integer' | 'number' | 'string' | 'boolean';
  default?: number | string;
  minLength?: number;
  maxLength?: number;
  minimum?: number;
  maximum?: number;
}

interface ApiParameter extends ApiParameterSchema {
  in: 'query' | 'header' | 'path' | 'query' | 'body';
  name: string;
  description: string;
  required?: boolean;
  schema?: ApiParameterSchema; // openapi v3.0
}

const props = withDefaults(
  defineProps<{
    id?: string;
    api?: string;
    version?: string;
    operationId: string;
    authentication?: ApiTryAuthentication;
    params?: string;
    visibleParams?: string;
    docRoot: ApiReferenceDocRoots;
  }>(),
  {
    authentication: 'collapsed',
    id: '',
    api: '',
    version: '',
    params: '',
    visibleParams: null,
  },
);

const formData = ref<{
  headers: {
    accept?: string;
    'Content-Type'?: string;//eslint-disable-line
    'api-key'?: string;//eslint-disable-line
    token?: string;
  };
  parameters: {};//eslint-disable-line
  body: {};//eslint-disable-line
}>({
  headers: {},
  parameters: {},
  body: {},
});

const eventRef = ref<HTMLElement>(null);

const emit = defineEmits(['onRequestSuccess', 'onRequestError']);

const destroySubject = new Subject();

const state = ref({
  response: {
    initial: null,
    text: null,
    code: null,
    headersText: null,
  },
  authentication: {
    enabled: false,
    collapsed: true,
  },
  initialized: false,
  loading: false,
  url: null,
  method: null,
  parameters: [],
  path: null,
  operation: null,
  versionInner: null,
  hasFormBody: false,
});

const library = markRaw({
  AppHtmlMarkdownComponent: AppHtmlMarkdownComponent,
});

onMounted(async () => {
  state.value.versionInner = props.version;
  if (!state.value.versionInner) {
    const apis = await getApis();
    const foundApi: Api = apis.find((item: Api) => item.apiName === props.api);

    state.value.versionInner = last(foundApi.versions);
  }

  await parse();

  env$.pipe(takeUntil(destroySubject)).subscribe(targetEnv => {
    refreshEnv(targetEnv);
  });

  apiKey$.pipe(takeUntil(destroySubject)).subscribe(apiKey => {
    refreshApiKey(apiKey);
  });

  targetEnvAccessToken$.pipe(takeUntil(destroySubject)).subscribe(token => {
    refreshToken(token);
  });

  env$
    .pipe(
      combineLatestWith([apiKey$, targetEnvAccessToken$]),
      map(([apiKey, targetEnvAccessToken]) => [apiKey, targetEnvAccessToken]),
      take(1),
      finalize(() => {
        state.value.initialized = true;
      }),
    )
    .subscribe(() => {
      const parsedParams = parseParams(props.params);
      setParams(parsedParams);
    });
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function updateResponseType(responseType: 'json' | 'yaml'): void {
  const responseTypeLC = responseType.toLowerCase();
  if (responseTypeLC === 'json') {
    state.value.response.text = JSON.stringify(state.value.response.initial, undefined, ' ');
  }

  if (responseTypeLC === 'yaml') {
    try {
      const tempResponse = jsYaml.dump(state.value.response.initial);
      state.value.response.text = tempResponse;
    } catch (error) {
      showToastr({
        message: `Please check the format. ${error.message}`,
        type: 'error',
      });
    }
  }
}

function setParams(params: ApiTryItParams): void {
  if (!params) return;

  for (const key in params) {
    if (!Object.prototype.hasOwnProperty.call(formData.value['parameters'], key)) {
      formData.value[key] = params[key];
    }
  }
}

async function request(): Promise<void> {
  const url = state.value.url;
  let responseHeaders: AxiosResponse['headers'];

  const headers = {
    Authorization: `Bearer ${formData.value['headers']['token']}`,
    accept: formData.value['headers']['accept'],
    'api-key': formData.value['headers']['api-key'],//eslint-disable-line
  };

  const params = {
    query: formData.value['parameters']['query'],
  };

  try {
    state.value.loading = true;
    const axiosResponse = (await axios({
      url,
      headers,
      params,
      data: formData.value['body'],
      method: state.value.method,
    })) as AxiosResponse<{
      _embedded: { items: { _id: string }[] };
    }>;

    if (axiosResponse.data._embedded && axiosResponse.data._embedded.items[0]) {
      var accountId = axiosResponse.data._embedded.items[0]._id;
      setParams({ accountId: accountId });
      setParams({ account: accountId });
    }

    eventRef.value.dispatchEvent(new CustomEvent(`${props.id}Success`, { detail: axiosResponse }));

    emit('onRequestSuccess', axiosResponse);

    state.value.response.initial = axiosResponse.data;
    state.value.response.text = JSON.stringify(axiosResponse.data, undefined, ' ');
    state.value.response.code = axiosResponse.status;
    responseHeaders = axiosResponse.headers;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (!axiosError.response) {
      showToastr({
        message: `The API call failed. Please try again later.`,
        type: 'error',
      });
      return;
    }
    emit('onRequestError', axiosError);
    state.value.response.initial = axiosError.response.data;
    state.value.response.text = JSON.stringify(axiosError.response.data, undefined, ' ');
    state.value.response.code = axiosError.response.status;
    responseHeaders = axiosError.response.headers;
  } finally {
    state.value.loading = false;
    state.value.response.headersText = JSON.stringify(responseHeaders, null, '  ');
  }
}

function reset(): void {
  state.value.response.initial = undefined;
  state.value.response.text = null;
  state.value.response.code = null;
  state.value.response.headersText = null;

  apiKey$
    .pipe(
      combineLatestWith([targetEnvAccessToken$]),
      map(([apiKey, targetEnvAccessToken]) => [apiKey, targetEnvAccessToken]),
      take(1),
    )
    .subscribe(([apiKey, token]: [string, string]) => {
      refreshApiKey(apiKey);
      refreshToken(token);
      formkitReset(props.id + 'form');
    });
}

function refreshToken(token: string): void {
  formData.value.headers['token'] = token;
}

function refreshApiKey(apiKey: string): void {
  formData.value.headers['api-key'] = apiKey;
}

function refreshEnv(env: TargetEnv): void {
  state.value.url = env.apiHost ? `https://${env.apiHost}/${props.api}${state.value.path}` : '';
}

function parseParams(params: string): ApiTryItParams {
  if (params) {
    const parsedParams = URI.parseQuery(`?${params}`);
    return parsedParams as ApiTryItParams;
  }
  return undefined;
}

async function parse(): Promise<void> {
  const document = await getApiDocument(props.docRoot, props.api, state.value.versionInner);

  state.value.path = Object.keys(document.paths).find((path: string) => {
    return Object.keys(document.paths[path]).some((method: string) => {
      const operationId = document.paths[path][method].operationId;
      return operationId === props.operationId;
    });
  });

  if (!state.value.path) {
    throw new Error(`operationId '${props.operationId}' has not been found.`);
  }

  const operations = document.paths[state.value.path];
  state.value.parameters = operations.parameters ?? [];
  const operationKey = Object.keys(operations).find(element => {
    return operations[element].operationId === props.operationId;
  });

  state.value.operation = operations[operationKey];
  state.value.parameters = state.value.parameters.concat(state.value.operation.parameters ?? []);
  state.value.method = operationKey.toUpperCase() || 'GET';

  generateHeadersForm();
  generateParametersForm();
  generateBodyForm();
}

function generateHeadersForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  const headersForm: (FormKitSchemaFormKit | FormKitSchemaComponent)[] = [];
  const produces = getProduces();
  const contentTypes = getContentTypes();
  const securitySchema = state.value.operation.security?.[0];
  const headerParameters = state.value.parameters.filter(parameter => parameter.in === 'header');
  const visibleParamsParsed = parseVisibleParams();

  if (produces) {
    headersForm.push({
      $formkit: 'select',
      label: 'Accept',
      name: 'accept',
      required: true,
      options: [...produces],
    });
    formData.value['headers']['accept'] = produces[0];
  }

  if (contentTypes) {
    headersForm.push({
      $formkit: 'select',
      title: 'Content-Type',
      name: 'Content-Type',
      required: true,
      enum: [...contentTypes],
    });
    formData.value['headers']['Content-Type'] = contentTypes[0];
  }

  if (securitySchema) {
    if (securitySchema['apiKey']) {
      state.value.authentication.enabled = true;
      headersForm.push({
        $formkit: state.value.authentication.collapsed ? 'hidden' : 'password',
        title: 'API-Key',
        name: 'api-key',
        required: true,
      });
    }
    if (securitySchema['accessToken']) {
      state.value.authentication.enabled = true;
      headersForm.push({
        $formkit: state.value.authentication.collapsed ? 'hidden' : 'password',
        title: 'Authorization (Bearer access token)',
        name: 'token',
        required: true,
      });
    }
  }

  headerParameters.forEach(parameter => {
    const formSchemaLength = headersForm.push(getParameterSchema(parameter));
    if (visibleParamsParsed && !visibleParamsParsed?.includes(parameter.name)) {
      headersForm[formSchemaLength - 1]['$formkit'] = 'hidden';
    } else {
      headersForm.push({
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: parameter.description,
        },
      });
    }
  });

  return headersForm;
}

function generateParametersForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  const parameterForm: (FormKitSchemaFormKit | FormKitSchemaComponent)[] = [];
  const formParameters = state.value.parameters.filter(parameter => parameter.in !== 'header');
  const visibleParamsParsed = parseVisibleParams();

  formParameters.forEach(parameter => {
    const formSchemaLength = parameterForm.push(getParameterSchema(parameter));

    if (parameter.in === 'body') {
      parameterForm[formSchemaLength - 1]['$formkit'] = 'textarea';
    }

    parameterForm[formSchemaLength - 1]['placeholder'] = parameter.default;

    if (visibleParamsParsed && !visibleParamsParsed?.includes(parameter.name)) {
      parameterForm[formSchemaLength - 1]['$formkit'] = 'hidden';
    } else {
      parameterForm.push({
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: parameter.description,
        },
      });
    }
  });

  return parameterForm;
}

function generateBodyForm(): (FormKitSchemaFormKit | FormKitSchemaComponent)[] {
  if (!state.value.operation.requestBody) return [];

  const contentType = formData.value['contentType'];
  if (!contentType) {
    state.value.hasFormBody = false;
    return [];
  }

  state.value.hasFormBody = true;

  // openapi v3.0
  if (state.value.operation.requestBody) {
    const requestBodySchema = state.value.operation.requestBody.content[contentType].schema;
    return [
      {
        $formkit: 'text',
        required: Boolean(requestBodySchema.required?.length),
        title: requestBodySchema.title,
      },
      {
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: requestBodySchema.description,
        },
      },
    ];
  } else {
    // openapi v2.0
    const bodyParameter = state.value.parameters.find(p => p.in === 'body');
    return [
      {
        $formkit: 'text',
        required: bodyParameter.required,
        title: bodyParameter.name,
      },
      {
        $cmp: 'AppHtmlMarkdownComponent',
        props: {
          html: bodyParameter.description,
        },
      },
    ];
  }
}

function getParameterSchema(parameter: ApiParameter): FormKitSchemaFormKit {
  const parameterFormSchema: FormKitSchemaFormKit = { $formkit: 'text' };
  const parameterSchema = parameter.schema ?? parameter;
  switch (parameterSchema.type) {
    case 'boolean':
      parameterFormSchema.$formkit = 'checkbox';
      break;
    case 'integer':
    case 'number':
      parameterFormSchema.$formkit = 'number';
      parameterFormSchema.min = parameterSchema.minimum;
      parameterFormSchema.max = parameterSchema.maximum;
      break;
    case 'string':
    default:
      parameterFormSchema.minlength = parameterSchema.minLength;
      parameterFormSchema.maxlength = parameterSchema.maxLength;
  }

  return {
    ...parameterFormSchema,
    label: parameter.in === 'path' ? `{${parameter.name}}` : parameter.name,
    name: parameter.in === 'path' ? `{${parameter.name}}` : parameter.name,
    required: parameter.required,
    // help: parameter.description,
    placeholder: parameter.in,
  };
}

function getProduces(): string[] {
  if (state.value.operation.produces) {
    // openapi v2.0
    return state.value.operation.produces;
  } else if (state.value.operation.responses) {
    // openapi v3.0
    return chain(state.value.operation.responses)
      .map(v => (v.content ? Object.keys(v.content) : []))
      .flatMap()
      .uniq()
      .value();
  }
  return [];
}

function getContentTypes(): string[] {
  // openapi v3.0
  if (state.value.operation.requestBody) {
    return Object.keys(state.value.operation.requestBody.content);
  }
  // openapi v2.0
  if (state.value.operation.consumes) {
    return state.value.operation.consumes;
  } else if (state.value.parameters.find(p => p.in === 'body')) {
    return ['application/json'];
  }

  return null;
}

function parseVisibleParams(): string[] {
  if (!props.visibleParams) return null;
  return props.visibleParams.split(',').map(item => item.trim());
}
</script>
