import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-test"]

import type { FormKitNode, FormKitFrameworkContext } from '@formkit/core';

import { ref, watch, computed } from 'vue';
import RepeaterContainer from './repeater-container.vue';
import { getUser } from '../../../services/portal-auth.service';
import { getEmailDomain } from '../../../helpers/email-helper';
import { Invitee } from '@apiture/client-organizations-client-sdk';
import AddSvg from '../svg/add-svg.component.vue';
import { token } from '@formkit/utils';
import { getNode } from '@formkit/core';
import RemoveButton from '../buttons/remove-button.component.vue';

const max = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'invitees.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const user = getUser();

const invitees = ref<({ id: string } & Invitee)[]>([
  { id: token(), firstName: '', lastName: '', emailAddress: '' },
]);

const inviteeNodeIds = ref<string[]>([]);

const addInviteeDisabled = computed(() => {
  if (
    invitees.value.some(invitee => !invitee.firstName || !invitee.lastName || !invitee.emailAddress)
  ) {
    return true;
  }

  const inviteeNodesValidity = inviteeNodeIds.value.map(
    inviteeNodeId => getNode<string>(inviteeNodeId)?.context?.state.valid,
  );

  return inviteeNodesValidity.includes(false) || invitees.value.length >= max;
});

watch(
  () => invitees.value,
  async newInviteesWithId => {
    const newInvitees = newInviteesWithId.map(newInvitee => {
      return {
        firstName: newInvitee.firstName,
        lastName: newInvitee.lastName,
        emailAddress: newInvitee.emailAddress,
      };
    });

    await props.context.node.input(newInvitees);
  },
  { deep: true },
);

function addInvitee() {
  if (addInviteeDisabled.value) {
    return;
  }

  invitees.value.push({ id: token(), firstName: '', lastName: '', emailAddress: '' });
}

function removeInvitee(index: number) {
  invitees.value.splice(index, 1);
}

function emailDomainsMatch(node: FormKitNode<string>) {
  const userDomain = user.domain;
  const emailDomain = getEmailDomain(node.value);

  return emailDomain && emailDomain === userDomain;
}

function handleNodeAdded(node: FormKitNode<string>) {
  if (!node.context?.id) {
    return;
  }

  inviteeNodeIds.value.push(node.context.id);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(RepeaterContainer, {
    name: props.context.node.name,
    "disable-add": addInviteeDisabled.value,
    onAdd: addInvitee
  }, {
    inputs: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invitees.value, (invitee, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: invitee.id,
          "data-test": `${props.context.node.name}-group-${Number(index) + 1}`,
          class: "d-inline-flex gap-xs"
        }, [
          _createVNode(_component_FormKit, {
            id: `invitee-${index + 1}-firstName`,
            modelValue: invitees.value[index].firstName,
            "onUpdate:modelValue": ($event: any) => ((invitees.value[index].firstName) = $event),
            type: "text",
            name: "firstName",
            label: "First Name",
            validation: "required|length:1,64",
            "outer-class": "form-input--full",
            "data-test": `invitee-${index + 1}-firstName`,
            ignore: "",
            delay: 250,
            "sections-schema": {
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-firstName-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-firstName-error`,
              },
            },
          },
            onNode: handleNodeAdded
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "data-test", "sections-schema"]),
          _createVNode(_component_FormKit, {
            id: `invitee-${index + 1}-lastName`,
            modelValue: invitees.value[index].lastName,
            "onUpdate:modelValue": ($event: any) => ((invitees.value[index].lastName) = $event),
            type: "text",
            name: "lastName",
            label: "Last Name",
            validation: "required|length:1,64",
            "outer-class": "form-input--full",
            "data-test": `invitee-${index + 1}-lastName`,
            ignore: "",
            delay: 250,
            "sections-schema": {
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-lastName-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-lastName-error`,
              },
            },
          },
            onNode: handleNodeAdded
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "data-test", "sections-schema"]),
          _createVNode(_component_FormKit, {
            id: `invitee-${index + 1}-emailAddress`,
            modelValue: invitees.value[index].emailAddress,
            "onUpdate:modelValue": ($event: any) => ((invitees.value[index].emailAddress) = $event),
            type: "email",
            label: "Email",
            name: "emailAddress",
            validation: "required|email|length:6,128|emailDomainsMatch",
            "outer-class": "form-input--full",
            "validation-rules": { emailDomainsMatch: emailDomainsMatch },
            "validation-messages": {
            emailDomainsMatch: `Email address must match your @${_unref(user).domain} domain.`,
          },
            ignore: "",
            delay: 250,
            "data-test": `invitee-${index + 1}-emailAddress`,
            "sections-schema": {
            messages: {
              attrs: {
                'data-test': `invitee-${index + 1}-emailAddress-errors`,
              },
            },
            message: {
              attrs: {
                'data-test': `invitee-${index + 1}-emailAddress-error`,
              },
            },
          },
            onNode: handleNodeAdded
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "validation-rules", "validation-messages", "data-test", "sections-schema"]),
          _createVNode(RemoveButton, {
            "data-test": `${props.context.node.name}-group-${Number(index) + 1}-remove`,
            label: `remove ${props.context.node.name} ${Number(index) + 1}`,
            onClick: ($event: any) => (removeInvitee(index))
          }, null, 8, ["data-test", "label", "onClick"])
        ], 8, _hoisted_1))
      }), 128))
    ]),
    "add-button-content": _withCtx(() => [
      _createVNode(AddSvg),
      _cache[0] || (_cache[0] = _createTextVNode(" Add Owners "))
    ]),
    _: 1
  }, 8, ["name", "disable-add"]))
}
}

})