<template>
  <span
    class="d-inline-flex"
    :data-test="props.id"
  >
    <p v-if="visible">
      {{ props.value }}
    </p>
    <template v-else>
      <p>
        {{ props.specialCharacter.repeat(props.specialCharactersCount) }}
      </p>
    </template>
    <FormKit
      :id="props.id + 'ToggleVisibility'"
      type="button"
      input-class="form-input--button-transparent"
      @click="toggle()"
    ><EyeSvg /></FormKit>
    <ClipboardCopyComponent
      :id="props.id"
      :title="props.title"
      :value="props.value"
    />
  </span>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import ClipboardCopyComponent from '../legacy/clipboard-copy.component.vue';
import EyeSvg from './svg/eye-svg.component.vue';

const props = withDefaults(
  defineProps<{
    id: string;
    title: string;
    value: string;
    specialCharacter?: string;
    specialCharactersCount?: number;
  }>(),
  { specialCharacter: '.', specialCharactersCount: 25 },
);

const visible = ref<boolean>(false);

function toggle(): void {
  visible.value = !visible.value;
}
</script>

<style lang="scss" scoped>
p {
  font-weight: 400;
  line-height: 1.5rem;
  word-break: break-all;
}

div {
  align-self: start;
}
</style>
