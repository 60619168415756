import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { class: "modal--header" }
const _hoisted_3 = ["data-loaded"]
const _hoisted_4 = {
  key: 0,
  class: "modal--footer"
}
const _hoisted_5 = ["data-test"]

import { closeModal, cancelModal } from '../../../hooks/modal-store.hook';
import CloseButton from '../close-button.component.vue';
import Header1 from '../headers/header-1.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'modal.component',
  props: {
    title: { default: '' },
    id: {},
    size: {},
    includeFooter: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  emits: ["close", "cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'modal--dialog',
      {
        'modal--dialog--large': props.size === 'large',
        'modal--dialog--small': props.size === 'small',
      },
    ]),
    "data-test": props.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      (props.title)
        ? (_openBlock(), _createBlock(Header1, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(CloseButton, {
        "test-id": `${props.id}-cancel`,
        onClick: _cache[0] || (_cache[0] = 
          () => {
            emit('cancel');
            _unref(cancelModal)();
          }
        )
      }, null, 8, ["test-id"])
    ]),
    _createElementVNode("div", {
      class: "modal--body",
      "data-loaded": !props.loading
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3),
    (props.includeFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "closeModal", {}, () => [
            _createElementVNode("button", {
              class: "form-input--submit",
              "data-test": `${props.id}-close`,
              onClick: _cache[1] || (_cache[1] = 
            () => {
              emit('close');
              _unref(closeModal)();
            }
          )
            }, " Close ", 8, _hoisted_5)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})