import {
  Product,
  APIProductApi,
  ProductState,
  ProductLine as ProductLineEnum,
} from '@apiture/api-products-client-sdk';
import { PromiseCache, PromiseCacheBrowserStorage } from '../../utils/promise-cache';
import { getOptions, createAdapterBuilder, config } from './base-api.service';
import { ProductLine } from '../../utils/product-lines';

const productsApi = new APIProductApi(config);

const productsCache: PromiseCache = new PromiseCache({
  storage: new PromiseCacheBrowserStorage('PRODUCTS'),
  timeout: 1000 * 60, // 1 minute
});

export async function getProducts(query: {
  start?: string;
  limit?: number;
  filter?: string;
  states?: ProductState[];
  productLine: ProductLine;
}) {
  const adapter = createAdapterBuilder()
    .cache({
      cache: productsCache,
    })
    .build();

  const apiProductsResponse = await productsApi.getApiProducts(
    {
      start: query?.start,
      limit: query?.limit,
      filter: query?.filter,
      state: query?.states,
      productLine: query.productLine as ProductLineEnum,
    },
    await getOptions({ adapter }),
  );

  return apiProductsResponse.data;
}

export async function getProduct(productId: string) {
  const apiProductResponse = await productsApi.getApiProduct(
    productId,
    undefined,
    await getOptions(),
  );
  return apiProductResponse.data;
}

export async function createProduct(product: Product) {
  const createApiProductResponse = await productsApi.createApiProduct(product, await getOptions());
  return createApiProductResponse.data;
}

export async function updateProduct(product: Product, productId: string) {
  const updateApiProductResponse = await productsApi.updateApiProduct(
    product,
    productId,
    undefined,
    await getOptions(),
  );

  return updateApiProductResponse.data;
}

export async function patchProduct(product: Product, productId: string) {
  const patchApiProductsResponse = await productsApi.patchApiProduct(
    product,
    productId,
    undefined,
    await getOptions(),
  );

  return patchApiProductsResponse.data;
}
