import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import jwt from 'jsonwebtoken';
import moment from 'moment';

import { toLocalDateTime } from '../../helpers/date-helper';
import CodeBlockComponent from './code-block.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'json-web-token.component',
  props: {
    token: {}
  },
  setup(__props: any) {

const props = __props;

const tokenText = getTokenText();

function getTokenText(): string {
  if (!props.token) return '';

  const decodedToken = jwt.decode(props.token);
  return (
    JSON.stringify(decodedToken, null, '  ') +
    '\n\n' +
    `  Issued At: ${formatDate(decodedToken['iat'])}` +
    '\n' +
    `  Expiration Time: ${formatDate(decodedToken['exp'])}`
  );
}

function formatDate(timestamp: number): string {
  return `${timestamp} ${toLocalDateTime(moment.unix(timestamp))}`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CodeBlockComponent, {
    language: "json",
    text: _unref(tokenText)
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(tokenText)), 1)
    ]),
    _: 1
  }, 8, ["text"]))
}
}

})