import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, onMounted, onUnmounted } from 'vue';
import { Subject, takeUntil } from 'rxjs';
import { partnerDomainValid$ } from '../../services/legacy/auth.service';
import AppAlertComponent from './app-alert.component.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'partner-domain-invalid-warning.component',
  setup(__props) {

const visible = ref<boolean>(false);
const destroySubject = new Subject();

onMounted(() => {
  partnerDomainValid$.pipe(takeUntil(destroySubject)).subscribe(partnerDomainValid => {
    visible.value = !partnerDomainValid;
  });
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppAlertComponent, { visible: visible.value }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Creating client applications is disabled for users who register with generic email service. Please register on the Apiture Developer Portal using your company email address. ")
    ])),
    _: 1
  }, 8, ["visible"]))
}
}

})