// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  background: rgba(191, 191, 191, 0.3);
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.panel[aria-expanded=true] .panel-header {
  border-bottom: 1px solid #cccccc;
}
.panel-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px 28px;
}
.panel-header .header-text {
  font-size: 18px;
  font-weight: bold;
}
.panel-header .header-icon {
  flex: none;
  margin-left: auto;
}
.panel-content {
  margin: 10px 0;
  padding: 0 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/legacy/expandable-panel.component.vue","webpack://./expandable-panel.component.vue"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,yBAAA;EACA,kBAAA;ACAF;ADGI;EACE,gCAAA;ACDN;ADMA;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;ACHF;ADKE;EACE,eAAA;EACA,iBAAA;ACHJ;ADME;EACE,UAAA;EACA,iBAAA;ACJJ;ADQA;EACE,cAAA;EACA,eAAA;ACLF","sourcesContent":["\n.panel {\n  background: rgba(191, 191, 191, 0.3);\n  border: 1px solid #cccccc;\n  border-radius: 4px;\n\n  &[aria-expanded='true'] {\n    .panel-header {\n      border-bottom: 1px solid #cccccc;\n    }\n  }\n}\n\n.panel-header {\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  padding: 10px 28px;\n\n  .header-text {\n    font-size: 18px;\n    font-weight: bold;\n  }\n\n  .header-icon {\n    flex: none;\n    margin-left: auto;\n  }\n}\n\n.panel-content {\n  margin: 10px 0;\n  padding: 0 28px;\n}\n",".panel {\n  background: rgba(191, 191, 191, 0.3);\n  border: 1px solid #cccccc;\n  border-radius: 4px;\n}\n.panel[aria-expanded=true] .panel-header {\n  border-bottom: 1px solid #cccccc;\n}\n\n.panel-header {\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  padding: 10px 28px;\n}\n.panel-header .header-text {\n  font-size: 18px;\n  font-weight: bold;\n}\n.panel-header .header-icon {\n  flex: none;\n  margin-left: auto;\n}\n\n.panel-content {\n  margin: 10px 0;\n  padding: 0 28px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
