<template>
  <div class="clearfix">
    <div class="form-container--end">
      <div class="form-container--row">
        <FormKit
          id="cursorPagination"
          type="group"
        >
          <FormKit
            type="radio"
            label="Show"
            name="limit"
            :options="[5, 10, 25, 50, 100]"
            :value="5"
            legend-class="form-input--pagination-legend"
            options-class="form-input--pagination-fieldset"
            fieldset-class="form-input--pagination-fieldset"
            @input="(value: string) => {
              raiseChange({ type: 'limitChange', limit: Number(value) })
            }"
          />
          <div class="form-container--row">
            <FormKit
              :disabled="!props.previous"
              type="button"
              label="Previous"
              prefix-icon="stepBack"
              input-class="form-input--button-transparent"
              @click="raiseChange({ type: 'navigatePrevious' })"
            />
            <FormKit
              :disabled="!props.next"
              type="button"
              label="Next"
              suffix-icon="stepForward"
              input-class="form-input--button-transparent"
              @click="raiseChange({ type: 'navigateNext' })"
            />
            <FormKit
              type="button"
              prefix-icon="refresh"
              label=" "
              input-class="form-input--button-transparent"
              @click="raiseChange({ type: 'refresh' })"
            />
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CursorPaginationEvent } from '../../helpers/cursor-pagination-helper';

const props = defineProps<{
  next: boolean;
  previous: boolean;
  limit: number | string;
}>();

const emit = defineEmits(['onChange']);

function raiseChange(event: CursorPaginationEvent): void {
  emit('onChange', event);
}
</script>

<style lang="scss" scoped>
:deep(.form-input--pagination-fieldset) {
  display: flex;
  flex-direction: row;
  min-height: min-content;
}

:deep(.form-input--pagination-legend) {
  width: auto;
  height: auto;
}
</style>
