// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p[data-v-81b99e6e] {
  font-weight: 400;
  line-height: 1.5rem;
  word-break: break-all;
}
div[data-v-81b99e6e] {
  align-self: start;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/sensitive-value.component.vue","webpack://./sensitive-value.component.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,mBAAA;EACA,qBAAA;ACAF;ADGA;EACE,iBAAA;ACAF","sourcesContent":["\np {\n  font-weight: 400;\n  line-height: 1.5rem;\n  word-break: break-all;\n}\n\ndiv {\n  align-self: start;\n}\n","p {\n  font-weight: 400;\n  line-height: 1.5rem;\n  word-break: break-all;\n}\n\ndiv {\n  align-self: start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
