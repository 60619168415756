<template>
  <div
    :class="[
      'modal--dialog',
      {
        'modal--dialog--large': props.size === 'large',
        'modal--dialog--small': props.size === 'small',
      },
    ]"
    :data-test="props.id"
  >
    <div class="modal--header">
      <Header1 v-if="props.title">
        {{ props.title }}
      </Header1>
      <CloseButton
        :test-id="`${props.id}-cancel`"
        @click="
          () => {
            emit('cancel');
            cancelModal();
          }
        "
      />
    </div>
    <div
      class="modal--body"
      :data-loaded="!props.loading"
    >
      <slot />
    </div>
    <div
      v-if="props.includeFooter"
      class="modal--footer"
    >
      <slot name="closeModal">
        <button
          class="form-input--submit"
          :data-test="`${props.id}-close`"
          @click="
            () => {
              emit('close');
              closeModal();
            }
          "
        >
          Close
        </button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { closeModal, cancelModal } from '../../../hooks/modal-store.hook';
import CloseButton from '../close-button.component.vue';
import Header1 from '../headers/header-1.component.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    id: string;
    size: 'small' | 'large';
    includeFooter?: boolean;
    loading?: boolean;
  }>(),
  { includeFooter: true, title: '', loading: false },
);

const emit = defineEmits<{ (event: 'close'): void; (event: 'cancel'): void }>();
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'src/scss/mixins';

.modal--dialog {
  background-color: $app-neutral-white;
  margin: 3rem auto;
  overflow-y: auto;
}

.modal--body {
  min-height: 15rem;
  position: relative;
}

.modal--dialog--large {
  max-width: 90%;
  min-width: 90%;
  max-height: 90vh;
  min-height: 20rem;

  @include lg {
    max-width: 60rem;
    min-width: 60rem;
  }

  @include xl {
    max-width: 72rem;
    min-width: 72rem;
  }
}

.modal--dialog--small {
  max-width: 80%;
  max-height: 80vh;
  min-height: 10rem;
  text-align: center;

  @include lg {
    max-width: 25rem;
    min-width: 25rem;
  }

  @include xl {
    max-width: 30rem;
    min-width: 30rem;
  }

  .modal--body button {
    width: 100%;
  }
}

.modal--body,
.modal--footer {
  padding: 1.5rem 3rem;

  :deep(.modal-section) {
    border-top: 1px solid $app-neutral-gray-20;
    border-bottom: 1px solid $app-neutral-gray-20;
    margin: 2rem 0;
  }
}

.modal--header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $app-neutral-gray-20;
  padding: 0.25rem 1.5rem;
}

:global(.modal--body li span button) {
  border: none;
  background-color: transparent;
  margin-left: 1.5rem;
  color: $app-apiture-blue;
  font-weight: 700;
  line-height: 24px;
  font-size: 0.9rem;
  text-decoration: underline;
  padding: 0;
}

:global(.modal--body li aside > p) {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 17px;
}

:global(.modal--body li > p) {
  font-weight: 600;
  line-height: 24px;
  font-size: 1rem;
}
</style>
