import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "form-container--row" }
const _hoisted_3 = { class: "form-container--submits" }
const _hoisted_4 = {
  key: 0,
  class: "text-right"
}

import { reactive, onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import {
  ApplicationType,
  ClientAuthentication,
  Application,
  ErrorResponse,
} from '@apiture/client-applications-client-sdk';
import { ProductLine } from '@apiture/api-products-client-sdk';
import { applicationTypesNames } from '../../services/apis.service';
import { applicationAuthenticationsNames } from '../../constants/application-authentications-names';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import {
  getApplication,
  patchApplication,
  createApplication,
} from '../../services/api/applications-api.service';
import { checkUserTermsAccepted } from '../../services/legacy/terms-conditions.service';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { getUrlParams } from '../../helpers/window-helper';
import { ClientState } from '@apiture/client-applications-client-sdk';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import { mapEnvironmentsToOptions, useEnvironments } from '../../hooks/use-environments.hook';
import { mapProductsToOptions, useProducts } from '../../hooks/use-products.hook';


export default /*@__PURE__*/_defineComponent({
  __name: 'application-form.component',
  setup(__props) {

const applicationFormData = ref<{
  type: ApplicationType;
  authentication: ClientAuthentication;
  name: string;
  description: string;
  applicationUrl: string;
  redirectUrl: string;
  products: string[];
  environments: string[];
}>({
  type: null,
  authentication: null,
  name: '',
  description: '',
  applicationUrl: '',
  redirectUrl: '',
  products: [],
  environments: [],
});

const products = useProducts(ProductLine.Open);
const environments = useEnvironments(ProductLine.Open);

const state = reactive<{
  initialized: boolean;
  loading: boolean;
  application: Application;
}>({
  initialized: false,
  loading: false,
  application: {},
});

function showDescriptionPreview(): void {
  const messageHtml = makeHtmlMarkdown(applicationFormData.value.description);
  bootbox.alert({
    title: 'Application Description',
    message: messageHtml,
  });
}

async function saveApplication(): Promise<void> {
  const isExistingApplication = !!state.application._id;

  try {
    state.loading = true;

    if (isExistingApplication) {
      Object.assign(state.application, getApplicationPatch());
      state.application = await patchApplication(state.application, state.application._id);
    } else {
      state.application = await createApplication(getApplicationPatch());
    }
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error?.message ?? Messages.internalError,
    });
    return;
  } finally {
    state.loading = false;
  }

  showToastr({
    type: 'success',
    message: `Application has been successfully ${isExistingApplication ? 'updated' : 'created'}.`,
  });

  fillFormData();

  setTimeout(function () {
    window.location.href = '/profile/applications/';
  }, 3000);
}

onMounted(async () => {
  const applicationId = getUrlParams().get('applicationId');

  checkUserTermsAccepted(
    applicationId
      ? 'It is mandatory to accept the terms & condition to edit an application.'
      : 'It is mandatory to accept the terms & condition before creating a new application.',
  );

  state.application = applicationId ? await getApplication(applicationId) : ({} as Application);
  state.initialized = true;

  fillFormData();
});

function getApplicationPatch(): Partial<Application> {
  const selectedEnvironments = environments.value
    .filter(environment => applicationFormData.value.environments.includes(environment._id))
    .map(environment => {
      return { name: environment.name, host: environment.host, _links: environment._links };
    });
  const selectedProducts = products.value
    .filter(product => {
      return applicationFormData.value.products.includes(product._id);
    })
    .map(product => {
      return { name: product.name, version: product.version, _links: product._links };
    });

  return {
    type: applicationFormData.value.type,
    authentication: applicationFormData.value.authentication,
    name: applicationFormData.value.name,
    description: applicationFormData.value.description,
    applicationUrl: applicationFormData.value.applicationUrl,
    environments: selectedEnvironments,
    products: selectedProducts,
    ...(applicationFormData.value.redirectUrl && {
      redirectUrls: [applicationFormData.value.redirectUrl],
    }),
  };
}

function fillFormData(): void {
  applicationFormData.value = {
    name: state.application?.name,
    type: state.application?.type,
    authentication: state.application?.authentication,
    description: state.application?.description,
    applicationUrl: state.application?.applicationUrl,
    products: state.application?.products?.map(product => `${product.name} v${product.version}`),
    environments: state.application?.environments?.map(environment => environment.name),
    ...(state.application?.redirectUrls?.length > 0 && {
      redirectUrl: state.application?.redirectUrls[0],
    }),
  };
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!state.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({ disabled: state.application.state === 'rejected' })
        }, [
          _createVNode(_component_FormKit, {
            modelValue: applicationFormData.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((applicationFormData).value = $event)),
            type: "form",
            "submit-label": "Save",
            actions: false,
            "data-test": "applicationFormOpen",
            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (saveApplication()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormKit, {
                  type: "select",
                  name: "type",
                  label: "Application Type",
                  help: "The type of\n          client application. Different application types have different API and security\n          requirements. For example, web and mobile applications are harder to keep secure because\n          they often operate in unsecure public networks. They use an client ID but do not use client\n          secrets key to authenticate the application; they use an API when invoking API\n          operations. Trusted service applications use client IDs and client secrets for their OAuth\n          flow and API keys for API calls.",
                  options: [
              { label: '', value: '' },
              ...Array.from(_unref(applicationTypesNames).entries(), ([type, name]) => {
                return { value: type, label: name };
              }),
            ],
                  validation: "required",
                  disabled: Boolean(state.application?._id),
                  "outer-class": "form-input--full",
                  "data-test": "type"
                }, null, 8, ["options", "disabled"]),
                _createVNode(_component_FormKit, {
                  type: "select",
                  name: "authentication",
                  label: "Authentication",
                  options: [
              { label: '', value: '' },
              ...Array.from(_unref(applicationAuthenticationsNames).entries(), ([type, name]) => {
                return { value: type, label: name };
              }),
            ],
                  disabled: Boolean(state.application?._id),
                  "outer-class": "form-input--full",
                  help: "Select how your application with authenticate to the Apiture services: Authorization Code Flow Flow for interactive Client applications or Credentials for secure back-office services and applications.",
                  "date-test": "authentication"
                }, null, 8, ["options", "disabled"])
              ]),
              _createVNode(_component_FormKit, {
                type: "text",
                label: "Application Name",
                name: "name",
                help: "The name of the client application.",
                validation: "required|length:6,64",
                disabled: state.application.state === _unref(ClientState).Rejected,
                "data-test": "applicationName"
              }, null, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                type: "textarea",
                name: "description",
                label: "Description and Purpose of Application",
                help: "The description of the client application, may use markdown.",
                validation: "length:0,512",
                disabled: state.application.state === _unref(ClientState).Rejected,
                "data-test": "description"
              }, null, 8, ["disabled"]),
              _createVNode(_component_FormKit, {
                name: "applicationUrl",
                type: "url",
                label: "Site URL / Download Location",
                help: "A URL of a web page with additional information about the application, including information on downloading or licensing the application.",
                validation: "required|length:16,4096|url",
                placeholder: "Publicly accessible URL",
                disabled: state.application.state === _unref(ClientState).Rejected,
                "data-test": "applicationUrl"
              }, null, 8, ["disabled"]),
              (applicationFormData.value.authentication === _unref(ClientAuthentication).AuthorizationCode)
                ? (_openBlock(), _createBlock(_component_FormKit, {
                    key: 0,
                    name: "redirectUrl",
                    type: "url",
                    label: "Redirect URL",
                    help: "The URL used in OAuth flows. When a user authenticates with the application's authorization server, the authorization server will redirect to this URL to complete the authentication process. This prevents other applications from using the application's client ID and secret.",
                    validation: "length:16,4096|url",
                    placeholder: "OAuth2 Redirect URL",
                    disabled: state.application.state === _unref(ClientState).Rejected,
                    "data-test": "redirectUrl"
                  }, null, 8, ["disabled"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormKit, {
                  name: "products",
                  type: "checkbox",
                  label: "Products",
                  validation: "required",
                  help: "Select the API products you client application will use.",
                  options: _unref(mapProductsToOptions)(_unref(products), false),
                  "data-test": "products"
                }, null, 8, ["options"]),
                _createVNode(_component_FormKit, {
                  name: "environments",
                  type: "checkbox",
                  label: "Environments",
                  validation: "required",
                  help: "Select the API environments that your client application will run against.",
                  options: _unref(mapEnvironmentsToOptions)(_unref(environments), false),
                  "data-test": "environments"
                }, null, 8, ["options"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_FormKit, {
                  type: "submit",
                  disabled: state.loading,
                  "data-test": "submit"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Save ")
                  ])),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_FormKit, {
                  disabled: !applicationFormData.value.description || state.loading,
                  type: "button",
                  "input-class": "form-input--button-transparent",
                  "data-test": "showDescription",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showDescriptionPreview()))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Preview Description ")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (state.application.state !== 'rejected')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true)
        ], 2))
  ]))
}
}

})