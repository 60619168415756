import type { Ref } from 'vue';

import { getAccessToken, refreshUserSession } from '../services/cognito.service';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { ref } from 'vue';
import { siteOptions } from '../options/site-options';

export type AccessTokenRefresherParams = {
  maxTimeoutTime: number;
  successCallback: (accessToken: string) => void;
  failCallback: () => void;
};

export function useAccessTokenRefresher(accessTokenRefresherParams: AccessTokenRefresherParams) {
  const accessToken = ref<CognitoAccessToken>(null);
  const timeoutHandlerId = ref<number>(null);

  if (!siteOptions.loginEnabled) {
    return {
      accessToken,
      timeoutHandlerId,
      stopAccessTokenRefresher,
    };
  }

  const startAccessTokenRefresher = async () => {
    const accessTokenResponse = await getAccessToken().catch(() => {
      accessTokenRefresherParams.failCallback();
    });

    if (!accessTokenResponse) {
      return;
    }

    accessToken.value = accessTokenResponse;

    scheduleAccessTokenRefresher(accessToken.value, accessTokenRefresherParams, timeoutHandlerId);
  };

  startAccessTokenRefresher();

  return {
    accessToken,
    timeoutHandlerId,
    stopAccessTokenRefresher,
  };
}

export function stopAccessTokenRefresher(timeoutHandlerId: number) {
  window.clearTimeout(timeoutHandlerId);
}

function scheduleAccessTokenRefresher(
  accessToken: CognitoAccessToken,
  accessTokenRefresherParams: AccessTokenRefresherParams,
  timeoutHandlerId: Ref<number>,
) {
  if (!accessToken) {
    return;
  }

  const timeout = Math.max(
    Math.min(
      accessToken.getExpiration() * 1000 - Date.now(),
      accessTokenRefresherParams.maxTimeoutTime,
    ),
    0,
  );

  timeoutHandlerId.value = window.setTimeout(async () => {
    try {
      await refreshUserSession();
      const refreshedAccessToken = await getAccessToken();
      accessTokenRefresherParams.successCallback(refreshedAccessToken.getJwtToken());

      scheduleAccessTokenRefresher(
        refreshedAccessToken,
        accessTokenRefresherParams,
        timeoutHandlerId,
      );
    } catch {
      accessTokenRefresherParams.failCallback();
    }
  }, timeout);
}
