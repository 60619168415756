import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "data-test", "disabled"]

import TrashSvg from '../svg/trash-svg.component.vue';

/**
 * The props for the remove button component.
 * 
 * - dataTest: the value of the attribute used for data-test.
 * - ariaLabel: the value of the attribute used for aria-label.
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'remove-button.component',
  props: {
    label: {},
    dataTest: {},
    disabled: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

function handleClick() {
  emits('click');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "align-self-center",
    "aria-label": props.label,
    "data-test": props.dataTest,
    disabled: props.disabled,
    onClick: handleClick
  }, [
    _createElementVNode("span", null, [
      _createVNode(TrashSvg)
    ])
  ], 8, _hoisted_1))
}
}

})