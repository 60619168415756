import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = ["aria-expanded", "data-test", "aria-label", "disabled", "title"]
const _hoisted_3 = ["data-test"]
const _hoisted_4 = { class: "dropdown-container flex-col" }

import { ref } from 'vue';
import { useDetectOutsideClick } from '../../hooks/detect-outside-click.hook';
import { startCase } from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'dropdown.component',
  props: {
    label: { default: '' },
    dropdownContentClass: { default: '' },
    dropdownButtonClass: { default: '' },
    name: { default: '' },
    disable: { type: Boolean, default: false },
    removePointer: { type: Boolean, default: false },
    closeOnInsideClick: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const isExpanded = ref(false);
const dropdown = ref<HTMLDivElement>();

useDetectOutsideClick(dropdown, () => {
  if (!isExpanded.value) {
    return;
  }

  isExpanded.value = false;
});

function handleCloseOnInsideClick() {
  if (!isExpanded.value || !props.closeOnInsideClick) {
    return;
  }

  isExpanded.value = false;
}

function toggleDropdown() {
  isExpanded.value = !isExpanded.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "dropdown",
    ref: dropdown,
    "data-test": props.name ? `${props.name}-dropdown` : 'dropdown'
  }, [
    _createElementVNode("button", {
      ref: "dropdownButton",
      type: "button",
      class: _normalizeClass([
        props.dropdownButtonClass ? props.dropdownButtonClass : 'dropdown--button-default',
        'dropdown--button-base',
      ]),
      "aria-haspopup": "true",
      "aria-expanded": isExpanded.value,
      "data-test": props.name ? `${props.name}-dropdown-toggle` : 'dropdown-toggle',
      "aria-label": props.name,
      disabled: props.disable,
      title: `Toggle ${_unref(startCase)(props.name) ?? 'dropdown'}`,
      onClick: _withModifiers(toggleDropdown, ["prevent","stop"])
    }, [
      _createTextVNode(_toDisplayString(props.label) + " ", 1),
      _renderSlot(_ctx.$slots, "labelIcon", { ref: "dropdownButtonIcon" })
    ], 10, _hoisted_2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass('dropdown'),
      "data-test": props.name ? `${props.name}-dropdown-content` : 'dropdown-content'
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass([
            props.dropdownContentClass ? props.dropdownContentClass : 'dropdown--content-default',
            !props.removePointer && 'dropdown-content-pointer',
            'dropdown-content-base',
          ]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleCloseOnInsideClick()))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ])
    ], 8, _hoisted_3), [
      [_vShow, isExpanded.value]
    ])
  ], 8, _hoisted_1))
}
}

})