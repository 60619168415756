import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "form-input--selectAll-fieldset" }
const _hoisted_2 = { class: "form-input form-input--selectAll" }
const _hoisted_3 = { class: "form-input-scopes--selectAll flex-row--gap" }
const _hoisted_4 = ["data-test", "checked"]
const _hoisted_5 = ["data-test"]
const _hoisted_6 = { class: "flex-row--gap align-items-center" }
const _hoisted_7 = ["id", "value", "data-test", "checked"]
const _hoisted_8 = ["for", "data-test"]

import type { FormKitFrameworkContext } from '@formkit/core';
import { ref, computed, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'select-all.component',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const allSelected = ref<boolean>(false);

const optionValues = computed(() => {
  return props.context.xOptions?.map(option => option);
});

watch(
  () => props.context.value,
  (current, previous) => {
    if (previous) {
      return;
    }

    checkAllSelectedByContextValue(current);
  },
);

function handleSelectAll(event: Event & { target: HTMLInputElement }) {
  if (!event.target.checked) {
    props.context.node.input([]);
    allSelected.value = false;
    return;
  }

  props.context.node.input(optionValues.value);
  allSelected.value = true;
}

async function handleCheck(event: Event & { target: HTMLInputElement }) {
  if (!event.target.checked) {
    props.context.node.input(props.context.value.filter(value => value !== event.target.value));
    allSelected.value = false;
    return;
  }

  const currentInput = props.context.value ?? [];

  props.context.node.input(currentInput.concat(event.target.value));
  await props.context.node.input(props.context.value.concat(event.target.value));

  checkAllSelectedByContextValue(props.context.value);
}

function shouldCheck(value: string) {
  if (!props.context.value || !Array.isArray(props.context.value)) {
    return false;
  }

  return props.context.value.includes(value);
}

function checkAllSelectedByContextValue(value: string[]) {
  if (value.length === props.context.xOptions?.length) {
    allSelected.value = true;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          id: "selectAll",
          type: "checkbox",
          class: "form-input--option",
          "data-test": `${props.context.node.name}-selectAll`,
          checked: allSelected.value,
          onInput: handleSelectAll
        }, null, 40, _hoisted_4),
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "selectAll" }, "Select All", -1))
      ]),
      _createElementVNode("ul", {
        "data-test": `${props.context.node.name}-list`,
        class: "form-input--option-list form-input--scrollable"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.context.xOptions, (option, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: option,
            class: "form-input--selectAll-option"
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("input", {
                id: `option-${Number(i) + 1}`,
                class: "form-input--option",
                type: "checkbox",
                value: option,
                "data-test": `${props.context.node.name}-${Number(i) + 1}`,
                checked: shouldCheck(option),
                onInput: handleCheck
              }, null, 40, _hoisted_7),
              _createElementVNode("label", {
                for: `option-${Number(i) + 1}`,
                "data-test": `${props.context.node.name}-label-${Number(i) + 1}`,
                class: "form-input--wrapper scopes-input--wrapper"
              }, _toDisplayString(option), 9, _hoisted_8)
            ])
          ]))
        }), 128))
      ], 8, _hoisted_5)
    ])
  ]))
}
}

})