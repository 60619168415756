export interface PageOptions {
  readonly type: 'auth' | 'profile';
  readonly auth: boolean;
  readonly authAdmin: boolean;
  readonly authPartnerDomainValid: boolean;
  readonly targetEnvSelector: boolean;
}

const pageOptions: PageOptions = window['aptPageOptions'] || {};

export function createPageOptions(): PageOptions {
  return pageOptions;
}
