<template>
  <div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <template v-else>
      <template v-if="state.keyType === 'explorer'">
        <ApplicationsListComponent
          mode="admin"
          :key-id="state.keyId"
          :product-line="props.productLine"
        />
      </template>
      <template v-else-if="state.applicationId || state.keyId">
        <ApplicationsListComponent
          mode="admin"
          :application-id="state.applicationId"
          :key-id="state.keyId"
          :product-line="props.productLine"
        />
      </template>
      <template v-else>
        <TabListComponent
          :labels="
            props.productLine === 'adb'
              ? ['Explorer Keys', 'Discoverer Keys']
              : ['Applications', 'Explorer Keys', 'Discoverer Keys']
          "
          :initial-tab="props.initialTab"
        >
          <template #cell(applications)>
            <ApplicationsListComponent
              v-if="props.productLine === 'open'"
              mode="admin"
              product-line="open"
            />
          </template>
          <template #cell(explorerKeys)>
            <keysListComponent
              mode="admin"
              type="explorer"
              :product-line="props.productLine"
            />
          </template>
          <template #cell(discovererKeys)>
            <DiscovererKeyFormComponent />
          </template>
        </TabListComponent>
      </template>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { KeyType } from '@apiture/api-keys-client-sdk';
import { getKey } from '../../services/api/keys-api.service';
import { getUrlParams, getIdFromUri } from '../../helpers/window-helper';
import LoadingIndicatorComponent from '../legacy/loading-indicator.component.vue';
import DiscovererKeyFormComponent from '../legacy/discoverer-key-form.component.vue';
import ApplicationsListComponent from '../legacy/applications-list.component.vue';
import keysListComponent from '../legacy/keys-list.component.vue';
import TabListComponent from '../portal/tab-list.component.vue';
import type { ProductLine } from '../../utils/product-lines';

const props = defineProps<{ productLine: ProductLine; initialTab: string }>();

const state = ref<{
  initialized: boolean;
  applicationId: string;
  keyId: string;
  keyType: KeyType;
}>({
  initialized: false,
  applicationId: null,
  keyId: null,
  keyType: null,
});

onMounted(async () => {
  const urlParams = getUrlParams();

  state.value.applicationId = urlParams.get('application');
  state.value.keyId = urlParams.get('key');

  if (state.value.keyId) {
    const key = await getKey(state.value.keyId);
    state.value.keyType = key.type;
    state.value.applicationId = getIdFromUri(key._links['apiture:clientApplication'].href);
  }

  state.value.initialized = true;
});
</script>
