import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invite-form" }
const _hoisted_2 = { class: "form-container--submits" }

import { onMounted, ref } from 'vue';
import bootbox from 'bootbox';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import {
  inviteMembers,
  getOrganizations,
} from '../../services/api/partner-organizations-api.service';
import { Organization, CreateInvitation, Invitee } from '@apiture/client-organizations-client-sdk';
import { getUser } from '../../services/legacy/auth.service';
import { getEmailDomain } from '../../helpers/email-helper';
import { makeHtmlMarkdown } from '../../helpers/markdown-helper';
import { FormKitNode } from '@formkit/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'partner-organization-invite-members.component',
  setup(__props) {

const organizationInviteMembersData = ref<{
  organizationName: string;
  message: string;
  invitees: Invitee[];
}>({
  organizationName: '',
  message: '',
  invitees: [],
});

const loading = ref<boolean>(false);
const organization = ref<Organization>();
const user = getUser();

onMounted(async () => {
  loading.value = true;
  const getOrganizationsResponse = await getOrganizations({
    domain: user.domain,
  });
  organization.value = getOrganizationsResponse._embedded.items[0];
  loading.value = false;

  fillFormData();
});

function previewMessage(): void {
  const messageHtml = makeHtmlMarkdown(organizationInviteMembersData.value.message);
  bootbox.alert({
    title: 'The following formatted text will be included in the message:',
    message: messageHtml,
  });
}

async function sendInvites(): Promise<void> {
  try {
    loading.value = true;
    await inviteMembers(organization.value._id, getInviteData());
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  showToastr({
    type: 'success',
    message: 'Invite(s) has been successfully sent.',
  });

  setTimeout(() => {
    window.location.href = '/profile/business/';
  }, 3000);
}

function updateNewOwners(emailAddress: string, formNode: FormKitNode): void {
  const userDomain = user.domain;
  if (emailAddress && getEmailDomain(emailAddress) !== userDomain) {
    formNode.setErrors(`Email address must match your @${userDomain} domain.`);
  }
}

function getInviteData(): CreateInvitation {
  return {
    message: organizationInviteMembersData.value.message,
    invitees: organizationInviteMembersData.value.invitees,
  };
}

function fillFormData() {
  organizationInviteMembersData.value = {
    organizationName: organization.value.name,
    invitees: [{ firstName: '', lastName: '', emailAddress: '' }],
    message: null,
  };
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormKit, {
      modelValue: organizationInviteMembersData.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((organizationInviteMembersData).value = $event)),
      type: "form",
      "submit-label": "Save",
      actions: false,
      "data-test": "organizationInviteMembersForm",
      onSubmit: _cache[2] || (_cache[2] = ($event: any) => (sendInvites()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          type: "text",
          name: "organizationName",
          label: "Organization Name",
          "data-test": "organizationName"
        }),
        _createVNode(_component_FormKit, {
          type: "textarea",
          name: "message",
          label: "Message",
          help: "Include this message in the invitation email to the invitees. You may use Markdown to format this text.",
          validation: "required|length:16,512",
          "data-test": "message"
        }),
        _createVNode(_component_FormKit, {
          type: "repeater",
          name: "invitees",
          "fieldset-label": "New Owners"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              type: "text",
              name: "firstName",
              label: "First Name",
              validation: "length:1,64",
              "outer-class": "form-input--full",
              "data-test": "firstName"
            }),
            _createVNode(_component_FormKit, {
              type: "text",
              name: "lastName",
              label: "Last Name",
              validation: "length:1,64",
              "outer-class": "form-input--full",
              "data-test": "lastName"
            }),
            _createVNode(_component_FormKit, {
              type: "email",
              label: "Email",
              name: "emailAddress",
              help: `Enter the invitee’s email address. The email addresses must match your partner organization's @${_unref(user).domain} domain.`,
              validation: "required|length:6,128",
              "outer-class": "form-input--full",
              "data-test": "emailAddress",
              onInput: updateNewOwners
            }, null, 8, ["help"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormKit, {
            disabled: loading.value,
            label: "Send",
            type: "submit",
            "data-test": "send"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Send ")
            ])),
            _: 1
          }, 8, ["disabled"]),
          (organizationInviteMembersData.value.message)
            ? (_openBlock(), _createBlock(_component_FormKit, {
                key: 0,
                type: "button",
                "input-class": "form-input--button-transparent",
                disabled: !organizationInviteMembersData.value.message || loading.value,
                "data-test": "previewMessage",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (previewMessage()))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Preview Message ")
                ])),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})