import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-9" }
const _hoisted_3 = { class: "card bg-light" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "float-right mb-3"
}
const _hoisted_6 = { class: "d-inline-block" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "px-3" }
const _hoisted_9 = { class: "text-nowrap font-weight-bold" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "ps-3" }
const _hoisted_12 = { class: "mt-3" }

import { onMounted, onUnmounted, ref, shallowRef } from 'vue';
import * as OrganizationSdk from '@apiture/client-organizations-client-sdk';
import * as ApiKeysSdk from '@apiture/api-keys-client-sdk';
import { TargetEnv } from '../../models/target-env';
import { getOrganizations } from '../../services/api/partner-organizations-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { getUser } from '../../services/legacy/auth.service';
import {
  checkSampleDataRequest,
  checkSampleDataRequestTimeout,
  createSampleDataRequest,
  setSampleDataRequestTimeout,
} from '../../services/api/keys-api.service';
import { Messages } from '../../constants/messages';
import { siteOptions } from '../../options/site-options';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import PartnerDomainInvalidWarningComponent from './partner-domain-invalid-warning.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import AppAlertComponent from './app-alert.component.vue';
import { getItemInSecureStorage, getItemInSessionStorage } from '../../services/storage.service';
import { getOktaAccessToken, oktaAuthClient } from '../../services/okta-auth.service';
import { apiKey$, env$, targetEnvAccessToken$ } from '../../services/legacy/target-env.service';
import { Subject, takeUntil, filter, map, forkJoin } from 'rxjs';
import { isEqual } from 'lodash';

interface ApiDetail {
  id: string;
  title: string;
  description?: string;
  value: string;
}

interface AccountInfoState {
  initialized: boolean;
  generateSampleDataDisabled: boolean;
  generateSampleDataTooltip: string;
  apiDetails: ApiDetail[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'account-info.component',
  setup(__props) {

const sampleDataGenerationEnabled = siteOptions.sampleDataGenerationEnabled;

const state = ref<AccountInfoState>({
  initialized: false,
  generateSampleDataDisabled: false,
  generateSampleDataTooltip: null,
  apiDetails: [],
});

const user = getUser();
const partnerDomainValid = shallowRef<boolean>(
  getItemInSecureStorage<boolean>('partnerDomainValid'),
);
const organization = ref<OrganizationSdk.Organization>();
const targetEnvAccessToken = ref(getItemInSessionStorage('targetEnvAccessToken'));
const targetEnv = ref<TargetEnv>(getItemInSecureStorage<TargetEnv>('targetEnv'));
const destroySubject = new Subject();

onMounted(async () => {
  if (user.role == 'Admin') {
    state.value.apiDetails.push({
      id: 'adminDeveloperPortalAccessToken',
      title: 'Developer Portal Access Token',
      value: await getOktaAccessToken(),
    });
  }

  organization.value = await getOrganization();

  forkJoin([env$, targetEnvAccessToken$, apiKey$]).subscribe(
    ([env, targetEnvAccessToken, apiKey]: [TargetEnv, string, string]) => {
      if (env && env.type === 'explorer') {
        state.value.apiDetails.push({
          id: 'explorerAccessToken',
          title: 'OAuth2 Access Token',
          description: `for ${env.apiHost}`,
          value: targetEnvAccessToken,
        });

        state.value.apiDetails.push({
          id: 'explorerApiKey',
          title: 'Explorer API Key',
          description: `for ${env.apiHost}`,
          value: apiKey,
        });
      }
    },
  );

  forkJoin([env$, targetEnvAccessToken$])
    .pipe(
      map(([env, targetEnvAccessToken]) => [env, targetEnvAccessToken]),
      filter(
        ([targetEnv, targetEnvAccessToken]: [TargetEnv, string]) =>
          targetEnv.type === 'discoverer' ||
          (!isEqual(targetEnv, targetEnv) && targetEnvAccessToken !== targetEnvAccessToken),
      ),
      takeUntil(destroySubject),
    )
    .subscribe(async ([env, accessToken]: [TargetEnv, string]) => {
      targetEnv.value = env;
      targetEnvAccessToken.value = accessToken;

      if (siteOptions.sampleDataGenerationEnabled && env.type === 'explorer') {
        await checkSampleData();
      }
    });

  state.value.initialized = true;

  oktaAuthClient.tokenManager.on('renewed', async () => await setAccessTokenValue());
});

onUnmounted(() => {
  oktaAuthClient.tokenManager.off('renewed');
  destroySubject.next(null);
  destroySubject.complete();
});

async function generateSampleData(): Promise<void> {
  try {
    await createSampleDataRequest(targetEnv.value.apiHost, targetEnvAccessToken.value);
    showToastr({
      message: `Data generation has been started.`,
      type: 'success',
    });
    state.value.generateSampleDataDisabled = true;
    setSampleDataRequestTimeout();
  } catch (error) {
    const errorResponse = error as ApiKeysSdk.ErrorResponse;
    showToastr({
      message: errorResponse?._error.message ?? Messages.internalError,
      type: 'error',
    });
  }
}

async function checkSampleData(): Promise<void> {
  if (!checkSampleDataRequestTimeout()) {
    state.value.generateSampleDataDisabled = true;
    return;
  }
  try {
    const sampleDataRequest = await checkSampleDataRequest(
      targetEnv.value.apiHost,
      targetEnvAccessToken.value,
    );
    if (sampleDataRequest.state === ApiKeysSdk.SampleDataRequestState.Started) {
      state.value.generateSampleDataDisabled = true;
      state.value.generateSampleDataTooltip =
        'We are already generating your sample data. Please check later.';
    }
  } catch (error) {
    const errorResponse = error as ApiKeysSdk.ErrorResponse;
    showToastr({
      message: errorResponse?._error.message ?? Messages.internalError,
      type: 'error',
    });
  }
}

async function getOrganization(): Promise<OrganizationSdk.Organization> {
  const organizations = await getOrganizations({
    domain: user.domain,
  });

  return organizations._embedded.items[0];
}

async function setAccessTokenValue() {
  const developerPortalAccessTokenIndex = state.value.apiDetails.findIndex(
    apiDetail => apiDetail.id === 'developerPortalAccessToken',
  );

  if (developerPortalAccessTokenIndex !== -1) {
    state.value.apiDetails[developerPortalAccessTokenIndex].value = await getOktaAccessToken();
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!state.value.initialized)
      ? (_openBlock(), _createBlock(LoadingIndicatorComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_unref(sampleDataGenerationEnabled) && targetEnv.value && targetEnv.value.type === 'explorer')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, [
                          _createVNode(_component_FormKit, {
                            disabled: state.value.generateSampleDataDisabled,
                            label: "Generate Sample Data",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (generateSampleData()))
                          }, null, 8, ["disabled"])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(PartnerDomainInvalidWarningComponent),
                  _createVNode(AppAlertComponent, {
                    visible: targetEnv.value?.type === 'discoverer' && Boolean(targetEnvAccessToken.value)
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Select an environment in the selector control at the top of the screen and authenticate to get an access token for that environment. ")
                    ])),
                    _: 1
                  }, 8, ["visible"]),
                  _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "font-weight-bold mb-4" }, " Profile Details: ", -1)),
                  _createElementVNode("p", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("b", null, "First Name: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(user).firstName), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("b", null, "Last Name: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(user).lastName), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("b", null, "Email Address: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(user).email), 1)
                  ]),
                  (partnerDomainValid.value && organization.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                        _cache[5] || (_cache[5] = _createElementVNode("b", null, "Company: ", -1)),
                        _createElementVNode("span", null, _toDisplayString(organization.value.name), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (state.value.apiDetails.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "font-weight-bold mt-4" }, [
                          _createTextVNode(" API Details: "),
                          _createElementVNode("a", {
                            id: "helpLink",
                            href: "/help/devbank",
                            target: "_blank"
                          }, "Help")
                        ], -1)),
                        _createElementVNode("div", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.apiDetails, (apiDetail) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: apiDetail.id,
                              class: "d-flex justify-content-start mt-3 flex-grow-1"
                            }, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("span", null, _toDisplayString(apiDetail.title), 1),
                                (apiDetail.description)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                                      _createTextVNode(" " + _toDisplayString(apiDetail.description), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                _cache[7] || (_cache[7] = _createElementVNode("span", null, ":", -1))
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("span", null, [
                                  _createVNode(SensitiveValueComponent, {
                                    id: apiDetail.id,
                                    title: apiDetail.title,
                                    value: apiDetail.value.toString()
                                  }, null, 8, ["id", "title", "value"])
                                ])
                              ])
                            ]))
                          }), 128))
                        ])
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("a", {
              id: "applicationsButton",
              href: "/profile/applications",
              class: _normalizeClass(["btn btn-secondary", { disabled: !partnerDomainValid.value }]),
              role: "button"
            }, "Application List", 2),
            _cache[10] || (_cache[10] = _createElementVNode("a", {
              id: "changePassword",
              href: "/auth/changePassword",
              class: "btn btn-secondary ms-2",
              role: "button"
            }, " Change Password ", -1))
          ])
        ], 64))
  ]))
}
}

})