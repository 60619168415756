<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div
      class="spinner-border my-2"
      role="status"
    >
      <span class="sr-only">{{ props.text }}</span>
    </div>
    <strong class="ml-2">{{ props.text }}</strong>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    text?: string;
  }>(),
  { text: 'Loading' },
);
</script>
