<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0919 0.0556938L12.3366 0.0556641L12.3268 0.0557646C12.0724 0.060985 11.8678 0.268808 11.8678 0.524414C11.8678 0.783298 12.0777 0.993164 12.3366 0.993164H14.9549L7.42699 8.52108L7.41904 8.52923C7.24397 8.71285 7.24663 9.00363 7.42699 9.184C7.61005 9.36705 7.90685 9.36705 8.08991 9.184L15.6178 1.65604V4.27441L15.6179 4.28422C15.6231 4.53858 15.831 4.74316 16.0866 4.74316C16.3455 4.74316 16.5553 4.5333 16.5553 4.27441V0.524414L16.5552 0.514607L16.5544 0.494715C16.5515 0.447702 16.5416 0.402568 16.5258 0.360324L16.5251 0.358607L16.5191 0.343368L16.5133 0.330036L16.5078 0.318501L16.5059 0.314734L16.4976 0.298854C16.4848 0.275479 16.4698 0.252968 16.4527 0.231617L16.4488 0.226789L16.4417 0.218385L16.4362 0.212163L16.4285 0.203748L16.418 0.192958L16.4111 0.186144L16.3988 0.1748L16.3863 0.163963L16.3845 0.162502L16.3664 0.148296C16.3446 0.132027 16.3217 0.117904 16.298 0.105946L16.2869 0.100488L16.2813 0.0978899C16.2723 0.0937987 16.2632 0.0899809 16.254 0.0864469L16.242 0.0820656L16.2333 0.0791273L16.2179 0.0742964C16.1894 0.0660229 16.1599 0.060369 16.1295 0.057624L16.1174 0.0566588L16.1061 0.056065L16.0999 0.0558492L16.0919 0.0556938ZM10.289 1.49659C10.289 1.19058 10.0407 0.942538 9.73451 0.942538H4.80195C2.47344 0.942538 0.579102 2.90961 0.579102 5.32746V11.6826C0.579102 14.1005 2.47344 16.0675 4.80195 16.0675H11.494C13.8225 16.0675 15.7169 14.1005 15.7169 11.6826V7.09271C15.7169 6.78669 15.4686 6.53865 15.1623 6.53865C14.8561 6.53865 14.6078 6.78669 14.6078 7.09271V11.6826C14.6078 13.4895 13.211 14.9594 11.494 14.9594H4.80195C3.08497 14.9594 1.68815 13.4895 1.68815 11.6826V5.32746C1.68815 3.52062 3.08497 2.05065 4.80195 2.05065H9.73451C10.0407 2.05065 10.289 1.80261 10.289 1.49659Z"
      fill="white"
    />
  </svg>
</template>
