// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-16df1645] .oktaLoginBtn {
  width: 100%;
  background-color: #005a7c;
  color: #ffffff;
  border: none;
  border-radius: 3rem;
  height: 2rem;
}
[data-v-16df1645] .oktaLoginBtn:hover {
  background-color: #003849;
}
form[data-v-16df1645] {
  background: #f2efe9;
}`, "",{"version":3,"sources":["webpack://./src/app/components/admin/login-form.component.vue","webpack://./src/scss/_variables.scss","webpack://./login-form.component.vue"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,yBCYiB;EDXjB,cCQkB;EDPlB,YAAA;EACA,mBAAA;EACA,YAAA;AEFF;AFIE;EACE,yBCQe;ACVnB;AFMA;EACE,mBAAA;AEHF","sourcesContent":["\n@import 'src/scss/variables';\n\n:deep(.oktaLoginBtn) {\n  width: 100%;\n  background-color: $app-apiture-blue;\n  color: $app-neutral-white;\n  border: none;\n  border-radius: 3rem;\n  height: 2rem;\n\n  &:hover {\n    background-color: $app-button-hover;\n  }\n}\n\nform {\n  background: #f2efe9;\n}\n","$app-img-folder-path: '../../img';\n\n$app-navbar-height-md: 90px;\n$app-navbar-height-lg: 112px;\n$app-body-padding-top: 20px;\n$app-footer-height: 46px;\n\n$app-card-header-font-size: 1.1rem;\n$app-card-header-padding-y: 0.5rem;\n\n$app-label-font-size: 14px;\n$app-label-font-weight: 300;\n\n$app-neutral-black: #132334;\n$app-neutral-white: #ffffff;\n$app-error-red: #a71930;\n$app-error-light-red: #f6e8ea;\n$app-apiture-blue: #005a7c;\n$app-link: #18bc9c;\n$app-success-green: #c7f7cd;\n$app-button-hover: #003849;\n\n// Grays\n$app-neutral-gray-0: #fafbfc;\n$app-neutral-gray-10: #f4f5f6;\n$app-neutral-gray-20: #dde0e4;\n$app-neutral-gray-30: #c6ccd2;\n$app-neutral-gray-40: #93a4ba;\n$app-neutral-gray-70: #6c7889;\n$app-neutral-gray-80: #5a6472;\n$app-neutral-gray-90: #48505b;\n\n// Dimensions\n$app-footer-height: 4rem;\n$app-nav-height: 5rem;\n",":deep(.oktaLoginBtn) {\n  width: 100%;\n  background-color: #005a7c;\n  color: #ffffff;\n  border: none;\n  border-radius: 3rem;\n  height: 2rem;\n}\n:deep(.oktaLoginBtn):hover {\n  background-color: #003849;\n}\n\nform {\n  background: #f2efe9;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
