import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-container--row" }
const _hoisted_2 = { class: "form-container--row" }

import { ref, onMounted } from 'vue';
import { ErrorResponse } from '@apiture/api-keys-client-sdk';
import { Messages } from '../../constants/messages';
import { showToastr } from '../../services/legacy/toastr.service';
import { updateUser, getUser } from '../../services/legacy/auth.service';
import PartnerDomainInvalidWarningComponent from './partner-domain-invalid-warning.component.vue';
import AppAlertComponent from './app-alert.component.vue';

type MyProfileFormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  birthDate: string;
  email: string;
  phoneNumber: string;
  citizen: boolean;
  certify: boolean;
  termsConditionsAccepted: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'personal-form.component',
  setup(__props) {

const profileFormData = ref<MyProfileFormData>({
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  birthDate: '',
  email: '',
  phoneNumber: '',
  citizen: false,
  certify: false,
  termsConditionsAccepted: false,
});

const loading = ref<boolean>(false);
const user = getUser();

onMounted(() => {
  fillFormData();
});

async function saveProfile() {
  // TODO: update when Okta auth is added to users.
  // Since okta is admin only we don't need to update the profile.
  if (user.auth === 'okta') {
    return;
  }

  if (!profileFormData.value.termsConditionsAccepted) {
    showToastr({
      type: 'error',
      message: 'You must accept terms and conditions.',
    });
    return;
  }
  try {
    loading.value = true;
    await updateUser(getUserPatch());
  } catch (error) {
    const errorResponse = error as ErrorResponse;
    showToastr({
      type: 'error',
      message: errorResponse?._error.message ?? Messages.internalError,
    });
    return;
  } finally {
    loading.value = false;
  }

  fillFormData();

  showToastr({
    type: 'success',
    message: 'You profile has been updated',
  });

  setTimeout(() => {
    window.location.href = '/profile/business';
  }, 3000);
}

function getUserPatch() {
  return {
    ...user,
    ...profileFormData.value,
  };
}

function fillFormData() {
  Object.assign(profileFormData.value, {
    firstName: user.firstName,
    lastName: user.lastName,
    middleName: user.middleName,
    birthDate: user.birthDate,
    suffix: user.suffix,
    termsConditionsAccepted: user.termsConditionsAccepted,
    citizen: user.citizen ? 'Yes' : 'No',
    email: user.email,
    phoneNumber: formatPhoneNumber(user.phoneNumber),
    certify: user.certify,
  });
}

function formatPhoneNumber(phoneNumber: string) {
  const withoutCountryCode = phoneNumber.includes('+1')
    ? phoneNumber.substring(2, user.phoneNumber.length)
    : phoneNumber;

  const areaCode = withoutCountryCode.substring(0, 3);
  const prefix = withoutCountryCode.substring(3, 6);
  const lineNumber = withoutCountryCode.substring(6, 10);

  return `${areaCode}-${prefix}-${lineNumber}`;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PartnerDomainInvalidWarningComponent),
    (!profileFormData.value.termsConditionsAccepted || !profileFormData.value.certify)
      ? (_openBlock(), _createBlock(AppAlertComponent, { key: 0 }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" You will not be able to edit existing client applications, view or manage your API keys, or create new client applications until you accept the Terms and Conditions and certify that your profile information is correct. ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_FormKit, {
      id: "profileForm",
      modelValue: profileFormData.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((profileFormData).value = $event)),
      type: "form",
      "submit-label": "Save",
      actions: false,
      "data-test": "profileForm",
      onSubmit: _cache[1] || (_cache[1] = ($event: any) => (saveProfile()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormKit, {
            type: "text",
            name: "firstName",
            label: "First Name",
            validation: "required",
            "outer-class": "form-input--full",
            "data-test": "firstName"
          }),
          _createVNode(_component_FormKit, {
            type: "text",
            name: "middleName",
            label: "Middle Name",
            "outer-class": "form-input--full",
            "data-test": "middleName"
          }),
          _createVNode(_component_FormKit, {
            type: "text",
            name: "lastName",
            label: "Last Name",
            "outer-class": "form-input--full",
            "data-test": "lastName"
          }),
          _createVNode(_component_FormKit, {
            type: "text",
            name: "suffix",
            label: "Suffix",
            "outer-class": "form-input--full",
            "data-test": "suffix"
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormKit, {
            type: "date",
            name: "birthDate",
            label: "Date of Birth",
            validation: "required|date_before",
            "outer-class": "form-input--full",
            "data-test": "birthDate"
          }),
          _createVNode(_component_FormKit, {
            type: "email",
            name: "email",
            label: "Email",
            validation: "required",
            disabled: true,
            "outer-class": "form-input--full",
            "data-test": "email"
          }),
          _createVNode(_component_FormKit, {
            type: "tel",
            name: "phoneNumber",
            label: "Phone number",
            placeholder: "xxx-xxx-xxxx",
            validation: [['matches', '/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/']],
            "validation-messages": {
            matches: 'Phone number must be in the format xxx-xxx-xxxx',
          },
            "validation-visibility": "live",
            "outer-class": "form-input--full",
            "data-test": "phoneNumber"
          })
        ]),
        _createVNode(_component_FormKit, {
          type: "radio",
          name: "citizen",
          label: "Are you a U.S. Citizen?",
          options: ['Yes', 'No'],
          "data-test": "citizen",
          "options-class": "form-input--citizen"
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_FormKit, {
            type: "checkbox",
            label: "I hereby certify, to the best of my knowledge, that the information provided above is complete and correct",
            name: "certify",
            validation: "accepted",
            "validation-visibility": "dirty",
            "help-class": "ms-5",
            help: "This information enables us to better understand our developer community, ensure the security of our APIs and components, and satisfy regulatory obligations. Both providing and then certifying your U.S. citizenship status is a condition to creating applications using our APIs and components. Please note, however, that the value you select for U.S. citizenship status will not limit or affect your ability to create applications and interact with the Apiture Developer Portal.",
            "validation-messages": {
            accepted: 'This field is required.',
          },
            "data-test": "certify"
          }),
          _createVNode(_component_FormKit, {
            type: "checkbox",
            label: "I accept the terms and conditions of the Apiture Open API License Agreement.",
            "sections-schema": {
            label: {
              children: [
                'I accept the ',
                {
                  $el: 'a',
                  attrs: {
                    href: '/docs/Apiture-Open-API-License-Agreement.pdf',
                    target: 'blank',
                  },
                  children: ' terms and conditions ',
                },
                ' of the Apiture Open API License Agreement.',
              ],
            },
          },
            name: "termsConditionsAccepted",
            validation: "accepted",
            "validation-visibility": "dirty",
            "validation-messages": {
            accepted: 'This field is required.',
          },
            "data-test": "termsConditionsAccepted"
          })
        ]),
        _createVNode(_component_FormKit, {
          type: "submit",
          disabled: loading.value,
          label: "Save",
          "data-test": "profileFormSubmit"
        }, null, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})