// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-300de90f] button {
  color: #132334;
  border: 1px solid #132334;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/legacy/clipboard-copy.component.vue","webpack://./src/scss/_variables.scss","webpack://./clipboard-copy.component.vue"],"names":[],"mappings":"AAGA;EACE,cCSkB;EDRlB,yBAAA;EACA,mBAAA;EACA,oBAAA;EAEA,kBAAA;AEHF","sourcesContent":["\n@import 'src/scss/variables';\n\n:deep(button) {\n  color: $app-neutral-black;\n  border: 1px solid $app-neutral-black;\n  border-radius: 1rem;\n  padding: 0.5rem 2rem;\n\n  align-self: center;\n}\n","$app-img-folder-path: '../../img';\n\n$app-navbar-height-md: 90px;\n$app-navbar-height-lg: 112px;\n$app-body-padding-top: 20px;\n$app-footer-height: 46px;\n\n$app-card-header-font-size: 1.1rem;\n$app-card-header-padding-y: 0.5rem;\n\n$app-label-font-size: 14px;\n$app-label-font-weight: 300;\n\n$app-neutral-black: #132334;\n$app-neutral-white: #ffffff;\n$app-error-red: #a71930;\n$app-error-light-red: #f6e8ea;\n$app-apiture-blue: #005a7c;\n$app-link: #18bc9c;\n$app-success-green: #c7f7cd;\n$app-button-hover: #003849;\n\n// Grays\n$app-neutral-gray-0: #fafbfc;\n$app-neutral-gray-10: #f4f5f6;\n$app-neutral-gray-20: #dde0e4;\n$app-neutral-gray-30: #c6ccd2;\n$app-neutral-gray-40: #93a4ba;\n$app-neutral-gray-70: #6c7889;\n$app-neutral-gray-80: #5a6472;\n$app-neutral-gray-90: #48505b;\n\n// Dimensions\n$app-footer-height: 4rem;\n$app-nav-height: 5rem;\n",":deep(button) {\n  color: #132334;\n  border: 1px solid #132334;\n  border-radius: 1rem;\n  padding: 0.5rem 2rem;\n  align-self: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
