<template>
  <div>
    <LoadingIndicatorComponent v-if="!state.initialized" />
    <div class="form-container--row">
      <FormKit
        v-if="!props.keyId"
        id="keyListFilter"
        v-model="keyListFilterData"
        type="group"
        @input="onFilterChange"
      >
        <FormKit
          type="text"
          label="Owner"
          name="owner"
          help="Filter API keys to match only those owned by the named user, as identified by their email address."
          outer-class="form-input--full"
        />
        <FormKit
          type="select"
          label="Environment"
          name="environment"
          help="Filter keys to match only those which deploy in the named environment."
          :options="mapEnvironmentsToOptions(environments)"
          outer-class="form-input--full"
          placeholder="Select an Environment"
        />
        <FormKit
          type="select"
          label="State"
          name="state"
          help="Filter keys to match only those with the selected state."
          :options="['', KeyState.Active, KeyState.Pending, KeyState.Inactive, KeyState.Rejected]"
          outer-class="form-input--full"
          placeholder="Select an Application State"
        />
      </FormKit>
    </div>
    <AppTableComponent
      table-class="data-table mb-0 mt-4"
      primary-key="key._id"
      :fields="generateTableFields()"
      :items="keyData"
      :busy="state.loading"
      striped
    >
      <template #cell(name)="{ row }: { row: { item: { key: Key } } }">
        {{ row.item.key.name }}
      </template>
      <template #cell(type)="{ row }: { row: { item: { key: Key } } }">
        {{ row.item.key.type }}
      </template>
      <template #cell(owner)="{ row }: { row: { item: { key: object } } }">
        {{ 'owner' in row.item.key && row.item.key.owner }}
      </template>
      <template #cell(state)="{ row }: { row: { item: KeyData } }">
        {{ row.item.key.state }}
      </template>
      <template #cell(environmentHost)="{ row }: { row: { item: KeyData } }">
        {{ row.item.key.environmentHost }}
      </template>
      <template #cell(created)="{ row }: { row: { item: KeyData } }">
        {{ row.item.keyCreatedAt }}
      </template>
      <template
        #cell(actions)="{
          row,
        }: {
          row: { item: KeyData, expandDetails: Function, isDetailsExpanded: boolean },
        }"
      >
        <div class="table-actions-btn-group">
          <!-- <span class="fa fa-plus-square"></span> -->
          <FormKit
            v-if="
              row.item.key.state === 'active' &&
                (row.item.key.clientSecret || row.item.key.key || row.item.key.clientId)
            "
            type="button"
            outer-class="align-self-start"
            input-class="btn btn-primary btn-sm"
            @click="row.expandDetails(row)"
          >
            {{ row.isDetailsExpanded ? 'Fewer Details' : 'More Details' }}
          </FormKit>
          <div v-if="props.mode === 'admin'">
            <!-- <i class="fa fa-toggle-on"></i>  -->
            <FormKit
              v-if="row.item.key.state === 'inactive' || row.item.key.state === 'rejected'"
              type="button"
              input-class="btn btn-sm btn-success"
              @click="activateSelectedKey(row.item)"
            >
              Activate
            </FormKit>
            <!-- <i class="fa fa-toggle-off"></i> -->
            <FormKit
              v-if="row.item.key.state === 'active'"
              type="button"
              input-class="btn btn-sm btn-danger"
              @click="revokeKey(row.item)"
            >
              Revoke
            </FormKit>
            <!-- <i class="fa fa-check-circle-o"></i> -->
            <FormKit
              v-if="row.item.key.state === 'pending'"
              type="button"
              input-class="btn btn-sm btn-success"
              @click="approveKey(row.item)"
            >
              Approve
            </FormKit>
            <!-- <i class="fa fa-ban"></i> -->
            <FormKit
              v-if="row.item.key.state !== 'rejected'"
              type="button"
              input-class="btn btn-sm btn-danger"
              @click="rejectSelectedKey(row.item)"
            >
              Reject
            </FormKit>
          </div>
          <template v-else-if="row.item.key.state !== 'rejected'">
            <!-- <i class="fa fa-toggle-on"></i> -->
            <FormKit
              v-if="row.item.key.state === 'inactive' && !!row.item.key._links['apiture:activate']"
              type="button"
              input-class="btn btn-sm btn-success"
              @click="reactivateKey(row.item)"
            >
              Reactivate
            </FormKit>
            <!-- <i class="fa fa-toggle-off"></i>  -->
            <FormKit
              v-if="row.item.key.state !== 'inactive'"
              type="button"
              input-class="btn btn-sm btn-warning"
              @click="deactivateSelectedKey(row.item)"
            >
              Deactivate
            </FormKit>
          </template>
        </div>
      </template>
      <template #row(expanded)="{ row }: { row: { item: KeyData } }">
        <div v-if="row.item.key.key">
          <b>API Key: </b>
          <SensitiveValueComponent
            :id="row.item.key._id + 'apiKey'"
            title="API Key"
            :value="row.item.key.key"
          />
        </div>
        <div v-if="row.item.key.clientId">
          <b>Client ID: </b>
          <SensitiveValueComponent
            :id="row.item.key._id + 'clientId'"
            title="Client ID"
            :value="row.item.key.clientId"
          />
        </div>
        <div v-if="row.item.key.clientSecret">
          <b>Client Secret: </b>
          <SensitiveValueComponent
            :id="row.item.key._id + 'clientSecret'"
            title="Client Secret"
            :value="row.item.key.clientSecret"
          />
        </div>
        <template v-if="row.item.key.serviceConnections">
          <div>
            <b>Queue URI: </b>
            <span class="text-break-all">
              {{ row.item.key.serviceConnections.queueConsumer.uri }}
            </span>
            <ClipboardCopyComponent
              :id="row.item.key._id + 'queueConsumerUri'"
              title="Queue URI"
              :value="row.item.key.serviceConnections.queueConsumer.uri"
            />
          </div>
          <div>
            <b>Queue Access ID: </b>
            <SensitiveValueComponent
              :id="row.item.key._id + 'queueConsumerAccessKeyId'"
              title="Queue Access ID"
              :value="row.item.key.serviceConnections.queueConsumer.accessKeyId"
            />
          </div>
          <div>
            <b>Queue Secret Access Key: </b>
            <SensitiveValueComponent
              :id="row.item.key._id + 'queueConsumerSecretAccessKey'"
              title="Queue Secret Access Key"
              :value="row.item.key.serviceConnections.queueConsumer.secretAccessKey"
            />
          </div>
        </template>
      </template>
    </AppTableComponent>
    <CursorPaginationComponent
      v-if="!props.keyId"
      :next="!!pagination.next"
      :previous="Boolean(pagination.previous.length)"
      :limit="pagination.limit"
      @on-change="onPaginationChange"
    />
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, onMounted, ref } from 'vue';
import { Environment } from '@apiture/api-environments-client-sdk';
import { Key, KeyState, KeyType, ExplorerKey } from '@apiture/api-keys-client-sdk';
import { Subject, from, EMPTY, takeUntil, switchMap, tap, map, catchError } from 'rxjs';
import { Messages } from '../../constants/messages';
import {
  createCursorPagination,
  clearNavState,
  processEvent,
  processLinks,
  CursorPaginationEvent,
  CursorPagination,
} from '../../helpers/cursor-pagination-helper';
import { toLocalDate } from '../../helpers/date-helper';
import {
  activateKey,
  rejectKey,
  deactivateKey,
  getExplorerKeys,
  getKeys,
  getKey,
} from '../../services/api/keys-api.service';
import { showToastr } from '../../services/legacy/toastr.service';
import { confirmDialog } from '../../services/legacy/dialog.service';
import ClipboardCopyComponent from './clipboard-copy.component.vue';
import LoadingIndicatorComponent from './loading-indicator.component.vue';
import SensitiveValueComponent from '../portal/sensitive-value.component.vue';
import CursorPaginationComponent from './cursor-pagination.component.vue';
import AppTableComponent from './app-table.component.vue';
import { useEnvironments, mapEnvironmentsToOptions } from '../../hooks/use-environments.hook';
import { setUrlParams, getIdFromUri, addUrlParamsToObject } from '../../helpers/window-helper';
import { ProductLine } from '../../utils/product-lines';

interface KeyData {
  key: Key;
  keyCreatedAt: string;
  environment: Environment;
}

interface KeyListFilterData {
  owner: string;
  environment: string;
  state: KeyState;
}

const props = withDefaults(
  defineProps<{
    mode?: 'user' | 'admin';
    applicationId?: string;
    keyId?: string;
    type: string;
    productLine: ProductLine;
  }>(),
  { mode: 'user', keyId: '', applicationId: '' },
);

const emit = defineEmits(['onKeyActivated', 'onKeyApproved']);

const keyListFilterData = ref<KeyListFilterData>({
  owner: '',
  environment: null,
  state: null,
});

const state = ref<{
  initialized: boolean;
  loading: boolean;
  saving: boolean;
  openModal: boolean;
}>({
  initialized: false,
  loading: false,
  saving: false,
  openModal: false,
});

const pagination = ref<CursorPagination>(createCursorPagination());

const keyData = ref<KeyData[]>([]);

const environments = useEnvironments(props.productLine);

const searchSubject = new Subject<{
  start: string;
  limit: number;
  application: string;
  environment: string;
  owner: string;
  state: KeyState[];
  productLine: ProductLine;
}>();

const destroySubject = new Subject();

onMounted(() => {
  addUrlParamsToObject<KeyListFilterData>(keyListFilterData.value);

  if (!props.keyId) {
    initSearchHandler();
  }

  state.value.initialized = true;
  refresh();
});

onUnmounted(() => {
  destroySubject.next(null);
  destroySubject.complete();
});

function generateTableFields() {
  const tableFields = ['Name', 'Environment Host', 'Type', 'State', 'Created', 'Actions'];

  if (props.type === 'explorer') {
    tableFields[0] = 'Owner';
    tableFields.splice(2, 1);
  }

  return tableFields;
}

function onFilterChange() {
  clearNavState(pagination.value);
  search();
}

function onPaginationChange(evt: CustomEvent<[CursorPaginationEvent]>) {
  processEvent(pagination.value, evt as CursorPaginationEvent);
  search();
}

async function activateSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to activate <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Activate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully activated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
    emit('onKeyActivated', data.key._id);
  }
}

async function approveKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to approve <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'check-circle-o',
          label: 'Approve',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await activateKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully approved.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
    emit('onKeyApproved', data.key._id);
  }
}

async function rejectSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to reject <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'ban',
          label: 'Reject',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await rejectKey(data.key._id);

      showToastr({
        type: 'success',
        message: `Key has been successfully rejected.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function revokeKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to revoke <b>${data.key.name}</b>?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Revoke',
          class: 'btn-danger',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateKey(data.key._id);
      showToastr({
        type: 'success',
        message: `Key has been successfully revoked.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function reactivateKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Do you want to reactivate API Key for ${data.key.environmentName} environment?`,
      buttons: {
        confirm: {
          faIcon: 'toggle-on',
          label: 'Reactivate',
          class: 'btn-success',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      const key = await activateKey(data.key._id);

      if (key.state !== 'active') {
        showToastr({
          type: 'info',
          message: `We have received your request to restore a deactivated API Key.
            Your API Key has been switched to the Pending state.
            Activation requires Apiture approval and intervention and thus may take some time.`,
        });
      } else {
        showToastr({
          type: 'success',
          message: `API Key has been successfully restored.`,
        });
      }
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

async function deactivateSelectedKey(data: KeyData) {
  if (
    await confirmDialog({
      message: `Please confirm you wish to deactivate API Key for ${data.key.environmentName} environment.
          Deactivation is immediate and users who are using your application in the corresponding target environment will lose access to the application.
          Re-activating a deactivated client application or API key requires manual administrative approval from Apiture.`,
      buttons: {
        confirm: {
          faIcon: 'toggle-off',
          label: 'Deactivate',
          class: 'btn-warning',
        },
      },
    })
  ) {
    state.value.saving = true;
    try {
      await deactivateKey(data.key._id);
      showToastr({
        type: 'success',
        message: `API Key has been successfully deactivated.`,
      });
    } catch {
      showToastr({ type: 'error', message: Messages.internalError });
    }
    state.value.saving = false;
    refresh();
  }
}

function initSearchHandler() {
  searchSubject
    .pipe(
      takeUntil(destroySubject),
      tap(() => {
        state.value.loading = true;
      }),
      switchMap(query =>
        from(props.type === KeyType.Explorer ? getExplorerKeys(query) : getKeys(query)).pipe(
          map(response => {
            return {
              query,
              response,
            };
          }),
          catchError(() => {
            showToastr({ type: 'error', message: Messages.internalError });
            return EMPTY;
          }),
        ),
      ),
      tap(() => {
        for (const paramKey in keyListFilterData.value) {
          if (paramKey === 'isOwner') continue;
          setUrlParams(paramKey, keyListFilterData.value[paramKey]);
        }
        state.value.loading = false;
      }),
    )
    .subscribe(result => {
      fillData(result.response._embedded.items);
      processLinks(pagination.value, result.response._links);
    });
}

function fillData(keys: (Key | ExplorerKey)[]) {
  keyData.value = keys.map(key => {
    const environmentId = getIdFromUri(key._links['apiture:environment']?.href);
    return {
      key,
      keyCreatedAt: toLocalDate(key.createdAt),
      environment: environmentId
        ? environments.value.find(environment => environment._id === environmentId)
        : undefined,
    };
  });
}

function search() {
  searchSubject.next({
    start: pagination.value.start,
    limit: pagination.value.limit,
    application: props.type === 'explorer' ? undefined : props.applicationId,
    environment: keyListFilterData.value.environment,
    owner: keyListFilterData.value.owner?.toLowerCase(),
    state: keyListFilterData.value.state ? [keyListFilterData.value.state] : undefined,
    productLine: props.productLine,
  });
}

async function refresh() {
  if (props.keyId) {
    await loadKey();
  } else {
    search();
  }
}

async function loadKey() {
  state.value.loading = true;
  let key: Key | ExplorerKey;
  try {
    key = await getKey(props.keyId);
  } catch {
    showToastr({ type: 'error', message: Messages.internalError });
    return;
  } finally {
    state.value.loading = false;
  }
  fillData([key]);
}
</script>
