// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-bffc773c] .nav--list #clients-dropdown > button {
  white-space: nowrap;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/portal/navigation.component.vue","webpack://./navigation.component.vue"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;ACAF","sourcesContent":["\n:deep(.nav--list #clients-dropdown > button) {\n  white-space: nowrap;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  color: #fff;\n}\n",":deep(.nav--list #clients-dropdown > button) {\n  white-space: nowrap;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
