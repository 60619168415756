import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { watchEffect } from 'vue';
import { useModalStore } from '../../../hooks/modal-store.hook';


export default /*@__PURE__*/_defineComponent({
  __name: 'modal-container.component',
  setup(__props) {

const modals = useModalStore();

watchEffect(() => {
  if (modals.value.length > 0) {
    document.body.classList.add('disable-scrolling');
    document.documentElement.classList.add('disable-scrolling');
  } else {
    document.body.classList.remove('disable-scrolling');
    document.documentElement.classList.remove('disable-scrolling');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(modals), (modal, i) => {
    return (_openBlock(), _createElementBlock("div", {
      key: `modal-${i.toString()}`,
      class: "modal disable-scrolling",
      tabindex: "-1"
    }, [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(modal.component), _mergeProps({ ref_for: true }, modal.props), null, 16))
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}
}

})