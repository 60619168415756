import { auth, sendMFACode, CognitoMfaRequiredError } from '../../services/cognito.service';

export class MfaConfirmComponent {
  private $modal: JQuery<HTMLElement>;
  private $modalConfirmCode: JQuery<HTMLElement>;
  private $modalConfirmButton: JQuery<HTMLElement>;
  private $modalErrorMessage: JQuery<HTMLElement>;
  private $modalInfoMessage: JQuery<HTMLElement>;

  private confirmCode: string;
  private userName: string;
  private userPassword: string;
  private openResolve: (value: boolean) => void;
  private confirmed = false;

  init(): void {
    this.$modal = $('#mfaConfirmComponent');
    this.$modalConfirmCode = this.$modal.find('[identifier="mfaConfirmCode"]');
    this.$modalConfirmButton = this.$modal.find('[identifier="mfaConfirmBtn"]');
    this.$modalErrorMessage = this.$modal.find('#mfaConfirmErrorMsg');
    this.$modalInfoMessage = this.$modal.find('#mfaConfirmInfoMsg');

    const $modalForm = this.$modal.find('#mfaConfirmForm');
    const $modalResendLink = this.$modal.find('#mfaConfirmResendLink');

    this.$modal.on('hidden.bs.modal', this.onModalHidden.bind(this));
    this.$modalConfirmCode.on('onChange', this.onConfirmCodeChange.bind(this));

    $modalForm.on('submit', this.confirm.bind(this));
    $modalResendLink.click(this.resendConfirmCode.bind(this));
  }

  open(userName: string, userPassword: string): Promise<boolean> {
    this.confirmed = false;
    this.userName = userName;
    this.userPassword = userPassword;

    this.$modal.modal('show');

    return new Promise(resolve => {
      this.openResolve = resolve;
    });
  }

  private onConfirmCodeChange($event: JQueryEventObject): void {
    const originalEvent = $event.originalEvent as CustomEvent<string>;
    this.confirmCode = originalEvent.detail;
    this.$modalConfirmButton.prop('disabled', (!this.confirmCode).toString());
  }

  private async resendConfirmCode(): Promise<void> {
    try {
      await auth(this.userName, this.userPassword);
    } catch (error) {
      if (error instanceof CognitoMfaRequiredError) {
        this.$modalInfoMessage.text('Code successfully resent').removeClass('d-none');
        setTimeout(() => {
          this.$modalInfoMessage.text('').addClass('d-none');
        }, 2000);
      } else {
        this.$modalErrorMessage.text(error.message).removeClass('d-none');
      }
    }

    this.$modalConfirmCode.prop('value', '');
  }

  private onModalHidden(): void {
    this.$modalErrorMessage.text('').addClass('d-none');
    this.confirmCode = undefined;
    this.$modalConfirmCode.prop('value', '');

    this.userName = undefined;
    this.userPassword = undefined;
    if (!this.confirmed) {
      this.openResolve(false);
    }
  }

  private async confirm(): Promise<void> {
    this.$modalErrorMessage.text('').addClass('d-none');

    try {
      this.$modalConfirmButton.prop('active', 'true');
      this.$modalConfirmButton.prop('disabled', 'true');
      await sendMFACode(this.confirmCode);

      this.confirmed = true;
      this.openResolve(true);
      this.$modal.modal('hide');
    } catch {
      this.$modalErrorMessage
        .text('Invalid verification code. Please try again.')
        .removeClass('d-none');
    } finally {
      this.$modalConfirmButton.prop('active', 'false');
      if (this.confirmCode) {
        this.$modalConfirmButton.prop('disabled', 'false');
      }
    }
  }
}
